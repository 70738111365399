import React, { useState } from "react";
import { useResource } from "hooks/useResource";

import { TeacherFAQDto } from "dto/user/teacherfaq.dto";

import Box from "@mui/material/Box";

import { MyButton } from "components/elements/button/MyButton";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { Collapse, IconButton, Link } from "@mui/material";

import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "components/user/teacher/TeacherTypeIcon";
import { TeacherInterfaceEditFAQ } from "./TeacherInterfaceEditFAQ";
import EditIcon from "@mui/icons-material/Edit";
import { LikeDislikeStatistic } from "components/system/rating/LikeDislikeStatistic";
import { ReportAbuse } from "components/support/abuse/ReportAbuse";
import { CommonTools } from "tools/utils/commontools";
import { Types } from "tools/types/types";
import { FlagOutlined } from "@mui/icons-material";

type Props = {
  obj: TeacherFAQDto;
  setObjectWasChanged: any;

  itemIndex: number;
  setObjects: any;
  objects: Array<TeacherFAQDto>;
};

const TeacherInterfaceFAQCard: React.FC<Props> = ({
  obj,
  setObjectWasChanged,
  itemIndex,
  setObjects,
  objects,
}) => {
  const { LL } = useResource();

  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    if (!obj.teacher_fullname) return null;

    return (
      <Box display="flex" alignItems="center" alignContent="center">
        <Box mr={1}>{processTeacherStatus()}</Box>
        <Typography variant="h6">
          {ComponentCommonTools.processObjectField(obj, "teacher_fullname")}
        </Typography>
      </Box>
    );
  };

  const processUserName = () => {
    if (!obj) return null;
    let fullUrl = "";
    if (obj.hasOwnProperty("user_fullurl") && obj.user_fullurl)
      fullUrl = obj.user_fullurl;

    if (!fullUrl) {
      return <Typography className="name">{processUserFullName()}</Typography>;
    }
    return (
      <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
        <Typography className="name">{processUserFullName()}</Typography>
      </Link>
    );
  };

  const processUserFullName = () => {
    if (!obj) return "";
    if (!obj.user_name) return "";
    if (!obj.user_surname) return obj.user_name;

    return obj.user_name + " " + obj.user_surname;
  };
  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("user_name") && obj.user_name) name = obj.user_name;
    if (obj.hasOwnProperty("user_surname") && obj.user_surname)
      surname = obj.user_surname;

    return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />;
  };

  const processTeacherAvatar = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    let name = "";
    let surname = "";
    if (obj.teacher.hasOwnProperty("name") && obj.teacher.name)
      name = obj.teacher.name;
    if (obj.teacher.hasOwnProperty("surname") && obj.teacher.name)
      surname = obj.teacher.surname;

    return (
      <MyAvatar
        obj={obj.teacher}
        name={name}
        surname={surname}
        sx={{ mr: 1 }}
      />
    );
  };

  const processTeacherStatus = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    return <TeacherTypeIcon obj={obj.teacher} />;
  };

  const processUserCard = () => {
    if (!obj) return null;
    return (
      <Box p={2} className="user-message">
        <Box display="flex" alignItems="center">
          {processAvatar()}
          <Box>
            <Typography className="student-name" variant="h6" component={"div"}>
              {processUserName()}
            </Typography>
            <Typography className="data">
              {ComponentCommonTools.processObjectField(obj, "date_name")}
            </Typography>
          </Box>
        </Box>
        <Typography className="message" sx={{ ml: 6, mt: 2 }}>
          {ComponentCommonTools.processObjectField(obj, "message")}
        </Typography>
        <Box>
          <LikeDislikeStatistic
            statistic={TeacherFAQDto.getLikeDislikeMessage(obj)}
          />
        </Box>
      </Box>
    );
  };

  const [extended, setExtended] = useState<boolean>(false);

  const processResponse = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("teacherresponse")) return null;
    if (!obj.teacherresponse) return null;
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Box>{processTeacherAvatar()}</Box>
          <Box>
            <Typography component={"div"}>{processTeacher()}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography sx={{ mt: 2 }}>
            {ComponentCommonTools.processObjectField(obj, "teacherresponse")}
          </Typography>
          <Typography className="data">
            {ComponentCommonTools.processObjectField(
              obj,
              "teacherresponsedate_name"
            )}
          </Typography>
        </Box>

        <Box>
          <LikeDislikeStatistic
            statistic={TeacherFAQDto.getLikeDislikeResponse(obj)}
          />
        </Box>
      </Box>
    );
  };

  const handleResponse = () => {
    setExtended(!extended);
  };
  const processTeacherCard = () => {
    if (!obj) return null;
    return (
      <Box className="teacher-response" px={2} mr={2} ml={8}>
        <Box my={1}>{processResponse()}</Box>
        <MyButton
          cb={handleResponse}
          className="response-btn"
          startIcon={<EditIcon />}>
          {LL("Response")}
        </MyButton>
        <Collapse in={extended} timeout="auto" unmountOnExit>
          {processFormContent()}
        </Collapse>
      </Box>
    );
  };

  const processFormContent = () => {
    if (!obj) return null;
    if (!extended) return null;
    return (
      <TeacherInterfaceEditFAQ
        _obj={obj}
        extended={extended}
        setExtended={setExtended}
        setObjectWasChanged={setObjectWasChanged}
        itemIndex={itemIndex}
        setObjects={setObjects}
        objects={objects}
      />
    );
  };

  const processCourseImage = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    if (!obj.course.hasOwnProperty("gallery")) return null;
    if (!obj.course.gallery) return null;
    if (!obj.course.gallery.length) return null;
    if (!obj.course.gallery[0]) return null;
    if (!obj.course.gallery[0].hasOwnProperty("fileInfoObj")) return null;
    if (!obj.course.gallery[0].fileInfoObj) return null;
    return ComponentCommonTools.getFileBlockImage(
      obj.course.gallery[0].fileInfoObj,
      50,
      50,
      "",
      false,
      { borderRadius: 50 }
    );
  };
  const processCourseName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    let fullUrl = "";
    if (obj.course.hasOwnProperty("fullurl") && obj.course.fullurl)
      fullUrl = obj.course.fullurl;
    if (!fullUrl) {
      return ComponentCommonTools.processObjectFieldOfObject(
        obj,
        "course",
        "name"
      );
    } else {
      return (
        <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
          {ComponentCommonTools.processObjectFieldOfObject(
            obj,
            "course",
            "name"
          )}
        </Link>
      );
    }
  };
  const processCourseInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    return (
      <Box display="flex" p={2} sx={{ alignItems: "center" }}>
        {processCourseImage()}
        <Typography
          variant="h6"
          ml={2}
          className="course-name"
          component={"div"}>
          {processCourseName()}
        </Typography>
      </Box>
    );
  };

  const [openReport, setOpenReport] = useState<boolean>(false);
  const onClickReport = () => {
    setOpenReport(true);
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Box className="box-faq-message" my={2} pb={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "row",
          }}>
          {processCourseInfo()}
          <IconButton onClick={onClickReport}>
            <FlagOutlined />
          </IconButton>
        </Box>
        <Divider />
        {processUserCard()}
        {processTeacherCard()}
        <ReportAbuse
          open={openReport}
          setOpen={setOpenReport}
          parent="teacherfaq"
          idparent={CommonTools.processObjectField(obj, ["id"])}
          typeSender={Types.TYPE_SENDER_TEACHER}
        />
      </Box>
    );
  };

  return processItem();
};

export { TeacherInterfaceFAQCard };
