import React, { useCallback } from "react";
import Drawer from "devextreme-react/drawer";
import { useChat } from "context/chat.context";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import { CommonTools } from "tools/utils/commontools";
import { ChatParticipantList } from "../chatparticipant/ChatParticipantList";
import { Close, GroupOutlined } from "@mui/icons-material";
import { useResource } from "hooks/useResource";
import { Types } from "tools/types/types";
import { ChatAvatar } from "./ChatAvatar";
import { useResponsive } from "hooks/useResponsive";
import { logger } from "tools/utils/logger";

type Props = {
  children: React.ReactNode;
};
const SideBarMenu: React.FC<Props> = ({ children }) => {
  const { openSidebar, setOpenSidebar, selectedChat } = useChat();
  const { LL } = useResource();
  const { matchesCustom4 } = useResponsive();
  const handleDrawerClose = useCallback(() => {
    setOpenSidebar(false);
    return false;
  }, [setOpenSidebar]);

  logger("SideBarMenu", setOpenSidebar);

  if (!selectedChat) return null;
  const isGroup =
    CommonTools.processObjectField(selectedChat, ["type"]).toString() ===
    Types.TYPE_CHAT_GROUP.toString();
  return (
    <Drawer
      opened={openSidebar}
      openedStateMode="overlap"
      position="right"
      revealMode="expand"
      height={"100%"}
      closeOnOutsideClick={matchesCustom4 ? handleDrawerClose : false}
      render={() => {
        return (
          <Box
            className="chatShadow"
            sx={{
              width: 300,
              height: "100%",
              background: "white",
              borderRadius: "10px",
            }}>
            <Box height={"100%"} sx={{ width: "calc(100% )" }} p={3}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}>
                <Box>{/* <Typography>{LL("Information")}</Typography> */}</Box>
                <Box sx={{ display: matchesCustom4 ? "block" : "none" }}>
                  <IconButton onClick={handleDrawerClose}>
                    <Close />
                  </IconButton>
                </Box>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                }}>
                <Box>
                  <ChatAvatar
                    idCourse={CommonTools.processObjectField(selectedChat, [
                      "idcourse",
                    ])}
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography variant="h5">
                      {CommonTools.processObjectField(selectedChat, ["name"])}
                    </Typography>
                  </Box>
                  {isGroup && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"flex-start"}
                      spacing={1}>
                      <GroupOutlined />
                      <Typography>
                        {CommonTools.processObjectField(selectedChat, [
                          "numberofparticipants",
                        ])}
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Box>
              <SideBarMenuList />
            </Box>
          </Box>
        );
      }}>
      {children}
    </Drawer>
  );
};

const SideBarMenuList: React.FC = () => {
  const { selectedChat } = useChat();
  if (!selectedChat) return null;
  const id = CommonTools.processObjectField(selectedChat, ["id"]);
  return (
    <Box>
      <ChatParticipantList idChat={id} />
    </Box>
  );
};
export { SideBarMenu };
