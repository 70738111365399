import { Tab, Tabs } from "@mui/material";
import React from "react";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  value: number;
  setValue: (value: number) => void;
};

const FilterStatusCourseTab: React.FC<Props> = ({ value, setValue }) => {
  const objects = Status.GA("course");

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };
  return (
    <Tabs
      variant="scrollable"
      value={value}
      onChange={handleChange}
      orientation="horizontal"
      scrollButtons
      sx={{
        maxWidth: { xs: "calc(100vw - 32px)", lg: "calc(100vw - 100px)" },
        paddingLeft: 0,
      }}
      allowScrollButtonsMobile
      className="tabVertical profileTeacher">
      {objects.map((object, index) => {
        return (
          <Tab
            className="tabsCourse"
            key={index}
            label={object.label}
            value={object.value}
          />
        );
      })}
    </Tabs>
  );
};

export { FilterStatusCourseTab };
