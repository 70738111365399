import { Box, Grid, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { StudyTypeSelect } from "components/elements/Select/StudyTypeSelect";
import MyTextField from "components/form/mytextfield";
import { StudentDto } from "dto/user/student.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";

type Props = {
  defaultObject: StudentDto;
  onSubmit: (obj: StudentDto) => void;
  loading: boolean;
};

const FormEditStudent: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, , vResult, , , objectWasChanged] =
    useForm<StudentDto>(
      defaultObject,
      RequiredValidator.getValidators(["iduser"])
    );
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <InformationObjectWasChanged objectWasChanged={objectWasChanged} />
      <form onSubmit={handleSubmit}>
        <Box className="generalInfoBox">
          <Grid container className="generalInfoGrid" spacing={2}>
            <Grid item sm={12}>
              <Typography variant="h6">{LL("Institution")}</Typography>
              <Box className="box-form-input-icon-end">
                <MyTextField
                  tabIndex={0}
                  fullWidth
                  id="institution"
                  name="institution"
                  className="form-input"
                  variant="standard"
                  _label={LL("institution")}
                  size="small"
                  value={obj.institution}
                  setObj={setObjField}
                  _vresults={vResult}
                />
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6">{LL("Type studies")}</Typography>
              <Box className="labelRemove">
                <StudyTypeSelect
                  value={obj.idtypestudies}
                  field="idtypestudies"
                  setObjectField={setObjField}
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h6">{LL("Biografie")}</Typography>
              <MyCKEditor
                data={obj.bio ? obj.bio : ""}
                setObj={setObjField}
                name="bio"
                field="bio"
              />
            </Grid>
          </Grid>

          <Box textAlign="center" mt={4}>
            <MyButton
              disabled={disabled || loading}
              color="primary"
              className="btn"
              variant="contained"
              size="large"
              type="submit"
              sx={{ gap: "4px" }}>
              <SaveOutlinedIcon /> {LL("Bt_Submit_changes")}
            </MyButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { FormEditStudent };
