import React from "react";

import { FilterDictionaryDto } from "dto/course/filterdictionary.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import { FilterDictionaryService } from "services/course/filterdictionary.service";

import { Box, Skeleton } from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import MyCheckboxField from "components/form/mycheckboxfield";

import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";

type Props = {
  currentRoute: any;
  idFilter: string;
  setFilters: any;
  filters: Array<RequestCriteriaDTO>;
};

const service = new FilterDictionaryService();

const FilterDictionaryItem: React.FC<Props> = ({
  idFilter,
  setFilters,
  filters,
}) => {
  //   const [isLoading, setIsLoading] = useState(true);
  //   const [objects, setObjects] = useState<Array<FilterDictionaryDto>>([]);

  //   const getList = () => {
  //     setIsLoading(true);
  //     const reqList = new RequestListDTO();
  //     reqList.onpage = 50;
  //     reqList.page = 1;
  //     reqList.filters = [];

  //     let f: RequestFilterDTO;

  //     f = new RequestFilterDTO();
  //     f.field = "status";
  //     f.values = [];
  //     f.values.push(Status.ACTIVE.toString());
  //     reqList.filters.push(f);

  //     f = new RequestFilterDTO();
  //     f.field = "idfilter";
  //     f.values = [];
  //     f.values.push(idFilter);
  //     reqList.filters.push(f);

  //     filterDictionaryService.getList(loadObjects, {}, reqList);

  //     // setIsLoading(false);
  //   };

  //   const loadObjects = (result: ResultListDTO) => {
  //     if (!result) return;
  //     if (!result.err) {
  //       const objects = result.objects ? result.objects : [];
  //       // const total = result.total !== undefined ? result.total : -1;
  //       // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
  //       // const reqInfo = result.requestinfo ? result.requestinfo : {};
  //       setObjects(objects);
  //       // setTotal(total);
  //       // setTotalPage(totalPage);
  //       // setResponseParams(reqInfo);
  //     }
  //   };

  //   const checkLoading = () => {
  //     let load = false;

  //     // if (total === -1) return true;
  //     // if (totalPage === -1) return true;
  //     if (!objects) load = true;
  //     if (objects.length === 0) load = true;
  //     // if (Object.keys(responseParams).length === 0) return true;

  //     setIsLoading(load);
  //   };

  //   useEffect(() => {
  //     getList();
  //   }, [idFilter]);

  //   useEffect(() => {
  //     checkLoading();
  //   }, [objects]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idFilter) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<FilterDictionaryDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idfilter", [idFilter]),
      ],
      1,
      -1
    ),
    [idFilter],
    true,
    `filter_dictionary_${idFilter}`
  );

  const onChange = (event: any) => {
    if (event.target.checked) {
      addFilter(event.target.value);
    }
    if (!event.target.checked) {
      removeFilter(event.target.value);
    }
  };

  const removeFilter = (id: string) => {
    if (!id || !filters || !idFilter) return;

    const newFilters = [...filters];
    const filterItem = newFilters.find((f) => f.id === idFilter);

    if (filterItem) {
      filterItem.values =
        filterItem.values?.filter((value) => value !== id) || [];
      if (filterItem.values.length === 0) {
        const index = newFilters.indexOf(filterItem);
        if (index > -1) {
          newFilters.splice(index, 1);
        }
      }
    }

    setFilters(newFilters);
  };

  const addFilter = (id: string) => {
    if (!id || !filters || !idFilter) return;

    const newFilters = [...filters];
    const filterItem = newFilters.find((f) => f.id === idFilter);

    if (filterItem) {
      filterItem.values = [...(filterItem.values || []), id];
    } else {
      const criteria = new RequestCriteriaDTO();
      criteria.id = idFilter;
      criteria.values = [id];
      newFilters.push(criteria);
    }

    setFilters(newFilters);
  };

  const processChecked = (id: string): boolean => {
    if (!id || !filters || !idFilter) return false;
    const filterItem = filters.find((f) => f.id === idFilter);
    if (filterItem && filterItem.values && filterItem.values.includes(id)) {
      return true;
    }
    return false;
  };

  const processFilterDictionaryItem = (
    obj: FilterDictionaryDto,
    index: number
  ) => {
    if (!obj) return null;
    if (!obj.id) return null;
    const checked = processChecked(obj.id);
    return (
      <Box key={index}>
        <MyCheckboxField
          _label={ComponentCommonTools.processObjectField(obj, "name")}
          setObj={(value: any) => {}}
          checked={checked}
          onChange={onChange}
          value={obj.id}
          field="idfilterdictionary"
          color="primary"
          size="small"
          _vresults={undefined}
        />
      </Box>
    );
  };

  const processFilterDictionaryList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <React.Fragment>
        {objects.map((item: FilterDictionaryDto, i: number) => {
          return processFilterDictionaryItem(item, i);
        })}
      </React.Fragment>
    );
  };

  return loading ? (
    <Skeleton />
  ) : (
    <Box>
      <Box>{processFilterDictionaryList()}</Box>
    </Box>
  );
};

export { FilterDictionaryItem };
