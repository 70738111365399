import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";

import { ChatDto } from "dto/course/chat.dto";
import { useAuth } from "hooks/useAuth";
import { useSocketMessage, useSocketSendMessage } from "hooks/useSocketMessage";

import React, {
  useReducer,
  useCallback,
  useContext,
  useEffect,
} from "react";

import { ChatService } from "services/course/chat.service";
import { Types } from "tools/types/types";

import { CommonTools } from "tools/utils/commontools";
import { logger } from "tools/utils/logger";
import { RouteTools } from "tools/utils/routetools";

interface ChatState {
  chats: Array<ChatDto>;
  total: number;
  totalPages: number;
  page: number;
  loading: boolean;
  selectedChat: ChatDto | null;
  openSidebar: boolean;
}

interface ChatActions {
  setPage: (page: number) => void;
  getList: (actionFunction: () => void, callbackAction: () => void) => void;
  handleSetPage: (page: number) => void;
  selectChat: (chat: ChatDto | null) => void;
  setOpenSidebar: (open: boolean) => void;
  getChatById: () => void;
}

export const ChatContext = React.createContext({
  state: {} as ChatState,
  actions: {} as ChatActions,
});

interface ProviderProps {
  children: React.ReactNode;
  currentRoute: any;
}

interface Action {
  type: string;
  payload?: any;
}

const reducer = (state: ChatState, action: Action) => {
  switch (action.type) {
    case "set_page": {
      return {
        ...state,
        page: action.payload,
      };
    }
    case "set_loading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "set_selected_chat": {
      return {
        ...state,
        selectedChat: action.payload,
        openSidebar: false,
      };
    }
    case "set_data_server": {
      const chats: Array<ChatDto> = action.payload.data;
      const newArray = [...state.chats, ...chats];
      return {
        ...state,
        chats: ChatDto.sortChatsByDate(ChatDto.uniqueArray(newArray)),
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    }

    case "set_open_sidebar": {
      return {
        ...state,
        openSidebar: action.payload,
      };
    }
    case "update_chat": {
      const chat = action.payload;
      let chats = state.chats.map((v) => {
        if (v.id === chat.id) {
          return chat;
        }
        return v;
      });
      chats = ChatDto.sortChatsByDate(ChatDto.uniqueArray(chats));
      if (state.selectedChat && state.selectedChat.id === chat.id) {
        return {
          ...state,
          chats,
          selectedChat: chat,
        };
      }

      return {
        ...state,
        chats: chats,
      };
    }
    case "add_chat": {
      const chat = action.payload;
      const chats = state.chats;
      chats.push(chat);
      return {
        ...state,
        chats: ChatDto.sortChatsByDate(ChatDto.uniqueArray(chats)),
        selectedChat: chat,
      };
    }
    case "reset_state": {
      return {
        ...state,
        chats: [],
        total: 0,
        totalPages: 0,
        page: 1,
        loading: false,
        selectedChat: null,
        openSidebar: false,
      };
    }

    default:
      return state;
  }
};

const service = new ChatService();

export const ChatProvider: React.FC<ProviderProps> = ({
  children,
  currentRoute,
}) => {
  const { user, userInterface } = useAuth();
  
  const idUser = CommonTools.processObjectField(user, ["id"]);
  const mainObject =
    userInterface === Types.STUDENT_INTERFACE ? "profile" : "teacherinterface";
  const idChat = CommonTools.getIdFromPath(currentRoute, mainObject, 0, 2);

  const [state, dispatch] = useReducer(reducer, {
    chats: [],
    total: 0,
    totalPages: 0,
    page: 1,
    loading: false,
    selectedChat: null,
    openSidebar: false,
  });

  const [sendMessage] = useSocketSendMessage();
  useEffect(() => {
    if (!state.chats) return;
    if (!state.chats.length) return;
    state.chats.forEach((v) => {
      if (!v.id) return;
      sendMessage("register-chat-room", { chatId: v.id });
    });

    return () => {
      if (!state.chats) return;
      if (!state.chats.length) return;
      state.chats.forEach((v) => {
        if (!v.id) return;
        sendMessage("unregister-chat-room", { chatId: v.id });
      });
    };
  }, [state.chats, sendMessage]);

  const onMessage = (data: any) => {
    if (!data) return;
    if (!data.id) return;
    dispatch({ type: "update_chat", payload: data });
  };
  useSocketMessage("update-chat-date", onMessage);

  const getChatById = useCallback(() => {
    if (!idChat) return;
    if (state.selectedChat) return;
    service.get(idChat, handleGetChatById, {});
  }, [state.selectedChat, idChat]);

  const handleGetChatById = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    logger("addChat", result.obj, dispatch);

    dispatch({ type: "add_chat", payload: result.obj });
  };

  useEffect(() => {
    return () => {
      dispatch({ type: "reset_state" });
    };
  }, []);

  const setOpenSidebar = useCallback((open: boolean) => {
    dispatch({ type: "set_open_sidebar", payload: open });
  }, []);

  const selectChat = (chat: ChatDto | null) => {
    dispatch({ type: "set_selected_chat", payload: chat });
    if (chat) {
      RouteTools.setHistory(
        `/${mainObject}/message/${CommonTools.processObjectField(chat, [
          "id",
        ])}`,
        {}
      );
    } else {
      RouteTools.setHistory(`/${mainObject}/message`, {});
    }
  };
  const setPage = (page: number) => {
    dispatch({ type: "set_page", payload: page });
  };

  const handleSetPage = useCallback(
    (page: number) => {
      logger("ChatProvider", page, state.totalPages, state.loading);
      if (state.loading) return;
      if (page > state.totalPages) return;
      if (state.total === state.chats.length) return;
      setPage(page);
    },
    [state.loading, state.totalPages, state.total, state.chats]
  );

  const getList = useCallback(
    (actionFunction: () => void, callbackAction: () => void) => {
      if (!idUser) return;
      dispatch({ type: "set_loading" });
      actionFunction();
      service.getList(
        handleSetServerData,
        { cb: callbackAction },
        new RequestListDTO(
          [RequestFilterDTO.prepareFilter("iduser", [idUser])],
          state.page,
          1,
          [RequestSortCriteriaDTO.prepareSortCriteria("dateupdated", false)]
        )
      );
    },
    [state.page, idUser]
  );

  const handleSetServerData = (result: ResultListDTO, cbParams?: any) => {
    if (!result) return;
    if (result.err) return;
    const objects: Array<ChatDto> = result.objects ?? [];
    const total: number = result.total ?? 0;
    const totalPages: number = result.totalpages ?? 0;

    dispatch({
      type: "set_data_server",
      payload: {
        data: objects,
        total,
        totalPages,
      },
    });
    if (cbParams && cbParams.cb) cbParams.cb();
  };

  useEffect(() => {
    getChatById();
  }, [getChatById]);

  return (
    <ChatContext.Provider
      value={{
        state: { ...state },
        actions: {
          setPage,
          getList,
          handleSetPage,
          selectChat,
          setOpenSidebar,
          getChatById,
        },
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = (): ChatState & ChatActions => {
  const { state, actions } = useContext(ChatContext);
  
  return {
    ...state,
    ...actions,
  };
};
