import Idto from "interfaces/idto.interface";

export class GeneralTeacherFAQRatingDto implements Idto {
  idteacherfaq: string;
  iduser: string;

  constructor(idteacherfaq: string, iduser: string) {
    this.idteacherfaq = idteacherfaq;
    this.iduser = iduser;
  }

  
}


