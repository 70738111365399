import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/utils/commontools";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";

import { CourseService } from "services/course/course.service";
import { CoursePageTopBlock } from "components/course/course/CoursePageTopBlock";
import { HtmlTag } from "components/form/HtmlTag";
import { useResource } from "hooks/useResource";
import { CourseLessonsList } from "components/course/lesson/CourseLessonsList";
import { TeacherInfoBlock } from "components/user/teacher/TeacherInfoBlock";
import { CourseVideo } from "components/course/course/CourseVideo";

import { CourseTestimonials } from "components/course/course/CourseTestimonials";
import { CourseListByTeacher } from "components/course/course/CourseListByTeacher";
import { NewsletterBlock } from "components/general/NewsletterBlock";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DateTools from "tools/utils/date.tools";
import { TeacherFAQBlock } from "components/user/teacher/TeacherFAQBlock";
import { CourseEnrollmentDialog } from "components/course/courseenrollment/CourseEnrollmentDialog";

import { SkeletonBanner2 } from "components/elements/Skeleton/Skeleton";

import {
  SkeletonDescription,
  SkeletonCourseLessons,
  SkeletonScnReviewsVertical,
  SkeletonTeacherFAQ,
  SkeletonTeacherProfile,
} from "components/elements/Skeleton/SkeletonCourse";
import { CallbackType } from "interfaces/commontypes.interface";
import useObject from "hooks/useObject";

const service = new CourseService();
const CoursePage: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { LL } = useResource();

  const idCourse = CommonTools.getIdFromPath(currentRoute, "course");
  // const [obj, setObj] = useState<CourseDto | undefined>();
  // const [loading, setLoading] = useState<boolean>(true);

  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.get(id, cb, cbParams);
  };

  const [loading, obj] = useObject<CourseDto>(get, idCourse, [idCourse]);

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : {};
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   if (!idCourse) return;
  //   setLoading(true);
  //   service.get(idCourse, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   if (idCourse !== "") getObject();
  // }, [idCourse]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  const processDescription = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("description")) return null;
    if (!obj.description) return null;
    return (
      <Box className="detailsGeneral" pt={5}>
        <Typography variant="h3">{LL("Description")}</Typography>
        <HtmlTag content={obj.description} />
      </Box>
    );
  };

  const processRequirementsText = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("requirementstext")) return null;
    if (!obj.requirementstext) return null;
    return (
      <Box className="detailsGeneralround" mt={5}>
        <Typography variant="h3">{LL("requirementstext")}</Typography>
        <HtmlTag content={obj.requirementstext} />
      </Box>
    );
  };

  const processWhatWillYouLearnText = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("whatwillyoulearntext")) return null;
    if (!obj.whatwillyoulearntext) return null;
    return (
      <Box className="detailsGeneral" pt={5}>
        <Typography variant="h3">{LL("whatwillyoulearntext")}</Typography>
        <HtmlTag content={obj.whatwillyoulearntext} />
      </Box>
    );
  };

  const processTargetAudienceText = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("targetaudiencetext")) return null;
    if (!obj.targetaudiencetext) return null;
    return (
      <Box className="detailsGeneralround " mt={5}>
        <Typography variant="h3">{LL("targetaudiencetext")}</Typography>
        <HtmlTag content={obj.targetaudiencetext} />
      </Box>
    );
  };

  const getCourseDuration = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("courseduration")) return 0;
    if (!obj.courseduration) return 0;
    return DateTools.displayTimestampInHHMM(obj.courseduration);
  };
  const getNumberOfLessons = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("numberoflessons")) return 0;
    if (!obj.numberoflessons) return 0;
    return obj.numberoflessons;
  };

  const processLessonsContentHeader = () => {
    if (!obj) return null;
    if (!obj.id) return null;

    const lessonNumber = getNumberOfLessons();
    const courseDuration = getCourseDuration();

    return (
      <>
        <Typography variant="h3" mt={5}>
          {LL("CourseContent")}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          my={3}
          alignItems="center"
          className="courseGeneralData">
          <Typography>
            <span>{LL("LessonsNumber")}</span> {lessonNumber}
          </Typography>
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} color="primary" />
          <Typography>
            <span>{LL("CourseDuration")}</span> {courseDuration}
          </Typography>
        </Stack>
      </>
    );
  };

  const processLessonsList = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    return (
      <Box id="lesson-block">
        <Box>{processLessonsContentHeader()}</Box>
        <Box>
          <CourseLessonsList idCourse={obj.id} />
        </Box>
      </Box>
    );
  };

  const processTeacherInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("idteacher")) return null;
    if (!obj.idteacher) return null;
    return (
      <Box mt={5}>
        <Typography variant="h3" mb={3}>
          {LL("TeacherInfo")}
        </Typography>
        <TeacherInfoBlock id={obj.idteacher} />
      </Box>
    );
  };

  const processCourseVideo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("video")) return null;
    if (!obj.video) return null;
    if (!obj.video.length) return null;

    // let videoWidth = 0;
    // let videoHeight = 0;

    // if (downSM) {
    //   videoWidth = 320;
    //   videoHeight = 180;
    // } else if (downMD) {
    //   videoWidth = 420;
    //   videoHeight = 1235;
    // } else {
    //   videoWidth = 640;
    //   videoHeight = 360;
    // }

    // logger("videoWidth", videoWidth);
    // logger("videoHeight", videoHeight);

    return (
      <Box mt={5}>
        <Typography variant="h3" mb={3}>
          {LL("CourseVideo")}
        </Typography>
        <Stack
          sx={{ width: "100%" }}
          direction={{ sm: "column", xl: "row" }}
          spacing={2}
          alignItems="center">
          <Box className="lessonExampleCourse">
            <CourseVideo obj={obj} width={400} height={235} controls={true} />
          </Box>
          <Box className="videoShortDescript">
            <InfoOutlinedIcon color="primary" sx={{ fontSize: "30px" }} />
            <Typography>{LL("CourseVideo description alert video")}</Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

  const processCourseTestimonials = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;

    return (
      <Box mt={5}>
        <Typography variant="h3">{LL("Testimonials")}</Typography>
        <CourseTestimonials idCourse={obj.id} />
      </Box>
    );
  };
  const processOtherCoursesByTeacher = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    if (!obj.hasOwnProperty("idteacher")) return null;
    if (!obj.idteacher) return null;
    if (!obj.hasOwnProperty("teacher")) return null;
    if (!obj.teacher) return null;

    return (
      <Box mb={5}>
        <CourseListByTeacher
          idTeacher={obj.idteacher}
          idCourse={obj.id}
          teacher={obj.teacher}
        />
      </Box>
    );
  };

  const processTopBlock = () => {
    if (!obj) return null;

    return (
      <CoursePageTopBlock
        obj={obj}
        loading={loading}
        setOpenEnrollmentDialog={setOpenEnrollmentDialog}
        openEnrollmentDialog={openEnrollmentDialog}
      />
    );
  };

  const processTeacherFAQ = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    if (!obj.hasOwnProperty("idteacher")) return null;
    if (!obj.idteacher) return null;

    return <TeacherFAQBlock idCourse={obj.id} idTeacher={obj.idteacher} />;
  };

  const [openEnrollmentDialog, setOpenEnrollmentDialog] =
    useState<boolean>(false);

  return idCourse && !loading && obj ? (
    <Box className="detailCourse">
      {processTopBlock()}
      <Box className="container">
        <Grid container>
          <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
            {processWhatWillYouLearnText()}
            {processDescription()}
            {processLessonsList()}
            {processRequirementsText()}
            {processTargetAudienceText()}
            {processCourseVideo()}
            {processTeacherInfo()}
            {processCourseTestimonials()}
            {processTeacherFAQ()}
            {processOtherCoursesByTeacher()}
          </Grid>
          {/* <Grid item md={12}>
            {processRequirementsText()}
            {processTargetAudienceText()}
            {processCourseVideo()}
            {processTeacherInfo()}
            {processCourseTestimonials()}
            {processTeacherFAQ()}
            {processOtherCoursesByTeacher()}
          </Grid> */}
        </Grid>
      </Box>
      <NewsletterBlock currentRoute={currentRoute} />
      <CourseEnrollmentDialog
        open={openEnrollmentDialog}
        setOpen={setOpenEnrollmentDialog}
        course={obj}
      />
    </Box>
  ) : (
    <React.Fragment>
      <SkeletonBanner2 width={368} />
      <SkeletonDescription lines={2} />
      <SkeletonDescription lines={3} />
      <SkeletonCourseLessons lines={2} />
      <SkeletonDescription lines={2} />
      <SkeletonDescription lines={2} />
      <SkeletonTeacherProfile />
      <SkeletonDescription lines={2} />
      <SkeletonScnReviewsVertical lines={3} />
      <SkeletonTeacherFAQ lines={4} />
    </React.Fragment>
  );
};

export { CoursePage };
