import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { CoursePriceDto } from "dto/course/courseprice.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";
import { SelectRecommendationPrice } from "../recommendationprice/SelectRecommendationPrice";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  defaultObj: CoursePriceDto;
  onSubmit: (obj: CoursePriceDto) => void;
  loading: boolean;
  canEdit: boolean;
};

const FormCoursePrice: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  canEdit,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject, , , , objectWasChanged] =
    useForm<CoursePriceDto>(
      defaultObj,
      RequiredValidator.getValidators(["idrecommendationprice"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  if (!obj.idpricepolicy) return null;
  return (
    <Box>
      <InformationObjectWasChanged
        objectWasChanged={
          objectWasChanged && CommonTools.processObjectField(obj, ["id"]) !== ""
        }
      />
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Recommendation price")}
          </Typography>
          <SelectRecommendationPrice
            setObjectField={setObjField}
            value={obj.idrecommendationprice ?? ""}
            idPricePolicy={obj.idpricepolicy}
          />
        </Box>
        <Box textAlign="center" mt={4}>
          <MyButton
            tabIndex={1}
            disabled={disabled || loading || !canEdit}
            color="primary"
            variant="contained"
            size="large"
            className="btn"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCoursePrice };
