import { Box, Typography } from "@mui/material";
import { ChatMessageDto } from "dto/course/chatmessage.dto";

import React from "react";

import { ChatMessageItemAvatar } from "./ChatMessageItemAvatar";
import DateTools from "tools/utils/date.tools";
import { ChatMessageItemContent } from "./ChatMessageItem";

type Props = {
  object: ChatMessageDto;
  displayAvatar: boolean;
  customComponentAvatar?: React.ReactNode;
};

const ChatMessageItemStranger: React.FC<Props> = ({
  object,
  displayAvatar,
  customComponentAvatar = null,
}) => {
  if (!object) return null;
  return (
    <Box
      sx={{
        margin: 1,

        display: "flex",
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 1,
        marginLeft: displayAvatar ? 2 : 8,
      }}>
      {displayAvatar && (
        <Box>
          {customComponentAvatar ? (
            customComponentAvatar
          ) : (
            <ChatMessageItemAvatar object={object} />
          )}
        </Box>
      )}
      <Box
        sx={{
          padding: "11px",
          borderRadius: "5px",
          backgroundColor: "#E8FAFF",
        }}>
        <ChatMessageItemContent object={object} />
      </Box>
      <Box>
        <Typography className="dateChat">
          {DateTools.displayTimestampInTime(object.date)}
        </Typography>
      </Box>
    </Box>
  );
};

export { ChatMessageItemStranger };
