import React from "react";
import { TeacherFAQDto } from "dto/user/teacherfaq.dto";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { Box, Typography } from "@mui/material";

import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { CommonTools } from "tools/utils/commontools";
import { LikeDislikeTeacherFAQ } from "components/system/teacherfaqrating/LikeDislikeTeacherFAQ";
import { TeacherFAQMessageRatingService } from "services/system/teacherfaqmessagerating.service";
import { TeacherFAQResponseRatingService } from "services/system/teacherfaqresponserating.service";

type Props = {
  obj: TeacherFAQDto;
};
const serviceMessage = new TeacherFAQMessageRatingService();
const serviceResponse = new TeacherFAQResponseRatingService();
const TeacherFAQCard: React.FC<Props> = ({ obj }) => {
  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    if (!obj.teacher_fullname) return null;
    const fullUrl = CommonTools.processObjectField(obj, ["teacher", "fullurl"]);

    if (!fullUrl) {
      return (
        <Box display="flex" alignItems="center">
          <Box mr={1}>{processTeacherStatus()}</Box>
          <Typography className="teacher-name">
            {CommonTools.processObjectField(obj, ["teacher_fullname"])}
          </Typography>
          <Typography className="data">
            {CommonTools.processObjectField(obj, ["teacherresponsedate_name"])}
          </Typography>
        </Box>
      );
    }
    return (
      <MyNavBt href={fullUrl} className="no-link">
        <Box display="flex" alignItems="center">
          <Box mr={1}>{processTeacherStatus()}</Box>
          <Typography className="teacher-name">
            {CommonTools.processObjectField(obj, ["teacher_fullname"])}
          </Typography>
          <Typography className="data">
            {CommonTools.processObjectField(obj, ["teacherresponsedate_name"])}
          </Typography>
        </Box>
      </MyNavBt>
    );
  };

  const processUserName = () => {
    if (!obj) return null;
    let fullUrl = "";
    if (obj.hasOwnProperty("user_fullurl") && obj.user_fullurl)
      fullUrl = obj.user_fullurl;

    if (!fullUrl) {
      return <Typography className="name">{processUserFullName()}</Typography>;
    }
    return (
      <MyNavBt href={fullUrl}>
        <Typography className="name">{processUserFullName()}</Typography>
      </MyNavBt>
    );
  };

  const processUserFullName = () => {
    if (!obj) return "";
    if (!obj.user_name) return "";
    if (!obj.user_surname) return obj.user_name;

    return obj.user_name + " " + obj.user_surname;
  };
  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("user_name") && obj.user_name) name = obj.user_name;
    if (obj.hasOwnProperty("user_surname") && obj.user_surname)
      surname = obj.user_surname;

    return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />;
  };

  const processTeacherAvatar = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    let name = "";
    let surname = "";
    if (obj.teacher.hasOwnProperty("name") && obj.teacher.name)
      name = obj.teacher.name;
    if (obj.teacher.hasOwnProperty("surname") && obj.teacher.name)
      surname = obj.teacher.surname;

    return (
      <MyAvatar
        obj={obj.teacher}
        name={name}
        surname={surname}
        sx={{ mr: 1 }}
      />
    );
  };

  const processTeacherStatus = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    return <TeacherTypeIcon obj={obj.teacher} />;
  };
  const processUserCard = () => {
    if (!obj) return null;
    return (
      <Box className="user-message" p={2}>
        <Box display="flex">
          {processAvatar()}
          <Box>
            <Box className="student-name">{processUserName()}</Box>
            <Typography className="data">
              {CommonTools.processObjectField(obj, ["date_name"])}
            </Typography>
          </Box>
        </Box>
        <Typography my={2} ml={6} className="message">
          {CommonTools.processObjectField(obj, ["message"])}
        </Typography>
        <Box>
          <LikeDislikeTeacherFAQ
            id={CommonTools.processObjectField(obj, ["id"])}
            service={serviceMessage}
            statistic={TeacherFAQDto.getLikeDislikeMessage(obj)}
          />
        </Box>
      </Box>
    );
  };

  const processTeacherCard = () => {
    if (!obj) return null;
    return (
      <Box className="teacher-response" px={2} ml={8}>
        <Box display="flex" alignItems="center">
          {processTeacherAvatar()}
          {processTeacher()}
        </Box>
        <Box>
          <Typography sx={{ mt: 2 }}>
            {CommonTools.processObjectField(obj, ["teacherresponse"])}
          </Typography>
        </Box>
        <Box>
          <LikeDislikeTeacherFAQ
            id={CommonTools.processObjectField(obj, ["id"])}
            service={serviceResponse}
            statistic={TeacherFAQDto.getLikeDislikeResponse(obj)}
          />
        </Box>
      </Box>
    );
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Box className="box-faq-message on-site" my={2} pb={2}>
        {processUserCard()}
        {processTeacherCard()}
      </Box>
    );
  };

  return processItem();
};

export { TeacherFAQCard };
