import React, { useCallback, useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseService } from "services/course/course.service";
import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/utils/routetools";
import RequestListDTO from "dto/app/requestlist.dto";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import Skeleton from "@mui/material/Skeleton";
import { Box, IconButton, Typography } from "@mui/material";

import Stack from "@mui/material/Stack";

import { MyNavBt } from "components/elements/button/MyNavBt";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MyPagination } from "components/elements/MyPagination/MyPagination";

import { MySortSelect } from "components/elements/Select/MySortSelect";

import { useAuth } from "hooks/useAuth";

import { TeacherWithoutCourses } from "./TeacherWithoutCourses";
import { TeacherCourseCard } from "components/course/course/TeacherCourseCard";
import MyTextField from "components/form/mytextfield";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { NoResult } from "components/general/NoResult";
import { SkeletonCourseList } from "components/elements/Skeleton/SkeletonTeacherPage";

import { FilterStatusCourseTab } from "./FilterStatusCourseTab";
import { Status } from "tools/types/status";
import { Clear } from "@mui/icons-material";
import { useCookie } from "hooks/useCookie";

const service = new CourseService();

const TeacherCoursePage: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { LL, CC } = useResource();
  const { teacher } = useAuth();
  const mainUrl =
    currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";

  const {
    cookies,
    setCookieValue,
    getCookie,
    getCookieObject,
    setCookieObject,
  } = useCookie();
  const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
  const defaultPage = reqListRoute.page ? reqListRoute.page : 1;
  const defaultSortCriteria =
    reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0
      ? reqListRoute.sortcriteria
      : [
          getCookieObject(
            "default_sort_course_teacher",
            RequestSortCriteriaDTO.prepareSortCriteria(
              "popularidentifier",
              false
            )
          ),
        ];

  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<CourseDto>>([]);
  const [page, setPage] = useState<number>(defaultPage);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});

  const [sortCriteria, setSortCriteria] =
    useState<Array<RequestSortCriteriaDTO>>(defaultSortCriteria);

  const updateObjects = (obj: CourseDto) => {
    const index = objects.findIndex((o) => o.id === obj.id);
    if (index === -1) return;
    objects[index] = obj;
    setObjects([...objects]);
  };
  const handleSetSortCriteria = (criteria: Array<RequestSortCriteriaDTO>) => {
    setSortCriteria(criteria);
    setCookieObject("default_sort_course_teacher", criteria[0]);
  };
  const [search, setSearch] = useState(
    getDefaultSearch(reqListRoute, "search")
  );

  const [searchReq, setSearchReq] = useState(
    getDefaultSearch(reqListRoute, "search")
  );
  const [statusFilter, setStatusFilter] = useState<number>(
    parseInt(
      getDefaultSearch(
        reqListRoute,
        "status",
        getCookie(
          "default_status_course_teacher",
          Status.COURSE_PUBLISHED.toString()
        )
      )
    )
  );

  const changeStatusFilter = (status: number) => {
    setCookieValue("default_status_course_teacher", status.toString());
    setStatusFilter(status);
    setPage(1);
  };
  const getList = useCallback(() => {
    const reqList = new RequestListDTO();
    reqList.onpage = parseInt(CC("CourseListOnPage", "10"));
    reqList.page = page;
    reqList.filters = [];
    reqList.sortcriteria = sortCriteria;

    let f: RequestFilterDTO;

    if (teacher && teacher.id) {
      f = new RequestFilterDTO();
      f.field = "idteacher";
      f.values = [teacher.id];
      reqList.filters.push(f);

      f = new RequestFilterDTO();
      f.field = "search";
      f.values = [searchReq];
      reqList.filters.push(f);

      f = new RequestFilterDTO();
      f.field = "status";
      f.values = [statusFilter.toString()];
      reqList.filters.push(f);

      const urlPart = RouteTools.prepareListLocation(reqList);
      setIsLoading(true);
      RouteTools.setHistory(mainUrl[0] + urlPart, {});
      service.getListForTeacher(loadObjects, {}, reqList);
    }
  }, [page, sortCriteria, searchReq, statusFilter, teacher]);

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };
  const checkLoading = useCallback(() => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  }, [objects, total, totalPage, responseParams]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processPagination = () => {
    if (totalPage === -1) return null;
    if (total === -1) return null;
    if (objects.length === 0) return null;

    return (
      <Box
        className="container"
        py={4}
        display="flex"
        sx={{ justifyContent: "center" }}>
        <MyPagination
          page={page}
          total={total}
          totalPage={totalPage}
          setPage={setPage}
        />
      </Box>
    );
  };

  const processSortSelect = () => {
    if (isLoading) return null;

    const objects = [
      "popularidentifier_asc",
      "popularidentifier_desc",
      "name_asc",
      "name_desc",
      "createddate_asc",
      "createddate_desc",
      "updateddate_asc",
      "updateddate_desc",
    ];
    return (
      <Box
        sx={{
          "& label": {
            display: "none",
          },
        }}>
        <MySortSelect
          objects={objects}
          setSortCriteria={handleSetSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"
        />
      </Box>
    );
  };

  const processCourseItem = (obj: CourseDto, index: number) => {
    if (!obj) return null;
    return (
      <Box key={index}>
        <TeacherCourseCard
          obj={obj}
          loading={isLoading}
          loadAction={getList}
          updateObject={updateObjects}
        />
      </Box>
    );
  };

  const processCourseList = () => {
    if (!objects.length) return <NoResult identifier="course" />;

    return (
      <React.Fragment>
        {objects.map((item: CourseDto, i: number) => {
          return processCourseItem(item, i);
        })}
      </React.Fragment>
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setPage(1);
    setSearchReq(search);
  };

  const handleClear = () => {
    setSearch("");
    setPage(1);
    setSearchReq("");
  };
  const processSearch = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Box
          sx={{ width: "100%" }}
          className="noPadd form-input-box"
          display="flex"
          alignItems="center">
          <SearchIcon />
          <MyTextField
            className="searchFieldCourse form-input"
            fullWidth
            id="search"
            name="search"
            _label={LL("Search course by name")}
            value={search}
            setObj={(field: any, value: any) => {
              setSearch(value);
            }}
            _vresults={undefined}
          />

          <Box sx={{ width: "40px" }}>
            {search && (
              <IconButton onClick={handleClear}>
                <Clear />
              </IconButton>
            )}
          </Box>
        </Box>
      </form>
    );
  };

  const processPageWithCourse = () => {
    return (
      <Box className="container">
        <Typography variant="h2" textAlign="center" my={4}>
          {LL("Title_page_teacher_my_course")}
        </Typography>
        <Stack direction={"row"} spacing={2} mb={4} useFlexGap flexWrap="wrap">
          <Box
            className="inputTextTeacher"
            sx={{ width: { xs: "100%", sm: "auto" } }}>
            {processSearch()}
          </Box>

          <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
            {processSortSelect()}
          </Box>

          <MyNavBt
            href={"/teacherinterface/addcourse"}
            className="btn btn-add"
            sx={{
              ml: { xs: "0px", md: "auto" },
              width: { xs: "100%", sm: "auto" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {LL("Add_course")}
            <AddIcon sx={{ ml: 1 }} />
          </MyNavBt>
        </Stack>
        <FilterStatusCourseTab
          value={statusFilter}
          setValue={changeStatusFilter}
        />
        {isLoading ? <Skeleton /> : processCourseList()}
        {isLoading ? <Skeleton /> : processPagination()}
      </Box>
    );
  };
  const processPageWithoutCourse = () => {
    return <TeacherWithoutCourses currentRoute={currentRoute} />;
  };

  const processPage = () => {
    if (isLoading) return <SkeletonCourseList lines={6} />;
    if (!teacher) return null;
    if (
      !teacher.hasOwnProperty("numberofcourses") ||
      !teacher.numberofcourses
    ) {
      return processPageWithoutCourse();
    }
    return processPageWithCourse();
  };

  return processPage();
};

export { TeacherCoursePage };

const getDefaultSearch = (
  req: RequestListDTO,
  filter: string,
  defaultValue?: string
) => {
  if (defaultValue === undefined) defaultValue = "";
  if (!req.filters) return defaultValue;
  if (!req.filters.length) return defaultValue;
  const f = req.filters.find((f: RequestFilterDTO) => f.field === filter);
  if (!f) return defaultValue;
  if (!f.values) return defaultValue;
  if (!f.values.length) return defaultValue;
  if (!f.values[0]) return defaultValue;
  return f.values[0];
};
