import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { TeacherRequestBoundary } from "./TeacherRequestBoundary";

const TeacherRequestBlock: React.FC<PageComponentProps> = () => {
  
  return <TeacherRequestBoundary />;
};

export { TeacherRequestBlock };
