import { fi } from "date-fns/locale";
import Idto from "interfaces/idto.interface";
import { Status } from "tools//types/status";
import { Types } from "tools/types/types";

export class TeacherDto implements Idto {
  id?: string;
  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  idssociallink?: string[];
  status?: number;
  idsterm?: string[];
  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  rating?: number;
  name?: string;
  surname?: string;
  typeoccupation?: any;
  typediscipline?: any;
  numberofstudents?: number;
  numberofcourses?: number;
  numberoftestimonials?: number;
  fullurl?: string;
  fullname?: string;
  videolessonexample?: any;
  videopresentation?: any;
  attachmentcv?: any;
  status_name?: string;
  typecollaboration_tmp?: number;
  statusvalidation?: number;
  typecollaboration?: number;
  companyname?: string;
  companyname_tmp?: string;
  cui?: string;
  cui_tmp?: string;
  idaddresscontract?: string;
  idaddresscontract_tmp?: string;
  admin_name?: string;
  admin_name_tmp?: string;
  admin_surname?: string;
  admin_surname_tmp?: string;
  cnp?: string;
  cnp_tmp?: string;
  persona_fiz_name?: string;
  persona_fiz_name_tmp?: string;
  persona_fiz_surname?: string;
  persona_fiz_surname_tmp?: string;

  constructor() {
    this.status = Status.TEACHER_UNVERIFIED;
  }

  static getAvatarColor = (status:string) => {
    if(!status) return "transparent";
    
    switch (parseInt(status.toString())) {
      case Status.TEACHER_UNVERIFIED:
        return "chipUnverified";
      case Status.TEACHER_VERIFIED:
        return "chipVerified";
      case Status.TEACHER_PRO:
        return "chipGradient";
      default:
        return "transparent";

    }
  }
}

export class TeacherContractDto implements Idto {
  id?: string;
  iduser?: string;
  statusvalidation?: number;
  typecollaboration?: number;
  typecollaboration_tmp?: number;
  companyname?: string;
  companyname_tmp?: string;
  cui?: string;
  cui_tmp?: string;
  idaddresscontract?: string;
  idaddresscontract_tmp?: string;
  admin_name?: string;
  admin_name_tmp?: string;
  admin_surname?: string;
  admin_surname_tmp?: string;
  cnp?: string;
  cnp_tmp?: string;
  persona_fiz_name?: string;
  persona_fiz_name_tmp?: string;
  persona_fiz_surname?: string;
  persona_fiz_surname_tmp?: string;

  static parseFromTeacherDto(obj?: TeacherDto): TeacherContractDto {
    if (!obj) return new TeacherContractDto();
    return new TeacherContractDto(
      obj.id,
      obj.iduser,
      obj.statusvalidation,
      obj.typecollaboration,
      obj.typecollaboration_tmp,
      obj.companyname,
      obj.companyname_tmp,
      obj.cui,
      obj.cui_tmp,
      obj.idaddresscontract,
      obj.idaddresscontract_tmp,
      obj.admin_name,
      obj.admin_name_tmp,
      obj.admin_surname,
      obj.admin_surname_tmp,
      obj.cnp,
      obj.cnp_tmp,
      obj.persona_fiz_name,
      obj.persona_fiz_name_tmp,
      obj.persona_fiz_surname,
      obj.persona_fiz_surname_tmp
    );
  }
  constructor(
    id?: string,
    iduser?: string,
    statusvalidation?: number,
    typecollaboration?: number,
    typecollaboration_tmp?: number,
    companyname?: string,
    companyname_tmp?: string,
    cui?: string,
    cui_tmp?: string,
    idaddresscontract?: string,
    idaddresscontract_tmp?: string,
    admin_name?: string,
    admin_name_tmp?: string,
    admin_surname?: string,
    admin_surname_tmp?: string,
    cnp?: string,
    cnp_tmp?: string,
    persona_fiz_name?: string,
    persona_fiz_name_tmp?: string,
    persona_fiz_surname?: string,
    persona_fiz_surname_tmp?: string
  ) {
    this.id = id || "";
    this.iduser = iduser || "";
    this.statusvalidation = statusvalidation || Status.NEED_VALIDATION;
    this.typecollaboration = typecollaboration || 0;
    this.typecollaboration_tmp =
      typecollaboration_tmp || Types.TYPE_PERSOANA_FIZICA;
    this.companyname = companyname || "";
    this.companyname_tmp = companyname_tmp || "";
    this.cui = cui || "";
    this.cui_tmp = cui_tmp || "";
    this.idaddresscontract = idaddresscontract || "";
    this.idaddresscontract_tmp = idaddresscontract_tmp || "";
    this.admin_name = admin_name || "";
    this.admin_name_tmp = admin_name_tmp || "";
    this.admin_surname = admin_surname || "";
    this.admin_surname_tmp = admin_surname_tmp || "";
    this.cnp = cnp || "";
    this.cnp_tmp = cnp_tmp || "";
    this.persona_fiz_name = persona_fiz_name || "";
    this.persona_fiz_name_tmp = persona_fiz_name_tmp || "";
    this.persona_fiz_surname = persona_fiz_surname || "";
    this.persona_fiz_surname_tmp = persona_fiz_surname_tmp || "";
  }
}

export class PostTeacherDto implements Idto {
  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  idssociallink?: string[];
  status?: number;
  idsterm?: string[];
  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  rating?: number;
  name?: string;
  surname?: string;
  fullurl?: string;

  constructor() {
    this.status = Status.TEACHER_UNVERIFIED;
  }
}

export class TeacherMediaFilesDto implements Idto {
  idteacher?: string;
  files?: any;

  constructor(idTeacher?: string) {
    this.idteacher = idTeacher || "";
    this.files = null;
  }

  static parseForSubmitCV(obj: TeacherMediaFilesDto): TeacherMediaDto {
    const object = new TeacherMediaDto();
    object.idteacher = obj.idteacher;
    object.files = obj.files;
    return object;
  }

  static deleteCV(idteacher: string): TeacherMediaDto {
    const object = new TeacherMediaDto();
    object.idteacher = idteacher;
    object.deleteattachmentcv = true;
    return object;
  }
}
export class TeacherMediaDto implements Idto {
  videolocationlessonexample?: string;
  videolocationpresentation?: string;
  idattachmentcv?: string;

  idteacher?: string;

  files?: any;
  fileInfoObj?: any;

  deleteattachmentcv?: boolean;
  deletevideopresentation?: boolean;
  deletevideolessonexample?: boolean;
}

export class TeacherMediaVideoDto implements Idto {
  idteacher: string;
  location: string;

  constructor(idteacher?: string, location?: string) {
    this.idteacher = idteacher || "";
    this.location = location || "";
  }

  static parseForSubmitLessonExample(
    obj: TeacherMediaVideoDto
  ): TeacherMediaDto {
    const object = new TeacherMediaDto();
    object.idteacher = obj.idteacher;
    object.videolocationlessonexample = obj.location;
    return object;
  }

  static deleteVideoLessonExample(idteacher: string): TeacherMediaDto {
    const object = new TeacherMediaDto();
    object.idteacher = idteacher;
    object.deletevideolessonexample = true;
    return object;
  }

  static parseForSubmitPresentation(
    obj: TeacherMediaVideoDto
  ): TeacherMediaDto {
    const object = new TeacherMediaDto();
    object.idteacher = obj.idteacher;
    object.videolocationpresentation = obj.location;
    return object;
  }

  static deletePresentation(idteacher: string): TeacherMediaDto {
    const object = new TeacherMediaDto();
    object.idteacher = idteacher;
    object.deletevideopresentation = true;
    return object;
  }
}

export class TeacherNotificationDto implements Idto {
  iduser?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;

  constructor(
    iduser?: string,
    notificationtype1?: boolean,
    notificationtype2?: boolean,
    notificationtype3?: boolean
  ) {
    this.iduser = iduser || "";
    this.notificationtype1 = notificationtype1 || false;
    this.notificationtype2 = notificationtype2 || false;
    this.notificationtype3 = notificationtype3 || false;
  }

  static fromTeacherDto(
    idUser?: string,
    obj?: TeacherDto
  ): TeacherNotificationDto {
    if (!obj) return new TeacherNotificationDto(idUser);
    return new TeacherNotificationDto(
      obj.iduser ?? idUser,
      obj.notificationtype1 ?? false,
      obj.notificationtype2 ?? false,
      obj.notificationtype3 ?? false
    );
  }
}

export class TeacherPrivacyDto implements Idto {
  id?: string;
  iduser?: string;
  showprofile?: boolean;
  showcourses?: boolean;

  constructor(
    id?: string,
    iduser?: string,
    showprofile?: boolean,
    showcourses?: boolean
  ) {
    this.id = id || "";
    this.iduser = iduser || "";
    this.showprofile = showprofile || false;
    this.showcourses = showcourses || false;
  }

  static fromTeacherDto(obj?: TeacherDto): TeacherPrivacyDto {
    if (!obj) return new TeacherPrivacyDto();
    return new TeacherPrivacyDto(
      obj.id,
      obj.iduser,
      obj.showprofile,
      obj.showcourses
    );
  }
}
