import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { PostCourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: PostCourseTeacherRequestDto;
  onSubmit: (obj: PostCourseTeacherRequestDto) => void;
  loading: boolean;
};

const FormCourseTeacherRequest: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject, vResult] =
    useForm<PostCourseTeacherRequestDto>(
      defaultObject,
      RequiredValidator.getValidators([
        "teachermessage",
        "idteacher",
        "idcourse",
      ])
    );

  useEffect(() => {
    if (!defaultObject) return;
    setObject(defaultObject);
  }, [defaultObject]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography variant="h6" mb={1}>
          {LL("Message")}
        </Typography>
        <Box className="box-form-input-icon-end">
          <MyTextField
            multiline
            minRows={4}
            tabIndex={0}
            fullWidth
            id="teachermessage"
            name="teachermessage"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("teachermessage")}
            size="small"
            value={obj.teachermessage}
            setObj={setObjField}
            _vresults={vResult}
          />
        </Box>
      </Box>
      <Box mt={2} textAlign={"center"}>
        <MyButton
          tabIndex={1}
          disabled={disabled || loading}
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          className="btn">
          {LL("Bt_Submit")}
        </MyButton>
      </Box>
    </form>
  );
};

export { FormCourseTeacherRequest };
