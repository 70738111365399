import React, { useState } from "react";
import { TeacherFAQService } from "services/user/teacherfaq.service";
import { TeacherFAQDto, TeacherFAQResponseDto } from "dto/user/teacherfaq.dto";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/utils/commontools";
import { FormTeacherInterfaceEditFAQ } from "./FormTeacherInterfaceEditFAQ";

const service = new TeacherFAQService();

type Props = {
  _obj: TeacherFAQDto;
  extended: boolean;
  setExtended: any;
  setObjectWasChanged: any;
  itemIndex: number;
  setObjects: any;
  objects: Array<TeacherFAQDto>;
};

const TeacherInterfaceEditFAQ: React.FC<Props> = ({
  _obj,
  extended,
  setExtended,
  setObjectWasChanged,
  itemIndex,
  setObjects,
  objects,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultObj, setDefaultObj] = useState<TeacherFAQResponseDto>(
    new TeacherFAQResponseDto()
  );
  // const [obj, setObj] = useState<TeacherFAQDto>(_obj);

  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateAll = () => {
  //   if (!obj) return;
  //   if (!validators) return;

  //   obj.showoncourse =
  //     obj.showoncourse !== undefined ? obj.showoncourse : false;
  //   obj.teacherresponse =
  //     obj.teacherresponse !== undefined ? obj.teacherresponse : "";

  //   setObj(obj);
  //   setLoading(false);
  //   checkIsFormDisabled();
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);
  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   return v;
  // };

  // const processValidators = () => {
  //   let v: any = {
  //     teacherresponse: [],
  //   };

  //   v.teacherresponse.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   processValidators();
  // }, [extended]);

  // useEffect(() => {
  //   validateAll();
  // }, [validators]);

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);

  //   validateField(field, value);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (!obj) return;
  //   if (!obj.id) return;
  //   setLoading(true);
  //   service.update(obj.id, handleResult, {}, obj);
  // };

  const onSubmit = (obj: TeacherFAQResponseDto) => {
    if (!_obj) return;
    const id = CommonTools.processObjectField(_obj, ["id"]);
    if (!id) return;
    setLoading(true);
    service.update(id, handleResult, {}, obj);
  };

  const handleResult = (result: any) => {
    if (!result) return;
    if (!result.err) {
      const obj = result.obj ? result.obj : {};
      setObjectWasChanged(CommonTools.generateRandomString(5));
      updateObjectAtIndex(itemIndex, obj);
      setLoading(false);
      setDefaultObj(new TeacherFAQResponseDto());
    }
  };

  const updateObjectAtIndex = (index: number, obj: TeacherFAQDto) => {
    const updatedArray = [...objects];
    updatedArray.splice(index, 1, { ...updatedArray[index], ...obj });

    setObjects(updatedArray);
  };

  // const checkIsFormDisabled = () => {
  //   var rez = false;
  //   if (!obj) rez = true;
  //   if (!obj.teacherresponse) rez = true;
  //   setIsFormDisabled(rez);
  // };

  // useEffect(() => {
  //   if (!obj) return;
  //   if (loading) return;

  //   checkIsFormDisabled();
  // }, [obj.teacherresponse, obj.showoncourse]);

  const handleClose = () => {
    setExtended(false);
  };
  // const processForm = () => {
  //   if (loading) return <Skeleton />;
  //   if (!obj) return <></>;
  //   if (!obj.id) return <></>;

  //   return (
  //     <Box>
  //       <form>
  //         <Box className="shadow-box" sx={{ mt: 3 }}>
  //           <MyTextField
  //             tabIndex={0}
  //             fullWidth
  //             multiline
  //             minRows={4}
  //             p={2}
  //             id="teacherresponse"
  //             name="teacherresponse"
  //             variant="standard"
  //             className="form-input"
  //             color="primary"
  //             _label={LL("teacherresponse")}
  //             size="small"
  //             value={obj.teacherresponse}
  //             setObj={setObjField}
  //             _vresults={
  //               vresults && vresults.teacherresponse
  //                 ? vresults.teacherresponse
  //                 : false
  //             }
  //           />
  //         </Box>
  //         <Box sx={{ mt: 1 }}>
  //           <MyCheckboxField
  //             tabIndex={1}
  //             id="showoncourse"
  //             name="showoncourse"
  //             _label={LL("showoncourse")}
  //             setObj={(value: any) =>
  //               CommonTools.handleCheckValue(obj, setObj, "showoncourse", value)
  //             }
  //             checked={obj.showoncourse}
  //             color="primary"
  //             size="small"
  //             _vresults={vResult}
  //           />
  //         </Box>
  //         <Stack direction="row" spacing={2}>
  //           <MyButton
  //             tabIndex={3}
  //             cb={handleSubmit}
  //             disabled={isFormDisabled}
  //             color="primary"
  //             variant="contained"
  //             type="submit"
  //             children={LL("Bt_save response")}
  //           />
  //           <MyButton cb={handleClose} className="cancel-response">
  //             {LL("Bt_Cancel response")}
  //           </MyButton>
  //         </Stack>
  //       </form>
  //     </Box>
  //   );
  // };

  return (
    <Box>
      <FormTeacherInterfaceEditFAQ
        defaultObj={defaultObj}
        onSubmit={onSubmit}
        loading={loading}
        handleClose={handleClose}
      />
    </Box>
  );
};

export { TeacherInterfaceEditFAQ };
