import { Box, Button, Typography } from "@mui/material";
import { PasswordField } from "components/form/PasswordField";
import ModifyPasswordDto from "dto/user/modifypassword.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import { CommonTools } from "tools/utils/commontools";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: ModifyPasswordDto;
  onSubmit: (obj: ModifyPasswordDto) => void;
  loading: boolean;
};

const FormChangePassword: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject, vResult] =
    useForm<ModifyPasswordDto>(
      defaultObject,
      RequiredValidator.getValidators([
        "email",
        "currentpassword",
        "newpassword",
      ])
    );
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const checkConfirmPassword = useCallback(() => {
    let disable = false;
    if (obj.newpassword !== confirmPassword) {
      disable = true;
    }
    setIsDisabled(disable);
  }, [confirmPassword, obj.newpassword]);

  useEffect(() => {
    if (!defaultObject) return;
    setObject(defaultObject);
    setConfirmPassword(
      CommonTools.processObjectField(defaultObject, ["newpassword"])
    );
  }, [defaultObject]);

  useEffect(() => {
    checkConfirmPassword();
  }, [checkConfirmPassword]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || isDisabled) return;
    onSubmit(obj);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3}>
          <Typography variant="h6">{LL("newpassword")}</Typography>
          <Box className="box-form-input-icon-end">
            <PasswordField
              fullWidth
              id="newpassword"
              name="newpassword"
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("newpassword")}
              value={obj.newpassword}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mb={3}>
          <Typography variant="h6">{LL("Confirm Password")}</Typography>
          <Box className="box-form-input-icon-end">
            <PasswordField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              className="form-input"
              variant="standard"
              _label={LL("Confirm Password")}
              value={confirmPassword}
              setObj={(field: any, value: any) => setConfirmPassword(value)}
              _vresults={vResult}
            />
          </Box>
        </Box>

        <Typography variant="h6">{LL("currentpassword")}</Typography>
        <Box className="box-form-input-icon-end">
          <PasswordField
            fullWidth
            id="currentpassword"
            name="currentpassword"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("currentpassword")}
            value={obj.currentpassword}
            setObj={setObjField}
            _vresults={vResult}
          />
        </Box>
        <Box my={4} mx="auto" textAlign="center">
          <Button
            disabled={disabled || loading || isDisabled}
            color="primary"
            className="btn"
            variant="contained"
            size="small"
            type="submit">
            {LL("Bt ChangPassword")}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export { FormChangePassword };
