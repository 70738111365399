import { useState } from "react";

import { useAuth } from "hooks/useAuth";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import ModifyPasswordDto from "dto/user/modifypassword.dto";
import { UserService } from "services/user/user.service";
import { FormChangePassword } from "./FormChangePassword";
import { CommonTools } from "tools/utils/commontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Box } from "@mui/material";
const service = new UserService();

const ChangePasswordForm: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();

  // const { LL } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [isFormDisabled, setIsFormDisabled] = useState(true);

  // const [obj, setObj] = useState<ModifyPasswordDto>(new ModifyPasswordDto());

  // const [validators, setValidators] = useState(false);
  // const [vresults, setVResults] = useState<any>(false);

  // const validateAll = () => {
  //   if (!obj) return;
  //   if (!validators) return;
  //   if (vresults) return;
  //   obj.email = user ? user.email : "";
  //   obj.currentpassword =
  //     obj.currentpassword != undefined ? obj.currentpassword : "";
  //   obj.newpassword = obj.newpassword != undefined ? obj.newpassword : "";
  //   setObj(obj);

  //   let t = validateField("currentpassword", obj.currentpassword);
  //   t = validateField("newpassword", obj.newpassword, t);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);
  //   setVResults(v);
  //   return v;
  // };

  // const processValidators = () => {
  //   let v: any = {
  //     currentpassword: [],
  //     newpassword: [],
  //   };

  //   // v.currentpassword.push(new RequiredValidator(LL('Required field currentpassword', LabelTypes.LABEL_VALIDATION), ''));
  //   // v.newpassword.push(new RequiredValidator(LL('Required field newpassword', LabelTypes.LABEL_VALIDATION), ''));
  //   v.currentpassword.push(new RequiredValidator("", ""));
  //   v.newpassword.push(new RequiredValidator("", ""));
  //   setValidators(v);
  // };

  // const checkIsLoading = () => {
  //   if (!isLoading) return;
  //   if (!obj) return;
  //   if (!validators) return;
  //   validateAll();
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   processValidators();
  // }, []);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj, validators]);

  // const [confirmPassword, setConfirmPassword] = useState("");

  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [vresults, confirmPassword]);

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);

  //   validateField(field, value);
  // };

  // const checkIsFormDisabled = () => {
  //   var rez = ValidateTools.vForm(vresults, "currentpassword");
  //   rez = ValidateTools.vForm(vresults, "newpassword", rez);
  //   if (obj.newpassword !== confirmPassword) {
  //     rez = true;
  //   }
  //   setIsFormDisabled(rez);
  // };

  // const handlePasswordChange = () => {
  //   setIsLoading(true);
  //   setIsFormDisabled(true);
  //   setObjField("currentpassword", "");
  //   setObjField("newpassword", "");
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();

  //   if (isFormDisabled) return false;
  //   service.modifyPassword(handlePasswordChange, {}, obj);
  // };

  // return !user && isLoading ? null : (
  //   <Box maxWidth="sm" mx="auto" mt={3}>
  //     <form onSubmit={handleSubmit}>
  //       <Box sx={{ flexGrow: 1 }}>
  //         <Box mb={3}>
  //           <Typography variant="h6">{LL("newpassword")}</Typography>
  //           <MyTextField
  //             fullWidth
  //             type="password"
  //             id="newpassword"
  //             name="newpassword"
  //             variant="standard"
  //             color="primary"
  //             className="form-input"
  //             _label={LL("newpassword")}
  //             value={obj.newpassword}
  //             setObj={setObjField}
  //             _vresults={
  //               vresults && vresults.newpassword ? vresults.newpassword : false
  //             }
  //           />
  //         </Box>
  //         <Box mb={3}>
  //           <Typography variant="h6">{LL("Confirm Password")}</Typography>
  //           <MyTextField
  //             fullWidth
  //             type="password"
  //             id="confirmPassword"
  //             name="confirmPassword"
  //             className="form-input"
  //             variant="standard"
  //             _label={LL("Confirm Password")}
  //             value={confirmPassword}
  //             setObj={(field: any, value: any) => setConfirmPassword(value)}
  //             _vresults={vResult}
  //           />
  //         </Box>

  //         <Typography variant="h6">{LL("currentpassword")}</Typography>
  //         <MyTextField
  //           fullWidth
  //           id="currentpassword"
  //           name="currentpassword"
  //           variant="standard"
  //           className="form-input"
  //           color="primary"
  //           type="password"
  //           _label={LL("currentpassword")}
  //           value={obj.currentpassword}
  //           setObj={setObjField}
  //           _vresults={
  //             vresults && vresults.currentpassword
  //               ? vresults.currentpassword
  //               : false
  //           }
  //         />
  //         <Box mt={4} mx="auto" textAlign="center">
  //           <Button
  //             disabled={isFormDisabled}
  //             color="primary"
  //             className="btn"
  //             variant="contained"
  //             size="small"
  //             type="submit"
  //           >
  //             {LL("Bt ChangPassword")}
  //           </Button>
  //         </Box>
  //       </Box>
  //     </form>
  //   </Box>
  // );

  if (!user) return null;
  return <LoggedComponent user={user} />;
};

type LoggedProps = {
  user: any;
};

const LoggedComponent: React.FC<LoggedProps> = ({ user }) => {
  const [obj, setObj] = useState<ModifyPasswordDto>(
    new ModifyPasswordDto(CommonTools.processObjectField(user, ["email"]))
  );
  const [loading, setLoading] = useState<boolean>(false);
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    setObj(
      new ModifyPasswordDto(CommonTools.processObjectField(user, ["email"]))
    );
    setLoading(false);
  };
  const onSubmit = (data: ModifyPasswordDto) => {
    setLoading(true);
    service.modifyPassword(handleResult, {}, data);
  };
  return (
    <Box maxWidth="sm" mx="auto" mt={3}>
      <FormChangePassword
        defaultObject={obj}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Box>
  );
};

export { ChangePasswordForm };
