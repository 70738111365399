import {
  ArrowBackIosNewOutlined,
  GroupOutlined,
  ViewSidebarOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useChat } from "context/chat.context";
import { ChatDto } from "dto/course/chat.dto";
import React, { useEffect, useState } from "react";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { useResponsive } from "hooks/useResponsive";
import { logger } from "tools/utils/logger";

type Props = {
  object: ChatDto;
};

const TopBarChat: React.FC<Props> = ({ object }) => {
  const { selectChat, openSidebar, setOpenSidebar } = useChat();
  const { matchesCustom4 } = useResponsive();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleClose = () => {
    selectChat(null);
  };

  useEffect(() => {
    const handleClick = () => {
      if (!matchesCustom4) {
        setOpenSidebar(true);
      } else {
        setOpenSidebar(false);
      }
    };

    if (!matchesCustom4) {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [matchesCustom4]);

  const handleDrawerOpen = () => {
    setOpenSidebar(true);
  };

  useEffect(() => {
    if (!matchesCustom4) {
      setOpenSidebar(true);
    } else {
      setOpenSidebar(false);
    }
  }, [matchesCustom4]);

  const isGroup =
    CommonTools.processObjectField(object, ["type"]).toString() ===
    Types.TYPE_CHAT_GROUP.toString();
  if (!object) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "70px",
        background: "white",
        borderRadius: "10px 10px 0px 0px ",
        width: "100%",
      }}>
      <Box>
        <IconButton onClick={handleClose}>
          <ArrowBackIosNewOutlined />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h5" noWrap>
          {CommonTools.processObjectField(object, ["name"])}
        </Typography>
        {isGroup && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"flex-start"}
            spacing={1}>
            <GroupOutlined />
            <Typography>
              {CommonTools.processObjectField(object, ["numberofparticipants"])}
            </Typography>
          </Stack>
        )}
      </Box>
      <Box sx={{ width: "40px", display: matchesCustom4 ? "block" : "none" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={[openSidebar && { display: "none" }]}>
          <ViewSidebarOutlined />
        </IconButton>
      </Box>
    </Box>
  );
};

export { TopBarChat };
