import { Box } from "@mui/material";
import { NextLessonButton } from "components/course/course/NextLessonButton";
import { ScheduleDto } from "dto/course/schedule.dto";
import { useAuth } from "hooks/useAuth";
import useObject from "hooks/useObject";
import React from "react";
import { ScheduleService } from "services/course/schedule.service";
import { CommonTools } from "tools/utils/commontools";

const service = new ScheduleService();
const NextLessonTeacherBlock: React.FC = () => {
  const { teacher } = useAuth();
  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);
  const get = (id: string, cb?: any, cbparameters?: any) => {
    service.getNextScheduleTeacher(cb, cbparameters);
  };
  const [loading, object] = useObject<ScheduleDto>(get, idTeacher, [idTeacher]);
  if (!idTeacher) return null;
  if (loading) return null;
  if (!object) return null;
  if (CommonTools.isEmpty(object)) return null;
  const url = ScheduleDto.generateUrlClassroom(object);

  return (
    <Box
      sx={{
        width: { xs: "calc(100% - 40px)", sm: "auto" },
        display: "flex",
        justifyContent: "center",
      }}>
      <NextLessonButton object={object} url={url} />
    </Box>
  );
};

export { NextLessonTeacherBlock };
