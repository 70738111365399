import React from "react";
import Box from "@mui/material/Box";
import { CourseDto } from "dto/course/course.dto";
import { VerifyCourseAction } from "../courseaction/new/VerifyCourseAction";
import { PublishCourseAction } from "../courseaction/new/PublichCourseAction";
import { DelistCourseAction } from "../courseaction/new/DelistCourseAction";
import { RescheduleCourseAction } from "../courseaction/new/RescheduleCourseAction";
import { StartCourseAction } from "../courseaction/new/StartCourseAction";

type Props = {
  currentRoute: any;
  course: CourseDto;
  setCourse: (course: CourseDto) => void;
};

const CourseActions: React.FC<Props> = ({
  course,
  setCourse,
}) => {
  
  if (!course) return null;
  return (
    <Box sx={{ maxWidth: "600px", mx: "auto" }} mb={4}>
      <VerifyCourseAction course={course} setCourse={setCourse} />
      <PublishCourseAction course={course} setCourse={setCourse} />
      <DelistCourseAction course={course} setCourse={setCourse} />
      <RescheduleCourseAction course={course} setCourse={setCourse} />
      <StartCourseAction course={course} setCourse={setCourse} />
    </Box>
  );
};

export default CourseActions;
