import { Alert, Box, Typography } from "@mui/material";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseDto } from "dto/course/course.dto";
import { CoursePriceDto } from "dto/course/courseprice.dto";
import { useAuth } from "hooks/useAuth";
import useObject from "hooks/useObject";
import { useResource } from "hooks/useResource";
import { CallbackType } from "interfaces/commontypes.interface";
import React, { useState } from "react";
import { CoursePriceService } from "services/course/courseprice.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";
import { FormCoursePrice } from "./FormCoursePrice";

type Props = {
  currentRoute: any;
  canEdit: boolean;

  course: CourseDto;
};

const EditCoursePrice: React.FC<Props> = ({
  currentRoute,
  canEdit,
  course,
}) => {
  const { teacher } = useAuth();
  const { LL } = useResource();

  const teacherStatus = CommonTools.processObjectField(teacher, ["status"]);
  const idTypeCourse = CommonTools.processObjectField(course, [
    "coursesettings",
    "idtypecourse",
  ]);
  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);
  const idCourse = CommonTools.processObjectField(course, ["id"]);
  if (!teacher) return null;
  if (!idTeacher) return null;
  if (!idCourse) return null;
  if (!teacherStatus) return null;
  if (!course) return null;
  if (!idTypeCourse)
    return (
      <Box mt={2}>
        <Alert severity="warning">
          {LL("You_have_to_set_the_course_type")}
        </Alert>
      </Box>
    );
  if (teacherStatus.toString() === Status.TEACHER_UNVERIFIED.toString())
    return (
      <Box mt={2}>
        <Alert severity="warning">
          {LL(
            "You_are_not_verified_yet_you_should_be_verified_to_set_the_price"
          )}
        </Alert>
      </Box>
    );
  return (
    <CoursePriceComponent
      idCourse={idCourse}
      idTeacher={idTeacher}
      idTypeCourse={idTypeCourse}
      teacherStatus={teacherStatus}
      canEdit={canEdit}
    />
  );
};

type CoursePriceComponentProps = {
  idCourse: string;
  idTeacher: string;
  idTypeCourse: string;
  teacherStatus: string;
  canEdit: boolean;
};
const service = new CoursePriceService();
const CoursePriceComponent: React.FC<CoursePriceComponentProps> = ({
  idCourse,
  idTeacher,
  idTypeCourse,
  teacherStatus,
  canEdit,
}) => {
  const { LL } = useResource();
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getPriceForCourse(
      id,
      idTeacher,
      teacherStatus,
      idTypeCourse,
      cb,
      cbParams
    );
  };
  const [loading, object, setObject] = useObject<CoursePriceDto>(
    get,
    idCourse,
    [idCourse, idTeacher, teacherStatus, idTypeCourse]
  );
  const onSubmit = (obj: CoursePriceDto) => {
    if (!obj) return;
    setLoadingAction(true);
    if (!obj.id) {
      service.add(handleResult, {}, obj);
    } else {
      service.update(obj.id, handleResult, {}, obj);
    }
  };
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) {
      setLoadingAction(false);
      return;
    }
    if (result.err) {
      setLoadingAction(false);
      return;
    }
    setLoadingAction(false);
    setObject(result.obj ?? null);
  };
  const noPolicy = (
    <Box mt={2}>
      <Alert severity="error">
        {LL(
          "Sorry_at_the_moment_there_is_no_price_policy_available_for_this_course"
        )}
      </Alert>
    </Box>
  );
  const processAlert = () => {
    if (!object) return null;
    if (!object.id)
      return (
        <Box mt={2} mb={2}>
          <Alert severity="warning">
            {LL(
              "the_price_was_not_set_yet_select_the_price_or_save_the_default_selected_price"
            )}
          </Alert>
        </Box>
      );
    return null;
  };

  if (loading) return <CircularLoading />;
  if (!object) return noPolicy;
  if (CommonTools.isEmpty(object)) return noPolicy;
  return (
    <Box sx={{ maxWidth: "600px", mx: "auto" }} mb={4}>
      <Box mt={3}>
        <Typography variant="h6" mb={1}>
          {LL("PricePolicy")}
        </Typography>
        <Typography variant="h6" mb={1}>
          {CommonTools.processObjectField(object, ["pricepolicy", "name"])}
        </Typography>
      </Box>
      <Box mt={3}>
        {processAlert()}
        <FormCoursePrice
          defaultObj={object}
          canEdit={canEdit}
          loading={loading || loadingAction}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
};

export { EditCoursePrice };
