import React, { useState } from "react";
import UserSettingsDto, {
  UserSettingsFormDto,
} from "dto/user/usersettings.dto";

import Box from "@mui/material/Box";
import { CommonTools } from "tools/utils/commontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { UserSettingsService } from "services/user/usersettings.service";
import { useAuth } from "hooks/useAuth";
import useObject from "hooks/useObject";
import { FormUserSettings } from "./FormUserSettings";
import { CircularLoading } from "components/elements/loading/CircularLoading";

const service = new UserSettingsService();

type Props = {
  currentRoute: any;
};

const EditUserSettingsInfo: React.FC<Props> = () => {
  const { user } = useAuth();
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);

  const idUser = CommonTools.processObjectField(user, ["id"]);

  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getByUserId(id, cb, cbParams);
  };
  const [loading, obj, setObj] = useObject<UserSettingsDto>(get, idUser, [
    idUser,
  ]);

  const onSubmit = (obj: UserSettingsFormDto) => {
    const id = CommonTools.processObjectField(obj, ["id"]);
    setLoadingAdd(true);
    obj.iduser = idUser;
    if (!id) {
      service.addMultipart(handleResult, {}, obj);
    } else {
      service.updateMultipart(id, handleResult, {}, obj);
    }
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const object = result.obj ? result.obj : {};
    setObj(object);
    setLoadingAdd(false);
  };

  if (loading) return <CircularLoading />;
  if (!obj) return null;
  return (
    <Box pt={3}>
      <FormUserSettings
        defaultObj={UserSettingsFormDto.parseFromUserSettingsDto(
          CommonTools.isEmpty(obj) ? undefined : obj
        )}
        onSubmit={onSubmit}
        loading={loadingAdd}
      />
    </Box>
  );
  // const _id = user !== undefined ? user.id : "";

  // const [id, setId] = useState<string | undefined>(_id);

  // const [obj, setObj] = useState<UserSettingsDto | undefined>();

  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateAll = () => {
  //   if (!obj) return;
  //   // if (!validators) return;
  //   // if (vresults) return;

  //   obj.id = obj.id ?? obj.id;
  //   obj.name = obj.name !== undefined ? obj.name : "";
  //   obj.surname = obj.surname !== undefined ? obj.surname : "";
  //   obj.birthday = obj.birthday !== undefined ? obj.birthday : undefined;
  //   obj.idtypegender = obj.idtypegender !== undefined ? obj.idtypegender : "";
  //   obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";
  //   obj.idavatar = obj.idavatar !== undefined ? obj.idavatar : null;

  //   setObj(obj);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);

  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));

  //   return v;
  // };

  // const processValidators = () => {
  //   // let v: any = {
  //   //     name: [],
  //   //     surname: [],
  //   // };
  //   // v.name.push(new RequiredValidator(LL('Required field name', LabelTypes.LABEL_VALIDATION), ''));
  //   // v.surname.push(new RequiredValidator(LL('Required field surname', LabelTypes.LABEL_VALIDATION), ''));
  //   // setValidators(v);
  // };

  // const checkLoading = () => {
  //   if (obj === undefined) return;
  //   // if (!validators) return;
  //   validateAll();
  //   setLoading(false);
  // };

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : {};
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined) {
  //     service.getByUserId(id, loadObject, {});
  //   }
  // };

  // useEffect(() => {
  //   processValidators();
  // }, []);
  // // const checkIsFormDisabled = () => {
  // //     var rez = ValidateTools.vForm(vresults, 'name');
  // //     rez = ValidateTools.vForm(vresults, 'surname', rez);
  // //     setIsFormDisabled(rez);
  // // };

  // useEffect(() => {
  //   validateAll();
  //   checkLoading();
  // }, [obj, validators]);

  // // useEffect(() => {
  // //     checkIsFormDisabled();
  // // }, [vresults]);

  // const [objectWasChanged, setObjectWasChanged] = useState<string>("");

  // useEffect(() => {
  //   getObject();
  // }, [id, objectWasChanged]);

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (!obj) return;
  //   if (obj && obj.id !== undefined) {
  //     obj.iduser = id;
  //     if (files !== null) obj.files = files;
  //     setLoading(true);
  //     service.update(
  //       obj.id,
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       { setObjectWasChanged },
  //       obj
  //     );
  //   } else {
  //     obj.iduser = id;
  //     if (files !== null) obj.files = files;
  //     setLoading(true);
  //     service.add(
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       { setObjectWasChanged },
  //       obj
  //     );
  //   }
  // };
  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);
  // };

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setId(user.id);
  //   }
  // }, [user]);

  // const setCheckDeleteAvatar = (value: any) => {
  //   CommonTools.handleCheckValue(obj, setObj, "deleteavatar", value);
  // };

  // const processAvatarDelete = () => {
  //   if (!obj) return <></>;
  //   return (
  //     <Box sx={{ mt: 3 }}>
  //       {ComponentCommonTools.processCheckboxDelete(
  //         obj,
  //         obj,
  //         "avatar",
  //         "deleteavatar",
  //         "name",
  //         setCheckDeleteAvatar,
  //         LL
  //       )}
  //     </Box>
  //   );
  // };

  // const [files, setFiles] = useState(null);
  // const setObjFile = (value: any) => {
  //   setFiles(value);
  // };
  // return !loading && obj ? (
  //   !CommonTools.isEmpty(obj) ? (
  //     <>
  //       <Box py={3}>
  //         <form onSubmit={handleSubmit}>
  //           <Grid container spacing={2}>
  //             <Grid item lg={6} xs={12}>
  //               <Typography variant="h6">{LL("Name")}</Typography>
  //               <MyTextField
  //                 tabIndex={0}
  //                 fullWidth
  //                 id="name"
  //                 name="name"
  //                 className="form-input"
  //                 variant="standard"
  //                 _label={LL("Name")}
  //                 value={obj.name}
  //                 setObj={setObjField}
  //                 _vresults={vresults && vresults.name ? vresults.name : false}
  //               />
  //             </Grid>
  //             <Grid item lg={6} xs={12}>
  //               <Typography variant="h6">{LL("Surname")}</Typography>
  //               <MyTextField
  //                 tabIndex={1}
  //                 fullWidth
  //                 id="surname"
  //                 name="surname"
  //                 className="form-input"
  //                 variant="standard"
  //                 _label={LL("Surname")}
  //                 value={obj.surname}
  //                 setObj={setObjField}
  //                 _vresults={
  //                   vresults && vresults.surname ? vresults.surname : false
  //                 }
  //               />
  //             </Grid>
  //             <Grid item lg={6} xs={12}>
  //               <Typography variant="h6">{LL("Birthday")}</Typography>
  //               <MyDatePicker
  //                 fullWidth
  //                 tabIndex={3}
  //                 field="birthday"
  //                 _defaultValue={obj.birthday}
  //                 LL={LL}
  //                 variant="standard"
  //                 setObjField={setObjField}
  //                 className="form-picker"
  //               />
  //             </Grid>
  //             <Grid item lg={6} xs={12}></Grid>
  //             <Grid item lg={6} xs={12}>
  //               <Typography variant="h6">{LL("Language")}</Typography>
  //               <LanguageSelect
  //                 tabIndex={4}
  //                 id="idlanguage"
  //                 name="idlanguage"
  //                 className="form-select"
  //                 variant="standard"
  //                 value={obj.idlanguage}
  //                 field="idlanguage"
  //                 setObj={setObjField}
  //                 obj={obj}
  //               />
  //             </Grid>
  //             <Grid item lg={6} xs={12}>
  //               <Typography variant="h6">{LL("Gender")}</Typography>
  //               <TypeGenderSelect
  //                 value={obj.idtypegender}
  //                 setObjectField={setObjField}
  //                 label=""
  //               />
  //             </Grid>
  //             <Grid item lg={12} xs={12}>
  //               <Box>
  //                 <AvatarCropper setFile={setObjFile} avatar={obj.avatar} />
  //               </Box>
  //               {processAvatarDelete()}
  //             </Grid>
  //             <Grid item xs={12} textAlign="center">
  //               <MyButton
  //                 tabIndex={4}
  //                 disabled={isFormDisabled}
  //                 color="primary"
  //                 variant="contained"
  //                 className="btn"
  //                 type="submit"
  //                 sx={{ mt: 4 }}
  //                 children={LL("Bt_Submit")}
  //               />
  //             </Grid>
  //           </Grid>
  //         </form>
  //       </Box>
  //     </>
  //   ) : (
  //     <AddUserSettingsInfo currentRoute={currentRoute} />
  //   )
  // ) : (
  //   <Loading />
  // );
};

export default EditUserSettingsInfo;
