import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { Box } from "@mui/material";

import { EditCourseGallery } from "./EditCourseGallery";
import { EditCourseVideo } from "./EditCourseVideo";

type Props = PageComponentProps & {
  canEdit: boolean;
};
const EditCourseMedia: React.FC<Props> = ({ currentRoute, canEdit }) => {
  return (
    <Box
      p={3}
      maxWidth="sm"
      mx={"auto"}
      justifyContent="center"
      display="flex"
      flexDirection="column">
      <Box py={2}>
        <EditCourseGallery currentRoute={currentRoute} canEdit={canEdit} />
      </Box>
      <Box py={2}>
        <EditCourseVideo currentRoute={currentRoute} canEdit={canEdit} />
      </Box>
    </Box>

    // <Box sx={{ maxWidth: "600px", mx: "auto" }} mb={4}>
    //   <EditCourseGallery currentRoute={currentRoute} canEdit={canEdit}/>
    //   <EditCourseVideo currentRoute={currentRoute} canEdit={canEdit} />
    // </Box>
  );
};

export { EditCourseMedia };
