import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import { TestDto } from "dto/course/test.dto";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { BlockTest } from "./new/BlockTest";

type Props = {
  idCourse: string;
  objectFromList: TestDto;
  canEdit: boolean;
  updateList: (obj: TestDto) => void;
  deleteFromList: (id: string) => void;
};

const EditCourseTestItem: React.FC<Props> = ({
  objectFromList,
  canEdit,
  updateList,
  deleteFromList,
  idCourse,
}) => {
  const { LL } = useResource();
  const [expend, setExpend] = useState(false);

  const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpend(expanded);
  };

  const processTestItem = () => {
    if (!objectFromList) return null;
    if (!objectFromList.id) return null;

    return (
      <Accordion
        sx={{ my: 2, maxWidth: "calc(100vw - 0px )" }}
        expanded={expend}
        className="accordionItem"
        onChange={handleExpend}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse", p: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}>
            <Typography className="name" ml={1}>
              {processName()}
            </Typography>
          </Stack>
        </AccordionSummary>
        {processTestDetails()}
      </Accordion>
    );
  };

  const processName = () => {
    if (!objectFromList) return null;
    if (expend) return <>{LL("Edit_Test")}</>;
    return (
      <>{ComponentCommonTools.processObjectField(objectFromList, "name")}</>
    );
  };

  const processTestDetails = () => {
    if (!objectFromList) return null;
    if (!objectFromList.id) return null;
    if (!expend) return null;

    return (
      <AccordionDetails className="accordionDetailTest">
        <BlockTest
          defaultObj={objectFromList}
          updateList={updateList}
          deleteFromList={deleteFromList}
          allowEdit
          idCourse={idCourse}
          allowDelete
          canEdit={canEdit}
        />
      </AccordionDetails>
    );
  };

  return processTestItem();
};

export { EditCourseTestItem };
