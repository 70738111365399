import React, { useState, useEffect } from "react";

import { SocialService } from "services/user/social.service";

import SocialDto from "dto/user/social.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import Box from "@mui/material/Box";
import { Types } from "tools/types/types";
import { SocialItemIcon } from "components/static/social/SocialItemIcon";
import { SkeletonSocial } from "components/elements/Skeleton/SkeletonTeacherPage";
import { Link, Skeleton, Stack } from "@mui/material";

const service = new SocialService();

interface Props {
  idUser: string;
  type: number;
}

const UserSocialBlock: React.FC<Props> = ({ idUser, type }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<Array<SocialDto> | undefined>();

  const loadObject = (obj: any) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : [];
      setObj(object);
    }
  };

  const getObject = () => {
    if (!idUser) return;
    service.getSocialsByUserId(idUser, loadObject, {});
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (!obj) load = true;
    setLoading(load);
  };

  useEffect(() => {
    if (!idUser) return;
    getObject();
  }, [idUser]);

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processItemLink = (item: SocialDto) => {
    if (!item) return null;
    if (!item.link) return null;
    return (
      <Link href={item.link} target="_blank" rel="noopener noreferrer">
        {processDetail(item)}
      </Link>
    );
  };
  const processDetail = (item: SocialDto) => {
    if (!item) return null;
    if (!item.typesocial) return null;
    if (!item.typesocial.hasOwnProperty("gallery"))
      return (
        <>
          {ComponentCommonTools.processObjectFieldOfObject(
            item,
            "typesocial",
            "name"
          )}
        </>
      );

    if (type === Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM) {
      return <SocialItemIcon obj={item} width={20} height={20} />;
    }
    if (type === Types.TYPE_SOCIAL_DISPLAY_LONG_FORM) {
      return (
        <>
          <SocialItemIcon obj={item} width={20} height={20} />
          {ComponentCommonTools.processObjectFieldOfObject(
            item,
            "typesocial",
            "name"
          )}
        </>
      );
    }
  };

  const processItem = (item: SocialDto, index: number) => {
    if (!item) return null;

    return (
      <Box className="blockEl" key={index}>
        {processItemLink(item)}
      </Box>
    );
  };

  const processList = () => {
    if (!obj) return null;
    if (obj.length === 0) return null;

    if (type === Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM) {
      return (
        <Stack direction="row" spacing={1}>
          {obj.map((item, index) => {
            return processItem(item, index);
          })}
        </Stack>
      );
    }
    return (
      <Box className="socialName">
        {obj.map((item, index) => {
          const isLastElement = index === obj.length - 1;
          const isOdd = obj.length % 2 !== 0;
          const className = isLastElement && isOdd ? "fullWidth" : "halfWidth";
          return (
            <Box key={index} className={className}>
              {processItem(item, index)}
            </Box>
          );
        })}
      </Box>
    );
  };

  return idUser && !loading ? (
    processList()
  ) : type === Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM ? (
    <Skeleton variant="rectangular" />
  ) : (
    <SkeletonSocial lines={5} />
  );
};

export default UserSocialBlock;
