import React, { useState } from "react";

import { useResource } from "hooks/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { EditCourseTestList } from "components/course/test/EditCourseTestList";
import { MyButton } from "components/elements/button/MyButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

type Props = {
  currentRoute: any;
  canEdit: boolean;
};

const EditCourseTest: React.FC<Props> = ({ currentRoute ,canEdit}) => {
  const { LL } = useResource();

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const processList = () => {
    if (!id) return null;
    return (
      <EditCourseTestList
        idCourse={id}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        canEdit={canEdit}
      />
    );
  };

  return (
    <React.Fragment>
      <Box sx={{ maxWidth: "600px", mx: "auto" }} mb={4}>
        <Typography sx={{ pb: 2 }} variant="h6">
          {LL("Test curs")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            my: 3,
          }}>
          <MyButton className="btn btnAddlesson" cb={handleOpenDialog} disabled={!canEdit}>
            <AddOutlinedIcon />
            {LL("Bt_Add_Test")}
          </MyButton>
        </Box>
        {processList()}
      </Box>
    </React.Fragment>
  );
};

export default EditCourseTest;
