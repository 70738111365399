import { Box, LinearProgress, Typography } from "@mui/material";
import { RatingRangesDto } from "dto/static/ratingranges.dto";
import React from "react";
import { MyRatingReadOnly } from "../MyRating/MyRatingReadOnly";

type RatingProgressProps = {
  obj: RatingRangesDto;
};

const RatingProgress: React.FC<RatingProgressProps> = ({ obj, ...props }) => {
  const processProgress = (value: number, percentage: number) => {
    const percentageLocal = Math.round(percentage);
    return (
      //   <Grid container direction="row" spacing={1} alignItems="center">
      //     <Grid item xs={6} lg={9} sx={{ color: "grey.700" }}>
      //       <LinearProgress
      //         variant="determinate"
      //         value={percentageLocal}
      //         color="inherit"
      //         sx={{ borderRadius: "5px", height: "6px" }}
      //       />
      //     </Grid>
      //     <Grid item xs={6} lg={3} sx={{ display: "flex", maxWidth: "100px" }}>
      //       <MyRatingReadOnly rating={value} showDetails={false} />
      //       <Typography color="primary" ml={2}>
      //         {percentageLocal}%
      //       </Typography>
      //     </Grid>
      //   </Grid>

      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <LinearProgress
            variant="determinate"
            value={percentageLocal}
            color="inherit"
            sx={{
              borderRadius: "5px",
              height: "6px",
              color: "grey.700",
              width: "100%",
            }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <MyRatingReadOnly rating={value} showDetails={false} />
          <Typography color="primary" sx={{ minWidth: "50px" }} ml={2}>
            {percentageLocal}%
          </Typography>
        </Box>
      </Box>
    );
  };

  const processItem = () => {
    if (!obj) return null;
    if (
      obj.firstrangecount === undefined ||
      obj.firstrange === undefined ||
      obj.secondrangecount === undefined ||
      obj.secondrange === undefined ||
      obj.thirdrangecount === undefined ||
      obj.thirdrange === undefined ||
      obj.fourthrangecount === undefined ||
      obj.fourthrange === undefined ||
      obj.fifthrangecount === undefined ||
      obj.fifthrange === undefined
    )
      return null;
    return (
      <Box>
        {processProgress(5, obj.fifthrange)}
        {processProgress(4, obj.fourthrange)}
        {processProgress(3, obj.thirdrange)}
        {processProgress(2, obj.secondrange)}
        {processProgress(1, obj.firstrange)}
      </Box>
    );
  };
  return processItem();
};

export { RatingProgress };
