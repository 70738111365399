import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type Props = {
  matchesXS: boolean;
  matchesSM: boolean;
  matchesMD: boolean;
  matchesLG: boolean;
  downXS: boolean;
  downSM: boolean;
  downMD: boolean;
  downLG: boolean;
  downXL: boolean;
  matchesCustom: boolean;
  matchesCustom2: boolean;
  matchesCustom3: boolean;
  matchesCustom4: boolean;
};

export const useResponsive = (): Props => {
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const downXS = useMediaQuery(theme.breakpoints.down("xs"));
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const downMD = useMediaQuery(theme.breakpoints.down("md"));
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));
  const matchesCustom = useMediaQuery("(min-width:1050px)");
  const matchesCustom2 = useMediaQuery("(max-width:770px)");
  const matchesCustom3 = useMediaQuery("(max-width:2000px)");
  const matchesCustom4 = useMediaQuery("(max-width:1300px)");

  return {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    matchesCustom,
    matchesCustom2,
    matchesCustom3,
    matchesCustom4,
    downXS,
    downSM,
    downMD,
    downLG,
    downXL,
  };
};
