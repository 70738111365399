import { Box, Typography } from "@mui/material";
import { SchedulerTypes } from "devextreme-react/scheduler";
import React from "react";
import { CommonTools } from "tools/utils/commontools";

import { RouteTools } from "tools/utils/routetools";
import { MyButton } from "../button/MyButton";
import { useResource } from "hooks/useResource";
import { ScheduleDto } from "dto/course/schedule.dto";
import SchoolIcon from "@mui/icons-material/School";

type Props = {
  data: {
    appointmentData: SchedulerTypes.Appointment;
  };
};

const AppointmentTooltip: React.FC<Props> = ({ data: { appointmentData } }) => {
  const { LL } = useResource();
  const gradient = CommonTools.processObjectField(appointmentData, ["color"]);
  const onClick = () => {
    const url = ScheduleDto.generateUrlClassroom(appointmentData.schedule);
    if (!url) return;
    RouteTools.setHistory(url, {});
  };

  return (
    <Box
      className={"dx-tooltip-appointment-item"}
      sx={{
        p: 5,
        width: "inherit",
        height: "inherit",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          width: "5px",
          background: gradient,
          borderRadius: "10px 0 0 10px",
        },
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          maxWidth: "400px",
        }}>
        <Box
          sx={{
            width: "22px",
            height: "20px",
            background: gradient,
            borderRadius: "50px",
          }}
        />
        <Typography variant="h6" whiteSpace={"preserve-spaces"} width={"100%"}>
          {CommonTools.processObjectField(appointmentData, ["text"])}
        </Typography>
      </Box>

      <Box className="virtualClass ">
        <MyButton claassName="virtualClassButton" cb={onClick}>
          <SchoolIcon sx={{ mr: "3px", color: "white" }} />
          <Typography sx={{ color: "white" }}>
            {LL("virtual_classroom")}
          </Typography>
        </MyButton>
      </Box>
    </Box>
  );
};

export { AppointmentTooltip };
