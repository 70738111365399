import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { Config } from "tools/utils/config";
import DateTools from "tools/utils/date.tools";


export const useCookie = () => {
  const [cookies, setCookie] = useCookies();
  const setCookieValue = useCallback((key: string, value: string | boolean | number) => {
    if (!value) return;
    setCookie(key, value, {
      path: "/",
      expires: DateTools.getDate(Config.COOKIE_EXPIRES),
    });
  },[]);

  const getCookie = (key: string, defaultValue?: string | boolean | number) => {
    if (defaultValue === undefined) defaultValue = "";
    const value = cookies[key] ?? defaultValue;
    return value;
  };

  const setCookieObject = useCallback((key: string, value: any) => {
    if (!value) return;
    setCookie(key, JSON.stringify(value), {
      path: "/",
      expires: DateTools.getDate(Config.COOKIE_EXPIRES),
    });
  },[])

  const getCookieObject = (key: string, defaultValue?: any) => {
    if (defaultValue === undefined) defaultValue = {};
    if(cookies[key] && typeof cookies[key] === "string") {
      cookies[key] = JSON.parse(cookies[key]);
    }

    const value = cookies[key] ? cookies[key] : defaultValue;
    return value;
  }
  return {
    setCookieValue,
    getCookie,
    cookies,
    setCookieObject,
    getCookieObject
  }
};
