import React from "react";

import { Chip, ChipProps } from "@mui/material";

import { CourseDto } from "dto/course/course.dto";
import { Status } from "tools//types/status";

type Props = ChipProps & {
  obj: CourseDto;
};

const StatusChipCourse: React.FC<Props> = ({ obj, ...props }) => {
  const processColor = (value: number) => {
    switch (value) {
      case Status.COURSE_DRAFT:
        return "warning";
      case Status.COURSE_UNVERIFIED:
        return "error";
      case Status.COURSE_TO_BE_REVERIFIED:
        return "info";
      case Status.COURSE_PUBLISHED:
        return "success";
      case Status.COURSE_INCOMPLETE:
        return "info";
      case Status.COURSE_STARTED:
        return "info";
      case Status.COURSE_ON_GOING:
        return "info";
      case Status.COURSE_FINISHED:
        return "success";
      case Status.COURSE_DELISTED:
        return "error";
      default:
        return "primary";
    }
  };
  const processChip = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (!obj.status) return null;
    if (!obj.status_name) return null;
    const color = processColor(obj.status);

    return <Chip label={obj.status_name} {...props} color={color} />;
  };

  return processChip();
};

export { StatusChipCourse };
