import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";
import { ChatMessageRepository } from "repositories/course/chatmessage.repository";
import Idto from "interfaces/idto.interface";

export class ChatMessageService
  extends GeneralService<ChatMessageRepository>
  implements IService
{
  constructor() {
    super(
      new ChatMessageRepository(),
      [
        { key: "status", type: "status" },
        { key: "date", type: "timestamp" },
      ],
      "date",
      false
    );
  }

  addVoice = async (
      cb?: any,
      cbparameters?: any,
      data?: Idto
    ): Promise<any> => {
      cbparameters = cbparameters ? cbparameters : {};
      cbparameters._cb = cb;
      const t = await this.repository.addVoice(
        this.handleGet,
        cbparameters,
        data
      );
  
      if (cb === undefined) {
        return this.handleGet(t);
      }
    };
  
}