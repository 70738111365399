import { Box } from "@mui/material";
import ScrollView, { ScrollViewRef } from "devextreme-react/cjs/scroll-view";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import { ChatDto } from "dto/course/chat.dto";
import { ChatMessageDto } from "dto/course/chatmessage.dto";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ChatMessageService } from "services/course/chatmessage.service";
import { CommonTools } from "tools/utils/commontools";
import { FormChat } from "./FormChat";
import { useSocketMessage, useSocketSendMessage } from "hooks/useSocketMessage";
import { SendingMessage } from "./SendingMessage";
import { useResponsive } from "hooks/useResponsive";

import { ChatMessageGroupDate } from "./ChatMessageGroupDate";
import { Types } from "tools/types/types";
import { Config } from "tools/utils/config";
import { logger } from "tools/utils/logger";

type Props = {
  object: ChatDto;
};

const service = new ChatMessageService();
const ChatMessageList: React.FC<Props> = ({ object }) => {
  const id = CommonTools.processObjectField(object, ["id"]);
  const [objects, setObjects] = useState<Array<ChatMessageDto>>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [sentByMe, setSentByMe] = useState(false);
  const scrollViewRef = useRef<ScrollViewRef | null>(null);
  const { matchesCustom4 } = useResponsive();
  const actionFunction = () => {
    const scrollView = scrollViewRef.current;
    if (!scrollView) return;
    scrollView.instance().release(false);
  };

  const getDefaultState = () => {
    setObjects([]);
    setPage(1);
    setLoading(false);
    setTotalPages(0);
    setSentByMe(false);
  };

  const callbackAction = () => {
    const scrollView = scrollViewRef.current;
    if (!scrollView) return;
    scrollView.instance().release(true);
  };

  const getList = useCallback(() => {
    actionFunction();
    setLoading(true);
    service.getList(
      handleResult,
      {},
      new RequestListDTO(
        [RequestFilterDTO.prepareFilter("idchat", [id])],
        page,
        10,
        [RequestSortCriteriaDTO.prepareSortCriteria("date", true)],
        undefined,
        undefined,
        undefined,
        true
      )
    );
  }, [page, id]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleSetPage = (page: number) => {
    if (loading) return;
    if (page > totalPages) return;
    setPage(page);
  };
  const handleResult = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const localObjects: Array<ChatMessageDto> = result.objects ?? [];
    const totalPages: number = result.totalpages ?? 0;
    const newObjects = [...localObjects, ...objects];

    handleSetObjects(newObjects, page === 1);
    setTotalPages(totalPages);
    setLoading(false);
    callbackAction();
  };

  const handleUpdateList = (obj: ChatMessageDto) => {
    const newObjects = [...objects, obj];
    handleSetObjects(newObjects, false);
  };

  const handleSetObjects = (
    objects: Array<ChatMessageDto>,
    scroll: boolean
  ) => {
    let newObjects = objects.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    if (newObjects.length > 0) {
      setObjects(newObjects.filter((item) => item.idchat === id));
    } else {
      setObjects(newObjects);
    }
    if (scroll) {
      setTimeout(() => {
        scrollToEnd();
      }, 100);
    }
  };

  const onPullDown = () => {
    if (page >= totalPages) {
      callbackAction();
    }
    handleSetPage(page + 1);
  };

  // const processItem = (item: ChatMessageDto) => {
  //   if (!item) return null;
  //   return (
  //     <ChatMessageItem
  //       object={item}
  //       key={CommonTools.processObjectField(item, ["id"])}
  //     />
  //   );
  // };
  const [sendMessage] = useSocketSendMessage();
  const onMessage = (data: any) => {
    if (!data) return;
    processMessageReceived(data);
  };
  useSocketMessage("new-chat-message", onMessage);

  const onSubmit = (obj: ChatMessageDto) => {
    obj.idchat = id;
    processSendingMessage(obj);
    if (obj.type === Types.TYPE_CHAT_MESSAGE_AUDIO) {
      service.addVoice(() => {}, {}, obj);
    } else {
      sendMessage("chat-message", obj);
    }
    setSentByMe(true);
  };

  const [sendingMessage, setSendingMessage] = useState<ChatMessageDto | null>(
    null
  );
  const [loadingMessage, setLoadingMessage] = useState(false);
  const processSendingMessage = (obj: ChatMessageDto) => {
    setSendingMessage(obj);
    setLoadingMessage(true);
  };
  const processMessageReceived = (obj: ChatMessageDto) => {
    setLoadingMessage(false);
    setTimeout(() => {
      handleUpdateList(obj);
      setSendingMessage(null);
      if (sentByMe) {
        setTimeout(() => {
          scrollToEnd();
          setSentByMe(false);
        }, 100);
      }
    }, 1000);
  };

  useEffect(() => {
    getDefaultState();
  }, [id]);

  const scrollToEnd = () => {
    const scrollView = scrollViewRef.current;
    if (!scrollView) return;
    const element = document.getElementById("scroll-to-bottom");
    if (element) {
      scrollView.instance().scrollToElement(element);
    }
  };

  if (!object) return null;

  const heightBox = `calc(100vh - ${Config.HEADER_HEIGHT}px - ${
    loadingMessage ? 200 : 200
  }px)`;
  return (
    <Box
      className="chatShadow"
      sx={{
        background: "white",
        width: matchesCustom4 ? "100%" : "calc(100% - 316px)",
        height: "calc(100vh - " + Config.HEADER_HEIGHT + "px - 102px )",
        borderRadius: "0px 0px 10px 10px",
      }}>
      <ScrollView
        ref={scrollViewRef}
        onPullDown={onPullDown}
        showScrollbar="onScroll"
        scrollByContent
        scrollByThumb={true}
        bounceEnabled={true}
        // height={"calc(100vh - " + Config.HEADER_HEIGHT + "px - 200px )"}
        height={heightBox}
        reachBottomText="">
        <Box sx={{ height: 630 }}>
          <ChatMessageGroupDate
            objects={objects}
            sendingMessage={sendingMessage}
            loadingMessage={loadingMessage}
          />
        </Box>
      </ScrollView>
      <Box sx={{ height: "120px", mt: "10px" }}>
        <Box
          className="borderTopMessageBox"
          sx={{
            height: "100px",
            display: "flex",
            width: "100% !important",
            alignItems: "center",
          }}>
          <FormChat object={object} onSubmit={onSubmit} />
        </Box>
      </Box>
    </Box>
  );
};

export { ChatMessageList };
