import { Box, Typography } from "@mui/material";

import VideoPlayer from "components/system/video/VideoPlayer";
import { VideoDto } from "dto/system/video.dto";

import { FileTools } from "./filetools";
import { Types } from "tools/types/types";
import MyCheckboxField from "components/form/mycheckboxfield";
import { RouteTools } from "./routetools";

import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ChildCareRoundedIcon from "@mui/icons-material/ChildCareRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CollectionsIcon from "@mui/icons-material/Collections";
import FilePresent from "@mui/icons-material/FilePresent";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { CommonTools } from "./commontools";
import DateTools from "./date.tools";
import NumberTools from "./numbertools";
import { logger } from "./logger";

class ComponentCommonTools {
  public static handleSuccessAddAndEditSpecial = (
    result: any,
    cbparameters: any
  ) => {
    const mainObject = cbparameters?.mainObject;
    const idLanguage = cbparameters?.idLanguage ? cbparameters?.idLanguage : "";
    const idRoute = cbparameters?.idRoute ? cbparameters?.idRoute : "";
    // Should return error route
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (result === undefined) return RouteTools.setHistory("/", {});
    if (result.obj === undefined) return RouteTools.setHistory("/", {});
    if (!result.obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});

    let idObject = result.obj.id;
    if (idRoute !== "") idObject = idRoute;
    // const mainUrl = CommonTools.generateMainUrl(mainObject);
    const mainUrl =
      "/" +
      cbparameters.parentType +
      "/" +
      cbparameters.parentId +
      "/" +
      cbparameters.specialPart;
    const state = {
      obj: result.obj,
      _mainurl: mainUrl,
      idLanguage: idLanguage,
    };
    const detailUrl =
      "/" +
      cbparameters.parentType +
      "/" +
      cbparameters.parentId +
      "/" +
      cbparameters.specialPart +
      "/" +
      idObject;
    const route = detailUrl;
    // return RouteTools.setHistory(route, state);

    return RouteTools.setHistory(mainUrl, state);
  };

  public static handleSuccessAddAndEdit = (result: any, cbparameters: any) => {
    const mainObject = cbparameters?.mainObject;
    const idLanguage = cbparameters?.idLanguage ? cbparameters?.idLanguage : "";
    const idRoute = cbparameters?.idRoute ? cbparameters?.idRoute : "";
    // Should return error route
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (result === undefined) return RouteTools.setHistory("/", {});
    if (result.obj === undefined) return RouteTools.setHistory("/", {});
    if (!result.obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});

    let idObject = result.obj.id;
    if (idRoute !== "") idObject = idRoute;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const state = {
      obj: result.obj,
      _mainurl: mainUrl,
      idLanguage: idLanguage,
    };
    const detailUrl = CommonTools.generateDetailUrl(mainObject, idObject);
    const route = detailUrl;
    return RouteTools.setHistory(route, state);
  };

  public static goToMainPage = (result: any, cbparameters: any) => {
    // logger("ResultObj", result);
    const mainObject = cbparameters?.mainObject;
    // Should return error route
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (result === undefined) return RouteTools.setHistory("/", {});
    if (result.obj === undefined) return RouteTools.setHistory("/", {});
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const state = { _mainurl: mainUrl };
    const route = mainUrl;
    return RouteTools.setHistory(route, state);
  };

  public static goToDetails = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const detailUrl = CommonTools.generateDetailUrl(mainObject, idObject);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToDetailsSpecial = (
    _obj: any,
    mainObject: any,
    specialtype?: string
  ) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = "/" + _obj.parent + "/" + _obj.idparent + "/" + specialtype;
    const detailUrl =
      "/" +
      _obj.parent +
      "/" +
      _obj.idparent +
      "/" +
      specialtype +
      "/" +
      idObject;
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToEditSpecial = (
    _obj: any,
    mainObject: any,
    specialtype?: string
  ) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = "/" + _obj.parent + "/" + _obj.idparent + "/" + specialtype;
    const detailUrl =
      "/" +
      _obj.parent +
      "/" +
      _obj.idparent +
      "/" +
      specialtype +
      "/" +
      "edit" +
      "/" +
      idObject;
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToDetailsKeepingMainObject = (
    _obj: any,
    mainObject: any,
    otherMainObject?: string,
    field?: string
  ) => {
    // Should return error route

    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (otherMainObject == undefined) otherMainObject = "Course";
    if (field == undefined) field = "idcourse";
    if (!_obj.hasOwnProperty(field)) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(otherMainObject);
    const courseDetailUrl = CommonTools.generateDetailUrl(
      otherMainObject,
      _obj[field]
    );
    const detailUrl = CommonTools.generateDetailUrl(mainObject, idObject);
    const state = {
      obj: _obj,
      _mainurl: mainUrl,
      _detailCourseUrl: courseDetailUrl,
    };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToEdit = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToGallery = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateGalleryUrl(mainObject, idObject);
    // logger("goToGallery -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToAttachment = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateAttachmentUrl(mainObject, idObject);
    // logger("goToAttachment -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToVideo = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateVideoUrl(mainObject, idObject);
    // logger("goToVideo -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };
  public static columns_GetCheck = () => {
    return {
      field: "__check__",
      hideable: false,
      sortable: false,
      width: 1,
      align: "center",
      headerAlign: "center",
    };
  };

  public static columns_StandardColumn = (
    LL: any,
    field: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {};
    obj["field"] = field;
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;

    if (width != undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_StandardObjectColumn = (
    LL: any,
    objField: string,
    field: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          if (params.row.hasOwnProperty(objField) && params.row[objField]) {
            if (
              params.row[objField].hasOwnProperty(field) &&
              params.row[objField][field]
            ) {
              // return params.row[objField][field];
              // logger("Field",CommonTools.processObjectField(params, [
              //   "row",
              //   objField,
              //   field,
              // ]))
              return CommonTools.processObjectField(params, [
                "row",
                objField,
                field,
              ]);
            }
          }
        } else {
          return "";
        }
      },
    };

    obj["field"] = objField + field;
    obj["headerName"] = LL("headerName_" + objField + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;

    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_ConstantColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    field = field ?? "status";

    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          if (params.row.hasOwnProperty(field + "_name")) {
            // return params.row[field + "_name"];
            return CommonTools.processObjectField(params, [
              "row",
              `${field}_name`,
            ]);
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_ImageColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string,
    imgwidth?: number,
    imgheight?: number
  ) => {
    imgwidth = imgwidth ?? 50;
    imgheight = imgheight ?? 50;

    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        field = field ?? "idfile";
        if (params.row !== undefined) {
          if (params.row !== undefined && params.row.hasOwnProperty(field)) {
            // params.row[field];
            const fieldValue = CommonTools.processObjectField(params, [
              "row",
              field,
            ]);
            let idfile: string = "";
            if (fieldValue !== undefined && typeof fieldValue === "string") {
              idfile = fieldValue as string;
            }

            if (idfile) {
              const imgUrl = FileTools.getFileImage(
                idfile,
                "",
                imgwidth,
                imgheight
              );
              const aUrl = FileTools.getFileUrl(idfile);

              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <a href={aUrl} target="_blank">
                    <img
                      src={imgUrl}
                      width={imgwidth}
                      height={imgheight}
                      alt={""}
                    />
                  </a>
                </div>
              );
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_FileColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        field = field ?? "idfile";
        if (params.row !== undefined) {
          if (params.row !== undefined && params.row.hasOwnProperty(field)) {
            // params.row[field];
            const fieldValue = CommonTools.processObjectField(params, [
              "row",
              field,
            ]);
            let idfile: string = "";
            if (fieldValue !== undefined && typeof fieldValue === "string") {
              idfile = fieldValue as string;
            }

            if (idfile) {
              const aUrl = FileTools.getFileUrl(idfile);

              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <a href={aUrl} target="_blank" rel="noreferrer">
                    {idfile}
                  </a>
                </div>
              );
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_UrlColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        field = field ?? "url";
        if (params.row !== undefined) {
          if (params.row !== undefined && params.row.hasOwnProperty(field)) {
            // params.row[field];
            const aUrl = CommonTools.processObjectField(params, ["row", field]);

            if (aUrl) {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <a href={aUrl} target="_blank" rel="noreferrer">
                    {aUrl}
                  </a>
                </div>
              );
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_ActionsColumn_IsAvaible = (options: any) => {};

  public static columns_ActionsColumn = (
    LL: any,
    mainObject: any,
    deleteFunct?: any,
    options?: string[],
    inmenu?: string[],
    cbParent?: any,
    specialtype?: string
  ) => {
    const obj: any = {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params: any) => [
        options && options.indexOf("gallery") != -1 ? (
          <GridActionsCellItem
            icon={<CollectionsIcon />}
            label={LL("BTLabList_gallery")}
            title={LL("BTTitleList_gallery")}
            onClick={() => {
              ComponentCommonTools.goToGallery(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("gallery") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options && options.indexOf("attachment") != -1 ? (
          <GridActionsCellItem
            icon={<FilePresent />}
            label={LL("BTLabList_attachment")}
            title={LL("BTTitleList_attachment")}
            onClick={() => {
              ComponentCommonTools.goToAttachment(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("attachment") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options && options.indexOf("video") != -1 ? (
          <GridActionsCellItem
            icon={<OndemandVideoIcon />}
            label={LL("BTLabList_video")}
            title={LL("BTTitleList_video")}
            onClick={() => {
              ComponentCommonTools.goToVideo(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("video") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options && options.indexOf("edit") != -1 ? (
          <GridActionsCellItem
            icon={<Edit />}
            label={LL("BTLabList_Edit")}
            title={LL("BTTitleList_Edit")}
            onClick={() => {
              ComponentCommonTools.goToEdit(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("edit") != -1 ? true : false
            }
          />
        ) : (
          <></>
        ),
        options && options.indexOf("editspecial") != -1 ? (
          <GridActionsCellItem
            icon={<Edit />}
            label={LL("BTLabList_Edit")}
            title={LL("BTTitleList_Edit")}
            onClick={() => {
              ComponentCommonTools.goToEditSpecial(
                params.row,
                mainObject,
                specialtype
              );
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("editspecial") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options && options.indexOf("detail") != -1 ? (
          <GridActionsCellItem
            icon={<FileCopyIcon />}
            label={LL("BTLabList_Detail")}
            title={LL("BTTitleList_Detail")}
            onClick={() => {
              ComponentCommonTools.goToDetails(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("detail") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options && options.indexOf("detailspecial") != -1 ? (
          <GridActionsCellItem
            icon={<FileCopyIcon />}
            label={LL("BTLabList_Detail")}
            title={LL("BTTitleList_Detail")}
            onClick={() => {
              ComponentCommonTools.goToDetailsSpecial(
                params.row,
                mainObject,
                specialtype
              );
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("detailspecial") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("delete") != -1 &&
        CommonTools.processCanDelete(params.row) ? (
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={LL("BTLabList_Delete")}
            title={LL("BTTitleList_Delete")}
            onClick={() => {
              if (deleteFunct) deleteFunct(params.row);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("delete") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options && options.includes("children") ? (
          <GridActionsCellItem
            icon={<ChildCareRoundedIcon />}
            label={LL("BTLabList_children")}
            title={LL("BTTitleList_children")}
            onClick={() => {
              if (cbParent) cbParent(params.row);
            }}
            showInMenu={
              inmenu != undefined && inmenu.includes("children") ? true : false
            }
          />
        ) : (
          <></>
        ),
      ],
    };

    return obj;
  };
  public static processObjectField = (
    obj: any,
    field: any,
    numberOfZerosAfterDot?: number
  ) => {
    if (obj === undefined || obj === null) return <></>;
    if (field === undefined || !obj.hasOwnProperty(field)) return <></>;
    if (obj[field] === undefined || obj[field] === null) return <></>;

    const label = obj[field];
    if (typeof label === "number")
      return <>{NumberTools.formatNumber(label, numberOfZerosAfterDot)}</>;
    return <>{label}</>;
  };

  public static processObjectFieldTimeStamp = (obj: any, field: any) => {
    if (obj === undefined || obj === null) return <></>;
    if (field === undefined || !obj.hasOwnProperty(field)) return <></>;
    if (obj[field] === undefined || obj[field] === null) return <></>;
    if (obj[field] === 0) {
      return <>0</>;
    }
    if (typeof obj[field] !== "number") return <>{obj[field]}</>;

    const label = DateTools.displayTimestampInHHMM(obj[field]);

    return <>{label}</>;
  };

  public static processObjectFieldOfObject = (
    obj: any,
    fieldObject: any,
    field: any,
    numberOfZerosAfterDot?: number
  ) => {
    if (obj === undefined || obj === null) return <></>;
    if (fieldObject === undefined || field === undefined) return <></>;
    if (obj[fieldObject] === undefined || obj[fieldObject] === null)
      return <></>;
    if (
      obj[fieldObject][field] === undefined ||
      obj[fieldObject][field] === null
    )
      return <></>;

    const label = obj[fieldObject][field];
    if (typeof label === "number")
      return <>{NumberTools.formatNumber(label, numberOfZerosAfterDot)}</>;
    return <>{label}</>;
  };

  public static getFileBlock = (
    imgUrl: string,
    w?: number,
    h?: number,
    alt?: string,
    fullUrl?: string
  ) => {
    if (w === undefined) w = 200;
    if (h === undefined) h = 200;
    if (alt === undefined) alt = "";
    if (!imgUrl) return <></>;
    if (!fullUrl) {
      return (
        <Box>
          <img src={imgUrl} width={w} height={h} alt={alt} />
        </Box>
      );
    }
    return (
      <Box>
        <a href={fullUrl} rel="noreferrer" target="_blank">
          <img src={imgUrl} width={w} height={h} alt={alt} />
        </a>
      </Box>
    );
  };

  public static processFileLink = (obj: any, showComponent?: any) => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("_id")) return <></>;
    if (!obj._id) return <></>;
    if (!showComponent)
      showComponent = ComponentCommonTools.processObjectField(obj, "name");
    const aUrl = FileTools.getFileUrl(obj._id);

    return (
      <a href={aUrl} target="_blank" rel="noreferrer">
        {showComponent}
      </a>
    );
  };

  public static getFileBlockVideo = (
    obj: VideoDto,
    width?: number | string,
    height?: number | string,
    controls?: boolean
  ) => {
    if (!obj) return <></>;
    if (!obj.videoid) return <></>;
    if (!obj.videoserver) return <></>;
    if (!width) width = 530;
    if (!height) height = 300;
    if (!controls) controls = true;

    return (
      <VideoPlayer
        videoId={obj.videoid}
        platform={obj.videoserver}
        width={width}
        height={height}
        controls={controls}
      />
    );
  };

  public static getFileBlockImage = (
    obj: any,
    w?: number,
    h?: number,
    alt?: string,
    withLink?: boolean,
    style?: any
  ) => {
    if (!style) style = {};
    if (w === undefined) w = 200;
    if (h === undefined) h = 200;
    if (alt === undefined) alt = "";
    if (withLink === undefined) withLink = false;
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("_id")) return <></>;
    if (!obj._id) return <></>;
    if (!obj.hasOwnProperty("name")) return <></>;
    if (!obj.name) return <></>;
    const imgUrl = FileTools.getFileImage(obj._id, obj.name, w, h);
    const fullUrl = FileTools.getFileUrl(obj._id, obj.name);

    if (!imgUrl) return <></>;
    if (!withLink) {
      return (
        <Box sx={style}>
          <img src={imgUrl} width={w} height={h} alt={alt} style={style} />
        </Box>
      );
    }
    return (
      <Box sx={style}>
        <a href={fullUrl} rel="noreferrer" target="_blank">
          <img src={imgUrl} width={w} height={h} alt={alt} style={style} />
        </a>
      </Box>
    );
  };

  public static getFileBlockSVG(obj: any) {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("_id")) return <></>;
    if (!obj._id) return <></>;
    if (!obj.hasOwnProperty("name")) return <></>;
    if (!obj.name) return <></>;
    if (!obj.hasOwnProperty("type")) return <></>;
    if (!obj.type) return <></>;
    if (obj.type !== "image/svg+xml")
      return ComponentCommonTools.getFileBlockImage(obj);
    const fullUrl = FileTools.getFileUrl(obj._id, obj.name);

    return <object type={obj.type} data={fullUrl}></object>;
  }

  public static processCheckboxDelete = (
    obj: any,
    objValue: any,
    field: any,
    valueField: string,
    labelField: string,
    setCheck: any,
    LL: any
  ) => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty(field)) return <></>;
    if (!obj[field]) return <></>;

    const label =
      obj[field].hasOwnProperty(labelField) && obj[field][labelField]
        ? obj[field][labelField]
        : LL(field);

    const checkedValue =
      objValue[valueField] !== undefined ? objValue[valueField] : false;

    return (
      <MyCheckboxField
        id={valueField}
        name={valueField}
        _label={label}
        setObj={setCheck}
        checked={checkedValue}
        color="primary"
        size="small"
        _vresults={undefined}
      />
    );
  };

  public static handleSuccess = (result: any, cbparameters: any) => {
    if (!cbparameters) return;
    const errorRoute = cbparameters.errorRoute;
    const route = cbparameters.route;

    if (!errorRoute) return RouteTools.setHistory("/", {});
    if (!route) return RouteTools.setHistory(errorRoute, {});

    if (!result) return RouteTools.setHistory(errorRoute, {});
    if (result.err) return RouteTools.setHistory(errorRoute, {});
    if (!result.obj) return RouteTools.setHistory(errorRoute, {});

    return RouteTools.setHistory(route, {});
  };

  public static handleSuccessAndCreateRouteWithId = (
    result: any,
    cbparameters: any
  ) => {
    if (!cbparameters) return;
    const errorRoute = cbparameters.errorRoute;
    const route = cbparameters.route;
    const cb = cbparameters.cb ?? null;

    if (!errorRoute) return RouteTools.setHistory("/", {});
    if (!route) return RouteTools.setHistory(errorRoute, {});

    if (!result) return RouteTools.setHistory(errorRoute, {});
    if (result.err) return RouteTools.setHistory(errorRoute, {});
    if (!result.obj) return RouteTools.setHistory(errorRoute, {});
    if (!result.obj.hasOwnProperty("id"))
      return RouteTools.setHistory(errorRoute, {});
    if (!result.obj.id) return RouteTools.setHistory(errorRoute, {});
    const url = route + "/" + result.obj.id;
    if (cb) cb();
    return RouteTools.setHistory(url, {});
  };
  public static processTypesField = (
    obj: any,
    field: any,
    withTypography?: boolean
  ) => {
    withTypography = withTypography ? withTypography : false;
    // var key = uuid();

    if (obj == undefined) return <></>;
    if (!obj) return <></>;
    if (obj[field] == undefined) return <></>;
    if (!obj[field]) return <></>;

    const label = Types.LL(obj[field]);
    return withTypography ? (
      <Typography
      //  key={key}
      >
        {label}
      </Typography>
    ) : (
      <>{label}</>
    );
  };
}

export { ComponentCommonTools };
