import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useAuth } from "hooks/useAuth";
import { IconButton } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "components/elements/Tabs/TabPanel";

import { CircularLoading } from "components/elements/loading/CircularLoading";

import { RouteTools } from "tools/utils/routetools";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { CommonTools } from "tools/utils/commontools";
import { CourseDto } from "dto/course/course.dto";
import { CourseService } from "services/course/course.service";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { EditGeneralInfo } from "components/course/courseteacherinterface/EditGeneralInfo";
import EditCourseSettingsInfo from "components/course/courseteacherinterface/EditCourseSettings";

import EditCourseFilter from "components/course/courseteacherinterface/EditCourseFilter";
import { EditCourseMedia } from "components/course/courseteacherinterface/EditCourseMedia";

import EditCourseTest from "components/course/courseteacherinterface/EditCourseTest";
import CourseActions from "components/course/courseteacherinterface/CourseActions";
import { SelectCoursePrice } from "components/course/courseteacherinterface/old/SelectCoursePrice";
import EditCourseLesson from "components/course/courseteacherinterface/EditCourseLesson";
import EditOnlyOneCourseCategory from "components/course/courseteacherinterface/EditOnlyOneCourseCategory";
import useObject from "hooks/useObject";
import { CallbackType } from "interfaces/commontypes.interface";
import { CanEdit } from "components/course/courseaction/new/CanEditAction";
import { EditCoursePrice } from "components/course/courseteacherinterface/EditCoursePrice";

const service = new CourseService();

const TeacherEditCourse: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { LL } = useResource();
  const { user, teacher } = useAuth();

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<CourseDto | undefined>();
  // const [objectHasChanged, setObjectHasChanged] = useState<string>("");

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.get(id, cb, cbParams);
  };

  const [loading, obj, setObj] = useObject<CourseDto>(get, id, [id]);
  // const checkLoading = () => {
  //   if (!loading) return;
  //   if (obj === undefined) return;

  //   setLoading(false);
  // };

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : {};
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined) {
  //     service.get(id, loadObject, {});
  //   }
  // };

  // useEffect(() => {
  //   getObject();
  // }, [currentRoute, objectHasChanged]);

  // useEffect(() => {
  //   checkLoading();
  // }, [obj]);

  const [value, setValue] = React.useState(
    CommonTools.getAnchor(currentRoute, "courseActions")
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    RouteTools.setAnchor(newValue);
  };
  if (!user) return null;
  if (!teacher) return null;
  if (loading) return <CircularLoading />;
  if (!obj) return null;
  const canEdit = obj.can_edit ?? false;
  return (
    <React.Fragment>
      <Box className="container" display="flex" flexDirection="row" my={4}>
        <MyNavBt href={"/teacherinterface/course"} size="large">
          <IconButton color="primary">
            <ArrowBackIcon />
          </IconButton>
        </MyNavBt>
        <Typography variant="h2" textAlign="center" mx="auto">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }} className="container" mt={2}>
        <Box my={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            orientation="horizontal"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              maxWidth: { xs: "calc(100vw - 64px)", lg: "calc(100vw - 100px)" },
              paddingLeft: 0,
            }}
            className="tabVertical profileTeacher">
            <Tab
              className="tabsCourse"
              label={LL("Course_Actions")}
              value={"courseActions"}
            />
            <Tab
              className="tabsCourse"
              label={LL("General_Info")}
              value={"generalInfo"}
            />
            <Tab
              className="tabsCourse"
              label={LL("Course_Settings")}
              value={"courseSettings"}
            />
            <Tab
              className="tabsCourse"
              label={LL("Course_Price")}
              value={"coursePrice"}
            />
            <Tab
              className="tabsCourse"
              label={LL("Course_Category")}
              value={"courseCategory"}
            />
            <Tab
              className="tabsCourse"
              label={LL("Course_Filter")}
              value={"courseFilter"}
            />
            <Tab
              className="tabsCourse"
              label={LL("Course_Media")}
              value={"courseMedia"}
            />
            <Tab
              className="tabsCourse"
              label={LL("Course_Lesson")}
              value={"courseLesson"}
            />
            <Tab
              className="tabsCourse"
              label={LL("Course_Test")}
              value={"courseTest"}
            />
          </Tabs>
        </Box>
        <Box maxWidth="md" mx="auto">
          {value !== "courseActions" ? <CanEdit canEdit={canEdit} /> : null}
          <TabPanel value={value} index={"courseActions"}>
            <CourseActions
              currentRoute={currentRoute}
              setCourse={setObj}
              course={obj}
            />
          </TabPanel>

          <TabPanel value={value} index={"generalInfo"}>
            <EditGeneralInfo
              currentRoute={currentRoute}
              defaultObj={obj}
              setObjectDefault={setObj}
              canEdit={canEdit}
            />
          </TabPanel>
          <TabPanel value={value} index={"courseSettings"}>
            <EditCourseSettingsInfo
              currentRoute={currentRoute}
              setObjectDefault={setObj}
              canEdit={canEdit}
            />
          </TabPanel>
          <TabPanel value={value} index={"coursePrice"}>
            <EditCoursePrice
              currentRoute={currentRoute}
              course={obj}
              canEdit={canEdit}
            />
          </TabPanel>
          <TabPanel value={value} index={"courseCategory"}>
            <EditOnlyOneCourseCategory
              currentRoute={currentRoute}
              canEdit={canEdit}
            />
          </TabPanel>
          <TabPanel value={value} index={"courseFilter"}>
            <EditCourseFilter currentRoute={currentRoute} canEdit={canEdit} />
          </TabPanel>
          <TabPanel value={value} index={"courseMedia"}>
            <EditCourseMedia currentRoute={currentRoute} canEdit={canEdit} />
          </TabPanel>
          <TabPanel value={value} index={"courseLesson"}>
            <EditCourseLesson currentRoute={currentRoute} canEdit={canEdit} />
          </TabPanel>
          <TabPanel value={value} index={"courseTest"}>
            <EditCourseTest currentRoute={currentRoute} canEdit={canEdit} />
          </TabPanel>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { TeacherEditCourse };
