import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";

import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { CourseDto, DuplicateCourseDto } from "dto/course/course.dto";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/routetools";
import { useResponsive } from "hooks/useResponsive";
import {
  Box,
  Stack,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";

import { TypeCourseCardChip } from "./TypeCourseCardChip";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { StatusChipCourse } from "./StatusChipCourse";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { CourseService } from "services/course/course.service";
import { useMessage } from "hooks/useMessage";
import { useCookies } from "react-cookie";

import { CourseCardPriceContent } from "components/elements/courseelements/CourseCardPriceContent";

import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import {
  ContentCopy,
  Delete,
  HighlightOffOutlined,
  IosShareOutlined,
} from "@mui/icons-material";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";

import { NextLessonCourseBoundary } from "./NextLessonCourse";
import { ShareDialog } from "components/elements/MyDialog/ShareDialog";
import { DelistCourseFastAction } from "../courseaction/new/DelistCourseAction";

type Props = {
  obj: CourseDto;
  loading: boolean;
  loadAction?: any;
  updateObject: (obj: CourseDto) => void;
};

const service = new CourseService();
const TeacherCourseCard: React.FC<Props> = ({
  obj,
  loading,
  loadAction,
  updateObject,
}) => {
  const { downMD, matchesCustom3 } = useResponsive();
  const { LL } = useResource();
  const [imgUrl, setImgUrl] = useState<string>("");
  const [loadingActon, setLoadingAction] = useState<boolean>(false);

  const { registerDialog } = useMessage();
  const [, setCookies] = useCookies();
  const goCourseEdit = () => {
    if (!obj) return;
    if (!obj.id) return;
    setCookies("tabValueTeacherEditCourse", "generalInfo");
    const url = "/teacherinterface/editcourse/" + obj.id;
    RouteTools.setHistory(url, {});
  };

  const prepareGallery = useCallback(() => {
    if (!obj) return;
    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      (value: string) => {},
      310,
      180
    );
  }, [obj]);

  useEffect(() => {
    prepareGallery();
  }, [prepareGallery]);

  const processImage = () => {
    if (!obj) return null;
    // if (!imgUrl) return null;

    return imgUrl ? (
      <CardMedia className="imageCardTeacher" image={imgUrl} title="">
        {processCourseStatus()}
        {processCourseType()}
      </CardMedia>
    ) : (
      <Box className="imageCardTeacher noImagekBox">
        <InsertPhotoOutlinedIcon />
        {processCourseStatus()}
        {processCourseType()}
      </Box>
    );
  };

  const processCourseType = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("coursesettings")) return null;
    if (!obj.coursesettings) return null;
    return (
      <Box p={1} className="liveStatus">
        <TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} />
      </Box>
    );
  };

  const processNumberOfStudents = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("numberofstudents")) return null;
    if (!obj.numberofstudents) return null;
    return (
      <Box className="cardPaddingXY">
        <Typography component="span" variant="h5" fontWeight="bold">
          {ComponentCommonTools.processObjectField(obj, "numberofstudents", 0)}{" "}
          {processMaxNumberOfStudents()}
        </Typography>
        <Typography display="flex" className="color-boulder">
          <AppRegistrationOutlinedIcon color="primary" />
          {LL("Number_of_students_on course")}
        </Typography>
      </Box>
    );
  };
  const processMaxNumberOfStudents = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("coursesettings")) return null;
    if (!obj.coursesettings) return null;
    if (!obj.coursesettings.hasOwnProperty("maxnumberofstudents")) return null;
    if (!obj.coursesettings.maxnumberofstudents) return null;
    return (
      <Typography component="span" variant="h5" fontWeight="bold">
        {LL("out_of")}{" "}
        {CommonTools.processObjectField(obj, [
          "coursesettings",
          "maxnumberofstudents",
        ])}
      </Typography>
    );
  };

  const processCourseStatus = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (!obj.status) return null;
    return (
      <Box p={1} className="statuses chipStatus">
        <StatusChipCourse obj={obj} />
      </Box>
    );
  };

  const processCourseStatusOutlined = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (!obj.status) return null;
    return (
      <Box className="statuses " sx={{ display: { xs: "none", sm: "block" } }}>
        <StatusChipCourse variant="outlined" obj={obj} />
      </Box>
    );
  };
  //
  const processCoursePrice = () => {
    if (!obj) return null;
    return <CourseCardPriceContent obj={obj} />;
  };

  const processCourseNextLesson = () => {
    if (!obj) return null;
    return <NextLessonCourseBoundary object={obj} />;
  };

  const processCourseDetails = () => {
    if (!obj) return null;

    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Stack direction="column" className="detailsGeneral">
            <Box>
              <Typography component="span" className="lp">
                {LL("Language course")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {CommonTools.processObjectField(obj, ["language", "name"])}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("CourseDuration")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldTimeStamp(
                  obj,
                  "courseduration"
                )}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("NumberOfLessons")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {CommonTools.processObjectField(obj, ["numberoflessons"])}
              </Typography>
            </Box>
            {processNumberOfLessonsRemaining()}
          </Stack>
        </Stack>
      </Box>
    );
  };

  const processNumberOfLessonsRemaining = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("numberoflessonsremaining")) return null;
    if (!obj.numberoflessonsremaining) return null;
    return (
      <Box>
        <Typography component="span" className="lp">
          {LL("NumberOfLessonsRemaining")}
        </Typography>
        <Typography p={1} component="span" className="rp">
          {ComponentCommonTools.processObjectField(
            obj,
            "numberoflessonsremaining",
            0
          )}
        </Typography>
      </Box>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionDelete = () => {
    if (!obj) return;
    handleClose();
    registerDialog(
      LL("Confirm_delete_course"),
      LL(
        "Are_you_sure_you_want_to_delete_this_course_deleting_all_content_will_be_lost"
      ),
      { cb: handleDeleteByTeacher },
      null
    );
  };

  const handleActionDuplicate = () => {
    if (!obj) return;
    handleClose();
    registerDialog(
      LL("Confirm_duplicate_course"),
      LL("Are_you_sure_you_want_to_duplicate_this_course"),
      { cb: handelDuplicateCourse },
      null
    );
  };
  const handleDeleteByTeacher = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    if (!obj.canDelete) return;
    setLoadingAction(true);
    service.delete(obj.id, handleResult, {});
  };

  const handelDuplicateCourse = () => {
    if (!obj) return;

    const id = CommonTools.processObjectField(obj, ["id"]);
    if (!id) return;
    setLoadingAction(true);
    service.duplicate(handleResult, {}, new DuplicateCourseDto(id));
  };

  const handleResult = (result: ResultObjectDTO) => {
    setLoadingAction(false);
    if (!loadAction) return;
    loadAction();
  };

  const processButtonGoToClassroom = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;

    const url = "/teacherinterface/classroom/" + obj.id;
    return (
      <MyNavBt href={url} className="goToClass">
        {downMD ? (
          LL("Go_to_classroom")
        ) : (
          <KeyboardDoubleArrowRightOutlinedIcon />
        )}
      </MyNavBt>
    );
  };
  const [openDelist, setOpenDelist] = useState(false);
  const handleOpenDelist = () => {
    setOpenDelist(true);
  };
  const processCardActions = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;
    if (!obj.hasOwnProperty("fullurl")) return null;
    if (!obj.fullurl) return null;

    return (
      <CardActions
        sx={{ width: { xs: "50%", md: "auto" }, px: { xs: 0, md: "auto" } }}
      >
        <IconButton
          className={downMD ? "goToClassSettings" : ""}
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {downMD ? (
            <Typography>{LL("crsTeacherSettings")}</Typography>
          ) : (
            <MoreVertIcon />
          )}
        </IconButton>

        <Menu
          className={
            matchesCustom3
              ? "menuActions triangleRight"
              : "menuActions triangleLeft"
          }
          id="long-button"
          aria-labelledby="long-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: matchesCustom3 ? "left" : "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: matchesCustom3 ? "right" : "left",
          }}
          variant="menu"
        >
          <MenuItem onClick={handleActionDuplicate}>
            <ListItemIcon>
              <ContentCopy />
            </ListItemIcon>
            <ListItemText>{LL("Duplicate")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={onClickPreview}>
            <ListItemIcon>
              <RemoveRedEyeIcon />
            </ListItemIcon>
            <ListItemText>{LL("Preview")}</ListItemText>
          </MenuItem>

          {!obj.canDelete ? null : (
            <MenuItem onClick={handleActionDelete}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>{LL("Delete")}</ListItemText>
            </MenuItem>
          )}
          {processShareButton()}

          {obj.can_delist ? (
            <MenuItem onClick={handleOpenDelist}>
              <ListItemIcon>
                <HighlightOffOutlined />
              </ListItemIcon>
              <ListItemText>{LL("delist_course_btn")}</ListItemText>
            </MenuItem>
          ) : null}
        </Menu>
      </CardActions>
    );
  };

  const processShareButton = () => {
    if (!obj) return null;
    if (!obj.can_share) return null;
    if (!obj.hasOwnProperty("fullurl")) return null;
    if (!obj.fullurl) return null;

    let imageUri = "";

    if (obj.hasOwnProperty("gallery") && obj.gallery) {
      const gallery = CommonTools.getGalleryObjectLogo(obj.gallery);
      if (gallery) {
        const urlObject = CommonTools.getFileUrlsGallery(gallery);
        if (urlObject && urlObject.hasOwnProperty("aUrl") && urlObject.aUrl) {
          imageUri = urlObject.aUrl;
        }
      }
    }
    return (
      <MenuItem sx={{ px: 0, py: 0 }}>
        <ShareDialog
          sx={{ width: "100%", px: 2 }}
          url={obj.fullurl}
          imageUrl={imageUri}
          component={"div"}
          buttonContent={
            <React.Fragment>
              <ListItemIcon>
                <IosShareOutlined />
              </ListItemIcon>
              <ListItemText>{LL("Share")}</ListItemText>
            </React.Fragment>
          }
        />
      </MenuItem>
    );
  };
  const onClickPreview = () => {
    if (!obj) return;
    const url = CommonTools.processObjectField(obj, ["fullurl"]);
    window.open(url, "_blank");
  };
  const processName = () => {
    if (!obj) return null;
    return (
      <Typography variant="h5" fontWeight="bold" className="cardPaddingX">
        {ComponentCommonTools.processObjectField(obj, "name")}
      </Typography>
    );
  };

  const processTeacherCourseCard = () => {
    if (!obj) return null;

    return (
      <Box py={1} onClick={goCourseEdit} sx={{ cursor: "pointer" }}>
        <Card
          sx={{ mx: "auto", maxWidth: { xs: "600px", md: "none" } }}
          className="courseCardH teacherCards"
        >
          <Grid container className="content" spacing={2} alignItems="center">
            <Grid item lg={2} md={3}>
              {processImage()}
            </Grid>
            <Grid item lg={5} md={4} mt={0}>
              {processName()}
              <Box
                className="cardPaddingX"
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", sm: "space-between" },
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  alignItems: { xs: "start", sm: "end" },
                  gap: { xs: 2, sm: "auto" },
                  marginTop: 3,
                  spacing: 2,
                  // overflowX: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "row",
                    gap: 4,
                    flexWrap: "wrap",
                  }}
                >
                  {processCourseStatusOutlined()}
                  {processCoursePrice()}
                </Box>
                {processCourseDetails()}
              </Box>
            </Grid>
            <Grid
              className="teacherCourseCardSet cardPaddingX"
              item
              sm={6}
              lg={2}
              md={2}
              sx={{ paddingTop: "0px" }}
            >
              {processNumberOfStudents()}
            </Grid>
            <Grid
              className="teacherCourseCardSet"
              item
              lg={2}
              md={2}
              p={0}
              sx={{ paddingX: { xs: 2, sm: "auto" } }}
            >
              {processCourseNextLesson()}
            </Grid>

            <Grid
              item
              md={1}
              xs={12}
              p={0}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={downMD ? 2 : 0}
                sx={{ paddingX: { xs: 2, sm: "auto" } }}
              >
                {processButtonGoToClassroom()}
                {processCardActions()}
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Box>
    );
  };

  return loading ? (
    <Skeleton variant="rectangular" />
  ) : (
    <React.Fragment>
      <MyBackdrop open={loadingActon} />
      {processTeacherCourseCard()}
      <DelistCourseFastAction
        course={obj}
        setCourse={updateObject}
        open={openDelist}
        setOpen={setOpenDelist}
      />
    </React.Fragment>
  );
};

export { TeacherCourseCard };
