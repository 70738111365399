// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import ResultSignInDTO from "dto/auth/resultsignin.dto";
// import SignInDto from "dto/auth/signin.dto";
// import SignInHashDto from "dto/auth/signinhash.dto";
// import ModifyPasswordDto from "dto/user/modifypassword.dto";
// import { PostUserDto } from "dto/user/postuser.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import GeneralService from "services/general.service";

// import { UserRepository } from "repositories/user/user.repository";
// import ForgotPasswordDto from "dto/auth/forgotpassword.dto";

// import { RegisterUserDto } from "dto/auth/registeruser.dto";
// import { BecomeTeacherDto } from "dto/user/becometeacher.dto";
// import SocialSignInDto from "dto/auth/socialsignin.dto";

// export default class UserService extends GeneralService implements IService {
//   userRepository: UserRepository = new UserRepository();

//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }

//   handleSignUp(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultSignInDTO();
//     rez = result;
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async generateToken(cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.generateToken(this.handleSignIn, cbparameters);
//   }

//   async signUp(data: SignInDto, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.signUp(data, this.handleSignUp, cbparameters);
//   }

//   handleRegister(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultSignInDTO();
//     rez = result;
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async register(
//     data: RegisterUserDto,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.register(data, this.handleRegister, cbparameters);
//   }

//   handleRegisterTeacher(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultSignInDTO();
//     rez = result;
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async registerTeacher(
//     data: BecomeTeacherDto,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.registerTeacher(
//       data,
//       this.handleRegisterTeacher,
//       cbparameters
//     );
//   }

//   handleResetPasswordCode(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async resetPasswordCode(
//     data: ForgotPasswordDto,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.passwordResetCode(
//       data,
//       this.handleResetPasswordCode,
//       cbparameters
//     );
//   }

//   handleSignIn(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultSignInDTO();
//     rez = result;
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async refreshToken(cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.refreshToken(this.handleSignIn, cbparameters);
//   }

//   async signInSocial(
//     data: SocialSignInDto,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.signInSocial(data, this.handleSignIn, cbparameters);
//   }

//   async signIn(data: SignInDto, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.signIn(data, this.handleSignIn, cbparameters);
//   }

//   async signInHash(
//     data: SignInHashDto,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.signInHash(data, this.handleSignIn, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//       { key: "statusemail", type: "status" },
//       { key: "statuspassword", type: "status" },
//       { key: "registerdate", type: "timestamp" },
//       { key: "birthday", type: "date" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.userRepository.get(id, this.handleGet, cbparameters);
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [
//       { key: "status", type: "status" },
//       { key: "statusemail", type: "status" },
//       { key: "statuspassword", type: "status" },
//       { key: "registerdate", type: "timestamp" },
//     ]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     const t = await this.userRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: PostUserDto): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.add(this.handleAdd, cbparameters, data);
//   }

//   async modifyPassword(
//     cb?: any,
//     cbparameters?: any,
//     data?: ModifyPasswordDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     if (
//       data?.email !== undefined &&
//       data.currentpassword !== undefined &&
//       data.newpassword !== undefined
//     ) {
//       this.userRepository.modifyPassword(this.handleAdd, cbparameters, data);
//     }
//   }

//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     //// logger("Put rez", rez)
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: PostUserDto
//   ): Promise<any> {
//     //// logger("Put data", data)
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.update(id, this.handleUpdate, data, cbparameters);
//   }

//   handlePatch(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async patch(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: PostUserDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.patch(id, this.handlePatch, data, cbparameters);
//   }

//   async modifyPasswordByAdmin(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: PostUserDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRepository.modifyPasswordByAdmin(
//       id,
//       this.handlePatch,
//       data,
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.userRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = "email";
//     defaultRequestList.sortcriteria = [];
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "email";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { UserRepository } from "repositories/user/user.repository";

import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { PostUserDto } from "dto/user/postuser.dto";
import ModifyPasswordDto from "dto/user/modifypassword.dto";
import ResultSignInDTO from "dto/auth/resultsignin.dto";
import SignInDto from "dto/auth/signin.dto";
import SignInHashDto from "dto/auth/signinhash.dto";
import { BecomeTeacherDto } from "dto/user/becometeacher.dto";
import ForgotPasswordDto from "dto/auth/forgotpassword.dto";

import SocialSignInDto from "dto/auth/socialsignin.dto";
import { RegisterDto, RegisterUserDto } from "dto/auth/registeruser.dto";
import {
  ActivateEmailDto,
  ResetPasswordDto,
  SendCodeDto,
} from "dto/user/user.dto";

export class UserService
  extends GeneralService<UserRepository>
  implements IService
{
  constructor() {
    super(
      new UserRepository(),
      [
        { key: "status", type: "status" },
        { key: "statusemail", type: "status" },
        { key: "statuspassword", type: "status" },
        { key: "registerdate", type: "timestamp" },
        { key: "birthday", type: "date" },
      ],
      "email"
    );
  }

  modifyPassword = async (
    cb?: any,
    cbparameters?: any,
    data?: ModifyPasswordDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    if (
      data?.email !== undefined &&
      data.currentpassword !== undefined &&
      data.newpassword !== undefined
    ) {
      const t = this.repository.modifyPassword(
        this.handleGet,
        cbparameters,
        data
      );

      if (cb === undefined) {
        return this.handleGet(t);
      }
    }
  };

  modifyPasswordByAdmin = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: PostUserDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.modifyPasswordByAdmin(
      id,
      this.handleGet,
      data,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  patch = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: PostUserDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.patch(
      id,
      this.handleGet,
      data,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  refreshToken = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.refreshToken(
      this.handleSignIn,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  generateToken = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.generateToken(
      this.handleSignIn,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  generate = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.generateToken(this.handleGet, cbparameters);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  refresh = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.refreshToken(this.handleGet, cbparameters);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  changeLanguage = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.changeLanguage(
      id,
      this.handleSignIn,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  logout = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.logout(this.handleSignIn, cbparameters);

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  register = async (
    data: RegisterUserDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.register(
      data,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  registerNewUser = async (
    data: RegisterDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.registerNewUser(
      data,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  resetPassword = async (
    data: ResetPasswordDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.resetPassword(
      this.handleSignIn,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  activateEmail = async (
    data: ActivateEmailDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.activateEmail(
      this.handleSignIn,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  activateEmailSendCode = async (
    data: SendCodeDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.activateEmailSendCode(
      this.handleSignIn,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  signIn = async (
    data: SignInDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.signIn(
      data,
      this.handleSignIn,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  signInHash = async (
    data: SignInHashDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    // logger("handleSetToken signInHash service", data, cb, cbparameters);
    const t = await this.repository.signInHash(
      data,
      this.handleSignIn,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  handleSignIn = (result?: any, cbparameters?: any, data?: any): any => {
    let rez = new ResultSignInDTO();
    rez = result;

    if (result.err) {
      this.callbackError(cbparameters);
      return;
    }

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  };

  registerTeacher = async (
    data: BecomeTeacherDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.registerTeacher(
      data,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  resetPasswordCode = async (
    data: ForgotPasswordDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.passwordResetCode(
      data,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  signInSocial = async (
    data: SocialSignInDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.signInSocial(
      data,
      this.handleSignIn,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleSignIn(t);
    }
  };

  getDefaultRequestListSelect = (onPage?: number): RequestListDTO => {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "email";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  };
}
