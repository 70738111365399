import Idto from "interfaces/idto.interface";
import { CourseDto } from "./course.dto";


export class CourseSettingsDto implements Idto {
  id?: string;
  idtypecourse?: string;
  idschedule?: string;
  password?: string;
  hastest?: boolean;
  idtest?: string;
  typecourse?: any;
  idcourse?: string;
  maxnumberofstudents?: number;
  minnumberofstudents?: number;
}

export class CourseSettingsFormDto implements Idto {
  id?: string;
  idtypecourse?: string;
  password?: string;
  idcourse?: string;
  maxnumberofstudents?: number;
  minnumberofstudents?: number;

  constructor(
    id?: string,
    idtypecourse?: string,
    password?: string,
    idcourse?: string,
    maxnumberofstudents?: number,
    minnumberofstudents?: number
  ) {
    this.id = id || "";
    this.idtypecourse = idtypecourse || "";
    this.password = password || "";
    this.idcourse = idcourse || "";
    this.maxnumberofstudents = maxnumberofstudents || undefined;
    this.minnumberofstudents = minnumberofstudents || 0;
  }

  static fromCourseSettingsDto(
    object: CourseSettingsDto
  ): CourseSettingsFormDto {
    return new CourseSettingsFormDto(
      object.id,
      object.idtypecourse,
      object.password,
      object.idcourse,
      object.maxnumberofstudents,
      object.minnumberofstudents
    );
  }
}

export class PostCourseSettingsDto implements Idto {
  idtypecourse?: string;
  idschedule?: string;
  password?: string;
  hastest?: boolean;
  idtest?: string;
  typecourse?: any;
  idcourse?: string;
  maxnumberofstudents?: number;
}

export class CourseSettingsWithCourseDto implements Idto {
  course: CourseDto;
  coursesettings: CourseSettingsDto;

  constructor(course: CourseDto, coursesettings: CourseSettingsDto) {
    this.course = course;
    this.coursesettings = coursesettings;
  }
}
