import { Box, Grid, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { CountryCodeSelect } from "components/elements/Select/CountryCodeSelect";

import MyTextField from "components/form/mytextfield";
import { PhoneFormDto } from "dto/user/phone.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";


type Props = {
  defaultObj: PhoneFormDto;
  onSubmit: (obj: PhoneFormDto) => void;
  loading: boolean;
};

const FormPhone: React.FC<Props> = ({ defaultObj, onSubmit, loading }) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject, vResult] =
    useForm<PhoneFormDto>(
      defaultObj,
      RequiredValidator.getValidators(["countrycode", "phonenumber"])
    );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(PhoneFormDto.parseFromPhoneDto(defaultObj));
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }} maxWidth={"sm"} mx="auto">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">{LL("countrycode")}</Typography>
            <CountryCodeSelect
              value={obj.countrycode}
              field="countrycode"
              size="small"
              variant="standard"
              color="primary"
              className="form-select"
              setObj={setObjField}
              obj={obj}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">{LL("phonenumber")}</Typography>
            <Box className="box-form-input-icon-end">
              <MyTextField
                fullWidth
                id="phonenumber"
                name="phonenumber"
                variant="standard"
                className="form-input"
                _label={LL("phonenumber")}
                value={obj.phonenumber}
                setObj={setObjField}
                _vresults={vResult}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="h6">{LL("idtypephone")}</Typography>
            <PhoneTypeSelect
              value={obj.idtypephone}
              field="idtypephone"
              setObjectField={setObjField}
              label=""
            />
          </Grid> */}
        </Grid>
        <Box my={4} textAlign="center">
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            sx={{ gap: "4px" }}
            className="btn">
            <SaveOutlinedIcon />

            {LL("Bt_Submit_changes")}
          </MyButton>
        </Box>
      </Box>
    </form>
  );
};

export { FormPhone };
