import { CourseDto } from "dto/course/course.dto";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { CourseActionBox, CourseActionButton } from "./CourseActionElements";
import { PublishOutlined } from "@mui/icons-material";

import ResultObjectDTO from "dto/app/resultobject.dto";

import { CourseService } from "services/course/course.service";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  course: CourseDto;
  setCourse: (course: CourseDto) => void;
};

const service = new CourseService();
export const PublishCourseAction: React.FC<Props> = ({ course, setCourse }) => {
  const { LL } = useResource();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const id = CommonTools.processObjectField(course, ["id"]);
    if (!id) return;
    setLoading(true);
    service.publish(id, handleResultAction, {});
  };

  const handleResultAction = (result: ResultObjectDTO) => {
    setLoading(false);
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setCourse(result.obj);
  };

  const disabled = !course.can_publish;

  return (
    <CourseActionBox
      title={LL("SendCourseTopublishing")}
      showTooltip={disabled || loading}
    >
      <CourseActionButton disabled={disabled || loading} action={handleSubmit}>
        <PublishOutlined />
        {LL("publish_course_btn")}
      </CourseActionButton>
    </CourseActionBox>
  );
};
