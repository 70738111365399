import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/commontools";

export default class UserSettingsDto implements Idto {
  id?: string;
  iduser?: string;
  name?: string;
  surname?: string;
  birthday?: Date;
  idtypegender?: string;
  idsphone?: string[];
  idlanguage?: string;
  files?: any;
  deleteavatar?: boolean;
  idavatar?: string | null;
  idphysicaladdress?: string;
  avatar?: any;

  static prepareFullName(obj: UserSettingsDto): string {
    if (!obj) return "";
    if (
      !CommonTools.processObjectField(obj, ["name"]) &&
      !CommonTools.processObjectField(obj, ["surname"])
    )
      return "";
    if (!CommonTools.processObjectField(obj, ["surname"]))
      return CommonTools.processObjectField(obj, ["name"]);
    if (!CommonTools.processObjectField(obj, ["name"]))
      return CommonTools.processObjectField(obj, ["surname"]);
    return `${CommonTools.processObjectField(obj, [
      "name",
    ])} ${CommonTools.processObjectField(obj, ["surname"])}`;
  }
}

export class UserSettingsFormDto implements Idto {
  id?: string;
  iduser?: string;
  name?: string;
  surname?: string;
  birthday?: Date;
  idtypegender?: string;
  idlanguage?: string;
  files?: any;
  deleteavatar?: boolean;
  idavatar?: string | null;

  avatar?: any;

  constructor(
    id?: string,
    iduser?: string,
    name?: string,
    surname?: string,
    birthday?: Date,
    idtypegender?: string,
    idlanguage?: string,
    files?: any,
    deleteavatar?: boolean,
    idavatar?: string | null,
    avatar?: any
  ) {
    this.id = id || "";
    this.iduser = iduser || "";
    this.name = name || "";
    this.surname = surname || "";
    this.birthday = birthday || undefined;
    this.idtypegender = idtypegender || "";
    this.idlanguage = idlanguage || "";
    this.files = files || null;
    this.deleteavatar = deleteavatar || false;
    this.idavatar = idavatar || null;
    this.avatar = avatar || null;
  }

  static parseFromUserSettingsDto(obj?: UserSettingsDto): UserSettingsFormDto {
    if (!obj) return new UserSettingsFormDto();
    return new UserSettingsFormDto(
      obj.id,
      obj.iduser,
      obj.name,
      obj.surname,
      obj.birthday,
      obj.idtypegender,
      obj.idlanguage,
      obj.files,
      false,
      obj.idavatar,
      obj.avatar
    );
  }
}
