import { StudentDto } from "dto/user/student.dto";
import { TeacherDto } from "dto/user/teacher.dto";
import Idto from "interfaces/idto.interface";
import { CourseDto } from "./course.dto";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class ChatDto implements Idto {
  id?: string;
  status?: number;
  type?: number;
  idcourse?: string;
  idstudent?: string;
  idteacher?: string;
  date?: number;
  dateupdated?: number;
  name?: string;
  numberofparticipants?: number;

  course?: CourseDto;
  student?: StudentDto;
  teacher?: TeacherDto;
  static sortChatsByDate = (chats: Array<ChatDto>) => {
    return chats.sort((a, b) => {
      if (!a.dateupdated || !b.dateupdated) return 0;
      return a.dateupdated > b.dateupdated ? -1 : 1;
    });
  };
  
  static uniqueArray = (array: Array<ChatDto>) => {
    if(!array) return [];
    if(!array.length) return [];
    return array.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
  }
  constructor(
    id?: string,
    status?: number,
    type?: number,
    idcourse?: string,
    idstudent?: string,
    idteacher?: string,
    date?: number,
    dateupdated?: number,
    name?: string,
    numberofparticipants?: number
  ) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.type = type || Types.TYPE_CHAT_GROUP;
    this.idcourse = idcourse || "";
    this.idstudent = idstudent || "";
    this.idteacher = idteacher || "";
    this.date = date || 0;
    this.dateupdated = dateupdated || 0;
    this.name = name || "";
    this.numberofparticipants = numberofparticipants || 0;

  }
}
