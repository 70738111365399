import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { TeacherMediaVideoDto } from "dto/user/teacher.dto";
import { useForm } from "hooks/useForm";
import { useMessage } from "hooks/useMessage";
import { useResource } from "hooks/useResource";
import React from "react";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: TeacherMediaVideoDto;
  onSubmit: (obj: TeacherMediaVideoDto) => void;
  loading: boolean;
  onDelete: () => void;
  canDelete?: boolean;
};

const FormTeacherVideo: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  onDelete,
  canDelete = false,
}) => {
  const { LL } = useResource();
  const { registerDialog } = useMessage();
  const [obj, disabled, setObjField, , vResult] = useForm<TeacherMediaVideoDto>(
    defaultObj,
    RequiredValidator.getValidators(["idteacher", "location"])
  );

  const handleSubmit = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (disabled) return;
    onSubmit(obj);
  };

  const handleOnDelete = () => {
    setObjField("location", "");
    onDelete();
  };
  const handleDelete = () => {
    if (!canDelete) return;
    registerDialog(LL("delete_video"), LL("are_you_want_to_video"), {
      cb: handleOnDelete,
    });
  };

  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        gap: { xs: 2, sm: 4 },
        width: "100%",
        flexDirection: { xs: "column", sm: "column" },
      }}>
      <Box
        sx={{
          width: {
            xs: "100%",
          },
        }}>
        <MyTextField
          fullWidth
          id="location"
          name="location"
          variant="standard"
          color="primary"
          className="form-input-custom"
          _label={LL("location")}
          size="small"
          value={obj.location}
          setObj={setObjField}
          _vresults={vResult}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          justifyContent: "cener",
          alignItems: "center",
        }}>
        {canDelete ? (
          <MyButton
            sx={{ width: "100%" }}
            disabled={loading}
            color="error"
            className="btn"
            variant="outlined"
            size="large"
            type="button"
            cb={handleDelete}>
            {LL("Bt_Delete")}
          </MyButton>
        ) : null}
        <MyButton
          disabled={disabled || loading}
          sx={{ width: "100%" }}
          color="primary"
          className="btn"
          variant="outlined"
          size="large"
          type="button"
          cb={handleSubmit}>
          {LL("Bt_Submit")}
        </MyButton>
      </Box>
    </Box>
  );
};

export { FormTeacherVideo };
