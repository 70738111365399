import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import { LessonDto } from "dto/course/lesson.dto";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import EditLesson from "./EditLesson";

type Props = {
  objectFromList: LessonDto;
  idCourse: string;
  
  setIsLessonInEditMode: any;
  updateList: (obj: LessonDto) => void;
  deleteFromList: (id: string) => void;
  canEdit: boolean;
};

const EditCourseLessonItem: React.FC<Props> = ({
  objectFromList,
  idCourse,
  canEdit,
  
  setIsLessonInEditMode,
  updateList,
  deleteFromList,
}) => {
  const { LL } = useResource();

  const [expend, setExpend] = useState(false);

  const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpend(expanded);
    setIsLessonInEditMode(expend);
  };

  const processLessonItem = () => {
    if (!objectFromList) return null;
    if (!objectFromList.id) return null;

    return (
      <Accordion
        sx={{ my: 2 }}
        expanded={expend}
        className="accordionItem"
        onChange={handleExpend}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse", p: 1 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography className="name" ml={1}>
              {processName()}
            </Typography>
          </Stack>
        </AccordionSummary>
        {processLessonDetails()}
      </Accordion>
    );
  };

  const processName = () => {
    if (!objectFromList) return null;
    if (expend) return <>{LL("Edit_Lesson")}</>;
    return (
      <>{ComponentCommonTools.processObjectField(objectFromList, "name")}</>
    );
  };

  const processLessonDetails = () => {
    if (!objectFromList) return null;
    if (!objectFromList.id) return null;
    if (!expend) return null;

    return (
      <AccordionDetails className="accordionDetailLesson">
        <EditLesson
          id={objectFromList.id}
          
          idCourse={idCourse}
          defaultObj={objectFromList}
          updateList={updateList}
          deleteFromList={deleteFromList}
          canEdit={canEdit}
        />
      </AccordionDetails>
    );
  };

  return processLessonItem();
};

export { EditCourseLessonItem };
