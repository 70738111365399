import { useCallback, useContext, useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Loading } from "components/elements/loading/Loading";
import { MainLayout } from "components/general/MainLayout";
import { useSocketMessage } from "hooks/useSocketMessage";
import { JWTContext } from "./JWTProvider";
import { EventTypes } from "tools/types/eventtypes";


const AppRoutes: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { refresh } = useContext(JWTContext);
  // ----------------------------------------
  const onMessage = useCallback((data: any) => {
    
    refresh();
  }, []);
  useSocketMessage(EventTypes.EVENT_NEED_REFRESH_TOKEN, onMessage);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<MainLayout />} />
          <Route path="*" element={<MainLayout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
