import { Delete } from "@mui/icons-material";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";

import { MyButton } from "components/elements/button/MyButton";
import { StaticSelect } from "components/elements/Select/newSelect/StaticSelect";
import MyTextField from "components/form/mytextfield";
import {
  AnswerTestDto,
  QuestionTestDto,
  QuestionWithAnswerTestDto,
} from "dto/course/newtest.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import { Types } from "tools/types/types";
import RequiredValidator from "validators/required.validator";

type Props = {
  question: QuestionTestDto;
  answers: AnswerTestDto[];
  forAdd?: boolean;
  onSubmit: (data: QuestionWithAnswerTestDto) => void;
  onDelete?: (id: string) => void;
};

const FormQuestionTest: React.FC<Props> = ({
  question,
  answers,
  forAdd = false,
  onSubmit,
  onDelete,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, , vResult] = useForm(
    question,
    RequiredValidator.getValidators(["idtest", "name", "type"])
  );
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [answerObjects, setAnswerObjects] = useState<AnswerTestDto[]>(answers);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");

  const setAnswerField = (index: number, field: string, value: any) => {
    const updatedAnswers = JSON.parse(JSON.stringify(answerObjects));
    updatedAnswers[index][field] = value;
    setAnswerObjects(updatedAnswers);
  };

  const removeAnswer = (index: number) => {
    const updatedAnswers = answerObjects.filter((_, i) => i !== index);
    if (!forAdd && answerObjects[index].id) {
      setDeleteIds([...deleteIds, answerObjects[index].id ?? ""]);
    }
    setAnswerObjects(updatedAnswers);
  };

  const handleAddAnswer = () => {
    if (answerObjects.length === 0) setAnswerObjects([new AnswerTestDto()]);
    else setAnswerObjects([...answerObjects, new AnswerTestDto()]);
  };

  const calculatePositiveScoreSum = (answerObjects: AnswerTestDto[]) => {
    let sum = 0;
    for (const answer of answerObjects) {
      if (!answer.score) continue;
      if (answer.score > 0) {
        sum += answer.score;
      }
    }
    return sum;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    if (isFormDisabled) return;
    obj.totalscore = calculatePositiveScoreSum(answerObjects);
    onSubmit(
      new QuestionWithAnswerTestDto(obj, answerObjects, forAdd ? [] : deleteIds)
    );
  };

  const processAnswer = (answer: AnswerTestDto, index: number) => {
    return (
      <Box className="dividerColor">
        <Divider sx={{ my: 2 }} />

        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
          }}>
          <Box
            sx={{
              width: "100%",
              flexShrink: 0,
            }}>
            <Typography variant="h6" mb={1}>
              {LL("Answer")} {index + 1}
            </Typography>
            <Box className="box-form-input-icon-end">
              <MyTextField
                fullWidth
                id="name"
                name="name"
                variant="standard"
                color="primary"
                className="form-input"
                _label={LL("Name")}
                size="small"
                value={answer.name}
                setObj={(field: any, value: any) => {
                  setAnswerField(index, field, value);
                }}
                _vresults={vResult}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}>
            <Box sx={{ width: { xs: "100%", md: "30%" }, flexShrink: 0 }}>
              <Typography variant="h6" mb={1}>
                {LL("score")}
              </Typography>
              <Box className="box-form-input-icon-end">
                <MyTextField
                  fullWidth
                  id="score"
                  name="score"
                  variant="standard"
                  color="primary"
                  className="form-input"
                  _label={LL("score")}
                  size="small"
                  value={answer.score}
                  type="number"
                  setObj={(field: any, value: any) => {
                    setAnswerField(index, field, parseFloat(value));
                  }}
                  _vresults={vResult}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "calc(70% - 72px)" },
                flexShrink: 0,
              }}>
              <Typography variant="h6" mb={1}>
                {LL("Type")}
              </Typography>
              <StaticSelect
                value={answer.type}
                type="answers"
                service={Types}
                setObjField={(field: any, value: any) => {
                  setAnswerField(index, field, value);
                }}
                field="type"
                label=""
              />
            </Box>
            <Box
              sx={{
                width: "40px",
                flexShrink: 0,
                mt: { xs: "0px", md: "40px" },
              }}>
              <IconButton onClick={() => removeAnswer(index)}>
                <Delete />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const checkQuestionIsValid = useCallback(() => {
    let rez = false;
    let errorMessage = "";

    if (!obj.name) {
      rez = true;
      errorMessage = "Question_cannot_be_empty";
    }
    if (answerObjects.length === 0) {
      rez = true;
      errorMessage = "Question_must_have_at_least_one_answer";
    }

    let trueAnswerCount = 0;

    for (let i = 0; i < answerObjects.length; i++) {
      if (!answerObjects[i].name) {
        rez = true;
        errorMessage = "Answer_cannot_be_empty";
        break;
      }
      if (answerObjects[i].type === Types.TRUE_ANSWER) {
        trueAnswerCount++;
      }
    }

    if (obj.type === Types.TYPE_QUESTION_SINGLE_CHOICE) {
      if (trueAnswerCount !== 1) {
        rez = true;
        errorMessage = "Single_choice_question_must_have_one_true_answer";
      }
    } else {
      if (trueAnswerCount < 1) {
        rez = true;
        errorMessage =
          "Multiple_choice_question_must_have_at_least_one_true_answer";
      }
    }

    setIsFormDisabled(rez);
    setMessage(errorMessage);
  }, [obj, answerObjects]);

  useEffect(() => {
    checkQuestionIsValid();
  }, [checkQuestionIsValid]);

  const processInformativeMessage = () => {
    if (!message) return null;
    return (
      <Box sx={{ mt: 2 }}>
        <Typography>{LL(message)}</Typography>
      </Box>
    );
  };
  return (
    <Box sx={{ maxWidth: "calc(100vw - 100px)" }}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            mb: 1,
            justifyContent: "space-between",
            alignItems: "center",
            // background: "green",
            // width: "calc(100vw - 64px)",
            // overflow: "hidden",
            // width: "calc(100%)",
          }}>
          <Box
            sx={{
              width: { xs: "100%", md: "66%" },
              flexShrink: 0,
            }}>
            <Typography variant="h6" mb={1}>
              {LL("Question")}
            </Typography>
            <Box className="box-form-input-icon-end">
              <MyTextField
                fullWidth
                id="name"
                name="name"
                variant="standard"
                color="primary"
                className="form-input"
                _label={LL("Name")}
                size="small"
                value={obj.name}
                setObj={setObjField}
                _vresults={vResult}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "calc(33% - 16px)" },
              flexShrink: 0,
            }}>
            <Typography variant="h6" mb={1}>
              {LL("Type")}
            </Typography>
            <StaticSelect
              setObjField={setObjField}
              value={obj.type}
              type="questiontype"
              service={Types}
              label=""
            />
          </Box>
        </Box>
        <Stack direction={"column"} spacing={2} mt={2}>
          {answerObjects.map((answer, index) => {
            return processAnswer(answer, index);
          })}
          <MyButton
            cb={handleAddAnswer}
            color="primary"
            variant="contained"
            size="large"
            className="btn">
            {LL("Add_answer")}
          </MyButton>
        </Stack>
        {processInformativeMessage()}
        <Box
          textAlign="center"
          my={2}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            width: "100%",
            gap: 2,
            justifyContent: "space-between",
          }}>
          <MyButton
            disabled={isFormDisabled || disabled}
            color="primary"
            variant="contained"
            size="large"
            className="btn"
            type="submit"
            fullWidth
            sx={{
              mr: 2,
            }}>
            {LL("Bt_Submit")}
          </MyButton>
          {onDelete && (
            <MyButton
              color="secondary"
              variant="contained"
              size="large"
              className="btn"
              onClick={() => onDelete(obj.id ?? "")}
              fullWidth>
              {LL("Bt_Delete")}
            </MyButton>
          )}
        </Box>
      </form>
    </Box>
  );
};

export { FormQuestionTest };
