import { Alert, DialogContent, DialogTitle, Typography } from "@mui/material";
import { GeneralDialog } from "components/elements/MyDialog/GeneralDialog";
import { TeacherDto } from "dto/user/teacher.dto";
import {
  PostTeacherRequestDto,
  TeacherRequestProcessedProDto,
} from "dto/user/teacherrequest.dto";
import { useAuth } from "hooks/useAuth";
import useObject from "hooks/useObject";
import { CallbackType } from "interfaces/commontypes.interface";
import React, { useState } from "react";
import { TeacherRequestService } from "services/user/teacherrequest.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";
import { TeacherRequestButtonContent } from "./TeacherRequestButtonContent";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { TeacherRequestForm } from "./TeacherRequestForm";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { MyButton } from "components/elements/button/MyButton";
import { useResource } from "hooks/useResource";
import { logger } from "tools/utils/logger";

const service = new TeacherRequestService();
type Props = {
  teacher: TeacherDto;
  idTeacher: string;
  status: number;
};
const TeacherRequestNewDialog: React.FC<Props> = ({
  teacher,
  idTeacher,
  status,
}) => {
  const [open, setOpen] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const { LL } = useResource();
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;

    if (!status) return;
    service.verifyProcessed(id, status, cb, cbParams);
  };

  const [loading, object, setObject] = useObject<TeacherRequestProcessedProDto>(
    get,
    idTeacher,
    [idTeacher, status, teacher]
  );

  const onSubmit = (obj: PostTeacherRequestDto) => {
    setLoadingAction(true);
    service.become(handleResult, {}, obj);
  };
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) {
      setLoadingAction(false);
      return;
    }
    if (result.err) {
      setLoadingAction(false);
      return;
    }
    if (!result.obj) return;
    setObject(result.obj);
    setLoadingAction(false);
  };

  if (loading) return null;
  if (!object) return null;
  return (
    <React.Fragment>
      <MyButton cb={handleOpen}>
        <TeacherRequestButtonContent obj={teacher} />
      </MyButton>
      <GeneralDialog open={open} handleClose={handleClose}>
        <DialogTitle>
          <TeacherRequestButtonContent obj={teacher} />
        </DialogTitle>
        <DialogContent sx={{ width: "400px" }}>
          {object.processed ? (
            loadingAction ? (
              <CircularLoading />
            ) : (
              <TeacherRequestForm
                defaultObj={new PostTeacherRequestDto(idTeacher)}
                onSubmit={onSubmit}
                loading={loadingAction}
              />
            )
          ) : (
            <Alert severity="info">
              <Typography variant="body1">
                {LL("teacher_request_not_processed_yet")}
              </Typography>
            </Alert>
          )}
        </DialogContent>
      </GeneralDialog>
    </React.Fragment>
  );
};

const TeacherRequestBoundary: React.FC = () => {
  const { teacher } = useAuth();
  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);
  const status = CommonTools.processObjectField(teacher, ["status"]);
  
  if (!teacher) return null;
  if (!idTeacher) return null;
  if (!status) return null;
  if (parseInt(status.toString()) === Status.TEACHER_PRO) return null;
  return (
    <TeacherRequestNewDialog
      teacher={teacher}
      idTeacher={idTeacher}
      status={parseInt(status.toString())}
    />
  );
};

export { TeacherRequestBoundary };
