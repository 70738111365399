import React, { useState, useEffect, useCallback } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { Box, Skeleton, Typography } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { useMessage } from "hooks/useMessage";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { PostVideoForCourseDto, VideoDto } from "dto/system/video.dto";

import { VideoService } from "services/system/video.service";

import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";
import { Status } from "tools/types/status";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { FormCourseVideo } from "./FormCourseVideo";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { logger } from "tools/utils/logger";
import { useResponsive } from "hooks/useResponsive";

const service = new VideoService();

type Props = PageComponentProps & {
  canEdit: boolean;
};
const EditCourseVideo: React.FC<Props> = ({ currentRoute, canEdit }) => {
  const { LL, _idlanguage } = useResource();
  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const { registerDialog } = useMessage();
  const [defaultObj, setDefaultObj] = useState<PostVideoForCourseDto>(
    new PostVideoForCourseDto(id, _idlanguage)
  );
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
  const [object, setObject] = useState<VideoDto | null>(null);
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!id) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , setObjects] = useList(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("idparent", [id]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      1
    ),
    [id]
  );

  const processObjects = useCallback(() => {
    if (!objects) {
      setObject(null);
      return;
    }
    if (!objects.length) {
      setObject(null);
      return;
    }
    if (!objects[0]) {
      setObject(null);
      return;
    }
    setObject(objects[0]);
  }, [objects]);

  useEffect(() => {
    processObjects();
  }, [processObjects]);

  const onSubmit = (obj: PostVideoForCourseDto) => {
    if (!obj) return;
    registerDialog(
      LL("Confirm_edit_course"),
      LL(
        "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
      ),
      { cb: handleConfirmAddVideo.bind(null, obj) },
      null
    );
  };

  const handleConfirmAddVideo = (obj: PostVideoForCourseDto) => {
    if (!obj) return;
    setLoadingAdd(true);
    service.addForCourse(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setObjects([result.obj]);
    setDefaultObj(new PostVideoForCourseDto(id, _idlanguage));
    setLoadingAdd(false);
  };

  const onDelete = () => {
    if (!object) return;
    registerDialog(
      LL("Confirm_delete_course_video"),
      LL("Are_you_sure_you_want_to_delete_this_course_video"),
      { cb: handleConfirmDelete },
      null
    );
  };

  const handleConfirmDelete = () => {
    if (!object) return;
    if (!object.id) return;
    setLoadingAdd(true);
    service.delete(object.id, handleResultDelete, {});
  };

  const handleResultDelete = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    setObjects([]);
    setLoadingAdd(false);
  };

  const hasVideo = !!object;
  const { downSM } = useResponsive();

  const processVideoPlayer = () => {
    if (loading || loadingAdd)
      return <Skeleton variant="rectangular" height={200} />;
    if (!object) return null;
    let videoHeight = "160px";
    if (downSM) videoHeight = "25vh";
    return (
      <Box
        sx={{
          height: videoHeight,
          width: { sm: "284px" },

          borderRadius: "5px",
          marginTop: "14px",
          overflow: "hidden",
          "& > :first-child": {
            height: "100%",
            width: "100%",
          },
        }}
        display="flex"
        flexDirection="column"
        alignItems="center">
        {ComponentCommonTools.getFileBlockVideo(object, 530, 298)}
      </Box>
    );
  };

  return (
    <Box className="videoTeacher">
      <Box sx={{ flexGrow: 1, mt: 1, minWidth: "218px" }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Typography variant="h6"> {LL("Course_Video")}</Typography>
        </Box>
        <Box>
          <FormCourseVideo
            defaultObj={defaultObj}
            onSubmit={onSubmit}
            onDelete={onDelete}
            loading={loading || loadingAdd}
            canDelete={object !== null}
            canEdit={canEdit}
          />
        </Box>
      </Box>
      <Box>
        {hasVideo ? (
          processVideoPlayer()
        ) : (
          <Box className="fileLinkBox">
            <Box className="fileLinkBoxEmpty">
              <VideoLibraryIcon />
              {LL("no_video")}
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, mb: 1 }}>
        <Typography variant="h6"> {LL("Course_Video")}</Typography>
      </Box>
    </Box>
  );

  // const [loadingVideo, setLoadingVideo] = useState<boolean>(true);
  // const [postObjVideo, setPostObjVideo] = useState<PostVideoForCourseDto>(
  //   new PostVideoForCourseDto()
  // );
  // const [isFormDisabledVideo, setIsFormDisabledVideo] = useState(true);
  // const [objectHasChangedVideo, setObjectHasChangedVideo] =
  //   useState<string>("");

  // const validateAll = () => {
  //   if (!postObjVideo) return;
  //   setLoadingVideo(true);
  //   postObjVideo.idparent = id;
  //   postObjVideo.idlanguage = _idlanguage ?? _idlanguage;
  //   setPostObjVideo(postObjVideo);
  //   setLoadingVideo(false);
  // };

  // useEffect(() => {
  //   validateAll();
  // }, [postObjVideo]);

  // useEffect(() => {
  //   if (!postObjVideo.videolocation) setIsFormDisabledVideo(true);
  //   else setIsFormDisabledVideo(false);
  // }, [postObjVideo.videolocation]);

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(postObjVideo));
  //   t[field] = value;
  //   setPostObjVideo(t);
  // };

  // const processVideoInputField = () => {
  //   if (!postObjVideo) return null;
  //   return (
  //     <Box sx={{ minWidth: "350px" }}>
  //       <MyTextField
  //         tabIndex={0}
  //         fullWidth
  //         id="videolocation"
  //         name="videolocation"
  //         variant="standard"
  //         className="form-input"
  //         color="primary"
  //         _label={LL("videolocation")}
  //         value={postObjVideo.videolocation}
  //         setObj={setObjField}
  //         _vresults={vResult}
  //       />
  //     </Box>
  //   );
  // };

  // const [video, setVideo] = useState<VideoDto | undefined | null>();
  // const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(true);
  // const getListVideo = () => {
  //   if (!id) return;
  //   setIsLoadingVideo(true);
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 1;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = undefined;

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "idparent";
  //   f.values = [id];
  //   reqList.filters.push(f);

  //   service.getList(loadObjectsVideo, {}, reqList);
  // };

  // const loadObjectsVideo = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     if (objects.length > 0) setVideo(objects[0]);
  //     else setVideo(null);
  //   }
  // };

  // const checkLoadingVideo = () => {
  //   let load = false;
  //   if (video === undefined) load = true;
  //   setIsLoadingVideo(load);
  //   setLoadingVideo(load);
  // };

  // useEffect(() => {
  //   checkLoadingVideo();
  // }, [video]);

  // useEffect(() => {
  //   getListVideo();
  //   setPostObjVideo(new PostVideoForCourseDto());
  // }, [objectHasChangedVideo]);

  // const processVideoDelete = () => {
  //   if (!video) return null;
  //   return (
  //     <Box>
  //       <MyButton tabIndex={2} cb={handleDelete}>
  //         <DeleteIcon color="secondary" />
  //       </MyButton>
  //     </Box>
  //   );
  // };

  // const handleDelete = () => {
  //   if (!video) return;
  //   registerDialog(
  //     LL("Confirm_delete_course_video"),
  //     LL("Are_you_sure_you_want_to_delete_this_course_video"),
  //     { cb: handleConfirmDelete },
  //     null
  //   );
  // };

  // const handleConfirmDelete = () => {
  //   if (!video) return;
  //   if (!video.id) return;
  //   setLoadingVideo(true);
  //   service.delete(video.id, CommonTools.handleSuccessAddAndEditStayOnPage, {
  //     setObjectWasChanged: setObjectHasChangedVideo,
  //   });
  // };

  // const processVideoPlayer = () => {
  //   if (!video) return null;
  //   if (isLoadingVideo) return <Skeleton variant="rectangular" height={200} />;
  //   return <Box>{ComponentCommonTools.getFileBlockVideo(video, 200, 200)}</Box>;
  // };
  // const processVideoItem = () => {
  //   if (!postObjVideo) return null;
  //   if (loadingVideo) return <Skeleton variant="rectangular" height={200} />;
  //   if (isLoadingVideo) return <Skeleton variant="rectangular" height={200} />;
  //   return (
  //     <>
  //       <Typography variant="h3" textAlign="center" mt={5} mb={3}>
  //         {LL("Course_Video")}
  //       </Typography>
  //       <Box className="courseGallery">
  //         {processVideoPlayer()}
  //         <Box
  //           display="flex"
  //           flexDirection="row"
  //           alignItems="center"
  //           justifyContent="center"
  //           mt={3}
  //         >
  //           <form onSubmit={handleSubmitVideo}>
  //             <Stack direction="row">
  //               {processVideoInputField()}
  //               <MyButton
  //                 tabIndex={2}
  //                 disabled={isFormDisabledVideo}
  //                 type="submit"
  //               >
  //                 <SaveIcon />
  //               </MyButton>
  //             </Stack>
  //           </form>
  //           {processVideoDelete()}
  //         </Box>
  //       </Box>
  //     </>
  //   );
  // };

  // const handleSubmitVideo = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabledVideo) return false;

  //   registerDialog(
  //     LL("Confirm_edit_course"),
  //     LL(
  //       "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
  //     ),
  //     { cb: handleConfirmAddVideo },
  //     null
  //   );
  // };

  // const handleConfirmAddVideo = () => {
  //   if (postObjVideo) {
  //     setLoadingVideo(true);
  //     service.addForCourse(
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       { setObjectWasChanged: setObjectHasChangedVideo },
  //       postObjVideo
  //     );
  //   }
  // };

  // return processVideoItem();
};

export { EditCourseVideo };
