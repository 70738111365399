import { ChatMessageDto } from "dto/course/chatmessage.dto";
import { useAuth } from "hooks/useAuth";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { ChatMessageItemOwn } from "./ChatMessageItemOwn";
import { ChatMessageItemStranger } from "./ChatMessageStranger";
import { Types } from "tools/types/types";
import { AudioPlayerMessage } from "./AudioPlayerMessage";
import { FileTools } from "tools/utils/filetools";
import { Box, Typography } from "@mui/material";

type Props = {
  object: ChatMessageDto;
  displayAvatar?: boolean;
  customComponentAvatar?: React.ReactNode;
  isLast?: boolean;
};

const ChatMessageItem: React.FC<Props> = ({
  object,
  displayAvatar = true,
  customComponentAvatar = null,
  isLast = false,
}) => {
  const { user } = useAuth();
  const idUser = CommonTools.processObjectField(user, ["id"]);
  if (!user) return null;
  if (!idUser) return null;
  if (!object) return null;
  if (CommonTools.processObjectField(object, ["iduser"]) === idUser) {
    return (
      <ChatMessageItemOwn
        object={object}
        displayAvatar={displayAvatar}
        isLast={isLast}
      />
    );
  }
  return (
    <ChatMessageItemStranger
      object={object}
      displayAvatar={displayAvatar}
      customComponentAvatar={customComponentAvatar}
    />
  );
};

type ContentProps = {
  object: ChatMessageDto;
};
const ChatMessageItemContent: React.FC<ContentProps> = ({ object }) => {
  if (!object) return null;
  if (object.type === Types.TYPE_CHAT_MESSAGE_AUDIO) {
    const id = CommonTools.processObjectField(object, ["idfile"]);
    if (!id) return null;
    const url = FileTools.getFileUrl(id);
    return <AudioPlayerMessage url={url} />;
  }
  return (
    <Typography sx={{ wordBreak: "break-all" }}>
      {CommonTools.processObjectField(object, ["message"])}
    </Typography>
  );
};

export { ChatMessageItem, ChatMessageItemContent };
