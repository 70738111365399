import React, { useState } from "react";

import { useResource } from "hooks/useResource";

import { CircularLoading } from "components/elements/loading/CircularLoading";
import { useMessage } from "hooks/useMessage";
import { LinkCourseCategoryService } from "services/course/linkcoursecategory.service";
import { LinkCourseCategoryDto } from "dto/course/linkcoursecategory.dto";

import { Box } from "@mui/material";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { CallbackType } from "interfaces/commontypes.interface";
import useObject from "hooks/useObject";
import { FormOneCourseCategory } from "./FormOneCourseCategory";

const service = new LinkCourseCategoryService();

type Props = {
  currentRoute: any;
  canEdit: boolean;
};

const EditOnlyOneCourseCategory: React.FC<Props> = ({ currentRoute,canEdit }) => {
  const { LL } = useResource();

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<LinkCourseCategoryDto | undefined>(
  //   new LinkCourseCategoryDto()
  // );
  const { registerDialog } = useMessage();
  // const [isFormDisabled, setIsFormDisabled] = useState(false);
  // const [loadingForm, setLoadingForm] = useState<boolean>(true);

  // const [defaultObject, setDefaultObject] = useState<
  //   CourseCategoryDto | null | undefined
  // >(undefined);
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getByIdCourse(id, cb, cbParams);
  };

  const [loading, object, setObject] = useObject<LinkCourseCategoryDto>(
    get,
    id,
    [id]
  );
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const onSubmit = (obj: LinkCourseCategoryDto) => {
    if (!id) return;

    registerDialog(
      LL("Confirm_edit_course"),
      LL(
        "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
      ),
      { cb: handleConfirmEdit.bind(null, obj) },
      null
    );
  };

  const handleConfirmEdit = (obj: LinkCourseCategoryDto) => {
    if (!id) return;
    if (!obj) return;
    obj.idcourse = id;
    setLoadingAction(true);
    service.addOne(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO<LinkCourseCategoryDto>) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setObject(result.obj);
    setLoadingAction(false);
  };

  // const getDefaultObject = () => {
  //   setLoading(true);
  //   service.getByIdCourse(id, handelGetDefaultObject, {});
  // };

  // const handelGetDefaultObject = (result: ResultObjectDTO) => {
  //   if (result.err) return;
  //   let obj: CourseCategoryDto | null = null;
  //   const mainObj: LinkCourseCategoryDto | null = result.obj
  //     ? result.obj
  //     : null;

  //   if (mainObj) {
  //     obj = mainObj.coursecategory ? mainObj.coursecategory : null;
  //   } else {
  //     obj = null;
  //   }
  //   setDefaultObject(obj);
  // };
  // const checkLoading = () => {
  //   let loading = false;
  //   if (defaultObject === undefined) loading = true;
  //   setLoading(loading);
  // };

  // useEffect(() => {
  //   checkLoading();
  // }, [defaultObject]);

  // const validateAll = () => {
  //   if (!obj) return;
  //   setLoadingForm(true);

  //   obj.idcoursecategory =
  //     obj.idcoursecategory !== undefined ? obj.idcoursecategory : "";

  //   setObj(obj);
  //   setLoadingForm(false);
  //   // let t = validateField("value", obj.value);
  // };

  // useEffect(() => {
  //   validateAll();
  // }, [obj]);

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (!obj) return;

  //   if (id !== undefined) {
  //     registerDialog(
  //       LL("Confirm_edit_course"),
  //       LL(
  //         "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
  //       ),
  //       { cb: handleConfirmEdit },
  //       null
  //     );
  //   }
  // };
  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);
  // };

  // const handleConfirmEdit = () => {
  //   if (obj !== undefined && id !== undefined) {
  //     obj.idcourse = id;
  //     service.addOne(handleCallbackConfirmEdit, {}, obj);
  //   }
  // };

  // const handleCallbackConfirmEdit = (result: any) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     setLoading(true);
  //     setLoadingForm(true);
  //     setObj(new LinkCourseCategoryDto());
  //     setDefaultObject(undefined);
  //     getDefaultObject();
  //   }
  // };

  // useEffect(() => {
  //   getDefaultObject();
  // }, [id]);

  // useEffect(() => {
  //   validateAll();
  // }, []);
  // //   // logger("EditOnlyOneCourseCategory",defaultObject)
  // const processForm = () => {
  //   if (!obj) return <></>;
  //   if (defaultObject === undefined) return <CircularLoading />;
  //   if (loadingForm) return <Skeleton variant="rectangular" height={100} />;
  //   return (
  //     <form onSubmit={handleSubmit}>
  //       <Typography variant="h6" mb={1}>
  //         {LL("Select course category")}
  //       </Typography>
  //       <CourseCategoryAutocomplete
  //         tabIndex={0}
  //         id="idcoursecategory"
  //         variant="standard"
  //         color="primary"
  //         field="idcoursecategory"
  //         setObj={setObj}
  //         obj={obj}
  //         setObjFieldGeneral={setObjField}
  //         _defaultValue={defaultObject}
  //       />

  //       <Box mt={4} textAlign="center">
  //         <MyButton
  //           tabIndex={1}
  //           disabled={isFormDisabled}
  //           color="primary"
  //           variant="contained"
  //           className="btn"
  //           type="submit"
  //           children={LL("Bt_Submit")}
  //         />
  //       </Box>
  //     </form>
  //   );
  // };

  // return !loading ? <Box>{processForm()}</Box> : <CircularLoading />;

  if (loading) return <CircularLoading />;
  if (!object) return null;
  return (
    <Box sx={{ maxWidth: "600px", mx: "auto" }} mb={4}>
      <FormOneCourseCategory
        defaultObj={object}
        loading={loading || loadingAction}
        onSubmit={onSubmit}
        canEdit={canEdit}
      />
    </Box>
  );
};

export default EditOnlyOneCourseCategory;
