import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import { TeacherFAQService } from "services/user/teacherfaq.service";

import { TeacherFAQDto } from "dto/user/teacherfaq.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

import { TeacherFAQCard } from "components/user/teacher/TeacherFAQCard";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import MyTextField from "components/form/mytextfield";
import SearchIcon from "@mui/icons-material/Search";
import { NoResult } from "components/general/NoResult";

const service = new TeacherFAQService();

type Props = {
  idCourse: string;
};

const TeacherFAQList: React.FC<Props> = ({ idCourse, ...props }) => {
  const { LL, CC } = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<TeacherFAQDto>>([]);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("OnPageTeacherFAQList", "10"))
  );

  const [sortCriteria, setSortCriteria] = useState<
    Array<RequestSortCriteriaDTO>
  >(new Array<RequestSortCriteriaDTO>());
  const [search, setSearch] = useState("");
  const [searchReq, setSearchReq] = useState("");

  const getList = () => {
    setIsLoading(true);
    const reqList = new RequestListDTO();
    reqList.onpage = onPage;
    reqList.page = 1;
    reqList.filters = [];
    reqList.sortcriteria = sortCriteria;

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "showoncourse";
    f.values = ["true"];
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idcourse";
    f.values = [idCourse];
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "search";
    f.values = [searchReq];
    reqList.filters.push(f);

    service.getList(loadObjects, {}, reqList);
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  };
  const defaultSortCriteria = () => {
    if (!sortCriteria || sortCriteria.length === 0) {
      const sortCriteria = new RequestSortCriteriaDTO();
      sortCriteria.field = "date";
      sortCriteria.asc = false;
      setSortCriteria([sortCriteria]);
    }
  };

  useEffect(() => {
    if (sortCriteria && sortCriteria.length > 0) getList();
  }, [idCourse, sortCriteria, searchReq, onPage]);

  useEffect(() => {
    defaultSortCriteria();
  }, []);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processTeacherFAQItem = (obj: TeacherFAQDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <TeacherFAQCard obj={obj} />
      </Box>
    );
  };

  const processSortSelect = () => {
    if (isLoading) return null;

    const objects = ["date_asc", "date_desc"];
    return (
      <Box
        sx={{
          "& label": {
            display: "none",
          },
        }}>
        <MySortSelect
          objects={objects}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"
        />
      </Box>
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    setSearchReq(search);
  };

  const processSearch = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Box className="form-input-box" display="flex" alignItems="center">
          <SearchIcon />
          <MyTextField
            fullWidth
            id="search"
            name="search"
            _label={LL("Search by message")}
            value={search}
            setObj={(field: any, value: any) => {
              setSearch(value);
            }}
            _vresults={undefined}
          />
        </Box>
      </form>
    );
  };

  const processLoadMoreObjects = () => {
    if (!objects || !objects.length) return null;
    return (
      <LoadMoreButton
        objects={objects}
        total={total}
        onPage={onPage}
        setOnPage={setOnPage}
        step={parseInt(CC("DefaultOnPageTeacherFAQListStep", "5"))}
        className="loadMorebtn"
      />
    );
  };

  const processTeacherFAQList = () => {
    if (!objects.length) return <NoResult identifier="teacher_faq" />;

    return (
      <Box>
        {objects.map((item: TeacherFAQDto, i: number) => {
          return processTeacherFAQItem(item, i);
        })}
        {processLoadMoreObjects()}
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          marginBottom: 2,
        }}>
        <Box sx={{ width: { xs: "100%", md: "75%" } }}>{processSearch()}</Box>
        <Box sx={{ width: { xs: "100%", md: "25%" } }}>
          {processSortSelect()}
        </Box>
      </Box>
      {isLoading ? <Skeleton /> : processTeacherFAQList()}
    </Box>
  );
};

export { TeacherFAQList };
