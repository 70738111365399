import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class AbuseDto implements Idto {
  id?: string;
  status?: number;
  type?: number;
  idmannager?: string;
  idstudent?: string;
  idteacher?: string;
  date?: number;
  responsedate?: number;
  message?: string;
  response?: string;
  iduser?: string;
  typesender?: number;
  idparent?: string;
  parent?: string;

  constructor(
    id?: string,
    status?: number,
    type?: number,
    idmannager?: string,
    idstudent?: string,
    idteacher?: string,
    date?: number,
    responsedate?: number,
    message?: string,
    response?: string,
    iduser?: string,
    typesender?: number,
    idparent?: string,
    parent?: string
  ) {
    this.id = id || "";
    this.status = status || Status.UNPROCESSED;
    this.type = type || Types.TYPE_VOCABULARY_ABUSE;
    this.idmannager = idmannager || "";
    this.idstudent = idstudent || "";
    this.idteacher = idteacher || "";
    this.date = date || 0;
    this.responsedate = responsedate || 0;
    this.message = message || "";
    this.response = response || "";
    this.iduser = iduser || "";
    this.typesender = typesender || Types.TYPE_SENDER_STUDENT;
    this.idparent = idparent || "";
    this.parent = parent || "";
  }
}

export class PostAbuseDto implements Idto {
  iduser?: string;
  typesender?: number;
  idparent?: string;
  parent?: string;
  status?: number;
  type?: number;
  idstudent?: string;
  idteacher?: string;
  message?: string;

  constructor(
    iduser?: string,
    typesender?: number,
    idparent?: string,
    parent?: string,
    status?: number,
    type?: number,
    message?: string,
    idstudent?: string,
    idteacher?: string
  ) {
    this.iduser = iduser || "";
    this.typesender = typesender || Types.TYPE_SENDER_STUDENT;
    this.idparent = idparent || "";
    this.parent = parent || "";
    this.status = status || Status.UNPROCESSED;
    this.type = type || Types.TYPE_VOCABULARY_ABUSE;
    this.idstudent = idstudent || "";
    this.idteacher = idteacher || "";
    this.message = message || "";
  }

  static prepareAbuseObjectStudent(
    iduser: string,
    idstudent: string,
    idparent: string,
    parent: string
  ): PostAbuseDto {
    return new PostAbuseDto(
      iduser,
      Types.TYPE_SENDER_STUDENT,
      idparent,
      parent,
      Status.UNPROCESSED,
      Types.TYPE_VOCABULARY_ABUSE,
      "",
      idstudent,
      ""
    );
  }

  static prepareAbuseObjectTeacher(
    iduser: string,
    idteacher: string,
    idparent: string,
    parent: string
  ): PostAbuseDto {
    return new PostAbuseDto(
      iduser,
      Types.TYPE_SENDER_TEACHER,
      idparent,
      parent,
      Status.UNPROCESSED,
      Types.TYPE_VOCABULARY_ABUSE,
      "",
      "",
      idteacher
    );
  }
}
