import React from "react";
import { useResource } from "hooks/useResource";

import { CourseService } from "services/course/course.service";

import { CourseDto } from "dto/course/course.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Box, Link, Typography } from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { CommonTools } from "tools/utils/commontools";
import { TeacherDto } from "dto/user/teacher.dto";
import { SkeletonCourseBlock } from "components/elements/Skeleton/Skeleton";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";
import { useSlidesToShow } from "hooks/useSlidesToShow";

const service = new CourseService();

type Props = {
  idTeacher: string;
  idCourse: string;
  teacher: TeacherDto;
};

const CourseListByTeacher: React.FC<Props> = ({
  idTeacher,
  idCourse,
  teacher,
}) => {
  const { LL, CC } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseDto>>([]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idTeacher) return;
    if (!idCourse) return;
    service.getListByTeacher(cb, cbParams, req);
  };

  const defSlides = parseInt(
    CC("DefaultValueShowCourseListByValueCarousel", "3")
  );

  const [loading, objects] = useList<CourseDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("idteacher", [idTeacher]),
        RequestFilterDTO.prepareFilter("status", Status.DISPLAY_ON_SITE_COURSES),
        RequestFilterDTO.prepareFilter("notidcourse", [idCourse]),
      ],
      1,
      parseInt(CC("course_list_by_teacher", "10")),
      [RequestSortCriteriaDTO.prepareSortCriteria("popularidentifier", false)]
    ),
    [idTeacher, idCourse]
  );

  const { slidesToShow } = useSlidesToShow({
    objects: objects || [],
    parseInt: defSlides,
  });

  // const getList = () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 10;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];
  //   let sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "popularidentifier";
  //   sortCriteria.asc = false;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);
  //   f = new RequestFilterDTO();
  //   f.field = "idteacher";
  //   f.values = [idTeacher];
  //   reqList.filters.push(f);
  //   f = new RequestFilterDTO();
  //   f.field = "notidcourse";
  //   f.values = [idCourse];
  //   reqList.filters.push(f);

  //   setIsLoading(true);

  //   service.getListByTeacher(loadObjects, {}, reqList);

  //   // setIsLoading(false);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];

  //     setObjects(objects);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   if (!objects) load = true;
  //   // if (objects.length === 0) load = true;

  //   setIsLoading(load);
  // };
  // useEffect(() => {
  //   if (!idTeacher) return;
  //   if (!idCourse) return;
  //   getList();
  // }, [idTeacher, idCourse]);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processCourseItem = (obj: CourseDto, index: number) => {
    // logger("InfoCourseLevel", obj);
    if (!obj) return null;
    return (
      <Box key={index}>
        <CourseCard obj={obj} loading={loading} />
      </Box>
    );
  };

  const processCourseList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <Box>
        <Box>
          <Typography variant="h3" mt={5} mb={3}>
            {LL("OtherCoursesByTeacher")}
            <Link
              sx={{ ml: 1, textDecoration: "none" }}
              href={teacher.fullurl}
              target="_blank"
              rel="noopener"
            >
              {ComponentCommonTools.processObjectField(teacher, "fullname")}
            </Link>
          </Typography>
        </Box>

        <Carousel _slidesToShow={slidesToShow}>
          {objects.map((item: CourseDto, i: number) => {
            return processCourseItem(item, i);
          })}
        </Carousel>
      </Box>
    );
  };

  return loading ? <SkeletonCourseBlock /> : <Box>{processCourseList()}</Box>;
};

export { CourseListByTeacher };
