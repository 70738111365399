import { Box } from "@mui/material";
import { Scheduler } from "components/elements/scheduler/Scheduler";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ScheduleDto } from "dto/course/schedule.dto";
import { useAuth } from "hooks/useAuth";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import React from "react";
import { ScheduleService } from "services/course/schedule.service";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/common/data";
import DateTools from "tools/utils/date.tools";
import ResultListDTO from "dto/app/resultlist.dto";

const service = new ScheduleService();

const myCustomStore = new CustomStore({
  key: "id",
  async load(loadOptions: LoadOptions) {
    const startDate = DateTools.getDateTimeStamp(loadOptions.startDate || null);
    const endDate = DateTools.getDateTimeStamp(loadOptions.endDate || null);
    if (!startDate || !endDate)
      return {
        data: [],
      };
    try {
      const response: ResultListDTO = await service.getListCalendarTeacher(
        undefined,
        undefined,
        new RequestListDTO(
          [
            RequestFilterDTO.prepareFilter("typedestenation", [
              Types.TYPE_SCHEDULE_TEACHER.toString(),
            ]),
            RequestFilterDTO.prepareFilter("startdategte", [
              startDate.toString(),
            ]),
            RequestFilterDTO.prepareFilter("enddatelte", [endDate.toString()]),
          ],
          1,
          -1
        )
      );
      const objects = response.objects || [];
      const data = ScheduleDto.parseAppointments(objects);
      return {
        data,
      };
    } catch (e) {}
  },
});

const CalendarBlock: React.FC<PageComponentProps> = () => {
  const { teacher } = useAuth();
  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);

  if (!idTeacher) return null;
  return (
    <Box>
      <Scheduler dataStore={myCustomStore} />
    </Box>
  );
};

export { CalendarBlock };
