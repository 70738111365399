import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { StaticSelect } from "components/elements/Select/newSelect/StaticSelect";
import MyTextField from "components/form/mytextfield";
import { PostAbuseDto } from "dto/support/abuse.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import { Types } from "tools/types/types";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: PostAbuseDto;
  onSubmit: (obj: PostAbuseDto) => void;
  loading: boolean;
};

const FormAbuse: React.FC<Props> = ({ defaultObj, onSubmit, loading }) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject, vResult] =
    useForm<PostAbuseDto>(
      defaultObj,
      RequiredValidator.getValidators(["message"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("type_abuse")}
          </Typography>
          <StaticSelect
            label=""
            setObjField={setObjField}
            service={Types}
            type="abusetype"
            field="type"
            value={obj.type}
          />
        </Box>

        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Message")}
          </Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              multiline
              minRows={4}
              tabIndex={0}
              fullWidth
              id="message"
              name="message"
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("message")}
              size="small"
              value={obj.message}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3} sx={{ textAlign: "center" }}>
          <MyButton
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
          >
            {LL("Bt_send_request")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormAbuse };
