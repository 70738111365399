import Idto from "interfaces/idto.interface";

export type LikeDislike = { likes: number; dislikes: number };

export class GeneralRatingDto implements Idto {
  rating?: number;

  static calculateLikeDislike = (
    total: number,
    rating: number
  ): LikeDislike => {
    const likes = (total + rating) / 2;
    const dislikes = total - likes;
    return { likes, dislikes };
  };
}
