import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/commontools";

export class PostRecommendationPriceDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idrecommendationprice?: string;

  name?: string;
  idpricepolicy?: string;
  idcurrency?: string;
  price?: number;
}

export class RecommendationPriceDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idrecommendationprice?: string;
  language?: any;
  status_name?: string;
  name?: string;
  idpricepolicy?: string;
  idcurrency?: string;
  price?: number;
  pricepolicy?: any;
  currency?: any;
  isdefault?: boolean;

  static selectDefaultValue = (arr: Array<RecommendationPriceDto>): string => {
    if(!arr) return "";
    if(!arr.length) return "";
    const newArr = arr.filter(
      (item: RecommendationPriceDto) => item.isdefault === true
    );
    if (newArr.length > 0) return newArr[0].id || "";
    else return "";
  };

  static parseToSelectOptions(
    data: Array<RecommendationPriceDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(RecommendationPriceDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: RecommendationPriceDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      `${CommonTools.processObjectField(obj, [
        "name",
      ])} - ${CommonTools.processObjectField(obj, [
        "price",
      ])} ${CommonTools.processObjectField(obj, ["currency", "identifier"])}`
    );
  };
}
