import { TestDto } from "dto/course/newtest.dto";
import React from "react";
import { EditTest } from "./EditTest";
import { Box } from "@mui/material";

type Props = {
  defaultObj: TestDto;
  updateList: (obj: TestDto) => void;
  deleteFromList: (id: string) => void;
  idCourse?: string;
  idLesson?: string;
  allowEdit?: boolean;
  allowDelete?: boolean;
  canEdit: boolean;
};

const BlockTest: React.FC<Props> = ({
  defaultObj,
  updateList,
  deleteFromList,
  idCourse = "",
  idLesson = "",
  allowEdit = false,
  allowDelete = false,
  canEdit,
}) => {
  const handleUpdate = (obj: TestDto | null) => {
    if (!obj) return;
    updateList(obj);
  };

  return (
    <Box
      sx={
        {
          // width: "calc(100% - 50px)",
          // background: "blue",
          // overflow: "hidden",
        }
      }>
      <EditTest
        object={defaultObj}
        setObject={handleUpdate}
        idCourse={idCourse}
        idLesson={idLesson}
        allowEdit={allowEdit}
        allowDelete={allowDelete}
        deleteFromList={deleteFromList}
        canEdit={canEdit}
      />
    </Box>
  );
};

export { BlockTest };
