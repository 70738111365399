import { useCallback, useEffect, useState } from "react";
import DateTools from "tools/utils/date.tools";

type PropsUseTimerParsedTime = (
  inputTime: number,
  updateInterval?: number
) => {
  timeLeft: TimerType | null;
};

type TimerType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const useTimerParsedTime: PropsUseTimerParsedTime = (
  inputTime,
  updateInterval = 60000
) => {
  const calculateTimeLeft = useCallback(() => {
    if(!inputTime) return null;
    const difference = inputTime - DateTools.getTimeStamp();
    let timeLeft: TimerType | null = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (60 * 60 * 24)),
        hours: Math.floor((difference / (60 * 60)) % 24),
        minutes: Math.floor((difference / 60) % 60),
        seconds: Math.floor(difference % 60),
      };
    } else {
      timeLeft = null;
    }

    return timeLeft;
  }, [inputTime]);

  const [timeLeft, setTimeLeft] = useState<TimerType | null>(
    calculateTimeLeft()
  );

  useEffect(() => {
    if (!timeLeft || !updateInterval) return;
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, updateInterval);

    return () => clearTimeout(timer);
  }, [timeLeft, calculateTimeLeft, updateInterval]);

  return {
    timeLeft,
  };
};

type PropsUseTimer = (inputTime: number, updateInterval?: number) => [number];
export const useTimer: PropsUseTimer = (
  inputTime: number,
  updateInterval = 60000
) => {
  const [timer, setTimer] = useState(DateTools.getTimeStamp());

  useEffect(() => {
    if (inputTime <= 0) return;
    setTimer(DateTools.getTimeStamp());
  }, [inputTime]);

  const processTime = useCallback(() => {
    let time = inputTime - timer;
    if (time < 0) time = 0;
    return time;
  }, [inputTime, timer]);

  useEffect(() => {
    if (processTime() <= 0 || !updateInterval) return;
    const interval = setTimeout(() => {
      setTimer(DateTools.getTimeStamp());
    }, updateInterval);
    return () => clearTimeout(interval);
  }, [processTime, updateInterval]);

  return [processTime()];
};
