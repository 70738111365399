import React, { useEffect, useState, useRef, useCallback } from "react";

import { useResource } from "hooks/useResource";

import Box from "@mui/material/Box";

import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import MyTextField from "components/form/mytextfield";
import { IconButton, Stack } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useMessage } from "hooks/useMessage";
import { CommonTools } from "tools/utils/commontools";
import { MessageTypes } from "tools/types/messagetypes";
import { ShareElements } from "components/elements/Share/ShareElements";

type Props = ButtonProps & {
  url: string;
  buttonContent?: React.ReactNode;
  title?: string;
  description?: string;
  imageUrl?: string;
};
const ShareDialog: React.FC<Props> = ({
  url,
  buttonContent,
  title = "",
  description = "",
  imageUrl = "",
  ...props
}) => {
  const { LL } = useResource();
  const { RegisterMessage } = useMessage();

  const [fullUrl, setFullUrl] = useState("");

  const prepareFullUrl = useCallback(() => {
    if (!url) return "";
    return CommonTools.generateFullUrlSite(url);
  }, [url]);
  useEffect(() => {
    setFullUrl(prepareFullUrl());
  }, [prepareFullUrl]);

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const processButtonContent = () => {
    if (!buttonContent) {
      return <>{LL("Share")}</>;
    }
    return buttonContent;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopyClick = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.select();
      inputElement.setSelectionRange(0, 99999);
      document.execCommand("copy");
      RegisterMessage(
        CommonTools.createMessage(
          LL("Link_copied_to_clipboard"),
          MessageTypes.MESSAGE_SUCCESS,
          " "
        )
      );
    }
  };
  const processShareElements = () => {
    if (!url) return null;
    return (
      <ShareElements
        url={fullUrl}
        title={title}
        description={description}
        imageUrl={imageUrl}
      />
    );
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} {...props}>
        {processButtonContent()}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{LL("Sharethiscourse")}</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack
            direction="row"
            sx={{ width: "100%", pb: 3 }}
            spacing={1}
            justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <MyTextField
                fullWidth
                id="fullUrl"
                name="fullUrl"
                size="small"
                variant="outlined"
                _label={LL("fullUrl_share")}
                value={fullUrl}
                setObj={(field: any, value: any) => {
                  setFullUrl(value);
                }}
                inputRef={inputRef}
                _vresults={undefined}
              />
            </Box>
            <Button onClick={handleCopyClick} variant="contained">
              {LL("Copy_Link")}
            </Button>
          </Stack>

          {processShareElements()}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export { ShareDialog };
