import React, { useCallback, useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { CommonTools } from "tools/utils/commontools";

import { AttachmentDto } from "dto/system/attachment.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { AttachmentService } from "services/system/attachment.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools//types/status";
import TbFilterDTO from "dto/app/tbfilter.dto";
import FilterTools from "tools/utils/filtertools";
import MyTable from "components/elements/TabelTools/MyTable";
import { MyButton } from "components/elements/button/MyButton";

import { EditAttachmentDialog } from "components/system/attachment/EditAttachmentDialog";
import { AddAttachmentDialog } from "components/system/attachment/AddAttachmentDialog";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { TransitionUp } from "hooks/useDialogTransition";


const attachmentService = new AttachmentService();
type AttachmentDialogProps = {
  open: boolean;
  setOpen: any;
  idparent: string;
  parent: string;
  canEdit: boolean;
};
const AttachmentDialog: React.FC<AttachmentDialogProps> = ({
  open,
  setOpen,
  idparent,
  parent,
  canEdit,
  ...props
}) => {
  const { LL, CC } = useResource();
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<AttachmentDto>>(
    new Array<AttachmentDto>()
  );
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [objectHasChanged, setObjectHasChanged] = useState<string>("");
  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO | null>(null);
  const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
    new TbFilterListDTO()
  );
  const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);
  const deleteUser = (obj: any) => {
    setDeleteOneObject(obj);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [];
  columns.push(ComponentCommonTools.columns_GetCheck());
  columns.push(ComponentCommonTools.columns_FileColumn(LL, "idfile"));
  columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
  columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
  columns.push(
    ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria")
  );
  columns.push(
    ComponentCommonTools.columns_StandardColumn(
      LL,
      "isdefault",
      undefined,
      true,
      true,
      "boolean"
    )
  );
  columns.push(
    ComponentCommonTools.columns_ActionsColumn(
      LL,
      parent,
      deleteUser,
      ["delete"],
      ["delete"],
      undefined,
      "attachment"
    )
  );

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };

  const getList = useCallback(() => {
    if (!open) return;
    if (!reqList) return;
    if (!reqList.filters) return;
    if (!reqList.filters.length) return;

    setLoading(true);

    attachmentService.getList(loadObjects, {}, reqList);
  }, [reqList, open]);

  const getReqList = useCallback(() => {
    const localReq = new RequestListDTO();

    const t = new RequestFilterDTO();
    t.field = "idparent";
    t.values = [idparent];

    localReq.page = 1;
    localReq.onpage = parseInt(CC("DEFAULT_ONPAGE_ATTACHEMENT", "10"));
    localReq.filters = [t];
    const sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = "ordercriteria";
    sortCriteria.asc = true;
    localReq.sortcriteria = [sortCriteria];

    setReqList(localReq);
  }, [idparent]);
  const checkIsLoading = useCallback(() => {
    if (!reqList) return;
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  }, [reqList, responseParams, data, total, totalPage, rows]);

  const processTbFilterList = () => {
    const _tobj = new TbFilterListDTO();

    var t: TbFilterDTO;

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    _tobj.addFilter(t);

    setTbFilterList(_tobj);
  };

  useEffect(() => {
    processTbFilterList();
  }, []);

  useEffect(() => {
    getReqList();
  }, [getReqList]);

  useEffect(() => {
    getList();
  }, [getList, objectHasChanged]);

  useEffect(() => {
    checkIsLoading();
  }, [checkIsLoading]);

  const handleDelete = (item: any) => {
    attachmentService.delete(item.id, getList, {});
  };
  const [openDialogAddAttachment, setOpenDialogAddAttachment] = useState(false);
  const handleOpenDialogAddAttachment = () => {
    setOpenDialogAddAttachment(true);
  };

  const [openDialogEditAttachment, setOpenDialogEditAttachment] =
    useState(false);
  const [idCurrent, setIdCurrent] = useState<string>("");

  const handleOpenDialogEditAttachment = (value: any) => {
    setIdCurrent(value.id);
    setOpenDialogEditAttachment(true);
  };

  return (
    <Dialog
      sx={{ ml: { xs: 0, lg: 6 } }}
      open={open}
      TransitionComponent={TransitionUp}
      keepMounted
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle>{LL("Attachment")}</DialogTitle>
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {reqList ? (
          <MyTable
            _columns={columns}
            _data={rows}
            _total={total}
            _totalPage={totalPage}
            _reqList={reqList}
            _tbFilterList={tbFilterList}
            setReqList={setReqList}
            loading={loading}
            currentRoute={parent}
            onDelete={handleDelete}
            mainObject={parent}
            fieldToShowOnDelete="name"
            deleteOneObject={deleteOneObject}
            setDeleteOneObject={setDeleteOneObject}
            onRowClickCb={handleOpenDialogEditAttachment}
            {...props}
          />
        ) : null}
      </DialogContent>
      <Box
        sx={{
          position: "fixed",
          bottom: "30px",
          right: "30px",
        }}
      >
        <Box className="addButtonAtachBox" textAlign="center">
          <MyButton
            className="addButtonAtach"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "50%" }}
            cb={handleOpenDialogAddAttachment}
          >
            {/* {LL("Bt_Add")} */}
            <AddOutlinedIcon />
          </MyButton>
        </Box>
        <AddAttachmentDialog
          open={openDialogAddAttachment}
          setOpen={setOpenDialogAddAttachment}
          idparent={idparent}
          setObjectWasChanged={setObjectHasChanged}
          parent={parent}
          canEdit={canEdit}
        />
        <EditAttachmentDialog
          open={openDialogEditAttachment}
          setOpen={setOpenDialogEditAttachment}
          id={idCurrent}
          setObjectWasChanged={setObjectHasChanged}
          idparent={idparent}
          setId={setIdCurrent}
          parent={parent}
          canEdit={canEdit}
        />
      </Box>
    </Dialog>
  );
};

export { AttachmentDialog };
