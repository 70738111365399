import {
  Box,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { GeneralDialog } from "components/elements/MyDialog/GeneralDialog";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseDto } from "dto/course/course.dto";
import { PostCourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import { useAuth } from "hooks/useAuth";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { FormCourseTeacherRequest } from "../FormCourseTeacherRequest";
import { CommonTools } from "tools/utils/commontools";
import { TransitionDown } from "hooks/useDialogTransition";

type Props = {
  title: string;
  children: React.ReactNode;
  showTooltip?: boolean;
};

export const CourseActionBox: React.FC<Props> = ({
  title,
  children,
  showTooltip = false,
}) => {
  const { LL } = useResource();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (showTooltip) setOpen(true);
    else setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Tooltip
      open={open}
      title={LL("you_are_not_allowed_to_perform_this_action")}
      followCursor
      onMouseOver={handleOpen}
      onMouseLeave={handleClose}>
      <Box>
        <Typography sx={{ py: 2 }} variant="h6">
          {title}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            my: 3,
          }}>
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
};
type ActionButtonProps = {
  disabled: boolean;
  children: React.ReactNode;
  action: () => void;
};
export const CourseActionButton: React.FC<ActionButtonProps> = ({
  disabled,
  children,
  action,
}) => {
  return (
    <MyButton
      disabled={disabled}
      variant="contained"
      cb={action}
      className="btn"
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
      }}>
      {children}
    </MyButton>
  );
};

type ActionProps = {
  course: CourseDto;
  disabled: boolean;
  handleSubmit: (
    obj: PostCourseTeacherRequestDto,
    handleResult: (result: ResultObjectDTO) => void
  ) => void;
  handleResult: (result: ResultObjectDTO) => void;
  contentButton: React.ReactNode;
  tittleDialog: string;
  titleAction: string;
};

export const ActionCourseComponent: React.FC<ActionProps> = ({
  course,
  disabled,
  contentButton,
  handleResult,
  handleSubmit,
  tittleDialog,
  titleAction,
}) => {
  const { teacher } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (obj: PostCourseTeacherRequestDto) => {
    setLoading(true);
    handleSubmit(obj, handleResultComponent);
  };

  const handleResultComponent = (result: ResultObjectDTO) => {
    handleClose();
    setLoading(false);
    if (!result) return;
    handleResult(result);
  };

  if (!teacher || !course) return null;
  return (
    <CourseActionBox title={titleAction} showTooltip={disabled}>
      <CourseActionButton disabled={disabled} action={handleOpen}>
        {contentButton}
      </CourseActionButton>
      {open ? (
        <GeneralDialog
          open={open}
          TransitionComponent={TransitionDown}
          keepMounted
          fullWidth
          maxWidth={"md"}
          handleClose={handleClose}>
          <DialogTitle>{tittleDialog}</DialogTitle>
          <DialogContent dividers>
            {loading ? (
              <CircularLoading />
            ) : (
              <FormCourseTeacherRequest
                loading={loading}
                defaultObject={
                  new PostCourseTeacherRequestDto(
                    CommonTools.processObjectField(teacher, ["id"]),
                    CommonTools.processObjectField(course, ["id"])
                  )
                }
                onSubmit={onSubmit}
              />
            )}
          </DialogContent>
        </GeneralDialog>
      ) : null}
    </CourseActionBox>
  );
};
