import { Box, Grid, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { StaticSelect } from "components/elements/Select/newSelect/StaticSelect";
import MyTextField from "components/form/mytextfield";
import { TeacherContractDto } from "dto/user/teacher.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import { Types } from "tools/types/types";
import RequiredValidator from "validators/required.validator";
import { AddressContract } from "../user/address/AddressContract";

import CNPValidator from "validators/cnp.validator";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";

type Props = {
  defaultObject: TeacherContractDto;
  onSubmit: (obj: TeacherContractDto) => void;
  loading: boolean;
};

const FormEditTeacherContract: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [disabledCNP, setDisabledCNP] = useState<boolean>(false);

  const [obj, disabled, setObjField, , vResult, , , objectWasChanged] =
    useForm<TeacherContractDto>(
      defaultObject,
      RequiredValidator.getValidators(["id", "iduser"])
    );

  const checkDisabledCNP = useCallback(() => {
    let isDisabled = disabled;
    if (obj.typecollaboration === Types.TYPE_PERSOANA_FIZICA) {
      isDisabled = !CNPValidator.validationAlgorithm(obj.cnp_tmp);
    }
    setDisabledCNP(isDisabled);
  }, [obj, disabled]);

  useEffect(() => {
    checkDisabledCNP();
  }, [checkDisabledCNP]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  const processLabelValidated = (
    <Box>
      <Box mb={3}>
        <Typography variant="h6">{LL("validated_data")}</Typography>
      </Box>
      <Box>
        <Typography variant="h6">{LL("typecollaboration")}</Typography>
        <StaticSelect
          service={Types}
          value={obj.typecollaboration}
          setObjField={setObjField}
          label=""
          disabled
          field="typecollaboration"
          type="typecollaboration"
        />
      </Box>
    </Box>
  );

  const processLabelNeedValidation = (
    <Box>
      <Box mb={3}>
        <Typography variant="h6">{LL("data_need_validation")}</Typography>
      </Box>
      <Box>
        <Typography variant="h6">{LL("typecollaboration")}</Typography>
        <StaticSelect
          service={Types}
          value={obj.typecollaboration_tmp}
          setObjField={setObjField}
          label=""
          field="typecollaboration_tmp"
          type="typecollaboration"
        />
      </Box>
    </Box>
  );

  const addressField = (
    <Box>
      <Typography variant="h6">{LL("address")}</Typography>
      <AddressContract
        value={obj.idaddresscontract || ""}
        setObjectField={setObjField}
        field="idaddresscontract"
        disabled
      />
    </Box>
  );
  const addressFieldTmp = (
    <Box>
      <Typography variant="h6">{LL("address")}</Typography>
      <AddressContract
        value={obj.idaddresscontract_tmp || ""}
        setObjectField={setObjField}
        field="idaddresscontract_tmp"
      />
    </Box>
  );
  const processFormPersoanaFizicaTmp = () => {
    return (
      <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
        {processLabelNeedValidation}
        <Box mt={3}>
          <Typography variant="h6">{LL("cnp")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("cnp")}
              size="small"
              value={obj.cnp_tmp}
              field="cnp_tmp"
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{LL("persona_fiz_name")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("persona_fiz_name")}
              size="small"
              value={obj.persona_fiz_name_tmp}
              field="persona_fiz_name_tmp"
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{LL("persona_fiz_surname")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("persona_fiz_surname")}
              size="small"
              value={obj.persona_fiz_surname_tmp}
              field="persona_fiz_surname_tmp"
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>{addressFieldTmp}</Box>
      </Grid>
    );
  };
  const processFormPersoanaFizica = () => {
    return (
      <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
        {processLabelValidated}
        <Box mt={3}>
          <Typography variant="h6">{LL("cnp")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              disabled
              _label={LL("cnp")}
              size="small"
              value={obj.cnp}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>

        <Box mt={3}>
          <Typography variant="h6">{LL("persona_fiz_name")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              disabled
              _label={LL("persona_fiz_name")}
              size="small"
              value={obj.persona_fiz_name}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>

        <Box mt={3}>
          <Typography variant="h6">{LL("persona_fiz_surname")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              disabled
              _label={LL("persona_fiz_surname")}
              size="small"
              value={obj.persona_fiz_surname}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>{addressField}</Box>
      </Grid>
    );
  };

  const processFormCompanyTmp = () => {
    return (
      <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
        {processLabelNeedValidation}
        <Box mt={3}>
          <Typography variant="h6">{LL("companyname")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("companyname")}
              size="small"
              value={obj.companyname_tmp}
              field="companyname_tmp"
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{LL("cui")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("cui")}
              size="small"
              value={obj.cui_tmp}
              field="cui_tmp"
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{LL("admin_name")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("admin_name")}
              size="small"
              value={obj.admin_name_tmp}
              field="admin_name_tmp"
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{LL("admin_surname")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("admin_surname")}
              size="small"
              value={obj.admin_surname_tmp}
              field="admin_surname_tmp"
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>{addressFieldTmp}</Box>
      </Grid>
    );
  };

  const processFormCompany = () => {
    return (
      <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
        {processLabelValidated}
        <Box mt={3}>
          <Typography variant="h6">{LL("companyname")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              disabled
              _label={LL("companyname")}
              size="small"
              value={obj.companyname}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{LL("cui")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              disabled
              _label={LL("cui")}
              size="small"
              value={obj.cui}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>

        <Box mt={3}>
          <Typography variant="h6">{LL("admin_name")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              disabled
              _label={LL("admin_name")}
              size="small"
              value={obj.admin_name}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>

        <Box mt={3}>
          <Typography variant="h6">{LL("admin_surname")}</Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              fullWidth
              variant="standard"
              color="primary"
              className="form-input"
              disabled
              _label={LL("admin_surname")}
              size="small"
              value={obj.admin_surname}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>{addressField}</Box>
      </Grid>
    );
  };

  const processForm = () => {
    if (!obj.typecollaboration) {
      if (
        obj.typecollaboration_tmp === Types.TYPE_PERSOANA_FIZICA_AUTORIZATA ||
        obj.typecollaboration_tmp === Types.TYPE_PERSOANA_JURIDICA
      ) {
        return (
          <React.Fragment>
            {processFormCompany()}
            {processFormCompanyTmp()}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {processFormPersoanaFizica()}
            {processFormPersoanaFizicaTmp()}
          </React.Fragment>
        );
      }
    }
    if (
      obj.typecollaboration === Types.TYPE_PERSOANA_FIZICA_AUTORIZATA ||
      obj.typecollaboration === Types.TYPE_PERSOANA_JURIDICA
    ) {
      if (
        obj.typecollaboration_tmp === Types.TYPE_PERSOANA_FIZICA_AUTORIZATA ||
        obj.typecollaboration_tmp === Types.TYPE_PERSOANA_JURIDICA
      ) {
        return (
          <React.Fragment>
            {processFormCompany()}
            {processFormCompanyTmp()}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {processFormCompany()}
            {processFormPersoanaFizicaTmp()}
          </React.Fragment>
        );
      }
    } else {
      if (
        obj.typecollaboration_tmp === Types.TYPE_PERSOANA_FIZICA_AUTORIZATA ||
        obj.typecollaboration_tmp === Types.TYPE_PERSOANA_JURIDICA
      ) {
        return (
          <React.Fragment>
            {processFormPersoanaFizica()}
            {processFormCompanyTmp()}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {processFormPersoanaFizica()}
            {processFormPersoanaFizicaTmp()}
          </React.Fragment>
        );
      }
    }
  };

  return (
    <Box className="general-form">
      <InformationObjectWasChanged objectWasChanged={objectWasChanged} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {processForm()}
          <Grid
            mt={1}
            mb={4}
            item
            xs={12}
            order={3}
            sx={{ display: "flex", justifyContent: "center", padding: 0 }}>
            <MyButton
              tabIndex={4}
              disabled={disabledCNP || loading}
              color="primary"
              className="btn"
              variant="contained"
              size="large"
              type="submit"
              sx={{}}>
              {LL("Bt_Submit")}
            </MyButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export { FormEditTeacherContract };
