import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/utils/commontools";
import { Box, Stack, Typography, Grid } from "@mui/material";
import { TeacherDto } from "dto/user/teacher.dto";

import { useResource } from "hooks/useResource";
import { TeacherInfoBlock } from "./TeacherInfoBlock";
import UserSocialBlock from "components/user/user/social/UserSocialBlock";
import { Types } from "tools/types/types";
import { CourseListByTeacherDisplayBlock } from "components/course/course/CourseListByTeacherDisplayBlock";
import { NewsletterBlock } from "components/general/NewsletterBlock";

import { TeacherStatistic } from "./TeacherStatistic";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { TeacherTestimonials } from "components/user/testimonial/TeacherTestimonials";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const PublicTeacherProfile: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();

  const idTeacher = CommonTools.getIdFromPath(currentRoute, "teacher");
  const [obj, setObj] = useState<TeacherDto | undefined>();

  const processTeacherInfo = () => {
    if (!idTeacher) return null;

    return (
      <Box className="aboutTeacher">
        <TeacherInfoBlock
          id={idTeacher}
          showSocials={false}
          setCurrentObject={setObj}
        />
      </Box>
    );
  };

  const processVideoPresentation = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("videopresentation")) return null;
    if (!obj.videopresentation) return null;
    return (
      <Box mb={2}>
        <Typography variant="h5" mb={3}>
          {LL("VideoPresentation")}
        </Typography>
        <Box className="videoTeacherPublic">
          {ComponentCommonTools.getFileBlockVideo(
            obj.videopresentation,
            "100%"
          )}
        </Box>
      </Box>
    );
  };

  const processVideoLessonExample = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("videolessonexample")) return null;
    if (!obj.videolessonexample) return null;
    return (
      <Box>
        <Typography variant="h2" mb={3} mt={5}>
          {LL("VideoLessonExample")}
        </Typography>
        <Stack
          sx={{ width: "100%" }}
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          alignItems="center">
          <Box className="lessonExample">
            {ComponentCommonTools.getFileBlockVideo(obj.videolessonexample)}
          </Box>
          <Box className="videoShortDescript">
            <InfoOutlinedIcon color="primary" sx={{ fontSize: "30px" }} />
            <Typography>{LL("CourseVideo description alert video")}</Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

  const processTeacherStatistic = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    return (
      <React.Fragment>
        <Typography variant="h2" my={4} className="generalTextAlign">
          {LL("Coursant review teacher")}
        </Typography>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          alignItems="center">
          {processRatingForStatistic()}
          <Box sx={{ width: "100%" }}>
            <TeacherStatistic idTeacher={obj.id} />
          </Box>
        </Stack>
      </React.Fragment>
    );
  };

  const processUserSocials = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("iduser")) return null;
    if (!obj.iduser) return null;
    return (
      <UserSocialBlock
        idUser={obj.iduser}
        type={Types.TYPE_SOCIAL_DISPLAY_LONG_FORM}
      />
    );
  };

  const processTeacherCourses = () => {
    if (!idTeacher) return null;
    return (
      <Box>
        <Typography className="generalTextAlign" variant="h2" my={3}>
          {LL("Cursurile mele Teacher")}
        </Typography>
        <CourseListByTeacherDisplayBlock idTeacher={idTeacher} />
      </Box>
    );
  };

  const processMyRatingReadOnly = () => {
    if (!obj) return null;
    if (
      !obj.hasOwnProperty("rating") ||
      !obj.hasOwnProperty("numberoftestimonials")
    )
      return <MyRatingReadOnly rating={0} count={0} showDetails={false} />;
    if (!obj.rating || !obj.numberoftestimonials)
      return <MyRatingReadOnly rating={0} count={0} showDetails={false} />;

    return (
      <MyRatingReadOnly
        rating={obj.rating}
        count={obj.numberoftestimonials}
        showDetails={false}
      />
    );
  };
  const processRatingForStatistic = () => {
    if (!obj) return null;
    let rating = 0;
    if (CommonTools.processObjectField(obj, ["rating"]))
      rating = parseFloat(
        CommonTools.processObjectField(obj, ["rating"]).toString()
      );

    return (
      <Stack
        direction="column"
        spacing={1}
        alignItems={"center"}
        justifyContent={"center"}>
        <Typography color="gold" fontSize={"64px"} fontWeight={"700"}>
          {CommonTools.roundToTenth(rating)}
        </Typography>
        {processMyRatingReadOnly()}
      </Stack>
    );
  };

  const processTeacherTestimonial = () => {
    if (!obj) return null;
    if (!obj.id) return null;

    return <TeacherTestimonials idTeacher={obj.id} />;
  };

  return idTeacher ? (
    <Box>
      <Box className="container teacherProfile">
        <Grid container direction="row" pt={10} pb={5} spacing={2}>
          <Grid item sm={12} md={6}>
            {processTeacherInfo()}
          </Grid>
          <Grid item sm={12} md={1}></Grid>
          <Grid item sm={12} md={5} alignItems="start">
            {processVideoPresentation()}
            {processUserSocials()}
          </Grid>
        </Grid>

        {processTeacherCourses()}
        <Grid container mb={5}>
          <Grid item sm={12} md={7}>
            {processTeacherStatistic()}
            {processTeacherTestimonial()}
            {processVideoLessonExample()}
          </Grid>
        </Grid>
      </Box>
      <NewsletterBlock currentRoute={currentRoute} />
    </Box>
  ) : null;
};

export { PublicTeacherProfile };
