import GeneralService from "services/globalgeneral.service";
import { AbuseRepository } from "repositories/support/abuse.repository";
import IService from "interfaces/iservice.interface";

export class AbuseService
  extends GeneralService<AbuseRepository>
  implements IService
{
  constructor() {
    super(
      new AbuseRepository(),
      [
        { key: "status", type: "status" },
        { key: "type", type: "types" },
        { key: "typesender", type: "types" },
        { key: "date", type: "timestamps" },
        { key: "responsedate", type: "timestamps" },
      ],
      "date"
    );
  }
}
