import { LabelTypes } from "tools/types/labeltypes";

export class Config {
  public static HEADER_HEIGHT = 96;
  public static FOOTER_HEIGHT = 282;

  
  public static WS_URL = process.env.REACT_APP_WS_SERVER || "";
  public static API_URL = process.env.REACT_APP_API_SERVER || "";
  public static SITE_IDENTIFIER = process.env.REACT_APP_SITE_IDENTIFIER || "";
  public static ADMIN_SERVER_URL = process.env.REACT_APP_ADMIN_SERVER || "";
  public static WWW_SERVER_URL = process.env.REACT_APP_WWW_SERVER || "";
  public static SUPPORT_SERVER_URL = process.env.REACT_APP_SUPPORT_SERVER || "";


  public static IS_DEV_MODE = process.env.NODE_ENV === "development";
  public static FB_CONSOLE_APP_ID =
  
    process.env.REACT_APP_FBCONSOLE_APP_ID || "";

  public static GOOGLE_CONSOLE_CLIENT_ID =
    process.env.REACT_APP_GOOGLECONSOLE_CLIENT_ID || "";
  public static CLOSE_MESSAGE_SUCCESS =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_SUCCESS) || 10000;
  public static CLOSE_MESSAGE_WARNING =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_WARNING) || 7000;
  public static CLOSE_MESSAGE_ERROR =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_ERROR) || 5000;

  public static RECAPTCHA_SITE_KEY =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

  public static COOKIE_EXPIRES =
    Number(process.env.REACT_APP_USERCOOKIES_EXPIRE) || 100000;

  public static LABEL_TYPE =
    Number(process.env.REACT_APP_LABEL_TYPE) || LabelTypes.LABEL_SITE;

  public static DEFAULT_LANGUAGE =
    process.env.REACT_APP_DEFAULT_LANGUAGE || "RO";
  public static COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE || "+40";

  public static ID_PAGE_1 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_1 || "";
  public static ID_PAGE_2 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_2 || "";
  public static ID_PAGE_3 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_3 || "";
  public static ID_PAGE_4 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_4 || "";
  public static ID_COPYRIGHT_PAGE =
    process.env.REACT_APP_IDPAGE_COPYRIGHT || "";
  public static ID_PAGE_STUDENT_NO_COURSE_YET =
    process.env.REACT_APP_ID_PAGE_STUDENT_NO_COURSE_YET || "";

  public static API_SERVER = process.env.REACT_APP_API_SERVER || "";

  public static MAX_LIMIT_RELOAD =
    Number(process.env.REACT_APP_MAX_LIMIT_RELOAD) || 3;
  public static TIME_SPAN_RELOAD =
    Number(process.env.REACT_APP_TIME_SPAN_RELOAD) || 10000;

  public static ID_PAGE_TEST_BEFORE_COURSE =
    process.env.REACT_APP_ID_PAGE_TEST_BEFORE_COURSE || "";
  public static ID_PAGE_TEST_LESSON =
    process.env.REACT_APP_ID_PAGE_TEST_LESSON || "";
  public static ID_PAGE_TEST_AFTER_COURSE =
    process.env.REACT_APP_ID_PAGE_TEST_AFTER_COURSE || "";

  public static ID_LESSON_TYPE_ONLINE =
    process.env.REACT_APP_ID_LESSON_TYPE_ONLINE || "";

  public static TIME_BUFFER_JWT =
    Number(process.env.REACT_APP_TIME_BUFFER_JWT) || 600;

  public static ID_BLOCK_SIGNUP_INFO =
    process.env.REACT_APP_ID_BLOCK_SIGNUP_INFO || "";

  public static ID_PAGE_TERMS = process.env.REACT_APP_ID_PAGE_TERMS || "";

  public static ID_PAGE_PRIVACY_POLICY =
    process.env.REACT_APP_ID_PAGE_PRIVACY_POLICY || "";

    public static NO_IMAGE_URL = "images/no-photo.png";

  public static DEFAULT_ID_LANGUAGE = process.env.REACT_APP_ID_LANGUAGE || "";

  public static VALIDATION_ALGORITHM_CNP = process.env.REACT_APP_VALIDATE_CNP_ALGORITHM || "";
  public static ID_TYPE_COURSE_LIVE = process.env.REACT_APP_ID_TYPE_COURSE_LIVE || "";
  public static DRAWER_WIDTH_MAIN = 400;
  public static DRAWER_ANCHOR: "left" | "top" | "right" | "bottom" | undefined =
  "right";

}
