import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from "@mui/material";

import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useResource } from "hooks/useResource";
import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";

import MyCheckboxField from "components/form/mycheckboxfield";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";
import { Status } from "tools/types/status";

type Props = {
  setFilterValue: any;
  filterValue: Array<string>;
  filterName: string;
  service: any;
};
const StaticNomenclatureFilter: React.FC<Props> = ({
  setFilterValue,
  filterValue,
  filterName,
  service,
}) => {
  const { LL } = useResource();
  //   const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  //   const [objects, setObjects] = useState<Array<PopulateTypeDto>>([]);

  const handleAccordionChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  //   const getList = () => {
  //     if (!service) return;
  //     if (!expanded) return;

  //     const reqList = service.getDefaultRequestListSelect(2000);
  //     setIsLoading(true);

  //     service.getList(loadObjects, {}, reqList);
  //   };

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!expanded) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<PopulateTypeDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1
    ),
    [expanded],
    true,
    `static_nomenclature_filter_${filterName}`
  );

  //   const loadObjects = (result: ResultListDTO) => {
  //     if (!result) return;
  //     if (!result.err) {
  //       const objects = result.objects ? result.objects : [];
  //       setObjects(objects);
  //     }
  //   };

  //   const checkLoading = useCallback(() => {
  //     let load = false;
  //     if (!objects) load = true;
  //     setIsLoading(load);
  //   }, [objects]);

  //   useEffect(() => {
  //     getList();
  //   }, [expanded]);

  //   useEffect(() => {
  //     checkLoading();
  //   }, [checkLoading]);

  const processFilterItem = () => {
    if (!filterValue) return null;
    if (!filterName) return null;
    return (
      <Box>
        <Accordion className="accordion" onChange={handleAccordionChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="summary">
            {LL("FN_" + filterName)}
          </AccordionSummary>
          <AccordionDetails>{processDetails()}</AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const processDetails = () => {
    if (!expanded) return null;
    if (loading) return <Skeleton />;
    if (!objects) return null;
    return (
      <AccordionDetails>
        <Box>
          {objects.map((obj, index) => {
            return <Box key={index}>{processItem(obj)}</Box>;
          })}
        </Box>
      </AccordionDetails>
    );
  };

  const onChange = (event: any) => {
    if (event.target.checked) {
      addFilter(event.target.value);
    }
    if (!event.target.checked) {
      removeFilter(event.target.value);
    }
  };

  const removeFilter = (id: string) => {
    if (!id || !filterValue) return;

    const newFilters = [...filterValue];
    const index = newFilters.indexOf(id);

    if (index !== -1) {
      newFilters.splice(index, 1);
    }

    setFilterValue(newFilters);
  };

  const addFilter = (id: string) => {
    if (!id || !filterValue) return;

    const newFilters = [...filterValue];
    newFilters.push(id);
    setFilterValue(newFilters);
  };

  const processItem = (obj: PopulateTypeDto) => {
    if (!obj) return null;
    if (!obj.id) return null;
    const checked = processChecked(obj.id);
    return (
      <MyCheckboxField
        _label={ComponentCommonTools.processObjectField(obj, "name")}
        setObj={(value: any) => {}}
        checked={checked}
        onChange={onChange}
        value={obj.id}
        field={filterName}
        color="primary"
        size="small"
        _vresults={undefined}
      />
    );
  };

  const processChecked = (id: string): boolean => {
    if (!id || !filterValue) return false;
    return filterValue.includes(id);
  };

  return processFilterItem();
};

export { StaticNomenclatureFilter };
