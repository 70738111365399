import dayjs from "dayjs";
import moment from "moment";

export default class DateTools {
  static getDateStr(obj: Date | null): string {
    if (!obj) return "";
    return moment(obj.toISOString()).format(DateTools.getDateFormatMoment());
  }

  static getDateTimeStr(
    date: Date | undefined | string,
    format: string
  ): string {
    if (!date) return "";
    return dayjs(date).format(format);
  }

  static getDateTimeStamp(obj: Date | null): number | null {
    if (!obj) return null;

    const diffLocale = -1 * obj.getTimezoneOffset() * 60000;
    const timestamp = obj.getTime() + diffLocale;

    return Math.round(timestamp / 1000);
  }
  
  static getDateFormat(): any {
    return "dd.MM.yyyy";
  }

  static getDateFormatDayJs(): any {
    return "DD.MM.YYYY";
  }

  static getDateFormatWithHourDayJs(): any {
    return "DD.MM.YYYY HH:mm";
  }

  static getHourDayJs(): any {
    return "HH:mm";
  }
  static getDateTimeFormat(): any {
    return "dd.MM.yyyy HH:mm";
  }

  static getDateFormatMoment(): any {
    return "YYYY-MM-DD";
  }

  static getDate(incrementSecond?: any): any {
    incrementSecond = incrementSecond ? parseInt(incrementSecond) : 0;
    incrementSecond = isNaN(incrementSecond) ? 0 : incrementSecond;

    var rez = new Date();
    rez.setSeconds(rez.getSeconds() + incrementSecond);

    return rez;
  }

  static getTimeStamp(incrementSecond?: any): number {
    const date = this.getDate(incrementSecond);
    let timestamp = date.getTime();
    timestamp = timestamp / 1000;
    timestamp = Math.round(timestamp);

    return timestamp;
  }

  public static getTimeStampServer(): number {
    let t = new Date().getTime();
    t = t / 1000;
    t = Math.round(t);
    return t;
  }

  public static convertTimestampToDate(timestamp: number | undefined): Date {
    if (!timestamp) return new Date();
    const t = timestamp * 1000;
    return new Date(t);
  }

  public static convertToDate(value: any): Date {
    return new Date(value);
  }

  public static displayTimestampInHHMM(timestamp: number | undefined): string {
    if (!timestamp) return "";
    const secondsPerHour = 3600;
    const secondsPerMinute = 60;

    const hours = Math.floor(timestamp / secondsPerHour);
    const remainingSeconds = timestamp % secondsPerHour;
    const minutes = Math.floor(remainingSeconds / secondsPerMinute);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  public static displayTimestampInDateAndHHMM(
    timestamp: number | undefined
  ): string {
    if (!timestamp) return "";
    const date = this.convertTimestampToDate(timestamp);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  public static displayTimestampInTime(timestamp: number | undefined): string {
    if (!timestamp) return "";
    const date = this.convertTimestampToDate(timestamp);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  public static timeAgo(timestamp: number | undefined, LL?: any): string {
    if (!timestamp) {
      return "";
    }

    const now = dayjs();
    const targetDate = dayjs(this.convertTimestampToDate(timestamp));
    const diff = now.diff(targetDate, "minute");
    const hours = now.diff(targetDate, "hour");
    const days = now.diff(targetDate, "day");

    if (diff < 60) {
      if (LL) return diff.toString() + " " + LL("minutes_ago");
      else return diff.toString();
    } else if (hours < 24) {
      if (LL) return hours.toString() + " " + LL("hours_ago");
      else return hours.toString();
    } else if (days <= 7) {
      if (LL) return days.toString() + " " + LL("days_ago");
      else return days.toString();
    } else {
      return targetDate.format(this.getDateFormatDayJs());
    }
  }

  public static displayTimestampUsingDayJsFormat(
    timestamp: number | undefined,
    format: string
  ): string {
    if (!timestamp) return "";
    const date = this.convertTimestampToDate(timestamp);
    return dayjs(date).format(format);
  }

  public static isLessThanOneHour(timestamp: number | undefined): boolean {
    if (!timestamp) return false;

    const now = dayjs();
    const targetDate = dayjs(this.convertTimestampToDate(timestamp));
    const diff = now.diff(targetDate, "minute");

    return diff < 60;
  }

  public static isLessThanOneDay(timestamp: number | undefined): boolean {
    if (!timestamp) return false;
    const now = DateTools.getTimeStamp();
    const targetDate = timestamp;
    const diff = Math.abs(now - targetDate) / 60 / 60;
    return diff < 24;
  }

  public static isLessThan15Minutes(timestamp: number | undefined): boolean {
    if (!timestamp) return false;
    const now = DateTools.getTimeStamp();
    const targetDate = timestamp;
    const diff = Math.abs(now - targetDate) / 60;
    return diff < 15;
  }
}
