import IRepository from "interfaces/irepository.interface";
import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";

export class ChatParticipantRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("chatparticipant");
  }
}
