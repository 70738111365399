import IRepository from "interfaces/irepository.interface";
import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";

export class ChatMessageRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("chatmessage");
  }

  addVoice = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `${this.mainUrl}/voice-message`;
    return await this.postAxiosMultipart(url, cb, data, cbParams);
  };
}
