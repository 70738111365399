import React from "react";
import { AudioPlayer } from "react-audio-player-component";
type Props = {
  url: string;
};

const AudioPlayerMessage: React.FC<Props> = ({ url }) => {
  if (!url) return null;
  return (
    <AudioPlayer
      src={url}
      minimal={true}
      width={350}
      trackHeight={75}
      barWidth={1}
      gap={1}
      visualise={true}
      backgroundColor="#FFF8DE"
      barColor="#C1D0B5"
      barPlayedColor="#99A98F"
      skipDuration={2}
      // seekBarColor="purple"
      // volumeControlColor="blue"
      hideSeekBar={true}
      // hideTrackKnobWhenPlaying={true}
    />
  );
};

export { AudioPlayerMessage };
