import { Box, Typography } from "@mui/material";
import {
  ChatMessageDto,
  ChatMessageGroupByDateDto,
} from "dto/course/chatmessage.dto";
import React from "react";
import { ChatMessageGroup } from "./ChatMessageGroup";
import DateTools from "tools/utils/date.tools";

type Props = {
  objects: ChatMessageDto[];
};

const ChatMessageGroupDate: React.FC<Props> = ({ objects }) => {
  if (!objects) return null;
  const parsedObjects = ChatMessageGroupByDateDto.parseList(objects);
  return (
    <React.Fragment>
      {parsedObjects.map((object, index) => (
        <ChatMessageGroupDateItem key={index} object={object} index={index} />
      ))}
      <Box id="scroll-to-bottom" />
    </React.Fragment>
  );
};

type ItemProps = {
  object: ChatMessageGroupByDateDto;
  index: number;
};
const ChatMessageGroupDateItem: React.FC<ItemProps> = ({ object, index }) => {
  if (!object) return null;
  if (!object.objects) return null;
  return (
    <Box>
      <Box>
        <Typography align="center">
          {DateTools.displayTimestampUsingDayJsFormat(
            object.date,
            DateTools.getDateFormatDayJs()
          )}
        </Typography>
      </Box>
      <Box>
        <ChatMessageGroup objects={object.objects} />
      </Box>
    </Box>
  );
};

export { ChatMessageGroupDate };
