import IValidator from "interfaces/ivalidator.interface";
import { useCallback, useEffect, useState } from "react";
import { CommonTools } from "tools/utils/commontools";
import ValidateTools from "tools/utils/validate.tools";
import ValidatorResult from "validators/validatorresult";

type UseFormProps = <T>(
  defaultValue: T,
  validators: IValidator[],
  customValidation?: () => boolean
) => [
  T,
  boolean,
  (field: string, value: any) => void,
  (obj: T) => void,
  ValidatorResult,
  string,
  () => void,
boolean
];

const useForm: UseFormProps = (defaultValue, validators, customValidation) => {
  const [obj, setObj] = useState(defaultValue);
  const [isDisabled, setIsDisabled] = useState(true);
  const [vResult, setVResult] = useState<ValidatorResult>(
    new ValidatorResult()
  );
  const [keyFileInput, setKeyFileInput] = useState<string>("");
  const [objectWasChanged, setObjectWasChanged] = useState<boolean>(false);
  const resetKeyFileInput = () => {
    setKeyFileInput(CommonTools.generateRandomString(6));
  };

  const checkObjectWasChanged = useCallback(() => {
    if(!obj || !defaultValue) return;
    const result = CommonTools.areObjectsEqual(defaultValue, obj);
    setObjectWasChanged(!result);
  }, [defaultValue, obj]);

  useEffect(() => {
    checkObjectWasChanged();
  }, [checkObjectWasChanged]);

  const setObjField = (field: string, value: any) => {
    const files = CommonTools.extractFiles(obj);
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    if (Object.keys(files).length > 0) Object.assign(t, files);
    setObj(t);
  };

  const validateField = (field: string, value: any, v?: ValidatorResult) => {
    v = v ? v : JSON.parse(JSON.stringify(vResult));
    if (!v) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vResult);
    setVResult(v);
    return v;
  };

  const validateObject = () => {
    let v = JSON.parse(JSON.stringify(vResult));

    for (const key in obj) {
      if (validators.hasOwnProperty(key)) v = validateField(key, obj[key], v);
    }

    setVResult(v);
  };

  useEffect(() => {
    validateObject();
  }, [obj]);

  const checkIsDisabled = () => {
    let rez = false;
    for (const key in vResult) {
      rez = ValidateTools.vForm(vResult, key, rez);
    }
    if (customValidation) rez = customValidation();
    setIsDisabled(rez);
  };

  useEffect(() => {
    checkIsDisabled();
  }, [vResult]);

  return [
    obj,
    isDisabled,
    setObjField,
    setObj,
    vResult,
    keyFileInput,
    resetKeyFileInput,
    objectWasChanged
  ];
};

export { useForm };
