import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

import Box from "@mui/material/Box";

import ModifyPasswordDto from "dto/user/modifypassword.dto";
import { UserService } from "services/user/user.service";
import { CommonTools } from "tools/utils/commontools";
import { FormChangePassword } from "./FormChangePassword";
import ResultObjectDTO from "dto/app/resultobject.dto";
const service = new UserService();

const ChangeTemporaryPassword: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { user } = useAuth();

  //   const { LL } = useResource();

  //   const [isLoading, setIsLoading] = useState(true);
  //   const [isFormDisabled, setIsFormDisabled] = useState(true);

  //   const [obj, setObj] = useState<ModifyPasswordDto>(new ModifyPasswordDto());

  //   const [validators, setValidators] = useState(false);
  //   const [vresults, setVResults] = useState<any>(false);

  //   const validateAll = () => {
  //     if (!obj) return;
  //     if (!validators) return;
  //     if (vresults) return;
  //     obj.email = user ? user.email : "";
  //     obj.currentpassword =
  //       obj.currentpassword != undefined ? obj.currentpassword : "";
  //     obj.newpassword = obj.newpassword != undefined ? obj.newpassword : "";
  //     setObj(obj);

  //     let t = validateField("currentpassword", obj.currentpassword);
  //     t = validateField("newpassword", obj.newpassword, t);
  //   };

  //   const validateField = (field: any, value: any, v?: any) => {
  //     v = v ? v : JSON.parse(JSON.stringify(vresults));
  //     if (v === false) v = {};
  //     v[field] = ValidateTools.validate(field, value, validators, vresults);
  //     setVResults(v);
  //     return v;
  //   };

  //   const processValidators = () => {
  //     let v: any = {
  //       currentpassword: [],
  //       newpassword: [],
  //     };

  //     // v.currentpassword.push(new RequiredValidator(LL('Required field currentpassword', LabelTypes.LABEL_VALIDATION), ''));
  //     // v.newpassword.push(new RequiredValidator(LL('Required field newpassword', LabelTypes.LABEL_VALIDATION), ''));
  //     v.currentpassword.push(new RequiredValidator("", ""));
  //     v.newpassword.push(new RequiredValidator("", ""));
  //     setValidators(v);
  //   };

  //   const checkIsLoading = () => {
  //     if (!isLoading) return;
  //     if (!obj) return;
  //     if (!validators) return;
  //     validateAll();
  //     setIsLoading(false);
  //   };

  //   useEffect(() => {
  //     processValidators();
  //   }, []);

  //   useEffect(() => {
  //     checkIsLoading();
  //   }, [obj, validators]);
  //   const [confirmPassword, setConfirmPassword] = useState("");

  //   useEffect(() => {
  //     checkIsFormDisabled();
  //   }, [vresults, confirmPassword]);

  //   const setObjField = (field: any, value: any) => {
  //     let t = JSON.parse(JSON.stringify(obj));
  //     t[field] = value;
  //     setObj(t);

  //     validateField(field, value);
  //   };

  //   const checkIsFormDisabled = () => {
  //     var rez = ValidateTools.vForm(vresults, "currentpassword");
  //     rez = ValidateTools.vForm(vresults, "newpassword", rez);
  //     if (obj.newpassword !== confirmPassword) {
  //       rez = true;
  //     }
  //     setIsFormDisabled(rez);
  //   };

  //   const handlePasswordChange = () => {
  //     setIsLoading(true);
  //     setIsFormDisabled(true);
  //     setObjField("currentpassword", "");
  //     setObjField("newpassword", "");
  //   };

  //   const handleSubmit = (event: any) => {
  //     event.preventDefault();

  //     if (isFormDisabled) return false;
  //     userService.modifyPassword(handlePasswordChange, {}, obj);
  //   };
  //   return user && !isLoading ? (
  //     <Container maxWidth="xs" className="changePasswordTmp">
  //       <Box
  //         mt={5}
  //         sx={{
  //           alignItems: "center",
  //           alignContent: "center",
  //           width: "100%",
  //           flexDirection: "column",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <Typography variant="h2" align="center" sx={{ mb: 5 }}>
  //           {LL("Password_Temporary_Change_It")}
  //         </Typography>

  //         <form onSubmit={handleSubmit}>
  //           <Box mb={4}>
  //             <Typography variant="h6">{LL("newpassword")}</Typography>
  //             <MyTextField
  //               fullWidth
  //               type="password"
  //               id="newpassword"
  //               name="newpassword"
  //               variant="standard"
  //               color="primary"
  //               className="form-input"
  //               _label={LL("newpassword")}
  //               value={obj.newpassword}
  //               setObj={setObjField}
  //               _vresults={
  //                 vresults && vresults.newpassword ? vresults.newpassword : false
  //               }
  //             />
  //           </Box>
  //           <Box mb={3}>
  //             <Typography variant="h6">{LL("Confirm Password")}</Typography>
  //             <MyTextField
  //               fullWidth
  //               type="password"
  //               id="confirmPassword"
  //               name="confirmPassword"
  //               className="form-input"
  //               variant="standard"
  //               _label={LL("Confirm Password")}
  //               value={confirmPassword}
  //               setObj={(field: any, value: any) => setConfirmPassword(value)}
  //               _vresults={vResult}
  //             />
  //           </Box>
  //           <Box>
  //             <Typography variant="h6">{LL("currentpassword")}</Typography>
  //             <MyTextField
  //               fullWidth
  //               id="currentpassword"
  //               name="currentpassword"
  //               variant="standard"
  //               className="form-input"
  //               color="primary"
  //               type="password"
  //               _label={LL("currentpassword")}
  //               value={obj.currentpassword}
  //               setObj={setObjField}
  //               _vresults={
  //                 vresults && vresults.currentpassword
  //                   ? vresults.currentpassword
  //                   : false
  //               }
  //             />
  //           </Box>
  //           <Box mt={2}>
  //             <Button
  //               disabled={isFormDisabled}
  //               color="primary"
  //               className="btn"
  //               variant="contained"
  //               size="small"
  //               type="submit"
  //               sx={{ mt: 4 }}
  //             >
  //               {LL("Bt ChangPassword")}
  //             </Button>
  //           </Box>
  //         </form>
  //       </Box>
  //     </Container>
  //   ) : (
  //     <></>
  //   );

  if (!user) return null;
  return <LoggedComponent user={user} />;
};

type LoggedProps = {
  user: any;
};

const LoggedComponent: React.FC<LoggedProps> = ({ user }) => {
  const { LL } = useResource();
  const [obj, setObj] = useState<ModifyPasswordDto>(
    new ModifyPasswordDto(CommonTools.processObjectField(user, ["email"]))
  );
  const [loading, setLoading] = useState<boolean>(false);
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    setObj(
      new ModifyPasswordDto(CommonTools.processObjectField(user, ["email"]))
    );
    setLoading(false);
  };
  const onSubmit = (data: ModifyPasswordDto) => {
    setLoading(true);
    service.modifyPassword(handleResult, {}, data);
  };
  return (
    <Container maxWidth="xs" className="changePasswordTmp">
      <Box
        mt={5}
        sx={{
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}>
        <Typography variant="h2" align="center" sx={{ mb: 5 }}>
          {LL("Password_Temporary_Change_It")}
        </Typography>
        <FormChangePassword
          defaultObject={obj}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Box>
    </Container>
  );
};

export { ChangeTemporaryPassword };
