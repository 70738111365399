import { GeneralTeacherFAQRatingDto } from "dto/system/generalteacherfaqrating.dto";
import { useAuth } from "hooks/useAuth";
import IService from "interfaces/iservice.interface";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { LikeDislikeForm } from "../rating/LikeDislikeForm";
import { LikeDislike } from "dto/system/generalrating.dto";
import { LikeDislikeStaticForm } from "../rating/LikeDislikeStaticForm";
import { useResource } from "hooks/useResource";

type Props = {
  id: string;
  service: IService & {
    getRating(
      id: string,
      idUser: string,
      cb?: any,
      cbparameters?: any
    ): Promise<any>;
    like(cb?: any, cbparameters?: any, data?: any): Promise<any>;
    dislike(cb?: any, cbparameters?: any, data?: any): Promise<any>;
  };
  statistic: LikeDislike;
};

const LikeDislikeTeacherFAQ: React.FC<Props> = ({ id, service, statistic }) => {
  const { user, registerMessageNeedAuth } = useAuth();
  const { LL } = useResource();
  const idUser = CommonTools.processObjectField(user, ["id"]);

  const like = (id: string, idUser: string, cb: any) => {
    service.like(cb, {}, new GeneralTeacherFAQRatingDto(id, idUser));
  };
  const dislike = (id: string, idUser: string, cb: any) => {
    service.dislike(cb, {}, new GeneralTeacherFAQRatingDto(id, idUser));
  };

  const getRating = (
    id: string,
    idUser: string,
    cb?: any,
    cbparameters?: any
  ) => {
    service.getRating(id, idUser, cb, cbparameters);
  };

  const likeDislikeFormNotLogged = (
    <LikeDislikeStaticForm
      pressDislike={registerMessageNeedAuth.bind(this, LL)}
      pressLike={registerMessageNeedAuth.bind(this, LL)}
      likes={statistic.likes}
      dislikes={statistic.dislikes}
      disabledDislike={false}
      disabledLike={false}
    />
  );
  if (!user) return likeDislikeFormNotLogged;
  if (!idUser) return likeDislikeFormNotLogged;
  if (!id) return null;
  return (
    <LikeDislikeForm
      id={id}
      idUser={idUser}
      like={like}
      dislike={dislike}
      getRating={getRating}
      statistic={statistic}
    />
  );
};

export { LikeDislikeTeacherFAQ };
