import { CalendarMonthOutlined } from "@mui/icons-material";
import { useResource } from "hooks/useResource";
import React from "react";
import { MyButton } from "../button/MyButton";
import { Typography } from "@mui/material";

type Props = {
  onClick: () => void;
};

const CalendarButton: React.FC<Props> = ({ onClick }) => {
  const { LL } = useResource();
  return (
    <MyButton
      cb={onClick}
      sx={{
        px: { xs: 0, sm: 4 },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        width: { xs: "40px", sm: "auto" },
        minWidth: { xs: "40px", sm: "64px" },
      }}>
      <CalendarMonthOutlined sx={{ fontSize: { xs: "20px", sm: "16px" } }} />
      <Typography
        sx={{
          fontSize: { xs: "16px", sm: "16px" },
          textTransform: "unset",
          display: { xs: "none", sm: "block" },
          "&::first-letter": {
            textTransform: "capitalize",
          },
        }}>
        {LL("Calendar")}
      </Typography>
    </MyButton>
  );
};

export { CalendarButton };
