import React from "react";
import { Box, ListItemButton, ListItemText } from "@mui/material";

import { ChatDto } from "dto/course/chat.dto";
import { CommonTools } from "tools/utils/commontools";
import { useChat } from "context/chat.context";
import { ChatAvatar } from "./ChatAvatar";

type Props = {
  object: ChatDto;
};

const ChatItem: React.FC<Props> = ({ object }) => {
  const { selectChat, selectedChat } = useChat();
  const onClick = () => {
    selectChat(object);
  };
  const isSelected =
    CommonTools.processObjectField(object, ["id"]) ===
    CommonTools.processObjectField(selectedChat, ["id"]);
  if (!object) return null;
  return (
    <ListItemButton
      onClick={onClick}
      selected={isSelected}
      sx={{ gap: 2, borderLeft: isSelected ? "solid 4px #006ADE" : "0px" }}>
      <Box sx={{ ml: isSelected ? "12px" : 2 }}>
        <ChatAvatar
          idCourse={CommonTools.processObjectField(object, ["idcourse"])}
        />
      </Box>
      <ListItemText
        primary={CommonTools.processObjectField(object, ["name"])}
      />
    </ListItemButton>
  );
};

export { ChatItem };
