import { Delete, Save } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { PostVideoForCourseDto } from "dto/system/video.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: PostVideoForCourseDto;
  onSubmit: (obj: PostVideoForCourseDto) => void;
  loading: boolean;
  onDelete: () => void;
  canDelete: boolean;
  canEdit: boolean;
};

const FormCourseVideo: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  onDelete,
  canDelete,
  canEdit,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject, vResult] =
    useForm<PostVideoForCourseDto>(
      defaultObj,
      RequiredValidator.getValidators(["videolocation", "idparent"])
    );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {/* <Box sx={{ minWidth: "350px" }}>
          <Stack direction="row" alignItems={"center"} justifyContent="center">
            <Box className="box-form-input-icon-end">
              <MyTextField
                fullWidth
                id="videolocation"
                name="videolocation"
                variant="standard"
                className="form-input"
                color="primary"
                _label={LL("videolocation")}
                value={obj.videolocation}
                setObj={setObjField}
                _vresults={vResult}
              />
            </Box>

            <Box>
              <MyButton
                disabled={disabled || loading || !canEdit}
                type="submit">
                <Save />
              </MyButton>
            </Box>
            {canDelete && (
              <Box>
                <MyButton cb={onDelete} disabled={!canEdit} color="secondary">
                  <Delete />
                </MyButton>
              </Box>
            )}
          </Stack>
        </Box> */}

        <Box
          sx={{
            mb: 2,
            display: "flex",
            gap: { xs: 2, sm: 4 },
            width: "100%",
            flexDirection: { xs: "column", sm: "column" },
          }}>
          <Box sx={{ width: "100%" }}>
            <MyTextField
              fullWidth
              id="videolocation"
              name="videolocation"
              variant="standard"
              className="form-input"
              color="primary"
              _label={LL("videolocation")}
              value={obj.videolocation}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              justifyContent: "cener",
              alignItems: "center",
            }}>
            {canDelete ? (
              <MyButton
                sx={{ width: "100%" }}
                disabled={!canEdit}
                color="error"
                className="btn"
                variant="outlined"
                size="large"
                type="button"
                cb={onDelete}>
                {LL("Bt_Delete")}
              </MyButton>
            ) : null}
            <MyButton
              disabled={disabled || loading || !canEdit}
              sx={{ width: "100%" }}
              color="primary"
              className="btn"
              variant="outlined"
              size="large"
              type="submit">
              {LL("Bt_Submit")}
            </MyButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseVideo };
