import React from "react";

import { Chip, ChipProps } from "@mui/material";

import { Status } from "tools//types/status";
import { TeacherDto } from "dto/user/teacher.dto";
import { logger } from "tools/utils/logger";

type Props = ChipProps & {
  obj: TeacherDto;
};

const StatusChipTeacher: React.FC<Props> = ({ obj, ...props }) => {
  const processColor = (value: number) => {
    switch (value) {
      case Status.TEACHER_PRO:
        return "chipGradient";
      case Status.TEACHER_UNVERIFIED:
        return "chipUnverified";
      case Status.TEACHER_VERIFIED:
        return "chipVerified";
      default:
        return "primary";
    }
  };
  const processChip = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (!obj.status) return null;

    const color = processColor(obj.status);

    

    return (
      <Chip
        label={Status.LL(obj.status)}
        {...props}
        size="small"
        className={`chipTop ${color}`}
        // color={color}
      />
    );
  };

  return processChip();
};

export { StatusChipTeacher };
