import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { LikeDislike } from "dto/system/generalrating.dto";
import React from "react";

type Props = {
  statistic: LikeDislike;
};

const LikeDislikeStatistic: React.FC<Props> = ({ statistic }) => {
  const total = statistic.likes + statistic.dislikes;
  const percentage = total ? (statistic.likes / total) * 100 : 0;
  return (
    <Box sx={{ py: 2, maxWidth: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          flexDirection: "row",
        }}>
        <Box
          sx={{
            display: "flex",
            gap: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <ThumbUp sx={{ fontSize: "18px" }} />
          <Typography sx={{ fontSize: "14px" }}>{statistic.likes}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <ThumbDown sx={{ fontSize: "18px" }} />
          <Typography sx={{ fontSize: "14px" }}>
            {statistic.dislikes}
          </Typography>
        </Box>
      </Box>
      <Box
        className="linearLikes"
        sx={{
          width: "120px",
          mt: 1,
        }}>
        <LinearProgress variant="determinate" value={percentage} />
      </Box>
    </Box>
  );
};

export { LikeDislikeStatistic };
