import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { TeacherFAQService } from "services/user/teacherfaq.service";

import { TeacherFAQDto } from "dto/user/teacherfaq.dto";
import { RouteTools } from "tools/utils/routetools";
import RequestListDTO from "dto/app/requestlist.dto";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import { Box, Grid } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";

import MyTextField from "components/form/mytextfield";
import SearchIcon from "@mui/icons-material/Search";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { useAuth } from "hooks/useAuth";
import { useCookies } from "react-cookie";
import { TeacherInterfaceFAQCard } from "./TeacherInterfaceFAQCard";

import { CourseByTeacherAutocomplete } from "components/elements/Autocomplete/CourseByTeacherAutocomplete";
import { NoResult } from "components/general/NoResult";
import { WithoutResponseFilter } from "components/elements/Filter/WithoutResponseFilter";
import { SkeletonFAQList } from "components/elements/Skeleton/SkeletonTeacherPage";
import { SkeletonPagination } from "components/elements/Skeleton/SkeletonPagination";

const service = new TeacherFAQService();

type Props = {
  currentRoute: any;
};

const TeacherInterfaceFAQList: React.FC<Props> = ({
  currentRoute,
  ...props
}) => {
  const { LL, CC } = useResource();
  const { teacher } = useAuth();
  const mainUrl =
    currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<TeacherFAQDto>>([]);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("OnPageTeacherInterfaceFAQList", "10"))
  );
  const [page, setPage] = useState<number>(1);
  const [sortCriteria, setSortCriteria] = useState<
    Array<RequestSortCriteriaDTO>
  >(new Array<RequestSortCriteriaDTO>());
  const [search, setSearch] = useState("");
  const [searchReq, setSearchReq] = useState("");
  const [cookies, setCookies] = useCookies();
  const [filterWithOutResponse, setFilterWithOutResponse] =
    useState<string>("");
  const [filterIdCourse, setFilterIdCourse] = useState<string>("");
  const [defaultFilterIdCourse, setDefaultFilterIdCourse] =
    useState<string>("");

  const getList = () => {
    if (!teacher) return;
    if (!teacher.id) return;
    setIsLoading(true);

    const reqList = new RequestListDTO();
    reqList.onpage = onPage;
    reqList.page = page;
    reqList.filters = [];
    reqList.sortcriteria = sortCriteria;

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "idteacher";
    f.values = [teacher.id];
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "search";
    f.values = [searchReq];
    reqList.filters.push(f);
    if (filterWithOutResponse) {
      f = new RequestFilterDTO();
      f.field = "teacherresponseexists";
      f.values = [filterWithOutResponse];
      reqList.filters.push(f);
    }
    if (filterIdCourse) {
      f = new RequestFilterDTO();
      f.field = "idcourse";
      f.values = [filterIdCourse];
      reqList.filters.push(f);
    }

    const urlPart = RouteTools.prepareListLocation(reqList);

    RouteTools.setHistory(mainUrl[0] + urlPart, {});
    service.getListTeacher(loadObjects, {}, reqList);
  };

  const processCourseFilter = () => {
    if (!teacher) return null;
    if (!teacher.id) return null;

    return (
      <Box>
        <CourseByTeacherAutocomplete
          valueFilter={filterIdCourse}
          setValueFilter={setFilterIdCourse}
          idTeacher={teacher.id}
          _defaultValue={defaultFilterIdCourse}
        />
      </Box>
    );
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  };
  const defaultSortCriteria = () => {
    if (!sortCriteria || sortCriteria.length === 0) {
      const sortCriteria = new RequestSortCriteriaDTO();
      sortCriteria.field = "date";
      sortCriteria.asc = false;
      setSortCriteria([sortCriteria]);
    }
  };

  const [objectWasChanged, setObjectWasChanged] = useState<string>("");
  useEffect(() => {
    if (sortCriteria && sortCriteria.length > 0) getList();
  }, [
    sortCriteria,
    searchReq,
    onPage,
    page,
    filterWithOutResponse,
    filterIdCourse,
  ]);

  useEffect(() => {
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);
    if (reqListRoute.filters) {
      if (reqListRoute.filters.length > 0) {
        const filter = reqListRoute.filters.find(
          (f: RequestFilterDTO) => f.field === "teacherresponseexists"
        );
        if (filter) {
          if (filter.values && filter.values.length > 0) {
            setFilterWithOutResponse(filter.values[0]);
          }
        }
        const filterCourse = reqListRoute.filters.find(
          (f: RequestFilterDTO) => f.field === "idcourse"
        );
        if (filterCourse) {
          if (filterCourse.values && filterCourse.values.length > 0) {
            setFilterIdCourse(filterCourse.values[0]);
            setDefaultFilterIdCourse(filterCourse.values[0]);
          }
        }
      }
    }

    if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
      setSortCriteria(reqListRoute.sortcriteria);
    } else {
      defaultSortCriteria();
    }
  }, []);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processTeacherFAQItem = (obj: TeacherFAQDto, index: number) => {
    if (!obj) return <></>;

    return (
      <Box key={index}>
        <TeacherInterfaceFAQCard
          obj={obj}
          setObjectWasChanged={setObjectWasChanged}
          itemIndex={index}
          setObjects={setObjects}
          objects={objects}
        />
      </Box>
    );
  };

  const handleChangeResponse = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterWithOutResponse((event.target as HTMLInputElement).value);
  };

  const processFilterWithResponse = () => {
    return (
      <Box>
        {/* <MyCheckboxField
                    tabIndex={1}
                    id="withoutresponse"
                    name="withoutresponse"
                    _label={LL("withoutresponse")}
                    setObj={(value: any) => {
                        setFilterWithOutResponse(value)
                    }}
                    checked={filterWithOutResponse}
                    color="primary"
                    size="small"
                    _vresults={vResult}
                /> */}

        {/* <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">{LL("withoutresponse")}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={filterWithOutResponse}
                        onChange={handleChangeResponse}
                    >
                        <FormControlLabel value="" control={<Radio />} label={LL("All")} />
                        <FormControlLabel value="false" control={<Radio />} label={LL("withoutresponse")} />
                        <FormControlLabel value="true" control={<Radio />} label={LL("withresponse")} />

                    </RadioGroup>
                </FormControl> */}

        <WithoutResponseFilter
          value={filterWithOutResponse}
          onChange={handleChangeResponse}
        />
      </Box>
    );
  };

  const processSortSelect = () => {
    const objects = ["date_asc", "date_desc"];
    return (
      <Box
        sx={{
          "& label": {
            display: "none",
          },
        }}>
        <MySortSelect
          objects={objects}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"
        />
      </Box>
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    setSearchReq(search);
  };

  const processSearch = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Box className="form-input-box" display="flex" alignItems="center">
          <SearchIcon />
          <MyTextField
            fullWidth
            id="search"
            name="search"
            _label={LL("Search by message")}
            value={search}
            setObj={(field: any, value: any) => {
              setSearch(value);
            }}
            _vresults={undefined}
          />
        </Box>
      </form>
    );
  };

  const processPagination = () => {
    if (totalPage === -1) return <></>;
    if (total === -1) return <></>;
    if (objects.length === 0) return <></>;

    return (
      <Box
        className="container"
        py={4}
        display="flex"
        sx={{ justifyContent: "center" }}>
        <MyPagination
          page={page}
          total={total}
          totalPage={totalPage}
          setPage={setPage}
        />
      </Box>
    );
  };

  const processTeacherInterfaceFAQList = () => {
    if (!objects.length) return <NoResult identifier="teacher_faq" />;

    return (
      <Box>
        {objects.map((item: TeacherFAQDto, i: number) => {
          return processTeacherFAQItem(item, i);
        })}
      </Box>
    );
  };

  return (
    <Box className="container">
      <Grid container spacing={2} py={2} sx={{ alignItems: "center" }}>
        <Grid item lg={3} xs={12}>
          {processSearch()}
        </Grid>
        <Grid item lg={2} xs={12}>
          {processSortSelect()}
        </Grid>
        <Grid item lg={2} xs={12}>
          {processCourseFilter()}
        </Grid>
        <Grid item lg={5} xs={12}>
          {processFilterWithResponse()}
        </Grid>
      </Grid>

      {isLoading ? (
        <SkeletonFAQList lines={5} />
      ) : (
        processTeacherInterfaceFAQList()
      )}
      {isLoading ? <SkeletonPagination /> : processPagination()}
    </Box>
  );
};

export { TeacherInterfaceFAQList };
