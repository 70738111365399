import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import { CommonTools } from "tools/utils/commontools";
import { ChatList } from "components/course/chat/chat/ChatList";
import { SelectedChat } from "components/course/chat/chat/SelectedChat";
import { Config } from "tools/utils/config";

const TeacherMessageBlock: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();
  const idUser = CommonTools.processObjectField(user, ["id"]);

  if (!idUser) return null;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "calc(100vh - " + Config.HEADER_HEIGHT + "px - 32px  )",
        width: "calc(100% - 32px)",
        mt: 2,
        mx: "auto",
        // justifyContent: "space-between",
        gap: 2,
      }}>
      <Box
        className="chatShadow"
        sx={{
          width: "25%",
          height: "calc(100vh - " + Config.HEADER_HEIGHT + "px - 32px  )",

          background: "white",
          borderRadius: "10px",
          overflow: "hidden",
        }}>
        <ChatList />
      </Box>
      <Box
        sx={{
          width: "75%",
          height: "calc(100vh - " + Config.HEADER_HEIGHT + "px - 32px  )",
        }}>
        <SelectedChat />
      </Box>
    </Box>
  );
};

export { TeacherMessageBlock };
