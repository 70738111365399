import { List, ListItem } from "@mui/material";
import { NoResult } from "components/general/NoResult";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { NotificationDto } from "dto/user/notification.dto";
import { useAuth } from "hooks/useAuth";
import { useResource } from "hooks/useResource";
import React, { useState, useEffect } from "react";
import { NotificationService } from "services/user/notification.service";
import { Status } from "tools//types/status";
import { Types } from "tools/types/types";
import { NotificationListItemTopMenu } from "./NotificationListItemTopMenu";
import { SkeletonNotification } from "components/elements/Skeleton/SkeletonNotifications";
import { useNotification } from "hooks/useNotification";

type Props = {
  currentRoute: any;
  typeRecipient: number;
  open: boolean;
  objectWasChanged: string;
  setObjectWasChanged: any;
  setIsDisableMarkAllRead: any;
};

const service = new NotificationService();
const NotificationListTopMenu: React.FC<Props> = ({
  currentRoute,
  typeRecipient,
  open,
  objectWasChanged,
  setObjectWasChanged,
  setIsDisableMarkAllRead,
  ...props
}) => {
  const { CC } = useResource();
  const { teacher, student } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [objects, setObjects] = useState<Array<NotificationDto>>([]);
  const {
    setNumberOfUnreadNotificationsStudent,
    setNumberOfUnreadNotificationsTeacher,
  } = useNotification();
  const getList = () => {
    if (!open) return;
    const reqList = new RequestListDTO();

    reqList.onpage = parseInt(CC("MaxOnPageNotificationTopMenu", "5"));
    reqList.page = 1;
    reqList.filters = [];

    const sort = new RequestSortCriteriaDTO();
    sort.field = "date";
    sort.asc = false;
    reqList.sortcriteria = [sort];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "type";
    f.values = [Types.TYPE_NOTIFICATION_TOP_SITE.toString()];
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [Status.NOTIFICATION_NEW.toString()];
    reqList.filters.push(f);

    if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_STUDENT) {
      if (!student) return;
      if (!student.id) return;
      f = new RequestFilterDTO();
      f.field = "idstudent";
      f.values = [student.id];
      reqList.filters.push(f);
    } else if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER) {
      if (!teacher) return;
      if (!teacher.id) return;
      f = new RequestFilterDTO();
      f.field = "idteacher";
      f.values = [teacher.id];
      reqList.filters.push(f);
    }

    setLoading(true);

    service.getList(loadObjects, {}, reqList);
  };

  const checkLoading = () => {
    let load = false;
    if (!objects) load = true;
    setLoading(load);
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      setObjects(objects);
      if (objects.length === 0) {
        setIsDisableMarkAllRead(true);
      } else {
        setIsDisableMarkAllRead(false);
      }
    }
    const total = result.total ? result.total : 0;
    if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_STUDENT) {
      setNumberOfUnreadNotificationsStudent(total);
    }
    if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER) {
      setNumberOfUnreadNotificationsTeacher(total);
    }
  };

  useEffect(() => {
    checkLoading();
  }, [objects]);

  useEffect(() => {
    getList();
  }, [open, objectWasChanged]);

  const processItem = (item: NotificationDto, i: number) => {
    if (!item) return null;
    return (
      <ListItem key={i}>
        <NotificationListItemTopMenu
          obj={item}
          setObjectWasChanged={setObjectWasChanged}
          setLoading={setLoading}
          setIsDisableMarkAllRead={setIsDisableMarkAllRead}
        />
      </ListItem>
    );
  };

  const processList = () => {
    if (loading) return <SkeletonNotification lines={3} />;
    if (!objects.length) return <NoResult identifier={"notification"} />;
    return (
      <List>
        {objects.map((item: NotificationDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  return processList();
};

export { NotificationListTopMenu };
