import React, { useState, useEffect, useCallback, useRef } from "react";
import { useResource } from "hooks/useResource";
import {
  EditLessonOrderDto,
  LessonDto,
  PatchLessonOrderDto,
} from "dto/course/lesson.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import { LessonService } from "services/course/lesson.service";

import { Box, Skeleton, Tooltip } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { MyButton } from "components/elements/button/MyButton";

import { EditCourseLessonItem } from "./EditCourseLessonItem";

import SortableList, { SortableItem } from "react-easy-sort";

import { useMessage } from "hooks/useMessage";

import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/commontools";
import { AddLessonDialog } from "./AddLessonDialog";

type Props = {
  idCourse: string;
  openDialogAdd: boolean;
  setOpenDialogAdd: (value: boolean) => void;
  canEdit: boolean;
};

const service = new LessonService();

const EditCourseLessonList: React.FC<Props> = ({
  idCourse,
  
  canEdit,
  openDialogAdd,
  setOpenDialogAdd,
}) => {
  const { LL } = useResource();
  const [loadingUpdateOrder, setLoadingUpdateOrder] = useState(false);

  const [initialObjects, setInitialObjects] = useState<Array<LessonDto>>([]);
  const [isLessonInEditMode, setIsLessonInEditMode] = useState(true);
  const { registerDialog } = useMessage();
  const [draggedObjects, setDraggedObjects] = useState<
    Array<EditLessonOrderDto>
  >([]);
  const isFirstRender = useRef(true);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idCourse) return;
    service.getListForEditCourse(cb, cbParams, req);
  };
  const [loading, objects, , , setObjects] = useList<LessonDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idcourse", [idCourse])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("ordercriteria", true)]
    ),
    [idCourse]
  );

  const processObjects = useCallback(() => {
    if (!objects) return;
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setInitialObjects(objects);
    }
  }, [objects]);

  useEffect(() => {
    processObjects();
  }, [processObjects]);

  //   const getList = () => {
  //     setLoading(true);
  //     const reqList = new RequestListDTO();
  //     reqList.onpage = parseInt(CC("DefaultOnPageEditCourseLessonList", "200"));
  //     reqList.page = 1;
  //     reqList.filters = [];
  //     reqList.sortcriteria = [];

  //     const sortCriteria = new RequestSortCriteriaDTO();
  //     sortCriteria.field = "ordercriteria";
  //     sortCriteria.asc = true;
  //     reqList.sortcriteria.push(sortCriteria);

  //     let f: RequestFilterDTO;

  //     f = new RequestFilterDTO();
  //     f.field = "idcourse";
  //     f.values = [idCourse];
  //     reqList.filters.push(f);

  //     service.getListForEditCourse(loadObjects, {}, reqList);
  //   };

  //   const loadObjects = (result: ResultListDTO) => {
  //     if (!result) return;
  //     if (!result.err) {
  //       const objects = result.objects ? result.objects : [];
  //       setInitialObjects(objects);
  //       setObjects(objects);
  //     }
  //   };

  //   const checkLoading = () => {
  //     let load = false;

  //     if (!objects) load = true;

  //     setLoading(load);
  //   };

  //   useEffect(() => {
  //     getList();
  //   }, [idCourse, objectHasChanged]);

  //   useEffect(() => {
  //     checkLoading();
  //   }, [objects]);

  const hasOrderChanged = (): boolean => {
    if (objects) {
      if (objects.length !== initialObjects.length) {
        return true;
      }
      for (let i = 0; i < initialObjects.length; i++) {
        if (initialObjects[i].id !== objects[i].id) {
          return true;
        }
      }
    }
    return false;
  };

  const processLessonItem = (obj: LessonDto, index: number) => {
    if (!obj) return <></>;
    if (!obj.id) return <></>;

    return (
      <Box key={index}>
        <EditCourseLessonItem
          objectFromList={obj}
          idCourse={idCourse}
          
          setIsLessonInEditMode={setIsLessonInEditMode}
          updateList={updateList}
          deleteFromList={deleteFromList}
          canEdit={canEdit}
        />
      </Box>
    );
  };

  const handleConfirmUpdateOrder = () => {
    if (!draggedObjects) return;
    if (!draggedObjects.length) return;
    const obj = new PatchLessonOrderDto();
    obj.neworder = draggedObjects;
    obj.idcourse = idCourse;
    if (!obj.idcourse) return;
    setLoadingUpdateOrder(true);
    service.updateOrder(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    const obj: any = result.obj;
    const objects = obj.objects ?? [];
    setObjects(objects);
    setInitialObjects(objects);
    setLoadingUpdateOrder(false);
  };

  const handleOnDragEnd = (oldIndex: number, newIndex: number) => {
    if (!objects) return;
    const reorderedItems = Array.from(objects);
    const [movedItem] = reorderedItems.splice(oldIndex, 1);
    reorderedItems.splice(newIndex, 0, movedItem);
    setObjects(reorderedItems);

    processNewOrder(reorderedItems);
  };

  const processNewOrder = (reorderedItems: Array<LessonDto>) => {
    const updatedOrder = reorderedItems.map((item, index) => {
      return { id: item.id, ordercriteria: index };
    });
    setDraggedObjects(updatedOrder);
  };

  const handleSubmit = () => {
    registerDialog(
      LL("Confirm_edit_lesson_order"),
      LL("Are_you_sure_you_want_to_edit_this_course_lessons_order"),
      { cb: handleConfirmUpdateOrder },
      { cb: handleCancel }
    );
  };

  const handleCancel = () => {
    setObjects(initialObjects);
  };

  const updateList = (obj: LessonDto) => {
    if (!objects) return;
    const arr = [...objects];
    const id = CommonTools.processObjectField(obj, ["id"]);
    const index = objects.findIndex((item) => item.id === id);
    if (index === -1) return;
    arr[index] = obj;
    setObjects(arr);
  };

  const deleteFromList = (id: string) => {
    if (!objects) return;
    const arr = objects.filter((item) => item.id !== id);
    setObjects(arr);
  };

  const addToList = (obj: LessonDto) => {
    if (!objects) return;
    const arr = [...objects, obj];
    setInitialObjects(arr);
    setObjects(arr);
  };

  const processButtonSubmit = () => {
    let disabled = true;
    if (draggedObjects.length > 0) disabled = false;
    if (hasOrderChanged()) disabled = false;
    else disabled = true;
    if (!isLessonInEditMode) disabled = true;
    return (
      <Box mt={4} textAlign="center">
        <MyButton
          cb={handleSubmit}
          disabled={disabled || loadingUpdateOrder || !canEdit}
          color="primary"
          className="btn"
          variant="contained"
          size="large"
          type="submit"
        >
          {LL("Bt_Submit")}
        </MyButton>
      </Box>
    );
  };

  const processList = () => {
    if (!objects) return <></>;
    if (!objects.length) return <></>;
    if (loading) return <Skeleton />;

    return (
      <SortableList
        onSortEnd={handleOnDragEnd}
        allowDrag={isLessonInEditMode && !loadingUpdateOrder}
      >
        {objects.map((item: LessonDto, i: number) => {
          if (!item) return <></>;
          if (!item.id) return <></>;
          return (
            <SortableItem key={item.id}>
              {processLessonItem(item, i)}
            </SortableItem>
          );
        })}
      </SortableList>
    );
  };

  return loading ? (
    <Skeleton />
  ) : (
    <React.Fragment>
      <AddLessonDialog
        id={idCourse}
        open={openDialogAdd}
        setOpen={setOpenDialogAdd}
        addToList={addToList}
        canEdit={canEdit}
      />
      <Tooltip
        open={loadingUpdateOrder}
        title={LL("Order_is_updating_please_wait")}
        followCursor
      >
        <Box>
          {processList()}
          {processButtonSubmit()}
        </Box>
      </Tooltip>
    </React.Fragment>
  );
};

export { EditCourseLessonList };
