import { CourseDto } from "dto/course/course.dto";
import { useResource } from "hooks/useResource";
import React from "react";
import { ActionCourseComponent } from "./CourseActionElements";
import { VerifiedUserOutlined } from "@mui/icons-material";

import { PostCourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseTeacherRequestService } from "services/course/courseteacherrequest.service";

type Props = {
  course: CourseDto;
  setCourse: (course: CourseDto) => void;
};

const service = new CourseTeacherRequestService();
export const VerifyCourseAction: React.FC<Props> = ({ course,
    setCourse
 }) => {
  const { LL } = useResource();

  const handleSubmit = (
    obj: PostCourseTeacherRequestDto,
    handleResult: (result: ResultObjectDTO) => void
  ) => {
    service.verify(handleResult, {}, obj);
  };

  const handleResultAction = (result: ResultObjectDTO) => {
    if(!result) return;
    if(result.err) return;
    if(!result.obj) return;
    setCourse(result.obj);
  };

  const disabled = !course.can_verify;
  return (
    <ActionCourseComponent
      titleAction={LL("SendCourseToverifying")}
      contentButton={
        <React.Fragment>
          <VerifiedUserOutlined />
          {LL("verify_course_btn")}
        </React.Fragment>
      }
      course={course}
      disabled={disabled}
      tittleDialog={LL("verify_course")}
      handleResult={handleResultAction}
      handleSubmit={handleSubmit}
    />
  );
};
