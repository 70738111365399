import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { ScheduleDto } from "dto/course/schedule.dto";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import DateTools from "tools/utils/date.tools";
import { RouteTools } from "tools/utils/routetools";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { useTimerParsedTime } from "hooks/useTimer";
import { useMessage } from "hooks/useMessage";
import { CommonTools } from "tools/utils/commontools";
import { MessageTypes } from "tools/types/messagetypes";

type Props = {
  object: ScheduleDto;
  url: string;
};

const NextLessonButton: React.FC<Props> = ({ object, url }) => {
  const { RegisterMessage } = useMessage();
  const { LL } = useResource();
  const onClick = () => {
    if (!url) return;
    const date = object.startdate || 0;
    if (!DateTools.isLessThan15Minutes(date)) {
      RegisterMessage(
        CommonTools.createMessage(
          LL("Button_will_be_active_15_minutes_before_the_start_of_the_lesson"),
          MessageTypes.MESSAGE_WARNING,
          "400"
        )
      );
      return;
    }
    RouteTools.setHistory(url, {});
  };
  if (!object) return null;

  return (
    <MyButton cb={onClick}>
      <NextLessonText object={object} />
    </MyButton>
  );
};

type NextLessonTextProps = {
  object: ScheduleDto;
};
type Color = "primary" | "success" | "warning" | "error";
const NextLessonText: React.FC<NextLessonTextProps> = ({ object }) => {
  const { LL } = useResource();
  const startDate = object.startdate || 0;
  const displayTimerHour = DateTools.isLessThanOneDay(startDate);

  const interval = displayTimerHour ? 60000 : 0;
  const { timeLeft } = useTimerParsedTime(startDate, interval);

  const getColorIcon = useCallback(() => {
    if (!interval) return "primary";
    if (!timeLeft) return "primary";
    if (timeLeft.days > 0) return "primary";
    if (timeLeft.hours >= 1 && timeLeft.days === 0) return "success";
    if (timeLeft.hours >= 0 && timeLeft.hours <= 1 && timeLeft.minutes > 15)
      return "warning";
    return "error";
  }, [timeLeft, interval]);

  const getColorText = useCallback(() => {
    if (!interval) return "#0072EF";
    if (!timeLeft) return "#0072EF";
    if (timeLeft.days > 0) return "#0072EF";
    if (timeLeft.hours >= 1 && timeLeft.days === 0) return "#01E65C";
    if (timeLeft.hours >= 0 && timeLeft.hours <= 1 && timeLeft.minutes > 15)
      return "#FFD102";
    return "#FF3434";
  }, [timeLeft, interval]);

  const [colorIcon, setColorIcon] = useState<Color>(getColorIcon());
  const [colorText, setColorText] = useState(getColorText());

  useEffect(() => {
    setColorIcon(getColorIcon());
    setColorText(getColorText());
  }, [getColorIcon, getColorText]);

  if (!object) return null;
  if (!timeLeft) return null;

  return (
    <Box className="buttonPaddingXYHeader">
      <Typography
        sx={{
          fontSize: { xs: "10px", sm: "16px" },
          color: "black",
          fontWeight: "600",
          textTransform: "unset",
          "&::first-letter": {
            textTransform: "capitalize",
          },
        }}>
        {LL("Next_lesson")}
      </Typography>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "6px",
          fontWeight: "400",
          textTransform: "lowercase",
          fontSize: { xs: "10px", sm: "16px" },
        }}
        color={colorText}>
        <EventOutlinedIcon
          className="svgFixCalendar"
          color={colorIcon}
          sx={{ fontSize: { xs: "14px", sm: "18px" } }}
        />
        {!interval
          ? DateTools.displayTimestampInDateAndHHMM(startDate)
          : `${LL("starts_in")} ${timeLeft.days
              .toString()
              .padStart(2, "0")}:${timeLeft.hours
              .toString()
              .padStart(2, "0")}:${timeLeft.minutes
              .toString()
              .padStart(2, "0")}`}
      </Typography>
    </Box>
  );
};

export { NextLessonButton, NextLessonText };
