import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MyButton } from "components/elements/button/MyButton";

import { EditCourseLessonList } from "../lesson/EditCourseLessonList";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

type Props = {
  currentRoute: any;
  canEdit: boolean;
};

const EditCourseLesson: React.FC<Props> = ({ currentRoute, canEdit }) => {
  const { LL } = useResource();

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const processLessonList = () => {
    if (!id) return null;
    return (
      <EditCourseLessonList
        idCourse={id}
        setOpenDialogAdd={setOpenDialog}
        openDialogAdd={openDialog}
        canEdit={canEdit}
      />
    );
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Box sx={{ maxWidth: "600px", mx: "auto" }} mb={4}>
      <Typography sx={{ pb: 2 }} variant="h6">
        {LL("Lectii curs")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          my: 3,
        }}
      >
        <MyButton
          className="btn btnAddlesson"
          cb={handleOpenDialog}
          disabled={!canEdit}
        >
          <AddOutlinedIcon />
          {LL("Bt_Add_Lesson")}
        </MyButton>
      </Box>
      {processLessonList()}
    </Box>
  );
};

export default EditCourseLesson;
