import { Box, List, ListItem } from "@mui/material";

import { useChat } from "context/chat.context";

import ScrollView, { ScrollViewRef } from "devextreme-react/scroll-view";

import { ChatDto } from "dto/course/chat.dto";
import React, { useCallback, useEffect, useRef } from "react";

import { CommonTools } from "tools/utils/commontools";

import { ChatItem } from "./ChatItem";
import { Config } from "tools/utils/config";
import { useResource } from "hooks/useResource";

const ChatList: React.FC = () => {
  const scrollViewRef = useRef<ScrollViewRef | null>(null);
  const { page, totalPages, getList, handleSetPage, chats, total } = useChat();

  const callGetList = useCallback(() => {
    getList(actionFunction, callbackAction);
  }, [getList]);

  const { LL } = useResource();
  const actionFunction = () => {
    const scrollView = scrollViewRef.current;
    if (!scrollView) return;
    scrollView.instance().release(false);
  };

  const callbackAction = () => {
    const scrollView = scrollViewRef.current;
    if (!scrollView) return;
    scrollView.instance().release(true);
  };

  useEffect(() => {
    callGetList();
  }, [callGetList]);

  const onReachBottom = () => {
    if (page >= totalPages || total === chats.length) {
      callbackAction();
    }
    handleSetPage(page + 1);
  };

  const processItem = (item: ChatDto) => {
    if (!item) return null;
    return (
      <ListItem
        key={CommonTools.processObjectField(item, ["id"])}
        sx={{
          margin: 0,
          py: "4px",
          px: 0,
        }}>
        <ChatItem object={item} />
      </ListItem>
    );
  };

  return (
    <Box
      p={0}
      sx={{
        pt: 1,
        height: "100%",
        width: "100%",
        maxHeight: "calc(100vh - " + Config.HEADER_HEIGHT + "px  )",
      }}>
      <ScrollView
        ref={scrollViewRef}
        onReachBottom={onReachBottom}
        showScrollbar="onScroll"
        scrollByContent
        scrollByThumb={true}
        bounceEnabled={true}
        height={"calc(100vh - " + Config.HEADER_HEIGHT + "px - 32px )"}
        reachBottomText={LL("noMOreText")}>
        <List
          sx={{
            minHeight: "calc(100vh - " + Config.HEADER_HEIGHT + "px - 32px  )",
            height: "auto",
            width: "calc(100% - 0px)",
          }}>
          {chats.map((item) => processItem(item))}
        </List>
      </ScrollView>
    </Box>
  );
};

export { ChatList };
