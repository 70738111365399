import Idto from "interfaces/idto.interface";
import { SchedulerTypes } from "devextreme-react/scheduler";
import { CommonTools } from "tools/utils/commontools";
import DateTools from "tools/utils/date.tools";
import { Types } from "tools/types/types";

export type AppointmentScheduler = SchedulerTypes.Appointment & {
  color: string;
  schedule: ScheduleDto;
};
export class ScheduleDto implements Idto {
  id?: string;
  startdate?: number;
  enddate?: number;
  typedestenation?: number;
  iduser?: string;
  idstudent?: string;
  idteacher?: string;
  idcourse?: string;
  idlesson?: string;
  status?: number;
  info?: string;

  static parseAppointments = (
    data?: ScheduleDto[] | null
  ): AppointmentScheduler[] => {
    if (!data) return [];
    const appointmentColors = ScheduleDto.groupCourseColors(data);
    return data.map((item) => {
      return {
        startDate: DateTools.convertTimestampToDate(item.startdate),
        endDate: DateTools.convertTimestampToDate(item.enddate),
        text: CommonTools.processObjectField(item, ["info"]),
        color:
          appointmentColors[CommonTools.processObjectField(item, ["idcourse"])],
        schedule: item,
      };
    });
  };

  constructor(
    id?: string,
    startdate?: number,
    enddate?: number,
    typedestenation?: number,
    iduser?: string,
    idstudent?: string,
    idteacher?: string,
    idcourse?: string,
    idlesson?: string,
    status?: number,
    info?: string
  ) {
    this.id = id || "";
    this.startdate = startdate || 0;
    this.enddate = enddate || 0;
    this.typedestenation = typedestenation || 0;
    this.iduser = iduser || "";
    this.idstudent = idstudent || "";
    this.idteacher = idteacher || "";
    this.idcourse = idcourse || "";
    this.idlesson = idlesson || "";
    this.status = status || 0;
    this.info = info || "";
  }

   static generateUrlClassroom = (object?: ScheduleDto | null) => {
      if (!object) return "";
      const idCourse = CommonTools.processObjectField(object, ["idcourse"]);
      const idLesson = CommonTools.processObjectField(object, ["idlesson"]);
      const idCourseEnrollment = CommonTools.processObjectField(object, [
        "idcourseenrollment",
      ]);
      const interfaceUser =
        CommonTools.processObjectField(object, ["typedestenation"]).toString() ===
        Types.TYPE_SCHEDULE_TEACHER.toString()
          ? `teacherinterface/classroom/${idCourse}`
          : `profile/courseenrollment/${idCourseEnrollment}`;
  
      return `/${interfaceUser}/${idLesson}`;
    };
  static appointmentColors = [
    "linear-gradient(120deg, #FF5733, #FF8C00)",
    "linear-gradient(30deg, #33FF57, #00FA9A)",
    "linear-gradient(to right, #3357FF, #1E90FF)",
    "linear-gradient(to right, #FF6347, #FF4500)",
    "linear-gradient(to right, #FF33FF, #C71585)",
    "linear-gradient(to right, #2fa0a0, #40E0D0)",
    "linear-gradient(to right, #800000, #A52A2A)",
    "linear-gradient(to right, #808000, #9ACD32)",
    "linear-gradient(to right, #008000, #32CD32)",
    "linear-gradient(to right, #800080, #BA55D3)",
    "linear-gradient(to right, #008080, #25a2a2)",
    "linear-gradient(to right, #000080, #4682B4)",
    "linear-gradient(to right, #FF4500, #DC143C)",
    "linear-gradient(to right, #DA70D6, #EE82EE)",
    "linear-gradient(to right, #7FFF00, #ADFF2F)",
    "linear-gradient(to right, #5F9EA0, #4682B4)",
    "linear-gradient(to right, #6495ED, #1E90FF)",
    "linear-gradient(to right, #DC143C, #FF4500)",
    "linear-gradient(to right, #FF8C00, #FFD700)",
    "linear-gradient(to right, #FFD700, #FFA500)",
    "linear-gradient(to right, #4B0082, #6A5ACD)",
    "linear-gradient(to right, #FF1493, #C71585)",
    "linear-gradient(to right, #1E90FF, #4169E1)",
    "linear-gradient(to right, #00CED1, #20B2AA)",
    "linear-gradient(to right, #FFDAB9, #FFE4B5)",
    "linear-gradient(to right, #7B68EE, #6A5ACD)",
    "linear-gradient(to right, #F4A460, #DEB887)",
    "linear-gradient(to right, #2E8B57, #3CB371)",
    "linear-gradient(to right, #00FF7F, #32CD32)",
    "linear-gradient(to right, #4682B4, #5F9EA0)",
    "linear-gradient(to right, #D2691E, #A0522D)",
    "linear-gradient(to right, #8A2BE2, #9370DB)",
    "linear-gradient(to right, #A52A2A, #8B0000)",
    "linear-gradient(to right, #FF7F50, #FA8072)",
    "linear-gradient(to right, #87CEEB, #00BFFF)",
    "linear-gradient(to right, #32CD32, #228B22)",
    "linear-gradient(to right, #BA55D3, #9932CC)",
    "linear-gradient(to right, #00FA9A, #20B2AA)",
    "linear-gradient(to right, #C71585, #DB7093)",
    "linear-gradient(to right, #FF6347, #FF4500)",
    "linear-gradient(to right, #40E0D0, #48D1CC)",
    "linear-gradient(to right, #EE82EE, #DA70D6)",
    "linear-gradient(to right, #F08080, #FA8072)",
    "linear-gradient(to right, #98FB98, #00FA9A)",
    "linear-gradient(to right, #AFEEEE, #48D1CC)",
    "linear-gradient(to right, #B0E0E6, #87CEEB)",
    "linear-gradient(to right, #CD853F, #D2B48C)",
    "linear-gradient(to right, #E9967A, #FFA07A)",
    "linear-gradient(to right, #6A5ACD, #483D8B)"
  ];
  
  
  static groupCourseColors = (data: ScheduleDto[]): Record<string, string> => {
    const colors = ScheduleDto.appointmentColors;
    const courseColors: Record<string, string> = {};
    let colorIndex = 0;
    data.forEach((item) => {
      if (!courseColors[CommonTools.processObjectField(item, ["idcourse"])]) {
        courseColors[CommonTools.processObjectField(item, ["idcourse"])] =
          colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length;
      }
    });
    return courseColors;
  };
}

export class PostScheduleDto implements Idto {
  startdate?: Date;
  enddate?: Date;
  islifetime?: boolean;
}
