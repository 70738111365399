import { Box, Typography } from "@mui/material";
import { PasswordField } from "components/form/PasswordField";
import { ResetPasswordDto } from "dto/user/user.dto";
import React, { useCallback, useEffect, useState } from "react";
import { PasswordStrengthChecker } from "./PasswordStrengthChecker";
import { useResource } from "hooks/useResource";
import { useForm } from "hooks/useForm";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/elements/button/MyButton";
import { useGoogleRecaptcha } from "hooks/useGoogleRecaptcha";

type Props = {
  onSubmit: (obj: ResetPasswordDto) => void;
  defaultObject: ResetPasswordDto;
  loading: boolean;
};

const FormResetPassword: React.FC<Props> = ({
  onSubmit,
  defaultObject,
  loading,
}) => {
  const { LL } = useResource();
  const { executeRecaptcha } = useGoogleRecaptcha();
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [globalDisabled, setGlobalDisabled] = useState<boolean>(false);
  const [obj, disabled, setObjField, setObject, vResult] =
    useForm<ResetPasswordDto>(
      defaultObject,
      RequiredValidator.getValidators(["email", "password", "code"])
    );

  const checkConfirmPassword = useCallback(() => {
    let localDisabled = disabled;
    if (obj.password !== confirmPassword) {
      localDisabled = true;
    }
    setGlobalDisabled(localDisabled);
  }, [disabled, obj.password, confirmPassword]);

  useEffect(() => {
    checkConfirmPassword();
  }, [checkConfirmPassword]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (globalDisabled) return;
    await executeRecaptcha();
    onSubmit(obj);
  };

  useEffect(() => {
    if (!defaultObject) return;
    setObject(defaultObject);
    setConfirmPassword("");
  }, [defaultObject]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6">{LL("Password")}</Typography>
          <Box className="box-form-input-icon-end">
            <PasswordField
              fullWidth
              id="password"
              name="password"
              className="form-input"
              variant="standard"
              _label={LL("Password")}
              value={obj.password}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mb={3}>
          <PasswordStrengthChecker password={obj.password} />
        </Box>
        <Box mb={3}>
          <Typography variant="h6">{LL("Confirm Password")}</Typography>
          <Box className="box-form-input-icon-end">
            <PasswordField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              className="form-input"
              variant="standard"
              _label={LL("Confirm Password")}
              value={confirmPassword}
              setObj={(field: any, value: any) => setConfirmPassword(value)}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box>
          <MyButton
            disabled={globalDisabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            type="submit"
            className="btn">
            {LL("bt_reset")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormResetPassword };
