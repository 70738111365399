import { useResource } from "hooks/useResource";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { Carousel } from "components/elements/Carousel/Carousel";
import { Box, Typography } from "@mui/material";

type Props = {
  url: string;
  title?: string;
  description?: string;
  imageUrl?: string;
};

const ShareElements: React.FC<Props> = ({
  url,
  title = "",
  description = "",
  imageUrl = "",
  ...props
}) => {
  const handOnClick = (url: string) => {
    if(!url) return;
    window.open(url, "_blank");
  };
  const { CC, LL } = useResource();
  const processFacebook = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `https://www.facebook.com/sharer/sharer.php?u=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.facebook.com/sharer/sharer.php?u=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-facebook-f"></i>
        <Typography>{LL("Facebook")}</Typography>
      </a>
    );
  };

  const processTwitter = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl =  `https://twitter.com/intent/tweet?url=${localUrl}&text=${title}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://twitter.com/intent/tweet?url=${localUrl}&text=${title}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-twitter"></i>
        <Typography>{LL("Twitter")}</Typography>
      </a>
    );
  };

  const processLinkedin = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localDescription = encodeURIComponent(description);
    const localImageUrl = encodeURIComponent(imageUrl);

    const actionUrl = `https://www.linkedin.com/shareArticle?url=${localUrl}&title=${localTitle}&summary=${localDescription}&source=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.linkedin.com/shareArticle?url=${localUrl}&title=${localTitle}&summary=${localDescription}&source=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-linkedin-in"></i>
        <Typography>{LL("LinkedIn")}</Typography>
      </a>
    );
  };

  const processWhatsapp = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `https://api.whatsapp.com/send?text=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://api.whatsapp.com/send?text=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-whatsapp"></i>
        <Typography>{LL("WhatsApp")}</Typography>
      </a>
    );
  };

  const processTelegram = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `https://telegram.me/share/url?url=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://telegram.me/share/url?url=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-telegram"></i>
        <Typography>{LL("Telegram")}</Typography>
      </a>
    );
  };

  const processEmail = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localDescription = encodeURIComponent(description);
    const actionUrl = `mailto:?subject=${localTitle}&body=${localDescription}%0A${localUrl}`;

    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`mailto:?subject=${localTitle}&body=${localDescription}%0A${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-envelope"></i>
        <Typography>{LL("Email")}</Typography>
      </a>
    );
  };

  const processGooglePlus = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `https://plus.google.com/share?url=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://plus.google.com/share?url=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-google-plus-g"></i>
        <Typography>{LL("GooglePlus")}</Typography>
      </a>
    );
  };

  const processPinterest = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://pinterest.com/pin/create/button/?url=${localUrl}&media=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://pinterest.com/pin/create/button/?url=${localUrl}&media=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-pinterest"></i>
        <Typography>{LL("Pinterest")}</Typography>
      </a>
    );
  };

  const processReddit = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `https://reddit.com/submit?url=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://reddit.com/submit?url=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-reddit"></i>
        <Typography>{LL("Reddit")}</Typography>
      </a>
    );
  };

  const processTumblr = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localDescription = encodeURIComponent(description);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://www.tumblr.com/share/link?url=${localUrl}&name=${localTitle}&description=${localDescription}&thumbnail=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.tumblr.com/share/link?url=${localUrl}&name=${localTitle}&description=${localDescription}&thumbnail=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-tumblr"></i>
        <Typography>{LL("Tumblr")}</Typography>
      </a>
    );
  };

  const processVk = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localDescription = encodeURIComponent(description);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://vk.com/share.php?url=${localUrl}&title=${localTitle}&description=${localDescription}&image=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://vk.com/share.php?url=${localUrl}&title=${localTitle}&description=${localDescription}&image=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-vk"></i>
        <Typography>{LL("VK")}</Typography>
      </a>
    );
  };

  const processXing = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localDescription = encodeURIComponent(description);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://www.xing.com/app/user?op=share;url=${localUrl};title=${localTitle};description=${localDescription};image=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.xing.com/app/user?op=share;url=${localUrl};title=${localTitle};description=${localDescription};image=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-xing"></i>
        <Typography>{LL("Xing")}</Typography>
      </a>
    );
  };

  const processBuffer = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const actionUrl = `https://buffer.com/add?text=${localTitle}&url=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://buffer.com/add?text=${localTitle}&url=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-buffer"></i>
        <Typography>{LL("Buffer")}</Typography>
      </a>
    );
  };

  const processHackerNews = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const actionUrl = `https://news.ycombinator.com/submitlink?u=${localUrl}&t=${localTitle}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://news.ycombinator.com/submitlink?u=${localUrl}&t=${localTitle}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-hacker-news"></i>
        <Typography>{LL("HackerNews")}</Typography>
      </a>
    );
  };

  const processFlipboard = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localDescription = encodeURIComponent(description);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://share.flipboard.com/bookmarklet/popout?v=2&title=${localTitle}&url=${localUrl}&t=${localTitle}&description=${localDescription}&images=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://share.flipboard.com/bookmarklet/popout?v=2&title=${localTitle}&url=${localUrl}&t=${localTitle}&description=${localDescription}&images=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-flipboard"></i>
        <Typography>{LL("Flipboard")}</Typography>
      </a>
    );
  };

  const processInstapaper = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const actionUrl = `https://www.instapaper.com/edit?url=${localUrl}&title=${localTitle}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.instapaper.com/edit?url=${localUrl}&title=${localTitle}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-instapaper"></i>
        <Typography>{LL("Instapaper")}</Typography>
      </a>
    );
  };

  const processLivejournal = () => {
    if (!url) return null;

    // const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localDescription = encodeURIComponent(description);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://www.livejournal.com/update.bml?subject=${localTitle}&event=${localDescription}&transform=1&prop_picture=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.livejournal.com/update.bml?subject=${localTitle}&event=${localDescription}&transform=1&prop_picture=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-livejournal"></i>
        <Typography>{LL("Livejournal")}</Typography>
      </a>
    );
  };

  const processMailru = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://connect.mail.ru/share?url=${localUrl}&title=${localTitle}&image_url=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://connect.mail.ru/share?url=${localUrl}&title=${localTitle}&image_url=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-mailru"></i>
        <Typography>{LL("Mailru")}</Typography>
      </a>
    );
  };

  const processMeneame = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const actionUrl = `https://www.meneame.net/submit.php?url=${localUrl}&title=${localTitle}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.meneame.net/submit.php?url=${localUrl}&title=${localTitle}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-meneame"></i>
        <Typography>{LL("Meneame")}</Typography>
      </a>
    );
  };

  const processOdnoklassniki = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${localUrl}&title=${localTitle}&imageUrl=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${localUrl}&title=${localTitle}&imageUrl=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-odnoklassniki"></i>
        <Typography>{LL("Odnoklassniki")}</Typography>
      </a>
    );
  };

  const processPrint = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `https://www.printfriendly.com/print?url=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.printfriendly.com/print?url=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-print"></i>
        <Typography>{LL("Print")}</Typography>
      </a>
    );
  };

  const processQzone = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${localUrl}&title=${localTitle}&pics=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${localUrl}&title=${localTitle}&pics=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-qzone"></i>
        <Typography>{LL("Qzone")}</Typography>
      </a>
    );
  };

  const processRenren = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `https://widget.renren.com/dialog/share?resourceUrl=${localUrl}&srcUrl=${localUrl}&title=${localTitle}&description=${localTitle}&pic=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://widget.renren.com/dialog/share?resourceUrl=${localUrl}&srcUrl=${localUrl}&title=${localTitle}&description=${localTitle}&pic=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-renren"></i>
        <Typography>{LL("Renren")}</Typography>
      </a>
    );
  };

  const processSkype = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const actionUrl = `https://web.skype.com/share?url=${localUrl}&text=${localTitle}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://web.skype.com/share?url=${localUrl}&text=${localTitle}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-skype"></i>
        <Typography>{LL("Skype")}</Typography>
      </a>
    );
  };

  const processSMS = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `sms:?&body=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`sms:?&body=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-sms"></i>
        <Typography>{LL("SMS")}</Typography>
      </a>
    );
  };

  const processStumbleupon = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const actionUrl = `https://www.stumbleupon.com/submit?url=${localUrl}&title=${localTitle}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`https://www.stumbleupon.com/submit?url=${localUrl}&title=${localTitle}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-stumbleupon"></i>
        <Typography>{LL("Stumbleupon")}</Typography>
      </a>
    );
  };

  const processViber = () => {
    if (!url) return null;
    const localUrl = encodeURIComponent(url);
    const actionUrl = `viber://forward?text=${localUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`viber://forward?text=${localUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-viber"></i>
        <Typography>{LL("Viber")}</Typography>
      </a>
    );
  };

  const processWeibo = () => {
    if (!url) return null;

    const localUrl = encodeURIComponent(url);
    const localTitle = encodeURIComponent(title);
    const localImageUrl = encodeURIComponent(imageUrl);
    const actionUrl = `http://service.weibo.com/share/share.php?url=${localUrl}&title=${localTitle}&pic=${localImageUrl}`;
    return (
      <a
        onClick={handOnClick.bind(this, actionUrl)}
        href={`http://service.weibo.com/share/share.php?url=${localUrl}&title=${localTitle}&pic=${localImageUrl}`}
        target="_blank"
        rel="noreferrer"
        className="shareIcon"
      >
        <i className="fab fa-weibo"></i>
        <Typography>{LL("Weibo")}</Typography>
      </a>
    );
  };

  const processElements = () => {
    const elements = [];
    if (CC("share_facebook", "true") === "true")
      elements.push(processFacebook());
    if (CC("share_twitter", "true") === "true") elements.push(processTwitter());
    if (CC("share_linkedin", "true") === "true")
      elements.push(processLinkedin());
    if (CC("share_whatsapp", "true") === "true")
      elements.push(processWhatsapp());
    if (CC("share_telegram", "true") === "true")
      elements.push(processTelegram());
    if (CC("share_email", "true") === "true") elements.push(processEmail());
    if (CC("share_google_plus", "true") === "true")
      elements.push(processGooglePlus());
    if (CC("share_pinterest", "true") === "true")
      elements.push(processPinterest());
    if (CC("share_reddit", "true") === "true") elements.push(processReddit());
    if (CC("share_tumblr", "true") === "true") elements.push(processTumblr());
    if (CC("share_vk", "true") === "true") elements.push(processVk());
    if (CC("share_xing", "true") === "true") elements.push(processXing());
    if (CC("share_buffer", "true") === "true") elements.push(processBuffer());
    if (CC("share_hacker_news", "true") === "true")
      elements.push(processHackerNews());
    if (CC("share_flipboard", "true") === "true")
      elements.push(processFlipboard());
    if (CC("share_instapaper", "true") === "true")
      elements.push(processInstapaper());
    if (CC("share_livejournal", "true") === "true")
      elements.push(processLivejournal());
    if (CC("share_mailru", "true") === "true") elements.push(processMailru());
    if (CC("share_meneame", "true") === "true") elements.push(processMeneame());
    if (CC("share_odnoklassniki", "true") === "true")
      elements.push(processOdnoklassniki());
    if (CC("share_print", "true") === "true") elements.push(processPrint());
    if (CC("share_qzone", "true") === "true") elements.push(processQzone());
    if (CC("share_renren", "true") === "true") elements.push(processRenren());
    if (CC("share_skype", "true") === "true") elements.push(processSkype());
    if (CC("share_sms", "true") === "true") elements.push(processSMS());
    if (CC("share_stumbleupon", "true") === "true")
      elements.push(processStumbleupon());
    if (CC("share_viber", "true") === "true") elements.push(processViber());
    if (CC("share_weibo", "true") === "true") elements.push(processWeibo());

    return elements;
  };

  const processCarousel = () => {
    if (!url) return null;
    const elements = processElements();
    if (!elements.length) return null;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowShareOptionsCarousel", "5")),
      elements.length
    );
    return (
      <Carousel _slidesToShow={slidesToShow}>
        {elements.map((item: any, i: number) => {
          return <Box key={i}>{item}</Box>;
        })}
      </Carousel>
    );
  };

  return processCarousel();
};

export { ShareElements };
