import { useCallback, useEffect } from "react";
import { useSocket } from "./useSocket";

// USAGE
//  const onM = useCallback((data: any) => {
//    console.log("useSocketPersonalMessage", data);
//  }, []);
//  useSocketMessage("test-event", onM);

const useSocketMessage = (type: string, onMessage: (data: any) => void) => {
  const socket = useSocket();

  useEffect(() => {
    if (!socket) {
      return;
    }

    const handleMessage = (data: any) => {
      if (onMessage) onMessage(data);
    };

    socket.on(type, handleMessage);

    socket.onAny((event, ...args) => {});

    return () => {
      socket.off(type, handleMessage);
    };
  }, [socket, onMessage, type]);
};

const useSocketSendMessage = () => {
  const socket = useSocket();

  const sendMessage = useCallback((type: string,message: any) => {
    if (socket) {
      socket.emit(type, message);
    }
  }, [socket]);

  return [sendMessage];
};

export { useSocketMessage, useSocketSendMessage };
