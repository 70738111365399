import { Avatar, Skeleton } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";
import useObject from "hooks/useObject";
import { useResource } from "hooks/useResource";
import { CallbackType } from "interfaces/commontypes.interface";
import React from "react";
import { CourseService } from "services/course/course.service";

type Props = {
    idCourse: string;
}
const service = new CourseService();
const ChatAvatar :React.FC<Props> = ({idCourse}) => {
    const { CC } = useResource();
    const get = (id: string, cb?: CallbackType, cbParams?: any) => {
        if(!id) return;
        service.getForChat(id, cb, cbParams);
    }

    const [loading, object] = useObject<CourseDto>(
        get,
        idCourse,
        [idCourse],
        true,
        `avatar_chat_course_${idCourse}`
    );
    
    const acronym = CC("AvatarDefaultSiteAcronym", "LC");
    const defaultComponent = <Avatar>
        {acronym}
    </Avatar>
    if(!idCourse) return defaultComponent
    if(loading) return <Skeleton variant="circular" width={40} height={40} />;
    if(!object) return defaultComponent
    const url = CourseDto.getUrlImage(object);
    if(!url) return defaultComponent
    return <Avatar src={url}/>;
}

export { ChatAvatar };

