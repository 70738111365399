import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { CourseDto } from "dto/course/course.dto";
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/routetools";
import { Box, Stack, Divider } from "@mui/material";
import { TeacherProcessNameCourse } from "components/user/teacher/TeacherProcessNameCourse";

import { TypeCourseCardChip } from "./TypeCourseCardChip";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { WishlistIconChecker } from "components/user/wishlist/WishlistIconChecker";
import { CourseCardPriceContent } from "components/elements/courseelements/CourseCardPriceContent";

type Props = {
  obj: CourseDto;
  loading: boolean;
  withWishlist?: boolean;
  onChangeSubscribe?: any;
};

const CourseCard: React.FC<Props> = ({
  obj,
  loading,
  withWishlist = false,
  onChangeSubscribe,
  ...props
}) => {
  const { LL, CC } = useResource();
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");

  const goCourseDetails = () => {
    if (!obj) return;
    if (!obj.id) return;
    if (!obj.hasOwnProperty("fullurl")) return;
    if (!obj.fullurl) return;
    const url = obj.fullurl;
    RouteTools.setHistory(url, {});
  };

  const processWishlistButton = () => {
    if (!withWishlist) return null;
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;

    return (
      <Box sx={{ bottom: "10px", right: "10px", position: "absolute" }}>
        <WishlistIconChecker
          isForCard={true}
          idCourse={obj.id}
          onChangeSubscribe={onChangeSubscribe}
        />
      </Box>
    );
  };

  const prepareGallery = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!obj) return null;
    if (!imgUrl) return null;

    return (
      <CardMedia sx={{ height: 180 }} image={imgUrl} title="">
        {processCourseType()}
        {processWishlistButton()}
      </CardMedia>
    );
  };

  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("teacher")) return null;
    if (!obj.teacher) return null;

    return (
      <Box>
        <TeacherProcessNameCourse obj={obj.teacher} />
      </Box>
    );
  };

  const processCourseType = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("coursesettings")) return null;
    if (!obj.coursesettings) return null;
    return (
      <Box p={1}>
        <TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} />
      </Box>
    );
  };

  const processCoursePrice = () => {
    if (!obj) return null;
    return <CourseCardPriceContent obj={obj} />;
  };
  const processCourseFilters = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("coursefiltermain")) return null;
    if (!obj.coursefiltermain) return null;
    if (obj.coursefiltermain.length === 0) return null;
    return (
      <Stack direction="column">
        {obj.coursefiltermain.map((item, index) => {
          return (
            <Stack
              key={index}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}>
              <Typography component="span" className="lp">
                {ComponentCommonTools.processObjectFieldOfObject(
                  item,
                  "filter",
                  "name"
                )}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldOfObject(
                  item,
                  "filterdictionary",
                  "name"
                )}
                {ComponentCommonTools.processObjectField(item, "value")}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const processCourseCategories = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("linkcoursecategory")) return null;
    if (!obj.linkcoursecategory) return null;
    if (obj.linkcoursecategory.length === 0) return null;
    const limitNumberOfElements = parseInt(
      CC("COURSECARDCATEGORIESLIMIT", "3")
    );
    return (
      <Stack direction="column">
        {obj.linkcoursecategory.map((item, index) => {
          if (index >= limitNumberOfElements) return null;
          return (
            <Box key={index} className="detailsGeneral">
              {ComponentCommonTools.processObjectFieldOfObject(
                item,
                "coursecategory",
                "name"
              )}
            </Box>
          );
        })}
      </Stack>
    );
  };

  const processCourseDetails = () => {
    if (!obj) return null;

    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}>
          <Stack direction="column" className="detailsGeneral">
            <Box>
              <Typography component="span" className="lp">
                {LL("Language course")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldOfObject(
                  obj,
                  "language",
                  "name"
                )}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("CourseDuration")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldTimeStamp(
                  obj,
                  "courseduration"
                )}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("NumberOfLessons")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectField(
                  obj,
                  "numberoflessons"
                )}
              </Typography>
            </Box>
          </Stack>
          <Box className="detailsGeneral" sx={{ textAlign: "right" }}>
            {processCourseFilters()}
          </Box>
        </Stack>
      </Box>
    );
  };

  const processLinkName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("fullurl")) return processName();
    if (!obj.fullurl) return processName();
    const url = obj.fullurl;

    return (
      <MyNavBt href={url} className="name">
        {processName()}
      </MyNavBt>
    );
  };

  const processName = () => {
    if (!obj) return null;
    return (
      <Typography className="name">
        {ComponentCommonTools.processObjectField(obj, "name")}
      </Typography>
    );
  };

  const processCourseCard = () => {
    if (!obj) return null;

    return (
      <Box p={1}>
        <Card className="courseCardV">
          <Box className="imageZone" sx={{ position: "relative" }}>
            {processImage()}
          </Box>
          <CardContent>
            {processLinkName()}
            {processTeacher()}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ height: "70px" }}>{processCourseDetails()}</Box>
            <Box sx={{ height: "70px" }}>
              <Box className="detailsGeneral" mt={1}>
                <Typography component="span" className="lp">
                  {LL("CourseCategories")}
                </Typography>
                <Typography component="span" className="rp">
                  {processCourseCategories()}
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", md: "row" },
                justifyContent: "space-between",
                alignItems: { xs: "start", md: "center" },
                gap: 2,
              }}>
              <MyButton
                cb={goCourseDetails}
                variant="contained"
                className="btn"
                sx={{ overflow: "hidden", width: { xs: "100%", md: "auto" } }}>
                {LL("Subscribe_to_course")}
              </MyButton>
              {processCoursePrice()}
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  };

  return loading ? <Skeleton variant="rectangular" /> : processCourseCard();
};

export { CourseCard };
