import { Box, ListItem } from "@mui/material";
import { ChatParticipantDto } from "dto/course/chatparticipant.dto";
import UserSettingsDto from "dto/user/usersettings.dto";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { ChatMessageItemAvatarContent } from "../chatmessage/ChatMessageItemAvatar";

type Props = {
  object: ChatParticipantDto;
};

const ChatParticipantItem: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  if (!object.user) return null;
  if (!object.user.usersettings) return null;
  return (
    <ListItem sx={{ display: "flex", gap: 1 }}>
      <Box>
        <ChatMessageItemAvatarContent userSettings={object.user.usersettings} />
      </Box>
      <Box>
        <Box>{UserSettingsDto.prepareFullName(object.user.usersettings)}</Box>
        <Box>{CommonTools.processObjectField(object, ["user", "email"])}</Box>
      </Box>
    </ListItem>
  );
};

export { ChatParticipantItem };
