import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";
import { CourseSettingsDto } from "dto/course/coursesettings.dto";

type Props = {
  obj: CourseSettingsDto;
  width: number;
  height: number;
};

const TypeCourseCardChip: React.FC<Props> = ({ obj, width, height }) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");

  const prepareGallery = () => {
    if (!obj) return;
    if (!obj.typecourse) return;
    if (!obj.typecourse.hasOwnProperty("gallery")) return;
    if (!obj.typecourse.gallery) return;
    if (obj.typecourse.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.typecourse.gallery);
    if (!galleryObj) return;
    if (!width) width = 14;
    if (!height) height = 14;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      width,
      height
    );
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processIcon = () => {
    if (!imgUrl) return <></>;
    return ComponentCommonTools.getFileBlock(imgUrl, 14, 14);
  };
  const processChip = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("typecourse")) return null;
    if (!obj.typecourse) return null;
    if (!obj.typecourse.hasOwnProperty("name")) return null;
    if (!obj.typecourse.name) return null;
    if (!imgUrl) return null;

    return (
      <Chip
        className="chipCourseType"
        icon={processIcon()}
        label={obj.typecourse.name}
        color="error"
      />
    );
  };

  return processChip();
};

export { TypeCourseCardChip };
