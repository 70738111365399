import { Box } from "@mui/material";
import { useChat } from "context/chat.context";
import React from "react";

import { TopBarChat } from "./TopBarChat";
import { ChatMessageList } from "../chatmessage/ChatMessageList";
import { useResource } from "hooks/useResource";

import { SideBarMenu } from "./SideBarMenu";
import { Config } from "tools/utils/config";
import { useResponsive } from "hooks/useResponsive";

const SelectedChat: React.FC = () => {
  const { selectedChat } = useChat();
  const { LL } = useResource();
  const { matchesCustom4 } = useResponsive();

  if (!selectedChat)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {LL("chat.selectchat")}
      </Box>
    );
  return (
    <Box
      sx={{
        // height: "calc(100vh - " + Config.HEADER_HEIGHT + "px - 32px )",
        borderRadius: "10px",
      }}>
      <SideBarMenu>
        <Box
          className="chatShadow"
          sx={{
            width: matchesCustom4 ? "100%" : "calc(100% - 316px)",
          }}>
          <TopBarChat object={selectedChat} />
        </Box>
        <Box className="" sx={{}}>
          <ChatMessageList object={selectedChat} />
        </Box>
      </SideBarMenu>
    </Box>
  );
};

export { SelectedChat };
