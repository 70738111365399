import Idto from "interfaces/idto.interface";
import { CourseEnrollmentDto } from "./courseenrollment.dto";
import { CommonTools } from "tools/utils/commontools";
import { Status } from "tools/types/status";
import { GalleryDto } from "dto/system/gallery.dto";
import { FileTools } from "tools/utils/filetools";

export class CourseDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  status?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idcoursesettings?: string;
  idlanguage?: string;
  idslesson?: string[];
  idscoursecategory?: string[];
  idscoursefilter?: string[];
  idteacher?: string;
  courseduration?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  teacher?: any;
  language?: any;
  courseprice?: any;
  coursecategory?: Array<any>;
  coursefilter?: any;
  attachment?: any;
  video?: any;
  gallery?: GalleryDto[];
  numberoflessons?: number;
  fullurl?: string;
  coursefiltermain?: Array<any>;
  coursesettings?: any;
  linkcoursecategory?: Array<any>;
  shortdescription?: string;
  status_name?: string;
  nextlessonstartdate?: number;
  numberofstudents?: number;
  numberoflessonsremaining?: number;
  courseenrollments?: CourseEnrollmentDto;
  createddate?: number;
  updateddate?: number;

  can_verify?: boolean;
  can_publish?: boolean;
  can_delist?: boolean;
  can_start?: boolean;
  can_edit?: boolean;
  can_share?: boolean;
  can_reschedule?: boolean;
  numberofreschedules_by_system?: number;
  numberofreschedules_by_teacher?: number;
  canDelete?: number;

  static getUrlImage(object: CourseDto): string {
    if (!object) return "";
    if (!object.gallery) return "";
    if (!object.gallery.length) return "";
    const defaultArr = object.gallery.filter(
      (item: GalleryDto) => item?.isdefault
    );
    if (!defaultArr.length) {
      if (!object.gallery[0]) return "";
      if (!object.gallery[0].fileInfoObj) return "";

      return FileTools.getFileUrl(
        CommonTools.checkForId(object.gallery[0].fileInfoObj)
      );
    } else {
      if (!defaultArr[0]) return "";
      if (!defaultArr[0].fileInfoObj) return "";
      return FileTools.getFileUrl(
        CommonTools.checkForId(defaultArr[0].fileInfoObj)
      );
    }
    
  }
}

export class PostCourseDto implements Idto {
  name?: string;
  description?: string;
  status?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idcoursesettings?: string;
  idlanguage?: string;
  idslesson?: string[];
  idscoursecategory?: string[];
  idscoursefilter?: string[];
  idteacher?: string;
  courseduration?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  numberoflessons?: number;
  fullurl?: string;
  coursesettings?: any;
  linkcoursecategory?: Array<any>;
  shortdescription?: string;
}

export class CreateCourseFirstStepDto implements Idto {
  name?: string;
  idcoursecategory?: string;
  idteacher?: string;
  idtypecourse?: string;

  constructor(
    idteacher?: string,
    idtypecourse?: string,
    name?: string,
    idcoursecategory?: string
  ) {
    this.idteacher = idteacher || "";
    this.idtypecourse = idtypecourse || "";
    this.name = name || "";
    this.idcoursecategory = idcoursecategory || "";
  }
}

export class EditGeneralInfoCourseDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  idlanguage?: string;
  shortdescription?: string;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  status?: number;

  constructor(
    id?: string,
    name?: string,
    description?: string,
    idlanguage?: string,
    shortdescription?: string,
    requirementstext?: string,
    whatwillyoulearntext?: string,
    targetaudiencetext?: string,
    status?: number
  ) {
    this.id = id || "";
    this.name = name || "";
    this.description = description || "";
    this.idlanguage = idlanguage || "";
    this.shortdescription = shortdescription || "";
    this.requirementstext = requirementstext || "";
    this.whatwillyoulearntext = whatwillyoulearntext || "";
    this.targetaudiencetext = targetaudiencetext || "";
    this.status = status || Status.COURSE_DRAFT;
  }

  static fromCourseDto(object: CourseDto): EditGeneralInfoCourseDto {
    return new EditGeneralInfoCourseDto(
      CommonTools.processObjectField(object, ["id"]),
      CommonTools.processObjectField(object, ["name"]),
      CommonTools.processObjectField(object, ["description"]),
      CommonTools.processObjectField(object, ["idlanguage"]),
      CommonTools.processObjectField(object, ["shortdescription"]),
      CommonTools.processObjectField(object, ["requirementstext"]),
      CommonTools.processObjectField(object, ["whatwillyoulearntext"]),
      CommonTools.processObjectField(object, ["targetaudiencetext"]),
      parseInt(CommonTools.processObjectField(object, ["status"]).toString())
    );
  }
}

export class DuplicateCourseDto implements Idto {
  id?: string;

  constructor(id?: string) {
    this.id = id || "";
  }
}
