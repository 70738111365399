import Idto from "interfaces/idto.interface";
import UserSettingsDto from "./usersettings.dto";

export default class UserDto implements Idto {
  id?: string;
  status?: number;
  registerdate?: number;
  email?: string;
  statusemail?: number;
  password?: string;
  statuspassword?: number;
  hash?: string;
  registerdate_name?: any;
  statusemail_name?: any;
  statuspassword_name?: any;
  status_name?: any;
  idusersettings?: string;
  userroles?: any;
  fullurl?: string;
  usersettings?:UserSettingsDto
}

export class ResetPasswordDto implements Idto {
  email: string;
  code: string;
  password: string;

  constructor(email?: string, code?: string, password?: string) {
    this.email = email || "";
    this.code = code || "";
    this.password = password || "";
  }
}

export class ActivateEmailDto implements Idto {
  email: string;
  code: string;
  

  constructor(email?: string, code?: string) {
    this.email = email || "";
    this.code = code || "";
    ;
  }
}

export class SendCodeDto implements Idto {
  email: string;

  constructor(email?: string) {
    this.email = email || "";
  }
}