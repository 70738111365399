import React, { useState, useEffect, useCallback } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";
import { GalleryDto, PostGalleryForCourseDto } from "dto/system/gallery.dto";
import MyFileField from "components/form/myfilefield";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { GalleryService } from "services/system/gallery.service";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { MyButton } from "components/elements/button/MyButton";
import { useMessage } from "hooks/useMessage";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";
import { Status } from "tools/types/status";
import { FormCourseGallery } from "./FormCourseGallery";
import { logger } from "tools/utils/logger";
import PermMediaIcon from "@mui/icons-material/PermMedia";

const service = new GalleryService();
type Props = PageComponentProps & {
  canEdit: boolean;
};
const EditCourseGallery: React.FC<Props> = ({ currentRoute, canEdit }) => {
  const { LL, _idlanguage } = useResource();
  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const { registerDialog } = useMessage();

  const [defaultObj, setDefaultObj] = useState<PostGalleryForCourseDto>(
    new PostGalleryForCourseDto(id, _idlanguage)
  );
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
  const [object, setObject] = useState<GalleryDto | null>(null);
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!id) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , setObjects] = useList(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("idparent", [id]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      1
    ),
    [id]
  );

  const processObjects = useCallback(() => {
    if (!objects) {
      setObject(null);
      return;
    }
    if (!objects.length) {
      setObject(null);
      return;
    }
    if (!objects[0]) {
      setObject(null);
      return;
    }
    setObject(objects[0]);
  }, [objects]);

  useEffect(() => {
    processObjects();
  }, [processObjects]);

  const onSubmit = (obj: PostGalleryForCourseDto) => {
    if (!obj) return;
    registerDialog(
      LL("Confirm_edit_course"),
      LL(
        "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
      ),
      { cb: handleConfirmAddGallery.bind(null, obj) },
      null
    );
  };

  const handleConfirmAddGallery = (obj: PostGalleryForCourseDto) => {
    if (!obj) return;
    setLoadingAdd(true);
    service.addForCourse(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setObjects([result.obj]);
    setDefaultObj(new PostGalleryForCourseDto(id, _idlanguage));
    setLoadingAdd(false);
  };

  const onDelete = () => {
    if (!object) return;
    registerDialog(
      LL("Confirm_delete_course_image"),
      LL("Are_you_sure_you_want_to_delete_this_course_image"),
      { cb: handleConfirmDelete },
      null
    );
  };

  const handleConfirmDelete = () => {
    if (!object) return;
    if (!object.id) return;
    setLoadingAdd(true);
    service.delete(object.id, handleResultDelete, {});
  };

  const handleResultDelete = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    setObjects([]);
    setLoadingAdd(false);
  };

  const image = object?.fileInfoObj;

  const processGalleryPlayer = () => {
    if (loading || loadingAdd)
      return <Skeleton variant="rectangular" height={200} />;
    if (!object) return null;
    if (!object.fileInfoObj) return null;
    return (
      <Box className="fileLinkBox">
        <Box className="imageDiv">
          {ComponentCommonTools.getFileBlockImage(object.fileInfoObj)}
        </Box>
      </Box>
    );
  };

  return (
    // <Box>
    //   <Typography variant="h3" textAlign="center" mb={3}>
    //     {LL("Course_Gallery")}
    //   </Typography>
    //   <Box className="courseGallery">
    //     {processGalleryPlayer()}
    //     <Box
    //       display="flex"
    //       flexDirection="row"
    //       alignItems="center"
    //       justifyContent="center"
    //       mt={3}>
    //       <FormCourseGallery
    //         defaultObj={defaultObj}
    //         onSubmit={onSubmit}
    //         onDelete={onDelete}
    //         loading={loading || loadingAdd}
    //         canDelete={object !== null}
    //         canEdit={canEdit}
    //       />
    //     </Box>
    //   </Box>
    // </Box>

    <Box className="videoTeacher">
      <Box sx={{ flexGrow: 1, mt: 1, minWidth: "218px" }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Typography variant="h6"> {LL("Course_Gallery")}</Typography>
        </Box>
        <Box>
          <FormCourseGallery
            defaultObj={defaultObj}
            onSubmit={onSubmit}
            onDelete={onDelete}
            loading={loading || loadingAdd}
            canDelete={object !== null}
            canEdit={canEdit}
          />
        </Box>
      </Box>
      <Box>
        {image ? (
          processGalleryPlayer()
        ) : (
          <Box className="fileLinkBox">
            <Box className="fileLinkBoxEmpty">
              <PermMediaIcon />
              {LL("no_photo")}
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, mb: 1 }}>
        <Typography variant="h6">{LL("Course_Gallery")}</Typography>
      </Box>
    </Box>
  );
  // const [loadingGallery, setLoadingGallery] = useState<boolean>(true);
  // const [postObjGallery, setPostObjGallery] = useState<PostGalleryForCourseDto>(
  //   new PostGalleryForCourseDto()
  // );
  // const [files, setFiles] = useState(null);
  // const [isFormDisabledGallery, setIsFormDisabledGallery] = useState(true);
  // const [objectHasChanged, setObjectHasChanged] = useState<string>("");

  // const setObjFile = (field: any, event: any) => {
  //   setFiles(event.target.files[0]);
  // };
  // const validateAll = () => {
  //   if (!postObjGallery) return;
  //   setLoadingGallery(true);
  //   postObjGallery.idparent = id;
  //   postObjGallery.idlanguage = _idlanguage ?? _idlanguage;
  //   setPostObjGallery(postObjGallery);
  //   setLoadingGallery(false);
  // };

  // useEffect(() => {
  //   validateAll();
  // }, [postObjGallery]);

  // useEffect(() => {
  //   if (files) setIsFormDisabledGallery(false);
  //   else setIsFormDisabledGallery(true);
  // }, [files]);

  // const processGalleryInputField = () => {
  //   if (!postObjGallery) return null
  //   return (
  //     <Box>
  //       <MyFileField
  //         tabIndex={1}
  //         fullWidth
  //         id="files"
  //         name="files"
  //         variant="standard"
  //         className="form-input"
  //         color="primary"
  //         _label={LL("files")}
  //         size="small"
  //         setObj={setObjFile}
  //         _vresults={vResult}
  //       />
  //     </Box>
  //   );
  // };

  // const processGalleryDelete = () => {
  //   if (!gallery) return null
  //   return (
  //     <Box>
  //       <MyButton tabIndex={2} size="large" cb={handleDelete}>
  //         <DeleteIcon color="secondary" />
  //       </MyButton>
  //     </Box>
  //   );
  // };

  // const handleDelete = () => {
  //   if (!gallery) return;
  //   registerDialog(
  //     LL("Confirm_delete_course_image"),
  //     LL("Are_you_sure_you_want_to_delete_this_course_image"),
  //     { cb: handleConfirmDelete },
  //     null
  //   );
  // };

  // const handleConfirmDelete = () => {
  //   if (!gallery) return;
  //   if (!gallery.id) return;
  //   setLoadingGallery(true);
  //   galleryService.delete(
  //     gallery.id,
  //     CommonTools.handleSuccessAddAndEditStayOnPage,
  //     { setObjectWasChanged: setObjectHasChanged }
  //   );
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabledGallery) return false;

  //   if (postObjGallery) postObjGallery.files = files;
  //   registerDialog(
  //     LL("Confirm_edit_course"),
  //     LL(
  //       "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
  //     ),
  //     { cb: handleConfirmAdd },
  //     null
  //   );
  // };

  // const handleConfirmAdd = () => {
  //   if (postObjGallery && files) {
  //     setLoadingGallery(true);
  //     galleryService.addForCourse(
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       { setObjectWasChanged: setObjectHasChanged },
  //       postObjGallery
  //     );
  //   }
  // };
  // useEffect(() => {
  //   getList();
  //   setPostObjGallery(new PostGalleryForCourseDto());
  //   setFiles(null);
  // }, [objectHasChanged]);

  // const [gallery, setGallery] = useState<GalleryDto | undefined | null>();
  // const [isLoadingGallery, setIsLoadingGallery] = useState<boolean>(true);
  // const getList = () => {
  //   if (!id) return;
  //   setIsLoadingGallery(true);
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 1;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = undefined;

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "idparent";
  //   f.values = [id];
  //   reqList.filters.push(f);

  //   galleryService.getList(loadObjects, {}, reqList);
  // };
  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     if (objects.length > 0) setGallery(objects[0]);
  //     else setGallery(null);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;
  //   if (gallery === undefined) load = true;
  //   setIsLoadingGallery(load);
  //   setLoadingGallery(load);
  // };

  // useEffect(() => {
  //   checkLoading();
  // }, [gallery]);

  // const processGalleryImage = () => {
  //   if (!gallery) return null
  //   if (isLoadingGallery)
  //     return <Skeleton variant="rectangular" height={298} />;
  //   return (
  //     <Box>
  //       {ComponentCommonTools.getFileBlockImage(gallery.fileInfoObj, 530, 298)}
  //     </Box>
  //   );
  // };
  // const processGalleryItem = () => {
  //   if (!postObjGallery) return null
  //   if (loadingGallery) return <Skeleton variant="rectangular" height={298} />;
  //   if (isLoadingGallery)
  //     return <Skeleton variant="rectangular" height={298} />;
  //   return (
  //     <>
  //       <Typography variant="h3" textAlign="center" mb={3}>
  //         {LL("Course_Image")}
  //       </Typography>
  //       <Box className="courseGallery">
  //         <Box>{processGalleryImage()}</Box>
  //         <Box
  //           display="flex"
  //           flexDirection="row"
  //           alignItems="center"
  //           justifyContent="center"
  //           mt={3}
  //         >
  //           <form onSubmit={handleSubmit}>
  //             <Stack direction="row">
  //               {processGalleryInputField()}

  //               <MyButton
  //                 tabIndex={2}
  //                 disabled={isFormDisabledGallery}
  //                 type="submit"
  //               >
  //                 <SaveIcon />
  //               </MyButton>
  //             </Stack>
  //           </form>
  //           {processGalleryDelete()}
  //         </Box>
  //       </Box>
  //     </>
  //   );
  // };

  // return processGalleryItem();
};

export { EditCourseGallery };
