import { CourseDto } from "dto/course/course.dto";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { ActionCourseComponent } from "./CourseActionElements";
import { HighlightOffOutlined } from "@mui/icons-material";

import { PostCourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseTeacherRequestService } from "services/course/courseteacherrequest.service";
import { GeneralDialog } from "components/elements/MyDialog/GeneralDialog";
import {
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { FormCourseTeacherRequest } from "../FormCourseTeacherRequest";
import { CommonTools } from "tools/utils/commontools";
import { useAuth } from "hooks/useAuth";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { logger } from "tools/utils/logger";

type Props = {
  course: CourseDto;
  setCourse: (course: CourseDto) => void;
};

const service = new CourseTeacherRequestService();
const titleDialog = "delist_course";
const titleButton = "delist_course_btn";
export const DelistCourseAction: React.FC<Props> = ({ course, setCourse }) => {
  const { LL } = useResource();

  const handleSubmit = (
    obj: PostCourseTeacherRequestDto,
    handleResult: (result: ResultObjectDTO) => void
  ) => {
    service.unpublish(handleResult, {}, obj);
  };

  const handleResultAction = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setCourse(result.obj);
  };

  const disabled = !course.can_delist;
  return (
    <ActionCourseComponent
      titleAction={LL("SendCourseTodelisting")}
      contentButton={
        <React.Fragment>
          <HighlightOffOutlined />
          {LL(titleButton)}
        </React.Fragment>
      }
      course={course}
      disabled={disabled}
      tittleDialog={LL(titleDialog)}
      handleResult={handleResultAction}
      handleSubmit={handleSubmit}
    />
  );
};

type PropsFast =  Props & {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const DelistCourseFastAction: React.FC<PropsFast> = ({
  open,
  setOpen,
  course,
  setCourse,
}) => {
  const { LL } = useResource();
  
  const [loading, setLoading] = useState(false);
  const { teacher } = useAuth();

  

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (obj: PostCourseTeacherRequestDto) => {
    logger("DelistCourseFastAction", obj);
    setLoading(true);
    service.unpublish(handleResultAction, {}, obj);
  };

  const handleResultAction = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setCourse(result.obj);
  };

  const disabled = !course.can_delist;
  if (!teacher || !course) return null;
  if (disabled) return null;
  return (
    <React.Fragment>
      
      {open ? (
        <GeneralDialog open={open} handleClose={handleClose}>
          <DialogTitle>{LL(titleDialog)}</DialogTitle>
          <DialogContent dividers>
            {loading ? (
              <CircularLoading />
            ) : (
              <FormCourseTeacherRequest
                loading={loading}
                defaultObject={
                  new PostCourseTeacherRequestDto(
                    CommonTools.processObjectField(teacher, ["id"]),
                    CommonTools.processObjectField(course, ["id"])
                  )
                }
                onSubmit={handleSubmit}
              />
            )}
          </DialogContent>
        </GeneralDialog>
      ) : null}
    </React.Fragment>
  );
};
