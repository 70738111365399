import React from "react";
import { useResource } from "hooks/useResource";

import { CourseService } from "services/course/course.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseDto } from "dto/course/course.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { CommonTools } from "tools/utils/commontools";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";

const service = new CourseService();

const CourseListSuggestions: React.FC<PageComponentProps> = () => {
  const { CC } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseDto>>([]);
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    service.getListSuggestion(cb, cbParams, req);
  };

  const [loading, objects] = useList<CourseDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", Status.DISPLAY_ON_SITE_COURSES),
      ],
      1,
      parseInt(CC("course_list_suggestion", "10")),
      [RequestSortCriteriaDTO.prepareSortCriteria("popularidentifier", false)]
    ),
    []
  );
  // const getList = () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 10;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];
  //   let sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "popularidentifier";
  //   sortCriteria.asc = false;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   setIsLoading(true);

  //   courseService.getListSuggestion(loadObjects, {}, reqList);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];

  //     setObjects(objects);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   if (!objects) load = true;

  //   setIsLoading(load);
  // };
  // useEffect(() => {
  //   getList();
  // }, [currentRoute]);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processCourseItem = (obj: CourseDto, index: number) => {
    if (!obj) return null;
    return (
      <Box key={index}>
        <CourseCard obj={obj} loading={loading} />
      </Box>
    );
  };

  const processCourseList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowCourseListByValueCarousel", "3")),
      objects.length
    );
    return (
      <Carousel _slidesToShow={slidesToShow}>
        {objects.map((item: CourseDto, i: number) => {
          return processCourseItem(item, i);
        })}
      </Carousel>
    );
  };

  return loading ? (
    <Skeleton variant="rectangular" height={300} />
  ) : (
    <Box>{processCourseList()}</Box>
  );
};

export { CourseListSuggestions };
