import { Alert } from "@mui/material";
import { useResource } from "hooks/useResource";
import React from "react";

type Props = {
  objectWasChanged: boolean;
};

const InformationObjectWasChanged: React.FC<Props> = ({ objectWasChanged }) => {
  const { LL } = useResource();
  if (!objectWasChanged) return null;
  return (
    <Alert severity="info">
      {LL("information_was_changed_save_the_changes")}
    </Alert>
  );
};

export { InformationObjectWasChanged };
