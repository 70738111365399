import { Box, Typography } from "@mui/material";
import {
  ChatMessageDto,
  ChatMessageGroupByDateDto,
} from "dto/course/chatmessage.dto";
import React from "react";
import { ChatMessageGroup } from "./ChatMessageGroup";
import DateTools from "tools/utils/date.tools";
import { SendingMessage } from "./SendingMessage";
import { logger } from "tools/utils/logger";

type Props = {
  objects: ChatMessageDto[];
  sendingMessage?: any;
  loadingMessage?: any;
};

const ChatMessageGroupDate: React.FC<Props> = ({
  objects,
  sendingMessage,
  loadingMessage,
}) => {
  if (!objects) return null;
  const parsedObjects = ChatMessageGroupByDateDto.parseList(objects);
  return (
    <React.Fragment>
      {parsedObjects.map((object, index) => (
        <ChatMessageGroupDateItem
          key={index}
          object={object}
          index={index}
          sendingMessage={sendingMessage}
          loadingMessage={loadingMessage}
        />
      ))}
      <Box id="scroll-to-bottom" />
    </React.Fragment>
  );
};

type ItemProps = {
  object: ChatMessageGroupByDateDto;
  index: number;
  sendingMessage?: any;
  loadingMessage?: any;
};
const ChatMessageGroupDateItem: React.FC<ItemProps> = ({
  object,
  index,
  sendingMessage,
  loadingMessage,
}) => {
  if (!object) return null;
  if (!object.objects) return null;
  return (
    <Box>
      <Box>
        <Typography align="center">
          {DateTools.displayTimestampUsingDayJsFormat(
            object.date,
            DateTools.getDateFormatDayJs()
          )}
        </Typography>
      </Box>
      <Box>
        <ChatMessageGroup objects={object.objects} />
        <Box sx={{ height: loadingMessage ? "70px" : "0px" }}>
          <SendingMessage object={sendingMessage} loading={loadingMessage} />
        </Box>
      </Box>
    </Box>
  );
};

export { ChatMessageGroupDate };
