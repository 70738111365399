import IValidator from "interfaces/ivalidator.interface";
import ValidatorResult from "./validatorresult";
import { Config } from "tools/utils/config";

export default class CNPValidator implements IValidator {
  messageErr: string;
  messageOk: string;

  constructor(mE: string, mO: string) {
    this.messageErr = mE;
    this.messageOk = mO;
  }

  static getValidators(keys: Array<string>): Array<IValidator> {
    let rez: any = {};
    for (const i of keys) {
      rez[i] = [new CNPValidator(`Field_${i}_invalid`, "")];
    }
    return rez;
  }

  validate = (value: any): ValidatorResult => {
    let rez: ValidatorResult = new ValidatorResult();
    if (CNPValidator.validationAlgorithm(value)) {
      rez.isOk = true;
      rez.message = this.messageOk;
    } else {
      rez.isOk = false;
      rez.message = this.messageErr;
    }

    return rez;
    // if (!value) {
    //   rez.isOk = false;
    //   rez.message = this.messageErr;
    //   return rez;
    // }
    // value = value.toString().trim();
    // if (value) {
    //   rez.isOk = true;
    //   rez.message = this.messageOk;
    // } else {
    //   rez.isOk = false;
    //   rez.message = this.messageErr;
    // }
    // const weights = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    // if (value.length === 13 && typeof value === "string") {
    //   const sum = value.split("").reduce((acc, digit, index) => {
    //     if (index < 12) {
    //       acc += parseInt(digit) * weights[index];
    //     }
    //     return acc;
    //   }, 0);
    //   const controlDigit = sum % 11;
    //   if (controlDigit === 10) {
    //     if (parseInt(value[12]) === 1) {
    //       rez.isOk = true;
    //       rez.message = this.messageOk;
    //     } else {
    //       rez.isOk = false;
    //       rez.message = this.messageErr;
    //     }
    //   } else {
    //     if (parseInt(value[12]) === controlDigit) {
    //       rez.isOk = true;
    //       rez.message = this.messageOk;
    //     } else {
    //       rez.isOk = false;
    //       rez.message = this.messageErr;
    //     }
    //   }
    // } else {
    //   rez.isOk = false;
    //   rez.message = this.messageErr;
    // }

    // return rez;
  };

  static validationAlgorithm = (value: any): boolean => {
    if(Config.VALIDATION_ALGORITHM_CNP === "ro") {
        return CNPValidator.validationAlgorithmRO(value);
    }
    else {
        return true;
    }
  }

  static validationAlgorithmRO = (value: any): boolean => {
    if (!value) return false;
    value = value.toString().trim();
    if (value) {
      const weights = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
      if (value.length === 13 && typeof value === "string") {
        const sum = value.split("").reduce((acc, digit, index) => {
          if (index < 12) {
            acc += parseInt(digit) * weights[index];
          }
          return acc;
        }, 0);
        const controlDigit = sum % 11;
        if (controlDigit === 10) {
          if (parseInt(value[12]) === 1) {
            return true;
          } else {
            return false;
          }
        } else {
          if (parseInt(value[12]) === controlDigit) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  
}

