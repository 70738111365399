import React, { useCallback, useEffect, useRef, useState } from "react";
import SchedulerDev, { SchedulerRef } from "devextreme-react/scheduler";
import "devextreme/dist/css/dx.light.css";
import CustomStore from "devextreme/data/custom_store";
import { AppointmentTooltip } from "./AppointmentTooltip";

import { LocalStorageApi } from "api/localstorage.api";
import { Appointment } from "./Appointment";
import { useResponsive } from "hooks/useResponsive";

type Props = {
  dataStore?: CustomStore;
};

const Scheduler: React.FC<Props> = ({ dataStore }) => {
  const ref = useRef<SchedulerRef>(null);
  const { downSM } = useResponsive();
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [currentView, setCurrentView] = useState<string>(
    getCurrentViewDefault()
  );
  const [schedulerWidth, setSchedulerWidth] = useState<number | null>(null);
  const prevSchedulerWidthRef = useRef<number | null>(null);
  const [scaleFactor, setScaleFactor] = useState<number>(1);
  const [appointmentWidth, setAppointmentWidth] = useState<number>(230);

  const storedScrollRatioX = useRef<number>(0);
  const storedScrollRatioY = useRef<number>(0);

  const getScrollContainer = useCallback(()=> {
    return document.querySelector(
      ".dx-scheduler-date-table-scrollable .dx-scrollable-container"
    ) as HTMLElement | null;
  }, []);

  const  storeScrollRatio = useCallback(() =>{
    const container = getScrollContainer();
    if (!container || !container.scrollWidth || !container.scrollHeight) return;
    storedScrollRatioX.current = container.scrollLeft / container.scrollWidth;
    storedScrollRatioY.current = container.scrollTop / container.scrollHeight;
  }, [getScrollContainer]);

  const  restoreScrollRatio = useCallback(() => {
    const container = getScrollContainer();
    if (!container) return;
    container.scrollLeft = storedScrollRatioX.current * container.scrollWidth;
    container.scrollTop = storedScrollRatioY.current * container.scrollHeight;
  }, [getScrollContainer]);

  const handleCurrentViewChange = useCallback((view: string) => {
    setCurrentView(view);
    LocalStorageApi.saveValue("current_view_scheduler", view);
  }, []);

  const onCellClick = useCallback((e: any) => {
    if (e.cellElement && e.cellData?.startDate) {
      const isoDate = e.cellData.startDate.toISOString();
      e.cellElement.setAttribute("data-mycell-date", isoDate);
    }
  }, []);

  const onDoubleClick = useCallback(
    (clickedElement: HTMLElement) => {
      if (currentView === "day") return;
      const cellElement = clickedElement.closest(
        ".dx-scheduler-date-table-cell"
      );
      if (!cellElement) return;

      const isoDate = cellElement.getAttribute("data-mycell-date");
      if (!isoDate) return;

      const parsedDate = new Date(isoDate);
      const schedulerInstance = ref.current?.instance();
      if (!schedulerInstance) return;

      schedulerInstance.option("currentView", "day");
      schedulerInstance.option("currentDate", parsedDate);
    },
    [currentView]
  );

  useEffect(() => {
    const schedulerElement = ref.current?.instance().element();
    if (!schedulerElement) return;

    const dblClickHandler = (e: MouseEvent) => {
      onDoubleClick(e.target as HTMLElement);
    };

    schedulerElement.addEventListener("dblclick", dblClickHandler);
    return () => {
      schedulerElement.removeEventListener("dblclick", dblClickHandler);
    };
  }, [onDoubleClick]);

  const disableOpenForm = useCallback((e: any) => {
    e.cancel = true;
  }, []);

  useEffect(() => {
    const schedulerInstance = ref.current?.instance();
    if (!schedulerInstance) return;

    const schedulerEl = schedulerInstance.element();
    if (!schedulerEl) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === schedulerEl) {
          const newWidth = entry.contentRect.width;

          if (resizeTimeoutRef.current) {
            clearTimeout(resizeTimeoutRef.current);
          }
          storeScrollRatio();
          resizeTimeoutRef.current = setTimeout(() => {
            setSchedulerWidth(newWidth);
            setTimeout(() => {
              restoreScrollRatio();
            }, 0);
          }, 50);
        }
      }
    });

    resizeObserver.observe(schedulerEl);
    return () => {
      resizeObserver.unobserve(schedulerEl);
    };
  }, [restoreScrollRatio, storeScrollRatio]);

  useEffect(() => {
    if (schedulerWidth == null) return;

    const oldWidth = prevSchedulerWidthRef.current;
    const newWidth = schedulerWidth;

    if (oldWidth && oldWidth > 0) {
      const oldDenominator = oldWidth - 102.1;
      const newDenominator = newWidth - 102.1;

      if (oldDenominator > 0 && newDenominator > 0) {
        const factor = newDenominator / oldDenominator;
        setScaleFactor(factor);
      }
    }
    prevSchedulerWidthRef.current = newWidth;
  }, [schedulerWidth]);

  useEffect(() => {
    if (schedulerWidth) {
      const newApointW =
        (schedulerWidth - 100) / 7 - (schedulerWidth < 1230 ? 0 : 20);

      setAppointmentWidth(newApointW);
    }
  }, [schedulerWidth]);

  const onContentReady = useCallback(() => {
    restoreScrollRatio();
  }, [restoreScrollRatio]);

  return (
    <SchedulerDev
      ref={ref}
      dataSource={dataStore}
      views={[
        { type: "week", maxAppointmentsPerCell: 3 },
        { type: "month", maxAppointmentsPerCell: downSM ? 0 : 3 },
        { type: "day" },
      ]}
      // maxAppointmentsPerCell={1}
      // height={800}
      // views={["week", "month", "day"]}
      currentView={currentView}
      startDayHour={8}
      editing={{
        allowAdding: false,
        allowDeleting: false,
        allowDragging: false,
        allowResizing: false,
        allowUpdating: false,
      }}
      remoteFiltering={true}
      appointmentTooltipComponent={AppointmentTooltip}
      onAppointmentDblClick={disableOpenForm}
      onAppointmentFormOpening={disableOpenForm}
      onCellClick={onCellClick}
      onCurrentViewChange={handleCurrentViewChange}
      onContentReady={onContentReady}
      appointmentComponent={(props: any) => (
        <Appointment
          {...props}
          scaleFactor={scaleFactor}
          appointmentWidth={appointmentWidth}
          currentView={currentView}
        />
      )}
      adaptivityEnabled={downSM}
    />
  );
};

export { Scheduler };

const getCurrentViewDefault = () => {
  const view = LocalStorageApi.getValue("current_view_scheduler");
  return view || "month";
};
