import { CourseDto } from "dto/course/course.dto";
import { ScheduleDto } from "dto/course/schedule.dto";
import useObject from "hooks/useObject";
import React from "react";
import { ScheduleService } from "services/course/schedule.service";
import { CommonTools } from "tools/utils/commontools";
import { Config } from "tools/utils/config";
import { NextLessonText } from "./NextLessonButton";


type Props = {
  object: CourseDto;
};
const service = new ScheduleService();
const NextLessonCourseBoundary: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  if (!CommonTools.processObjectField(object, ["id"])) return null;
  if (
    !CommonTools.processObjectField(object, ["coursesettings", "idtypecourse"])
  )
    return null;
  if (
    CommonTools.processObjectField(object, [
      "coursesettings",
      "idtypecourse",
    ]) !== Config.ID_TYPE_COURSE_LIVE
  )
    return null;

  return (
    <NextLessonCourse id={CommonTools.processObjectField(object, ["id"])} />
  );
};

type NextLessonCourseProps = {
  id: string;
};
const NextLessonCourse: React.FC<NextLessonCourseProps> = ({ id }) => {
    
  const get = (id: string, cb?: any, cbparameters?: any) => {
    service.getNextScheduleCourse(id, cb, cbparameters);
  };
  const [loading, object] = useObject<ScheduleDto>(get, id, [id]);
  if (!id) return null;
  if (loading) return null;
  if (!object) return null;
  if (CommonTools.isEmpty(object)) return null;
  return <NextLessonText object={object} />;
};

export { NextLessonCourseBoundary };
