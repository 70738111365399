import { Skeleton } from "@mui/material";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { ChatMessageDto } from "dto/course/chatmessage.dto";

import UserSettingsDto from "dto/user/usersettings.dto";
import { useAuth } from "hooks/useAuth";
import useObject from "hooks/useObject";
import { CallbackType } from "interfaces/commontypes.interface";
import React from "react";

import { UserSettingsService } from "services/user/usersettings.service";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  object: ChatMessageDto;
  isOwn?: boolean;
};
const service = new UserSettingsService();
const ChatMessageItemAvatar: React.FC<Props> = ({ object, isOwn = false }) => {
  if (!object) return null;
  if (isOwn) {
    return <ChatMessageAvatarOwn object={object} />;
  }
  return <ChatMessageAvatarStranger object={object} />;
};

type ItemProps = {
  object: ChatMessageDto;
};

const ChatMessageAvatarOwn: React.FC<ItemProps> = ({ object }) => {
  const { usersettings } = useAuth();
  if (!object) return null;
  if (!usersettings) return null;
  return <ChatMessageItemAvatarContent userSettings={usersettings} />;
};

const ChatMessageAvatarStranger: React.FC<ItemProps> = ({ object }) => {
  const idUser = CommonTools.processObjectField(object, ["iduser"]);

  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getByUserId(id, cb, cbParams);
  };

  const [loading, item] = useObject<UserSettingsDto>(
    get,
    idUser,
    [idUser],
    true,
    `avatar_chat_user_${idUser}`
  );

  if (!object) return null;
  if (!idUser) return null;

  if (loading) return <Skeleton variant="circular" width={40} height={40} />;
  if (!item) return null;
  return <ChatMessageItemAvatarContent userSettings={item} />;
};

type ItemContentProps = {
  userSettings: UserSettingsDto;
};
const ChatMessageItemAvatarContent: React.FC<ItemContentProps> = ({
  userSettings,
}) => {
  if (!userSettings) return null;
  return (
    <MyAvatar
      obj={userSettings}
      name={CommonTools.processObjectField(userSettings, ["name"])}
      surname={CommonTools.processObjectField(userSettings, ["surname"])}
    />
  );
};
export { ChatMessageItemAvatar, ChatMessageItemAvatarContent };
