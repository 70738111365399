import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import { TeacherNotificationDto } from "dto/user/teacher.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: TeacherNotificationDto;
  onSubmit: (obj: TeacherNotificationDto) => void;
  loading: boolean;
};

const FormTeacherNotification: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField,,,,,objectWasChanged] = useForm<TeacherNotificationDto>(
    defaultObject,
    RequiredValidator.getValidators(["iduser"])
  );
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <InformationObjectWasChanged objectWasChanged={objectWasChanged} />
      <form onSubmit={handleSubmit}>
        <Box className="likeCard" mt={3}>
          <MyCheckboxField
            label={LL("notificationtype1")}
            checked={obj.notificationtype1}
            setObjectField={setObjField}
            field="notificationtype1"
            color="primary"
            size="small"
          />
        </Box>
        <Box className="likeCard" mt={3}>
          <MyCheckboxField
            label={LL("notificationtype2")}
            checked={obj.notificationtype2}
            setObjectField={setObjField}
            field="notificationtype2"
            color="primary"
            size="small"
          />
        </Box>
        <Box className="likeCard" mt={3}>
          <MyCheckboxField
            label={LL("notificationtype3")}
            checked={obj.notificationtype3}
            setObjectField={setObjField}
            field="notificationtype3"
            color="primary"
            size="small"
          />
        </Box>
        <Box textAlign="center" my={4}>
          <MyButton
            disabled={disabled || loading}
            color="primary"
            className="btn"
            variant="contained"
            size="large"
            type="submit">
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTeacherNotification };
