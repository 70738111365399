import { DialogContent, DialogTitle } from "@mui/material";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { GeneralDialog } from "components/elements/MyDialog/GeneralDialog";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { PostAbuseDto } from "dto/support/abuse.dto";
import { useAuth } from "hooks/useAuth";
import { useMessage } from "hooks/useMessage";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { AbuseService } from "services/support/abuse.service";
import { MessageTypes } from "tools/types/messagetypes";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { FormAbuse } from "./FormAbuse";
import { TransitionDown } from "hooks/useDialogTransition";

type Props = {
  parent: string;
  idparent: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  typeSender: number;
};

const service = new AbuseService();
const ReportAbuse: React.FC<Props> = ({
  parent,
  idparent,
  open,
  setOpen,
  typeSender,
}) => {
  const { student, teacher, user } = useAuth();
  const { LL } = useResource();
  const { RegisterMessage } = useMessage();
  const idStudent = CommonTools.processObjectField(student, ["id"]);
  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);
  const idUser = CommonTools.processObjectField(user, ["id"]);
  const [loading, setLoading] = useState(false);

  const onSubmit = (obj: PostAbuseDto) => {
    if (!obj) return;
    obj.iduser = idUser;
    obj.idparent = idparent;
    obj.parent = parent;
    obj.typesender = typeSender;
    if (typeSender === Types.TYPE_SENDER_STUDENT) {
      if (!idStudent) return;
      obj.idstudent = idStudent;
    } else {
      if (!idTeacher) return;
      obj.idteacher = idTeacher;
    }
    setLoading(true);
    service.add(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    setLoading(false);
    if (!result) return;
    if (result.err) return;
    RegisterMessage(
      CommonTools.createMessage(
        LL("your_report_has_been_sent"),
        MessageTypes.MESSAGE_SUCCESS,
        "200"
      )
    );
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (!open) return null;
  const defaultObj =
    typeSender === Types.TYPE_SENDER_STUDENT
      ? PostAbuseDto.prepareAbuseObjectStudent(
          idUser,
          idStudent,
          idparent,
          parent
        )
      : PostAbuseDto.prepareAbuseObjectTeacher(
          idUser,
          idTeacher,
          idparent,
          parent
        );
  return (
    <GeneralDialog
      open={open}
      fullWidth
      maxWidth={"md"}
      TransitionComponent={TransitionDown}
      keepMounted
      handleClose={handleClose}>
      <DialogTitle>{LL("report_abuse")}</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularLoading />
        ) : (
          <FormAbuse
            defaultObj={defaultObj}
            onSubmit={onSubmit}
            loading={loading}
          />
        )}
      </DialogContent>
    </GeneralDialog>
  );
};

export { ReportAbuse };
