import queryString from "query-string";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestPopulateDTO from "dto/app/requestpopulate.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Types } from "tools/types/types";
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { UrlRelationService } from "services/system/urlrelation.service";

const urlRelationService = new UrlRelationService();

class RouteTools {
  static _LL: any = false;
  static hasAccess: any = false;
  static checkUser: any = false;
  static isTeacher: any = false;

  static userInterface: any = false;
  static userInterfaceFunction: any = false;

  static setUserInterface(_l: any): any {
    RouteTools.userInterface = _l;
  }

  static setUserInterfaceFunction(_l: any): any {
    RouteTools.userInterfaceFunction = _l;
  }

  static setLL(_l: any): any {
    RouteTools._LL = _l;
  }

  static setHasAccess(_h: any): any {
    RouteTools.hasAccess = _h;
  }

  static setCheckUser(_h: any): any {
    RouteTools.checkUser = _h;
  }

  static setIsTeacher(_h: any): any {
    RouteTools.isTeacher = _h;
  }

  // -----------------------------------------

  // -----------------------------------------
  static processSpecial(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m === "test") {
      return { mobj: "test", permissionhash: "test" };
    }

    if (m === "login") {
      return { mobj: "login", permissionhash: "post-auth-signin" };
    }

    if (m === "teachersignup") {
      return { mobj: "teachersignup", permissionhash: "post-teachersignup" };
    }
    if (m === "forbidden") {
      return { mobj: "forbidden", permissionhash: "forbidden" };
    }

    if (m === "resetpassword") {
      return { mobj: "resetpassword", permissionhash: "resetpassword" };
    }
    if (m === "activateemail") {
      return { mobj: "activateemail", permissionhash: "activateemail" };
    }

    if (m === "signup") {
      return { mobj: "signup", permissionhash: "post-auth-signin" };
    }

    if (m === "signup-google-result") {
      return {
        mobj: "signup-google-result",
        permissionhash: "post-auth-signin",
      };
    }

    if (m === "faq") {
      return { mobj: "faq", permissionhash: "get-faq" };
    }

    if (m === "checkout") {
      return { mobj: "checkout", permissionhash: "get-course" };
    }

    if (m === "wishlist") {
      return { mobj: "wishlist", permissionhash: "getList-wishlist" };
    }

    if (m === "becometeacherpage") {
      return { mobj: "becometeacherpage", permissionhash: "/" };
    }

    if (m === "forgotpassword") {
      return {
        mobj: "forgotpassword",
        permissionhash: "post-user-password-reset-code",
      };
    }
    if (m === "changetemporarypassword") {
      return {
        mobj: "changetemporarypassword",
        permissionhash: "post-user-modify-password",
      };
    }
    if (m === "coursesearch") {
      return { mobj: "coursesearch", permissionhash: "getList-course-search" };
    }
  }

  //---------------------------------------

  static processUser(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m != "profile") {
      return false;
    }

    if (paths.length === 1) {
      return {
        mobj: "profile",
        permissionhash: "get-usersettings",
        currentItemMenu: "profile",
      };
    } else if (paths.length > 1) {
      const m2 = paths[1];
      if (m2 === "edit") {
        return {
          mobj: "profile",
          permissionhash: "edit-usersettings",
          currentItemMenu: "editprofile",
        };
      }
      if (m2 === "course") {
        return {
          mobj: "profile",
          permissionhash: "getList-course",
          currentItemMenu: "course",
        };
      }
      if (m2 === "courseenrollment") {
        return {
          mobj: "profile",
          permissionhash: "get-courseenrollment",
          currentItemMenu: "course",
        };
      }
      if (m2 === "faq")
        return {
          mobj: "profile",
          permissionhash: "getList-teacherfaq",
          currentItemMenu: "teacherfaq",
        };
      if (m2 === "ticket")
        return {
          mobj: "profile",
          permissionhash: "getList-ticket",
          currentItemMenu: "ticket",
        };
      if (m2 === "testimonial")
        return {
          mobj: "profile",
          permissionhash: "getList-testimonial",
          currentItemMenu: "testimonial",
        };
      if (m2 === "notification")
        return {
          mobj: "profile",
          permissionhash: "getList-notification",
          currentItemMenu: "notification",
        };
      if (m2 === "calendar")
        return {
          mobj: "profile",
          permissionhash: "getList-schedule",
          currentItemMenu: "calendar",
        };
      if (m2 === "message")
        return {
          mobj: "profile",
          permissionhash: "getList-chat",
          currentItemMenu: "message",
        };
    }

    // return false;
  }
  //---------------------------------------
  static processCourseCategory(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m != "coursecategory") {
      return false;
    }

    // if (paths.length >= 1) {
    //   return { mobj: "coursecategory", permissionhash: "get-coursecategory" };
    // }
    return { mobj: "coursecategory", permissionhash: "get-coursecategory" };

    // return false;
  }

  static processCourse(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m != "course") {
      return false;
    }

    return { mobj: "course", permissionhash: "get-course" };

    // return false;
  }

  static processCertificate(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m != "certificate") {
      return false;
    }

    return { mobj: "certificate", permissionhash: "get-by-hash-certificate" };

    // return false;
  }

  static processTeacher(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];
    // if(m === 'user')return { mobj: "teacher", permissionhash: "getbyidoriduser-teacher" };

    if (m != "teacher") {
      return false;
    }

    if (paths.length === 1) {
      return { mobj: "teacher", permissionhash: "getList-teacher" };
    }

    if (paths.length > 1) {
      return { mobj: "teacherpage", permissionhash: "get-teacher" };
    }

    // return false;
  }

  static processTeacherInterface(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }
    const m = paths[0];
    if (m != "teacherinterface") return false;
    if (!RouteTools.isTeacher()) return false;

    if (paths.length === 2) {
      const m2 = paths[1];
      if (m2 === "course")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-course",
          currentItemMenu: "course",
        };
      if (m2 === "calendar")
        return {
          mobj: "teacherinterface",
          permissionhash: "get-schedule",
          currentItemMenu: "calendar",
        };
      if (m2 === "addcourse")
        return {
          mobj: "teacherinterface",
          permissionhash: "addFirstStep-course",
          currentItemMenu: "addcourse",
        };
      if (m2 === "testresult")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-testresult",
          currentItemMenu: "testresult",
        };
      if (m2 === "profile")
        return {
          mobj: "teacherinterface",
          permissionhash: "get-user",
          currentItemMenu: "profile",
        };
      if (m2 === "editprofile")
        return {
          mobj: "teacherinterface",
          permissionhash: "get-user",
          currentItemMenu: "editprofile",
        };
      if (m2 === "faq")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-teacherfaq",
          currentItemMenu: "teacherfaq",
        };
      if (m2 === "ticket")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-ticket",
          currentItemMenu: "ticket",
        };
      if (m2 === "testimonial")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-testimonial",
          currentItemMenu: "teachertestimonial",
        };
      if (m2 === "student")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-courseenrollment",
          currentItemMenu: "students",
        };
      if (m2 === "notification")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-notification",
          currentItemMenu: "notification",
        };
      if (m2 === "teacherrequest")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-teacherrequest",
          currentItemMenu: "teacherrequest",
        };
      if (m2 === "courseteacherrequest")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-courseteacherrequest",
          currentItemMenu: "courseteacherrequest",
        };
    }

    if (paths.length === 3) {
      const m2 = paths[1];

      if (m2 === "editcourse")
        return {
          mobj: "teacherinterface",
          permissionhash: "update-course",
          currentItemMenu: "course",
        };
    }

    if (paths.length >= 3) {
      const m2 = paths[1];

      if (m2 === "classroom")
        return {
          mobj: "teacherinterface",
          permissionhash: "get-course",
          currentItemMenu: "course",
        };

      if (m2 === "message")
        return {
          mobj: "teacherinterface",
          permissionhash: "getList-message",
          currentItemMenu: "message",
        };
    }

    return {
      mobj: "teacherinterface",
      permissionhash: "get-teacherinterface",
      currentItemMenu: "dashboard",
    };
  }

  static processStudent(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m != "student") {
      return false;
    }

    return { mobj: "student", permissionhash: "get-student" };

    // return false;
  }

  static processPage(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m != "page") {
      return false;
    }

    if (paths.length < 2) {
      return false;
    }

    const idobj = paths[1] ?? "";

    if (!idobj) {
      return false;
    }

    return { mobj: "page", permissionhash: "get-page", idobj: idobj };

    // return false;
  }

  static processBlog(paths: any, anchor: string): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m != "blog") {
      return false;
    }

    // if (paths.length < 2) {
    //   return false;
    // }

    if (paths.length >= 2) {
      const idobj = paths[1] ?? "";

      if (!idobj) {
        return false;
      }

      return { mobj: "blogpage", permissionhash: "get-blog", idobj: idobj };
    }

    return { mobj: "blog", permissionhash: "getList-blog" };

    // return false;
  }

  static async processUserFriendly(paths: any, fullUrl: any): Promise<any> {
    const finded = await urlRelationService.getByUrl(fullUrl);

    if (finded === undefined || finded === null || finded === false || !finded)
      return false;

    if (finded.obj === undefined) {
      return false;
    }

    if (finded.obj.identifier === "page") {
      return {
        mobj: "page",
        permissionhash: "get-page",
        idobj: finded.obj.idobject,
        _obj: finded.obj.obj,
      };
    }

    if (finded.obj.identifier === "blog") {
      return {
        mobj: "blogpage",
        permissionhash: "get-blog",
        idobj: finded.obj.idobject,
        _obj: finded.obj.obj,
      };
    }

    return false;
  }

  static async processRoute(
    paths: any,
    parsed: any,
    fullUrl: any,
    anchor: string
  ): Promise<any> {
    var mo: any = false;

    mo = RouteTools.processSpecial(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processUser(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processCourseCategory(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processCourse(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processTeacher(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processCertificate(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processPage(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processBlog(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processStudent(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    mo = RouteTools.processTeacherInterface(paths, anchor);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo,
        true
      );

    // last check in list
    mo = await RouteTools.processUserFriendly(paths, fullUrl);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        anchor,
        mo.permissionhash,
        mo
      );

    return RouteTools.processRouteVars(
      "notfound",
      paths,
      parsed,
      fullUrl,
      anchor,
      "/"
    );
  }

  // -----------------------------------------
  static processRouteVarsTeacherSignUp(mainObj: string) {
    // if (mainObj === "teachersignup") {
    //   RouteTools.userInterfaceFunction(Types.TEACHER_INTERFACE);
    // }
    // logger("processTeacherInterface", mainObj);

    if (
      mainObj === "teachersignup" &&
      RouteTools.checkUser() &&
      RouteTools.isTeacher()
    ) {
      RouteTools.setHistory("/", {});
      mainObj = "homepage";
    }
    if (
      mainObj === "teachersignup" &&
      !RouteTools.checkUser() &&
      !RouteTools.isTeacher()
    ) {
      RouteTools.userInterfaceFunction(Types.TEACHER_INTERFACE);
      RouteTools.setHistory("/signup", {});
      mainObj = "signup";
    }

    if (
      mainObj === "homepage" &&
      RouteTools.checkUser() &&
      !RouteTools.isTeacher() &&
      RouteTools.userInterface() === Types.TEACHER_INTERFACE
    ) {
      RouteTools.userInterfaceFunction(Types.STUDENT_INTERFACE);

      RouteTools.setHistory("/teachersignup", {});
      mainObj = "teachersignup";
    }
  }

  static processRouteVars(
    mainObj: any,
    paths: any,
    parsed: any,
    fullUrl: any,
    anchor: string,
    permissionhash: any,
    processedData?: any,
    isTeacherInterface?: boolean
  ): any {
    if (!isTeacherInterface) isTeacherInterface = false;
    if (isTeacherInterface) {
      RouteTools.userInterfaceFunction(Types.TEACHER_INTERFACE);
    } else {
      RouteTools.userInterfaceFunction(Types.STUDENT_INTERFACE);
    }

    if (!anchor) anchor = "";
    if (!RouteTools.hasAccess(permissionhash)) {
      mainObj = "forbidden";
    }
    // if (!RouteTools.checkUser()) {
    //   RouteTools.setHistory("/login", {});
    //   mainObj = "login";
    // } else if (mainObj === "login") {
    //   RouteTools.setHistory("/", {});
    //   mainObj = "homepage";
    // }

    if (
      (mainObj === "login" ||
        mainObj === "signup" ||
        mainObj === "forgotpassword" ||
        mainObj === "resetpassword") &&
      RouteTools.checkUser()
    ) {
      RouteTools.setHistory("/", {});
      mainObj = "homepage";
    }

    if (
      (mainObj === "profile" ||
        mainObj === "changetemporarypassword" ||
        mainObj === "teacherinterface") &&
      !RouteTools.checkUser()
    ) {
      RouteTools.setHistory("/login", {});
      mainObj = "login";
    }

    RouteTools.processRouteVarsTeacherSignUp(mainObj);

    return {
      _mainobject: mainObj,
      _paths: paths,
      _getVars: parsed,
      url: fullUrl,
      processedData: processedData,
      anchor: anchor,
    };
  }

  // -----------------------------------------
  // -----------------------------------------
  // -----------------------------------------

  // -----------------------------------------
  static prepareListRequest(currentRoute: any, cookies: any): RequestListDTO {
    // const cOnPage =
    //   cookies.onPage != undefined
    //     ? Number(cookies.onPage)
    //     : Number(process.env.REACT_APP_ONPAGE);
    const cOnPage = 1;

    const obj = new RequestListDTO();
    const _getVars =
      currentRoute && currentRoute._getVars ? currentRoute._getVars : {};

    obj.page = _getVars.page ? Number(_getVars.page) : 1;
    obj.onpage = _getVars.onpage ? Number(_getVars.onpage) : cOnPage;

    obj.filters = [];
    if (_getVars.filters != null && _getVars.filters != undefined) {
      const filters = _getVars.filters.split("|");

      for (let i in filters) {
        const t = filters[i].split(",");

        const _o = new RequestFilterDTO();
        _o.field = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.field) continue;
        if (_o.values === undefined || !_o.values.length) continue;

        obj.filters.push(_o);
      }
    }

    obj.criteria = [];
    if (_getVars.criteria != null && _getVars.criteria != undefined) {
      const criteria = _getVars.criteria.split("|");

      for (let i in criteria) {
        const t = criteria[i].split(",");

        const _o = new RequestCriteriaDTO();
        _o.id = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.id) continue;
        if (_o.values === undefined || !_o.values.length) continue;

        obj.criteria.push(_o);
      }
    }

    obj.range = [];
    if (_getVars.range != null && _getVars.range != undefined) {
      const range = _getVars.range.split("|");

      for (let i in range) {
        const t = range[i].split(",");

        const _o = new RequestCriteriaDTO();
        _o.id = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.id) continue;
        if (_o.values === undefined || !_o.values.length) continue;

        obj.range.push(_o);
      }
    }

    if (_getVars.populates != null && _getVars.populates != undefined) {
      const _o = new RequestPopulateDTO();
      _o.populates = _getVars.populates.split(",");
      obj.populate = _o;
    }

    obj.sortcriteria = [];
    if (_getVars.order != null && _getVars.order != undefined) {
      const order = _getVars.order.split("|");

      for (let i in order) {
        const t = order[i].split(",");

        const _o = new RequestSortCriteriaDTO();
        _o.field = t[0].trim();
        _o.asc = t.length > 1 && t[1] === "asc" ? true : false;

        if (!_o.field) continue;

        obj.sortcriteria.push(_o);
      }
    }

    return obj;
  }

  // -----------------------------------------
  static prepareListLocation(obj: RequestListDTO): string {
    let str = "";

    if (obj.page) {
      str += str ? "&" : "?";
      str += "page=" + obj.page.toString();
    }

    if (obj.onpage) {
      str += str ? "&" : "?";
      str += "onpage=" + obj.onpage.toString();
    }

    if (obj.filters) {
      let fstr = "";

      for (let i in obj.filters) {
        if (!obj.filters[i].values) continue;
        if (!Array.isArray(obj.filters[i].values)) continue;
        if (!obj.filters[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.filters[i]?.field;
        fstr += ",";
        fstr += obj.filters[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "filters=" + fstr;
      }
    }

    if (obj.range) {
      let fstr = "";

      for (let i in obj.range) {
        if (!obj.range[i].values) continue;
        if (!Array.isArray(obj.range[i].values)) continue;
        if (!obj.range[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.range[i]?.id;
        fstr += ",";
        fstr += obj.range[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "range=" + fstr;
      }
    }

    if (obj.criteria) {
      let fstr = "";

      for (let i in obj.criteria) {
        if (!obj.criteria[i].values) continue;
        if (!Array.isArray(obj.criteria[i].values)) continue;
        if (!obj.criteria[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.criteria[i]?.id;
        fstr += ",";
        fstr += obj.criteria[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "criteria=" + fstr;
      }
    }

    if (obj.sortcriteria) {
      let fstr = "";

      for (let i in obj.sortcriteria) {
        fstr += fstr ? "|" : "";
        fstr += obj.sortcriteria[i]?.field;
        fstr += ",";
        fstr += obj.sortcriteria[i]?.asc ? "asc" : "desc";
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "order=" + fstr;
      }
    }

    if (
      obj.populate &&
      obj.populate.populates &&
      obj.populate.populates.length
    ) {
      str += str ? "&" : "?";
      str += "populates=" + obj.populate.populates.join(",");
    }

    return str;
  }

  // -----------------------------------------
  static async parseLocation(location: any): Promise<any> {
    // logger("processRouteVarsprocessRouteVarsprocessRouteVars", location);

    const parsed = queryString.parse(location.search);

    const fullUrl = location.pathname + location.search;

    var tpaths = location.pathname.split("/");
    tpaths = tpaths.filter(Boolean);

    let anchor = location.hash.replace("#", "");
    if (!anchor) anchor = "";

    return await RouteTools.processRoute(tpaths, parsed, fullUrl, anchor);
  }

  // -----------------------------------------
  static setHistory(location: any, state: any, anchor?: string): any {
    state = state ? state : {};
    if (anchor) location += "#" + anchor;
    window.history.pushState(state, "", location);

    var popStateEvent = new PopStateEvent("popstate", state);
    dispatchEvent(popStateEvent);
  }

  static setAnchor(anchor: string): void {
    window.location.hash = anchor;
  }
  // -----------------------------------------
}

export { RouteTools };
