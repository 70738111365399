import React, { useState, useRef, useEffect } from "react";
import EmojiPickerComponent, { EmojiClickData } from "emoji-picker-react";
import { Box, IconButton } from "@mui/material";
import { EmojiEmotionsOutlined } from "@mui/icons-material";

type Props = {
  field: string;
  setObjectField: (field: string, value: any) => void;
  value?: string;
};

const EmojiPicker: React.FC<Props> = ({ field, setObjectField, value }) => {
  const [open, setOpen] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleEmoji = (object: EmojiClickData, event: MouseEvent) => {
    let input = value || "";
    input += object.emoji;
    setObjectField(field, input);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box ref={pickerRef} style={{ position: "relative" }}>
      <IconButton onClick={handleToggle}>
        <EmojiEmotionsOutlined />
      </IconButton>
      
        <EmojiPickerComponent
          onEmojiClick={handleEmoji}
          style={{
            position: "absolute",
            bottom: 50,
            right: 5,
            zIndex: 1000,
          }}
          open={open}
          
        />
      
    </Box>
  );
};

export { EmojiPicker };
