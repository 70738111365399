// import RequestListDTO from "dto/app/requestlist.dto";
// import Idto from "interfaces/idto.interface";
// import IRepository from "interfaces/irepository.interface";
// import GeneralRepository from "repositories/general.repository";

// const { REACT_APP_API_SERVER, REACT_APP_URLPREFIX } = process.env;

// export default class CourseRepository
//   extends GeneralRepository
//   implements IRepository
// {
//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       "/course/" +
//       id;
//     return await this.deleteAxios(url, cb, cbparameters);
//   }

//   async update(
//     id: string,
//     cb?: any,
//     data?: Idto | undefined,
//     cbparameters?: any
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       "/course/" +
//       id;
//     return await this.putAxios(url, cb, data, cbparameters);
//   }

//   async publishByTeacher(
//     id: string,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       "/course/publish-by-teacher/" +
//       id;
//     return await this.putAxios(url, cb, {}, cbparameters);
//   }

//   async updateGeneralInfo(
//     cb?: any,
//     cbparameters?: any,
//     data?: Idto | undefined
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       "/course/general-info";
//     return await this.putAxios(url, cb, data, cbparameters);
//   }

//   async deleteByTeacher(
//     cb?: any,
//     cbparameters?: any,
//     id?: string
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       "/course/delete-by-teacher/" +
//       id;
//     return await this.putAxios(url, cb, undefined, cbparameters);
//   }

//   patch(
//     id: string,
//     cb?: any,
//     data?: Idto | undefined,
//     cbparameters?: any
//   ): Promise<any> {
//     throw new Error("Method not implemented.");
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/${id}`;
//     return await this._get(url, cb, cbparameters);
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") + (REACT_APP_URLPREFIX ?? "") + `/course`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListSearch(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/search`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListPopular(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/popular`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListByTeacher(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/by-teacher`;
//     return await this._getList(url, cb, cbparameters, data);
//   }
//   async getListSuggestion(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/suggestion`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListByStudent(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/by-student`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListInTendency(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/in-tendency`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListForTeacher(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/for-teacher`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListForStudent(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/for-student`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListRecommendedHome(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/recommended-home`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async getListRecommendedStudent(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/recommended-student`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") + (REACT_APP_URLPREFIX ?? "") + `/course`;

//     return await this.postAxios(url, cb, data, cbparameters);
//   }

//   async addFirstStep(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/course/first-step`;

//     return await this.postAxios(url, cb, data, cbparameters);
//   }
// }

import IRepository from "interfaces/irepository.interface";
import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";
import Idto from "interfaces/idto.interface";
import RequestListDTO from "dto/app/requestlist.dto";

export class CourseRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("course");
  }

  getForChat = async (id: string, cb?: any, cbParams?: any) => {
    const url = `${this.mainUrl}/for-chat/${id}`;
    return await this._get(url, cb, cbParams);
  };

  async duplicate(
    cb?: any,
    cbparameters?: any,
    data?: Idto | undefined
  ): Promise<any> {
    const url = `${this.mainUrl}/duplicate`;
    return await this.postAxios(url, cb, data, cbparameters);
  }

  async publish(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = `${this.mainUrl}/publish/${id}`;
    return await this.putAxios(url, cb, { id: id }, cbparameters);
  }

  async start(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = `${this.mainUrl}/start/${id}`;
    return await this.putAxios(url, cb, { id: id }, cbparameters);
  }

  async reschedule(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = `${this.mainUrl}/reschedule/${id}`;
    return await this.putAxios(url, cb, { id: id }, cbparameters);
  }

  async updateGeneralInfo(
    cb?: any,
    cbparameters?: any,
    data?: Idto | undefined
  ): Promise<any> {
    const url = `${this.mainUrl}/general-info`;
    return await this.putAxios(url, cb, data, cbparameters);
  }

  async deleteByTeacher(
    cb?: any,
    cbparameters?: any,
    id?: string
  ): Promise<any> {
    const url = `${this.mainUrl}/delete-by-teacher/${id}`;
    return await this.putAxios(url, cb, undefined, cbparameters);
  }

  async getListSearch(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/search`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListPopular(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/popular`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListByTeacher(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/by-teacher`;
    return await this._getList(url, cb, cbparameters, data);
  }
  async getListSuggestion(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/suggestion`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListByStudent(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/by-student`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListInTendency(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/in-tendency`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListForTeacher(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/for-teacher`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListForStudent(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/for-student`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListRecommendedHome(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/recommended-home`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async getListRecommendedStudent(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const url = `${this.mainUrl}/recommended-student`;
    return await this._getList(url, cb, cbparameters, data);
  }

  async addFirstStep(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
    const url = `${this.mainUrl}/first-step`;

    return await this.postAxios(url, cb, data, cbparameters);
  }
}
