import { Box, Typography } from "@mui/material";
import { ChatMessageDto } from "dto/course/chatmessage.dto";

import React from "react";

import { ChatMessageItemAvatar } from "./ChatMessageItemAvatar";
import DateTools from "tools/utils/date.tools";
import { ChatMessageItemContent } from "./ChatMessageItem";

type Props = {
  object: ChatMessageDto;
  displayAvatar: boolean;
  isLast?: boolean;
};

const ChatMessageItemOwn: React.FC<Props> = ({
  object,
  displayAvatar,
  isLast,
}) => {
  if (!object) return null;

  return (
    <Box
      sx={{
        margin: 1,
        display: "flex",
        direction: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: displayAvatar ? 2 : 8,
        gap: 1,
      }}>
      <Box>
        <Typography className="dateChat">
          {DateTools.displayTimestampInTime(object.date)}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "11px",
          borderRadius: "5px",
          backgroundColor: "#0072EF",
          color: "white",
          maxWidth: "600px",
        }}>
        <ChatMessageItemContent object={object} />
      </Box>
      {displayAvatar && (
        <Box>
          <ChatMessageItemAvatar object={object} isOwn />
        </Box>
      )}
    </Box>
  );
};

export { ChatMessageItemOwn };
