import { Box, Divider, Typography } from "@mui/material";

import { AppointmentScheduler } from "dto/course/schedule.dto";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import DateTools from "tools/utils/date.tools";

type Props = {
  data: {
    appointmentData: AppointmentScheduler;
  };
  style?: React.CSSProperties;
  className?: string;
  scaleFactor?: number;
  appointmentWidth?: number;
  currentView: string;
  [key: string]: any;
};

const Appointment: React.FC<Props> = ({
  data: { appointmentData },
  className,
  style,
  appointmentWidth = 230,
  scaleFactor = 1,
  currentView = "day",
}) => {
  const gradient = CommonTools.processObjectField(appointmentData, ["color"]);

  const isDayView = currentView === "month";

  let newTransform = style?.transform;
  if (newTransform?.startsWith("translate(")) {
    const match = newTransform.match(/translate\(([^,]+),\s*([^)]+)/);
    if (match) {
      const oldXStr = match[1];
      const oldYStr = match[2];
      const oldX = parseFloat(oldXStr);
      const scaledX = oldX * scaleFactor;
      newTransform = `translate(${scaledX}px, ${oldYStr}`;
    }
  }

  const overriddenStyle: React.CSSProperties = {
    ...style,
    transform: newTransform,
    background: gradient,
    // width: "inherit",
    // height: "inherit",
  };

  return (
    <Box
      className={`test333 my-custom-appointment ${className ?? ""}`}
      style={overriddenStyle}
      sx={{
        backgroundColor: gradient,
        height: "inherit",
        width: appointmentWidth,

        px: 2,
        // marginTop: "22px",
        borderRadius: !isDayView ? "10px" : "50px",
        transition: "all ease 0.2s",
        minHeight: !isDayView ? "inheerit" : "auto",
        "&:hover::after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.1)",
          pointerEvents: "none",
          borderRadius: !isDayView ? "10px" : "50px",

          transition: "all ease 0.2s",
        },
      }}>
      <Typography
        sx={{
          width: "100%",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: !isDayView ? 3 : 1,
          // height: "64px",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          fontWeight: "600",
          fontSize: "13px",

          pt: "1px",
          pb: "1px",
        }}>
        {CommonTools.processObjectField(appointmentData, ["text"])}
      </Typography>
      {!isDayView && (
        <>
          <Divider sx={{ width: "100%" }} />
          <Typography
            sx={{
              py: "5px",
              width: "100%",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}>
            {DateTools.getDateTimeStr(
              appointmentData.startDate,
              DateTools.getHourDayJs()
            )}
            {" - "}
            {DateTools.getDateTimeStr(
              appointmentData.endDate,
              DateTools.getHourDayJs()
            )}
          </Typography>
        </>
      )}
    </Box>
  );
};

export { Appointment };
