import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

type Props = {
  likes: number;
  dislikes: number;
  pressLike: () => void;
  pressDislike: () => void;
  disabledLike: boolean;
  disabledDislike: boolean;
};

const LikeDislikeStaticForm: React.FC<Props> = ({
  likes,
  dislikes,
  pressLike,
  pressDislike,
  disabledLike,
  disabledDislike,
}) => {
  return (
    <Box sx={{ py: 0, maxWidth: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 0,
          flexDirection: "row",
        }}>
        <IconButton onClick={pressLike} disabled={disabledLike}>
          <Box
            sx={{
              display: "flex",
              gap: "3px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <ThumbUp
              color={disabledLike ? "primary" : "inherit"}
              sx={{ fontSize: "18px" }}
            />
            <Typography
              color={disabledLike ? "primary" : "inherit"}
              sx={{ fontSize: "14px" }}>
              {likes}
            </Typography>
          </Box>
        </IconButton>
        <IconButton onClick={pressDislike} disabled={disabledDislike}>
          <Box
            sx={{
              display: "flex",
              gap: "3px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <ThumbDown
              color={disabledDislike ? "primary" : "inherit"}
              sx={{ fontSize: "18px" }}
            />
            <Typography
              color={disabledDislike ? "primary" : "inherit"}
              sx={{ fontSize: "14px" }}>
              {dislikes}
            </Typography>
          </Box>
        </IconButton>
      </Box>
    </Box>
  );
};

export { LikeDislikeStaticForm };
