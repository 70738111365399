import Idto from "interfaces/idto.interface";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import RequestPopulateDTO from "dto/app/requestpopulate.dto";
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";

export default class RequestListDTO implements Idto {
  filters?: Array<RequestFilterDTO>;
  page?: number;
  onpage?: number;
  sortcriteria?: Array<RequestSortCriteriaDTO>;
  populate?: RequestPopulateDTO;
  criteria?: Array<RequestCriteriaDTO>;
  range?: Array<RequestCriteriaDTO>;
  backwardpagination: boolean;

  constructor(
    filters?: Array<RequestFilterDTO>,
    page?: number,
    onpage?: number,
    sortcriteria?: Array<RequestSortCriteriaDTO>,
    populate?: RequestPopulateDTO,
    criteria?: Array<RequestCriteriaDTO>,
    range?: Array<RequestCriteriaDTO>,
    backwardpagination?: boolean
  ) {
    this.filters = filters ?? [];
    this.page = page ?? 1;
    this.onpage = onpage ?? 10;
    this.sortcriteria = sortcriteria ?? [];
    this.populate = populate ?? new RequestPopulateDTO();
    this.criteria = criteria ?? [];
    this.range = range ?? [];
    this.backwardpagination = backwardpagination ?? false;
  }
  
}
