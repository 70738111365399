import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";
import { TeacherFAQMessageRatingRepository } from "repositories/system/teacherfaqmessagerating.repository";

export class TeacherFAQMessageRatingService
  extends GeneralService<TeacherFAQMessageRatingRepository>
  implements IService
{
  constructor() {
    super(new TeacherFAQMessageRatingRepository(), [], "date");
  }

  async getRating(
    id: string,
    idUser: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getRating(
      id,
      idUser,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  }

  like = async (cb?: any, cbparameters?: any, data?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.like(this.handleGet, cbparameters, data);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  dislike = async (cb?: any, cbparameters?: any, data?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.dislike(this.handleGet, cbparameters, data);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
