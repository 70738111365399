import { Alert, Box } from "@mui/material";
import { useResource } from "hooks/useResource";

type Props = {
  canEdit: boolean;
};

export const CanEdit: React.FC<Props> = ({ canEdit }) => {
  const { LL } = useResource();
  if (canEdit) return null;
  return (
    <Box my={2} >
      <Alert severity="error">
        {LL("you_are_not_allowed_to_edit_the_content_of_this_course")}
      </Alert>
    </Box>
  );
};
