import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";
import { ChatParticipantRepository } from "repositories/course/chatparticipant.repository";

export class ChatParticipantService
  extends GeneralService<ChatParticipantRepository>
  implements IService
{
  constructor() {
    super(
      new ChatParticipantRepository(),
      [
        { key: "status", type: "status" },
        { key: "type", type: "types" },
        { key: "date", type: "timestamp" },
      ],
      "sortfield",
      false
    );
  }
}