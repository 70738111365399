import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { CourseDto } from "dto/course/course.dto";
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/routetools";
import { Box, Stack, Grid } from "@mui/material";
import { TeacherProcessNameCourse } from "components/user/teacher/TeacherProcessNameCourse";

import { TypeCourseCardChip } from "./TypeCourseCardChip";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { CourseCardPriceContent } from "components/elements/courseelements/CourseCardPriceContent";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { useResponsive } from "hooks/useResponsive";

type Props = {
  obj: CourseDto;
  loading: boolean;
};

const CourseListCard: React.FC<Props> = ({ obj, loading }) => {
  const { LL } = useResource();
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");
  const { downMD, matchesCustom3 } = useResponsive();

  const goCourseDetails = () => {
    if (!obj) return;
    if (!obj.id) return;
    if (!obj.hasOwnProperty("fullurl")) return;
    if (!obj.fullurl) return;
    const url = obj.fullurl;
    RouteTools.setHistory(url, {});
  };

  const prepareGallery = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!obj) return null;
    // if (!imgUrl) return null;

    return imgUrl ? (
      <CardMedia
        className="imageCardTeacher"
        sx={{ height: 180 }}
        image={imgUrl}
        title="">
        {processCourseType()}
      </CardMedia>
    ) : (
      <Box className="imageCardTeacher noImagekBox">
        <InsertPhotoOutlinedIcon />
        {processCourseType()}
      </Box>
    );
  };

  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("teacher")) return null;
    if (!obj.teacher) return null;

    return (
      <Box sx={{ px: { xs: "16px", md: "0px" } }} className="processteacher">
        <TeacherProcessNameCourse obj={obj.teacher} />
      </Box>
    );
  };

  const processCourseType = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("coursesettings")) return null;
    if (!obj.coursesettings) return null;
    return (
      <Box p={1} className="statuses chipStatus">
        <TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} />
      </Box>
    );
  };

  const processCoursePrice = () => {
    if (!obj) return null;
    return <CourseCardPriceContent obj={obj} />;
  };
  const processCourseFilters = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("coursefiltermain")) return null;
    if (!obj.coursefiltermain) return null;
    if (obj.coursefiltermain.length === 0) return null;
    return (
      <Stack direction="column">
        {obj.coursefiltermain.map((item, index) => {
          return (
            <Typography key={index}>
              <Typography component="span" className="lp">
                {ComponentCommonTools.processObjectFieldOfObject(
                  item,
                  "filter",
                  "name"
                )}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldOfObject(
                  item,
                  "filterdictionary",
                  "name"
                )}
                {ComponentCommonTools.processObjectField(item, "value")}
              </Typography>
            </Typography>
          );
        })}
      </Stack>
    );
  };

  const processCourseCategories = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("linkcoursecategory")) return null;
    if (!obj.linkcoursecategory) return null;
    if (obj.linkcoursecategory.length === 0) return null;
    return (
      <Stack direction="column">
        {obj.linkcoursecategory.map((item, index) => {
          return (
            <Box key={index} className="detailsGeneral">
              {ComponentCommonTools.processObjectFieldOfObject(
                item,
                "coursecategory",
                "name"
              )}
            </Box>
          );
        })}
      </Stack>
    );
  };

  const processCourseDetails = () => {
    if (!obj) return null;

    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}>
          <Stack direction="column" className="detailsGeneral">
            <Box>
              <Typography component="span" className="lp">
                {LL("Language course")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldOfObject(
                  obj,
                  "language",
                  "name"
                )}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("CourseDuration")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldTimeStamp(
                  obj,
                  "courseduration"
                )}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("NumberOfLessons")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectField(
                  obj,
                  "numberoflessons"
                )}
              </Typography>
            </Box>
          </Stack>
          <Box className="detailsGeneral" sx={{ textAlign: "right" }}>
            {processCourseFilters()}
          </Box>
        </Stack>
      </Box>
    );
  };

  const processLinkName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("fullurl")) return processName();
    if (!obj.fullurl) return processName();
    const url = obj.fullurl;

    return (
      <MyNavBt href={url} sx={{ textDecoration: "none" }}>
        {processName()}
      </MyNavBt>
    );
  };

  const processName = () => {
    if (!obj) return null;
    return (
      <Typography sx={{ px: { xs: "16px", md: "0px" } }} className="name">
        {ComponentCommonTools.processObjectField(obj, "name")}
      </Typography>
    );
  };

  const processCourseListCard = () => {
    if (!obj) return null;

    return (
      <Box py={1} sx={{ cursor: "pointer" }}>
        <Card
          sx={{ mx: "auto", maxWidth: { xs: "600px", md: "none" } }}
          className="courseCardH">
          <Grid container className="content" spacing={2} alignItems="center">
            <Grid item lg={2} md={3}>
              {processImage()}
            </Grid>
            <Grid item lg={5} md={6} mt={0}>
              {processLinkName()}
              {processTeacher()}
              <Box
                className="cardPaddingX"
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: { xs: "start", sm: "space-between" },
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  alignItems: { xs: "start", sm: "end" },
                  gap: { xs: 2, sm: "auto" },
                  marginTop: 3,
                  spacing: 2,
                  // overflowX: "hidden",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "row",

                    gap: 4,
                    flexWrap: "wrap",
                    width: "100%",
                  }}>
                  {processCoursePrice()}
                  <MyButton
                    sx={{ display: { xs: "block", lg: "block" } }}
                    cb={goCourseDetails}
                    variant="contained"
                    className="btn">
                    {LL("Subscribe_to_course")}
                  </MyButton>
                </Box>
              </Box>
            </Grid>
            <Grid
              className="teacherCourseCardSet cardPaddingX"
              item
              sm={12}
              lg={2}
              md={2}
              sx={{
                paddingTop: "0px",
                px: { xs: "16px", md: "0px" },
                width: "100%",
              }}>
              <Box
                className="detailsGeneral"
                sx={{ px: { xs: "16px", md: "0px" } }}
                my={1}>
                {processCourseDetails()}
                <Typography component="span" className="lp">
                  {LL("CourseCategories")}
                </Typography>
                <Typography component="span" className="rp">
                  {processCourseCategories()}
                </Typography>
              </Box>
              <Box
                className="priceBoxCardPopular"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  pl: { xs: "16px", md: "0px" },
                  pb: { xs: "0px", sm: "20px", md: "0px" },
                }}>
                <MyButton
                  sx={{
                    display: { xs: "block", md: "none" },
                    width: { xs: "100%", sm: "auto" },
                  }}
                  cb={goCourseDetails}
                  variant="contained"
                  className="btn">
                  {LL("Subscribe_to_course")}
                </MyButton>
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  {processCoursePrice()}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    );
  };

  return loading ? <Skeleton variant="rectangular" /> : processCourseListCard();
};

export { CourseListCard };
