import React from "react";
import { useResource } from "hooks/useResource";
import { TeacherDto } from "dto/user/teacher.dto";
import { Status } from "tools//types/status";
import { Stack, Typography } from "@mui/material";
import { TeacherTypeIcon } from "components/user/teacher/TeacherTypeIcon";
import { logger } from "tools/utils/logger";
type Props = {
  obj: TeacherDto;
};

const TeacherRequestButtonContent: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();
  
  const processItem = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (obj.status === Status.TEACHER_UNVERIFIED) {
      return (
        <Stack direction={"row"} spacing={1}>
          <TeacherTypeIcon obj={{ status: Status.TEACHER_VERIFIED }} />
          <Typography color={"#01E65C"}>{LL("Become")}</Typography>
          <Typography color={"#01E65C"}>
            {Status.LL(Status.TEACHER_VERIFIED)}
          </Typography>
        </Stack>
      );
    }
    if (obj.status === Status.TEACHER_VERIFIED) {
      return (
        <Stack direction={"row"} spacing={1}>
          <TeacherTypeIcon obj={{ status: Status.TEACHER_PRO }} />
          <Typography color={"#FFD102"}>{LL("Become")}</Typography>
          <Typography color={"#FFD102"}>
            {Status.LL(Status.TEACHER_PRO)}
          </Typography>
        </Stack>
      );
    }

    return null;
  };

  return processItem();
};

export { TeacherRequestButtonContent };
