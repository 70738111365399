export class EventTypes {
  static EVENT_NEED_REFRESH_TOKEN: string = "need-refresh";
  static EVENT_NEW_NOTIFICATION_TOP_SITE_USER: string =
    "new-notification-top-site-user";
  static EVENT_NEW_NOTIFICATION_TOP_SITE_ADMIN: string =
    "new-notification-top-site-admin";
  static EVENT_NEW_NOTIFICATION_TOP_SITE_TEACHER: string =
    "new-notification-top-site-teacher";
  static EVENT_NEW_NOTIFICATION_TOP_SITE_STUDENT: string =
    "new-notification-top-site-student";
}
