import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import { AddressDto, AddressFormDto } from "dto/nomenclature/address.dto";
import { AddressService } from "services/nomenclature/address.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/commontools";

import { useAuth } from "hooks/useAuth";
import { NewMySelect } from "components/elements/Select/newSelect/NewMySelect";
import RequestListDTO from "dto/app/requestlist.dto";
import { useList } from "hooks/useList";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { FormInlineAddress } from "./FormInlineAddress";
import { useResource } from "hooks/useResource";

type Props = {
  value: string;
  setObjectField: (field: string, value: string) => void;
  field: string;
  disabled?: boolean;
};
const service = new AddressService();
const AddressContract: React.FC<Props> = ({
  value,
  setObjectField,
  field,
  disabled = false,
}) => {
  const { user } = useAuth();
  const idUser = CommonTools.processObjectField(user, ["id"]);
  const [addAddress, setAddAddress] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const { LL } = useResource();
  const handleToggle = () => {
    setAddAddress(!addAddress);
  };

  const handleSubmit = (obj: AddressFormDto) => {
    if (!idUser) return;
    obj.iduser = idUser;
    setLoadingAdd(true);
    service.add(handleResult, {}, obj);
  };
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    const id = CommonTools.processObjectField(result, ["obj", "id"]);
    addToList(result.obj);
    setObjectField(field, id);
    setAddAddress(false);
    setLoadingAdd(false);
  };

  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!idUser) return;
    if (!data) data = new RequestListDTO();

    service.getList(cb, cbParams, data);
  };

  const addToList = (obj: AddressDto) => {
    if (!obj) return;
    if (!obj.id) return;
    if (!objects) return;
    setObjects([...objects, obj]);
  };
  const [loading, objects, , , setObjects] = useList<AddressDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("iduser", [idUser]),
      ],
      1,
      -1
    ),
    [idUser]
  );

  if (!idUser) return null;
  if (loading) return <Skeleton variant="rectangular" height={50} />;
  if (!objects) return null;

  return (
    <Box>
      <NewMySelect
        options={AddressDto.parseToSelectOptions(objects)}
        _label={""}
        setObjectField={setObjectField}
        field={field}
        value={value}
        size="small"
        variant="standard"
        color="primary"
        className={"form-select"}
        disabled={disabled}
      />
      {disabled ? null : (
        <React.Fragment>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button onClick={handleToggle}>
              {addAddress ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Remove />
                  <Typography variant="body2">{LL("hide")}</Typography>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Add />
                  <Typography variant="body2">{LL("addAdress")}</Typography>
                </Box>
              )}
            </Button>
          </Box>
          <Box>
            {addAddress ? (
              <Box sx={{ px: 0 }}>
                <FormInlineAddress
                  defaultObj={new AddressFormDto()}
                  onSubmit={handleSubmit}
                  loading={loadingAdd}
                />
              </Box>
            ) : null}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export { AddressContract };
