import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TopBar } from "components/general/TopBar";
import Box from "@mui/material/Box";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import { UserInterfaceBlock } from "components/user/user/header/UserInterfaceBlock";

import { Types } from "tools/types/types";
import { NotificationTopMenu } from "components/notification/NotificationTopMenu";
import { TeacherRequestBlock } from "components/user/teacherrequest/TeacherRequestBlock";

import { RouteTools } from "tools/utils/routetools";

import { NextLessonTeacherBlock } from "../teacherinterface/NextLessonTeacherBlock";
import { CalendarButton } from "components/elements/scheduler/CalendarButton";

const HeaderTeacher: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { user, userInterface } = useAuth();
  const { isTeacher } = useResource();

  const processHeader = () => {
    if (userInterface !== Types.TEACHER_INTERFACE) return null;
    if (!isTeacher) return null;
    if (!user) return null;

    return (
      <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
        <TeacherRequestBlock currentRoute={currentRoute} />
        <NotificationTopMenu
          currentRoute={currentRoute}
          typeRecipient={Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER}
        />
        <UserInterfaceBlock currentRoute={currentRoute} />
        <TopBar currentRoute={currentRoute} />
      </Box>
    );
  };
  return processHeader();
};
const HeaderTeacherRightPart: React.FC = () => {
  const { user, userInterface } = useAuth();
  const { isTeacher } = useResource();
  const handleGoToCalendar = () => {
    RouteTools.setHistory("/teacherinterface/calendar", {});
  };

  if (userInterface !== Types.TEACHER_INTERFACE) return null;
  if (!isTeacher) return null;
  if (!user) return null;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        flexDirection: "row",
        gap: "10px",
        width: "100%",
      }}>
      <NextLessonTeacherBlock />
      <CalendarButton onClick={handleGoToCalendar} />
    </Box>
  );
};
export { HeaderTeacher, HeaderTeacherRightPart };
