import { Done } from "@mui/icons-material";
import { Box } from "@mui/material";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { ChatMessageDto } from "dto/course/chatmessage.dto";
import React from "react";

import { ChatMessageItemOwn } from "./ChatMessageItemOwn";
import { Types } from "tools/types/types";
import { AudioPlayerMessage } from "./AudioPlayerMessage";

type Props = {
  object: ChatMessageDto | null;
  loading: boolean;
};

const SendingMessage: React.FC<Props> = ({ object, loading }) => {
  const processDisplay = () => {
    if (!object) return null;
    if (object.type === Types.TYPE_CHAT_MESSAGE_AUDIO) {
      if (!object.files) return null;
      const url = URL.createObjectURL(object.files);
      return <AudioPlayerMessage url={url} />;
    }
    return <ChatMessageItemOwn object={object} displayAvatar={false} />;
  };
  if (!object) return null;

  return (
    <Box
      sx={{
        margin: 1,
        display: "flex",
        direction: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Box>{processDisplay()}</Box>
      <Box>{loading ? <CircularLoading /> : <Done />}</Box>
    </Box>
  );
};

export { SendingMessage };
