import React, { createContext, useCallback, useEffect, useState } from "react";

import IProvider from "interfaces/provider.interface";
import { NotificationService } from "services/user/notification.service";
import { useAuth } from "hooks/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";

import { useSocketMessage } from "hooks/useSocketMessage";
import { EventTypes } from "tools/types/eventtypes";

type ContextType = {
  numberOfUnreadNotificationsStudent: number;
  numberOfUnreadNotificationsTeacher: number;
  setNumberOfUnreadNotificationsStudent: (value: number) => void;
  setNumberOfUnreadNotificationsTeacher: (value: number) => void;
};

export const NotificationContext = createContext<ContextType>({
  numberOfUnreadNotificationsStudent: 0,
  numberOfUnreadNotificationsTeacher: 0,
  setNumberOfUnreadNotificationsStudent: () => {},
  setNumberOfUnreadNotificationsTeacher: () => {},
});

const service = new NotificationService();
export const NotificationProvider: React.FC<IProvider> = ({ children }) => {
  const { student, teacher } = useAuth();

  const onNotificationTeacher = useCallback((data: any) => {
    const count = CommonTools.processObjectField(data, ["object", "count"]);
    if (!count) return;
    setNumberOfUnreadNotificationsTeacher(parseInt(count.toString()));
  }, []);

  const onNotificationStudent = useCallback((data: any) => {
    const count = CommonTools.processObjectField(data, ["object", "count"]);
    if (!count) return;
    setNumberOfUnreadNotificationsStudent(parseInt(count.toString()));
  }, []);

  useSocketMessage(
    EventTypes.EVENT_NEW_NOTIFICATION_TOP_SITE_TEACHER,
    onNotificationTeacher
  );
  useSocketMessage(
    EventTypes.EVENT_NEW_NOTIFICATION_TOP_SITE_STUDENT,
    onNotificationStudent
  );
  
  const [
    numberOfUnreadNotificationsStudent,
    setNumberOfUnreadNotificationsStudent,
  ] = useState<number>(0);
  const [
    numberOfUnreadNotificationsTeacher,
    setNumberOfUnreadNotificationsTeacher,
  ] = useState<number>(0);
  const getNumberOfNotificationStudent = useCallback(() => {
    if (!student) {
      setNumberOfUnreadNotificationsStudent(0);
      return;
    }
    const id = CommonTools.processObjectField(student, ["id"]);
    if (!id) return;
    service.getCount(
      getResult,
      { student: true },
      new RequestListDTO([
        RequestFilterDTO.prepareFilter("type", [
          Types.TYPE_NOTIFICATION_TOP_SITE.toString(),
        ]),
        RequestFilterDTO.prepareFilter("idstudent", [id]),
      ])
    );
  }, [student]);

  const getNumberOfNotificationTeacher = useCallback(() => {
    if (!teacher) {
      setNumberOfUnreadNotificationsTeacher(0);
      return;
    }
    const id = CommonTools.processObjectField(teacher, ["id"]);
    if (!id) return;
    service.getCount(
      getResult,
      { teacher: true },
      new RequestListDTO([
        RequestFilterDTO.prepareFilter("type", [
          Types.TYPE_NOTIFICATION_TOP_SITE.toString(),
        ]),
        RequestFilterDTO.prepareFilter("idteacher", [id]),
      ])
    );
  }, [teacher]);

  const getResult = (result: ResultObjectDTO, cbParams?: any) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    const count = CommonTools.processObjectField(result, ["obj", "count"]);
    if (cbParams && cbParams.student) {
      setNumberOfUnreadNotificationsStudent(parseInt(count.toString()));
    } else {
      setNumberOfUnreadNotificationsTeacher(parseInt(count.toString()));
    }
  };

  useEffect(() => {
    getNumberOfNotificationStudent();
  }, [getNumberOfNotificationStudent]);

  useEffect(() => {
    getNumberOfNotificationTeacher();
  }, [getNumberOfNotificationTeacher]);

  return (
    <NotificationContext.Provider
      value={{
        numberOfUnreadNotificationsStudent,
        numberOfUnreadNotificationsTeacher,
        setNumberOfUnreadNotificationsStudent,
        setNumberOfUnreadNotificationsTeacher,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
