import { Box, IconButton, Stack } from "@mui/material";

import React, { useCallback, useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

import { Delete } from "@mui/icons-material";
import { AudioPlayerMessage } from "./AudioPlayerMessage";

type Props = {
  setIsRecording: (isRecording: boolean) => void;
  onSubmit: (file: Blob) => void;
  onDelete: () => void;
  url: string;
  setUrl: (url: string) => void;
};

const ChatMessageVoiceRecorder: React.FC<Props> = ({
  setIsRecording,
  onSubmit,
  onDelete,
  url,
  setUrl,
}) => {
  
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => {}
  );

  const handleRecordingState = useCallback(() => {
    const value = recorderControls.isRecording;
    setIsRecording(value);
  }, [recorderControls.isRecording]);

  useEffect(() => {
    handleRecordingState();
  }, [handleRecordingState]);

  const addAudioElement = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    setUrl(url);
    onSubmit(blob);
    
  };
  const handleDelete = () => {
    setUrl("");
    onDelete();
  };
  if (!navigator.mediaDevices) return null;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        {url ? (
          <Stack direction="row" spacing={2}>
            <AudioPlayerMessage url={url} />
            <IconButton onClick={handleDelete}>
              <Delete />
            </IconButton>
          </Stack>
        ) : null}
      </Box>
      <Box>
        <AudioRecorder
          onRecordingComplete={(blob) => addAudioElement(blob)}
          recorderControls={recorderControls}
          downloadFileExtension="mp3"
          showVisualizer={true}
          onNotAllowedOrFound={(err) => {}}
        />
      </Box>
    </Box>
  );
};

export { ChatMessageVoiceRecorder };
