import { Box, IconButton, Stack } from "@mui/material";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { TestDto } from "dto/course/newtest.dto";

import React, { useState } from "react";

import { TestService } from "services/course/test.service";
import { Types } from "tools/types/types";
import { FormTest } from "./FormTest";
import { TestEditBlock } from "./TestEditBlock";
import { Delete, Done, Edit } from "@mui/icons-material";
import { CommonTools } from "tools/utils/commontools";
import { useMessage } from "hooks/useMessage";
import { useResource } from "hooks/useResource";

type Props = {
  object: TestDto | null;
  setObject: (value: TestDto | null) => void;
  idCourse?: string;
  idLesson?: string;
  allowEdit?: boolean;
  allowDelete?: boolean;
  deleteFromList: (id: string) => void;
  canEdit: boolean;
};

const service = new TestService();

const EditTest: React.FC<Props> = ({
  object,
  setObject,
  idCourse = "",
  idLesson = "",
  allowEdit = false,
  allowDelete = false,
  deleteFromList,
  canEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { registerDialog } = useMessage();
  const { LL } = useResource();
  const handleActionDelete = () => {
    registerDialog(
      LL("Confirm_delete_test"),
      LL("Are_you_sure_you_want_to_delete_this_test"),
      { cb: onDelete },
      null
    );
  };

  const onSubmit = (data: TestDto) => {
    setLoading(true);
    service.add(handleResult, {}, data);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) {
      setLoading(false);
      return;
    }
    if (!result.obj) {
      setLoading(false);
      return;
    }
    setObject(result.obj);
    setLoading(false);
    if (allowEdit && editMode) setEditMode(false);
  };

  const onEdit = (data: TestDto) => {
    setLoading(true);
    if (!object) return;
    if (!object.id) return;
    service.update(object.id, handleResult, {}, data);
  };

  const onDelete = () => {
    if (!object) return;
    const id = CommonTools.processObjectField(object, ["id"]);
    setLoading(true);
    service.delete(id, handleDelete, { id });
  };
  const handleDelete = (result: ResultObjectDTO, cbParams?: any) => {
    if (!result) return;
    if (result.err) return;
    if (!cbParams) return;
    const id = CommonTools.processObjectField(cbParams, ["id"]);
    if (!id) return;
    deleteFromList(id);
    setLoading(false);
  };

  return (
    <React.Fragment>
      {object ? (
        <Box>
          <Box>
            {allowEdit && (
              <Box
                sx={{
                  display: "flex",
                  position: { xs: "block", sm: "absolute" },
                  right: "10px",
                  top: "90px",
                  width: { xs: "100%", sm: "auto" },
                  justifyContent: "end",
                }}>
                <IconButton onClick={toggleEditMode}>
                  {editMode ? <Done /> : <Edit />}
                </IconButton>
                {allowDelete && (
                  <IconButton onClick={handleActionDelete}>
                    <Delete color="error" />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
          {editMode ? (
            <FormTest
              defaultObject={object}
              canEdit={canEdit}
              loading={loading}
              onSubmit={onEdit}
            />
          ) : (
            <>
              <TestEditBlock
                object={object}
                setObject={setObject}
                open={true}
              />
            </>
          )}
        </Box>
      ) : (
        <Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}>
              <CircularLoading />
            </Box>
          ) : (
            <FormTest
              defaultObject={
                new TestDto(
                  idCourse,
                  idLesson,
                  undefined,
                  undefined,
                  undefined,
                  Types.TYPE_TEST_LESSON
                )
              }
              fromLesson
              loading={false}
              onSubmit={onSubmit}
              canEdit={canEdit}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  );
};

export { EditTest };
