import { Box, Stack, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";
import MyDateTimePicker from "components/elements/MyDatePicker/MyDateTimePicker";
import { LessonTypeSelect } from "components/elements/Select/LessonTypeSelect";
import { StaticSelect } from "components/elements/Select/newSelect/StaticSelect";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import MyTextField from "components/form/mytextfield";
import { LessonFormDto } from "dto/course/lesson.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import { Status } from "tools/types/status";
import { Config } from "tools/utils/config";
import DateTools from "tools/utils/date.tools";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: LessonFormDto;
  onSubmit: (obj: LessonFormDto) => void;
  loading: boolean;
  canEdit: boolean;
};

const FormLesson: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  canEdit,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject, vResult, , , objectWasChanged] =
    useForm<LessonFormDto>(
      defaultObj,
      RequiredValidator.getValidators(["name"])
    );
  const [validDate, setValidDate] = useState<boolean>(false);
  useEffect(() => {
    if (!defaultObj) return;
    setObject(LessonFormDto.parseFromLessonDto(defaultObj));
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  const setObjDateField = (field: any, value: any) => {
    setObjField(field, DateTools.getDateTimeStamp(value));
  };

  const processDate = useCallback(() => {
    let valid = true;
    if (obj.idtypelesson === Config.ID_LESSON_TYPE_ONLINE) {
      if (obj.startdate !== undefined && obj.enddate !== undefined) {
        if (obj.startdate > obj.enddate) {
          valid = false;
        }
      } else {
        valid = false;
      }
    }
    setValidDate(valid);
  }, [obj.idtypelesson, obj.startdate, obj.enddate]);

  useEffect(() => {
    processDate();
  }, [processDate]);

  const processLessonLive = () => {
    if (!obj) return null;
    if (obj.idtypelesson !== Config.ID_LESSON_TYPE_ONLINE) return null;
    return (
      <Box>
        <Box mt={3}>
          <MyCheckboxField
            label={LL("islive")}
            field="islive"
            checked={obj.islive}
            setObjectField={setObjField}
            color="primary"
            size="small"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" mb={1}>
            {LL("Seteaza data si ora lectiei")}
          </Typography>
          <Stack direction="row" spacing={2}>
            <MyDateTimePicker
              disablePast
              className="form-picker"
              field="startdate"
              _defaultValue={DateTools.convertTimestampToDate(obj.startdate)}
              setObjField={setObjDateField}
            />

            <MyDateTimePicker
              disablePast
              className="form-picker"
              field="enddate"
              _defaultValue={DateTools.convertTimestampToDate(obj.enddate)}
              setObjField={setObjDateField}
            />
          </Stack>
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      <InformationObjectWasChanged objectWasChanged={objectWasChanged} />
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1} mt={3}>
            {LL("Name")}
          </Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              tabIndex={0}
              fullWidth
              id="name"
              name="name"
              variant="standard"
              className="form-input"
              color="primary"
              _label={""}
              size="small"
              value={obj.name}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("TypeLesson")}
          </Typography>
          <LessonTypeSelect
            value={obj.idtypelesson}
            field="idtypelesson"
            setObjectField={setObjField}
            label=""
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Status")}
          </Typography>
          <StaticSelect
            setObjField={setObjField}
            service={Status}
            field="status"
            value={obj.status}
            type="clasificator"
            label=""
          />
        </Box>
        {processLessonLive()}
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("descriere lectie")}
          </Typography>
          <MyCKEditor
            data={obj.description ? obj.description : ""}
            setObj={setObjField}
            name="description"
          />
        </Box>
        <Box my={4} textAlign="center">
          <MyButton
            disabled={disabled || !validDate || loading || !canEdit}
            color="primary"
            className="btn"
            variant="contained"
            size="large"
            type="submit"
            children={LL("Bt_Submit Modification")}
          />
        </Box>
      </form>
    </Box>
  );
};

export { FormLesson };
