import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { InfoCourseCategoryLevel } from "./InfoCourseCategoryLevel";
import Box from "@mui/material/Box";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { Stack } from "@mui/material";
import { logger } from "tools/utils/logger";

type InfoCourseCategoryItemProps = {
  currentRoute: any;
  level: number;
  obj: CourseCategoryDto;
  hasChildren?: boolean;
};

const InfoCourseCategoryItem: React.FC<InfoCourseCategoryItemProps> = ({
  currentRoute,
  level,
  obj,
  hasChildren,
  ...props
}) => {
  const [nextLevel, setNextLevel] = useState<number>(1);
  const [maxLevel, setMaxLevel] = useState<number>(1);
  const [isChildsShowed, setIsChildsShowed] = useState<boolean>(false);

  const { CC } = useResource();

  useEffect(() => {
    let ml: number = parseInt(CC("MAXLEVEL_Category_Top_MENU", "4"));
    ml = isNaN(ml) || ml == undefined ? 1 : ml;
    setMaxLevel(ml);
  }, []);

  useEffect(() => {
    setNextLevel(level + 1);
  }, [level]);

  useEffect(() => {
    setIsChildsShowed(false);
  }, [obj]);

  const processBT = () => {
    if (!obj) return;

    if (!obj.fullurl) {
      return (
        <Stack direction="row" spacing={2}>
          <Box>{processIcon()}</Box>
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Stack>
      );
    }

    return (
      <MyNavBt href={obj.fullurl}>
        <Stack direction="row" spacing={2}>
          <Box
            className="icontopBar"
            sx={{
              width: "30px",
              height: "30px",
              maxHeight: "30px",
              maxWidth: "30px",
            }}>
            {processIcon()}
          </Box>
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Stack>
      </MyNavBt>
    );
  };

  const processDetail = () => {
    if (!obj) return null;

    return <Box>{processBT()}</Box>;
  };

  const processBts = () => {
    if (nextLevel > maxLevel || !hasChildren) return null;

    if (isChildsShowed)
      return (
        <Box
          className="icon-minus"
          onClick={() => {
            setIsChildsShowed(false);
          }}></Box>
      );
    if (!isChildsShowed)
      return (
        <Box
          className="icon-plus"
          onClick={() => {
            setIsChildsShowed(true);
          }}></Box>
      );
  };
  const processIcon = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("icon")) return null;
    if (!obj.icon) return null;
    return (
      <Box className="imageDivTopBar" sx={{ height: "20px" }}>
        {ComponentCommonTools.getFileBlockSVG(obj.icon)}
      </Box>
    );
  };
  const processChilds = () => {
    if (!hasChildren) return null;
    if (nextLevel > maxLevel) return null;
    if (!isChildsShowed) return null;
    if (!obj) return null;

    return (
      <Box className={"section" + level}>
        <Box className="content" sx={{ minHeight: "450px" }}>
          <InfoCourseCategoryLevel
            currentRoute={currentRoute}
            level={nextLevel}
            idparent={obj.id ?? ""}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      className="item"
      onMouseOut={() => {
        setIsChildsShowed(false);
      }}
      onMouseOver={() => {
        setIsChildsShowed(true);
      }}>
      {processDetail()}
      {processBts()}
      {processChilds()}
    </Box>
  );
};

export { InfoCourseCategoryItem };
