import { Delete, Save } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";
import { MyFileField } from "components/form/MyFileField";

import { PostGalleryForCourseDto } from "dto/system/gallery.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useRef } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: PostGalleryForCourseDto;
  onSubmit: (obj: PostGalleryForCourseDto) => void;
  loading: boolean;
  onDelete: () => void;
  canDelete: boolean;
  canEdit: boolean;
};

const FormCourseGallery: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  onDelete,
  canDelete,
  canEdit,
}) => {
  const { LL } = useResource();
  const [obj, disabled, , setObject] = useForm<PostGalleryForCourseDto>(
    defaultObj,
    RequiredValidator.getValidators(["idparent"])
  );
  const inputRef = useRef<any>(null);
  const clearInput = useCallback(() => {
    if (!obj.files && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [obj.files]);

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  useEffect(() => {
    clearInput();
  }, [clearInput]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            gap: { xs: 2, sm: 4 },
            width: "100%",
            flexDirection: { xs: "column", sm: "column" },
          }}>
          <Box sx={{ width: "100%" }}>
            <MyFileField
              sx={{ width: "100%" }}
              fullWidth
              inputRef={inputRef}
              field="files"
              label={LL("files")}
              setObj={setObject}
              obj={obj}
              size="small"
              variant="standard"
              className="form-input-custom"
              color="primary"
            />
          </Box>
          {/* <Box>
              <MyButton
                disabled={disabled || loading || !obj.files || !canEdit}
                type="submit"
              >
                <Save />
              </MyButton>
            </Box>
            {canDelete && (
              <Box>
                <MyButton cb={onDelete} disabled={!canEdit} color="secondary">
                  <Delete />
                </MyButton>
              </Box>
            )} */}

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              justifyContent: "cener",
              alignItems: "center",
            }}>
            {canDelete ? (
              <MyButton
                sx={{ width: "100%" }}
                disabled={!canEdit}
                color="error"
                className="btn"
                variant="outlined"
                size="large"
                type="button"
                cb={onDelete}>
                {LL("Bt_Delete")}
              </MyButton>
            ) : null}
            <MyButton
              disabled={disabled || loading || !obj.files || !canEdit}
              sx={{ width: "100%" }}
              color="primary"
              className="btn"
              variant="outlined"
              size="large"
              type="submit">
              {LL("Bt_Submit")}
            </MyButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseGallery };
