import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { TeacherService } from "services/user/teacher.service";

import { TeacherDto } from "dto/user/teacher.dto";

import { Types } from "tools/types/types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";

import { Button, Stack } from "@mui/material";
import { HtmlTag } from "components/form/HtmlTag";
import UserSocialBlock from "components/user/user/social/UserSocialBlock";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { CommonTools } from "tools/utils/commontools";
import {
  SkeletonTeacherProfile,
  SkeletonDescription,
} from "components/elements/Skeleton/SkeletonCourse";
import { CallbackType } from "interfaces/commontypes.interface";
import useObject from "hooks/useObject";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type Props = {
  id: string;
  showSocials?: boolean;
  setCurrentObject?: (obj: any) => void;
};

const service = new TeacherService();

const TeacherInfoBlock: React.FC<Props> = ({
  id,
  showSocials = true,
  setCurrentObject,
  ...props
}) => {
  const { LL } = useResource();
  const [expanded, setExpanded] = useState(false);
  // const [obj, setObj] = useState<TeacherDto | undefined>();
  // const [loading, setLoading] = useState<boolean>(true);
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.get(id, cb, cbParams);
  };

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const [loading, obj] = useObject<TeacherDto>(get, id, [id]);
  useEffect(() => {
    if (!obj) return;
    if (setCurrentObject !== undefined) setCurrentObject(obj);
  }, [obj]);

  // const getObject = () => {
  //   if (!id) return;
  //   setLoading(true);
  //   service.get(id, loadObject, {});
  // };

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : {};
  //     setObj(object);
  //     if (setCurrentObject !== undefined) setCurrentObject(object);
  //   }
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   if (!id) return;
  //   getObject();
  // }, [id]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  const processMyRatingReadOnly = () => {
    if (!obj) return null;
    if (
      !obj.hasOwnProperty("rating") ||
      !obj.hasOwnProperty("numberoftestimonials")
    )
      return <MyRatingReadOnly rating={0} count={0} showDetails={false} />;
    if (!obj.rating || !obj.numberoftestimonials)
      return <MyRatingReadOnly rating={0} count={0} showDetails={false} />;

    return (
      <MyRatingReadOnly
        rating={obj.rating}
        count={obj.numberoftestimonials}
        showDetails={false}
      />
    );
  };
  const processRating = () => {
    if (!obj) return null;
    let rating = 0;
    if (CommonTools.processObjectField(obj, ["rating"]))
      rating = parseFloat(
        CommonTools.processObjectField(obj, ["rating"]).toString()
      );

    return (
      <Stack direction="row" spacing={1}>
        <Typography color="gold">{CommonTools.roundToTenth(rating)}</Typography>
        {processMyRatingReadOnly()}
      </Stack>
    );
  };

  // const processAvatarName = () => {
  //     if (!obj) return CC("DefaultAvatarCredentials", "LC");
  //     if (!obj.hasOwnProperty('name')) return CC("DefaultAvatarCredentials", "LC");
  //     if (!obj.name) return CC("DefaultAvatarCredentials", "LC");
  //     if (!obj.hasOwnProperty('surname')) return obj.name.charAt(0).toUpperCase();
  //     if (!obj.surname) return obj.name.charAt(0).toUpperCase();

  //     return obj.name.charAt(0).toUpperCase() + obj.surname.charAt(0).toUpperCase()
  // }
  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("name") && obj.name) name = obj.name;
    if (obj.hasOwnProperty("surname") && obj.surname) surname = obj.surname;

    return (
      <MyAvatar
        obj={obj}
        name={name}
        surname={surname}
        className="avatar"
        sx={{ mr: 1 }}
      />
    );
  };

  const processTeacherStatus = () => {
    if (!obj) return null;
    return <TeacherTypeIcon obj={obj} />;
  };

  const processFullName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("name") && !obj.name) return null;
    if (!obj.hasOwnProperty("surname") && !obj.surname) return null;
    return (
      <React.Fragment>
        <Stack direction="row" spacing={1}>
          {processTeacherStatus()}
          <Typography variant="h3">
            {ComponentCommonTools.processObjectField(obj, "name")}
          </Typography>
          <Typography variant="h3">
            {ComponentCommonTools.processObjectField(obj, "surname")}
          </Typography>
        </Stack>
      </React.Fragment>
    );
  };

  const processOccupationAndDiscipline = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("typeoccupation") && !obj.typeoccupation) {
      return (
        <Stack direction="row" spacing={1}>
          <Typography>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typediscipline",
              "name"
            )}
          </Typography>
        </Stack>
      );
    }
    if (!obj.hasOwnProperty("typediscipline") && !obj.typediscipline) {
      return (
        <Stack direction="row" spacing={1}>
          <Typography>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typeoccupation",
              "name"
            )}
          </Typography>
        </Stack>
      );
    }
    return (
      <>
        <Stack direction="row" spacing={1} my={1} divider={<>,</>}>
          <Typography className="discipline">
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typeoccupation",
              "name"
            )}
          </Typography>
          <Typography className="discipline">
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typediscipline",
              "name"
            )}
          </Typography>
        </Stack>
      </>
    );
  };

  const processInfoColumn = () => {
    if (!obj) return null;
    return (
      <Stack direction="column">
        {processFullName()}
        {processOccupationAndDiscipline()}
        {processUserSocials()}
      </Stack>
    );
  };

  const processTestimonials = () => {
    if (!obj) return null;
    if (
      !obj.hasOwnProperty("numberoftestimonials") &&
      !obj.numberoftestimonials
    )
      return <>0</>;

    return (
      <>
        {ComponentCommonTools.processObjectField(
          obj,
          "numberoftestimonials",
          0
        )}
      </>
    );
  };
  const processNumberOfStudents = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("numberofstudents") && !obj.numberofstudents)
      return <>0</>;

    return (
      <>{ComponentCommonTools.processObjectField(obj, "numberofstudents", 0)}</>
    );
  };

  const processInfoColumnStatistic = () => {
    if (!obj) return null;
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "column",
          gap: 2,
          flexWrap: "wrap",
          width1: "100%",
          maxWidth: "500px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "column",
            flex: "1 1 30%",
            minWidth: "150px",
          }}
          className="numbers">
          {LL("numberofstudents")}
          <Typography variant="h4" mt={2}>
            {processNumberOfStudents()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "column",
            flex: "1 1 30%",
            minWidth: "150px",
          }}
          className="numbers">
          {LL("numberoftestimonials")}
          <Typography variant="h4" mt={2}>
            {processTestimonials()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "column",
            flex: "1 1 30%",
            minWidth: "150px",
          }}
          className="numbers">
          {LL("Rating")}
          <Typography variant="h4" mt={2} component={"div"}>
            {processRating()}
          </Typography>
        </Box>
      </Box>
    );
  };

  const processBio = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("bio")) return null;
    if (!obj.bio) return null;

    const bioContent = CommonTools.processObjectField(obj, ["bio"]);
    const isContentLong = bioContent.length > 1100;

    return (
      <Box>
        <Typography variant="h5" my={2}>
          {LL("About_me")}
        </Typography>
        {isContentLong ? (
          <>
            <Box className={expanded ? "" : "showMoreEffect"}>
              <Collapse in={expanded} collapsedSize={310}>
                <HtmlTag content={bioContent} />
              </Collapse>
            </Box>
            <Button
              onClick={toggleExpand}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <Typography>
                {expanded ? LL("showLess") : LL("showMore")}
              </Typography>
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Box>
            </Button>
          </>
        ) : (
          <HtmlTag content={bioContent} />
        )}
      </Box>
    );
  };
  const processUserSocials = () => {
    if (!showSocials) return null;
    if (!obj) return null;
    if (!obj.iduser) return null;
    return (
      <Box className="socialIcons">
        <UserSocialBlock
          idUser={obj.iduser}
          type={Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM}
        />
      </Box>
    );
  };

  const processItem = () => {
    if (!obj) return null;

    return (
      <Box className="teacherDetailsDown">
        <Stack direction="row" spacing={2} alignItems="center">
          {processAvatar()}
          <Box className="info">{processInfoColumn()}</Box>
        </Stack>
        <Box my={5}>{processInfoColumnStatistic()}</Box>

        {processBio()}
      </Box>
    );
  };

  return id && !loading ? (
    processItem()
  ) : (
    <React.Fragment>
      <SkeletonTeacherProfile />
      <SkeletonDescription lines={3} />
      <SkeletonDescription lines={2} />
      <SkeletonDescription lines={4} />
    </React.Fragment>
  );
};

export { TeacherInfoBlock };
