import React, { useState, useEffect, useCallback } from "react";
import { useResource } from "hooks/useResource";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/utils/commontools";
import { useMessage } from "hooks/useMessage";
import { CourseFilterService } from "services/course/coursefilter.service";
import MyTable from "components/elements/TabelTools/MyTable";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import {
  CourseFilterDto,
  CourseFilterFormDto,
} from "dto/course/coursefilter.dto";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { FormCourseFilter } from "./FormCourseFilter";

const service = new CourseFilterService();

type Props = {
  currentRoute: any;
  canEdit: boolean;
};

const EditCourseFilter: React.FC<Props> = ({ currentRoute,canEdit }) => {
  const { LL } = useResource();
  const { registerDialog } = useMessage();
  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!id) return;
    service.getList(cb, cbParams, req);
  };
  const [reqList, setReqList] = useState<RequestListDTO>(
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idcourse", [id])],
      1,
      10
    )
  );
  const [defaultObj, setDefaultObj] = useState<CourseFilterFormDto>(
    new CourseFilterFormDto()
  );
  const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, objects, total, totalPages, setObjects] =
    useList<CourseFilterDto>(getList, reqList, [reqList]);

  const getObjects = useCallback(() => {
    if (!objects) return [];
    return objects;
  }, [objects]);

  useEffect(() => {
    CourseFilterDto.setFunGetObjects(getObjects);
  }, [getObjects]);

  const handleResultDelete = (result: ResultObjectDTO, cbParams?: any) => {
    if (!result) return;
    if (result.err) return;
    if (!cbParams) return;
    const objects: CourseFilterDto[] = CourseFilterDto.handleFunGetObjects();
    const id = CommonTools.processObjectField(cbParams, ["id"]);
    if (!id) return;
    const array = objects.filter((item) => item.id !== id);
    setObjects(array);
  };

  const handleDelete = (obj: CourseFilterDto) => {
    const id = CommonTools.processObjectField(obj, ["id"]);
    if (!id) return;
    if(!canEdit) return;
    service.delete(id, handleResultDelete, { id });
  };

  const onSubmit = (obj: CourseFilterFormDto) => {
    if (!obj) return;

    registerDialog(
      LL("Confirm_edit_course"),
      LL(
        "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
      ),
      { cb: handleConfirmEdit.bind(null, obj) },
      null
    );
  };

  const handleConfirmEdit = (obj: CourseFilterFormDto) => {
    if (!id) return;
    if (!obj) return;
    obj.idcourse = id;
    setLoadingForm(true);
    service.add(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    if (!objects) return;
    const array = [...objects, result.obj];
    setObjects(array);
    setLoadingForm(false);
    setDefaultObj(new CourseFilterFormDto());
  };
  return (
    <Box sx={{ maxWidth: "600px", mx: "auto" }} mb={4}>
      <Box>
        <FormCourseFilter
          defaultObj={defaultObj}
          onSubmit={onSubmit}
          loading={loading || loadingForm}
          canEdit={canEdit}
        />
      </Box>
      <Box mt={4} mb={4}>
        <MyTable
          _columns={CourseFilterDto.getColumnsSpecial(
            LL,
            "coursefilter",
            handleDelete
          )}
          _data={objects ?? []}
          _total={total}
          _totalPage={totalPages}
          _reqList={reqList}
          setReqList={setReqList}
          loading={loading}
          currentRoute={currentRoute}
          onDelete={handleDelete}
          mainObject={"coursefilter"}
          fieldToShowOnDelete="id"
          deleteOneObject={deleteOneObject}
          setDeleteOneObject={setDeleteOneObject}
          onRowClickCb={() => {}}
        />
      </Box>
    </Box>
  );
  // const [loading, setLoading] = useState<boolean>(true);
  // const [loadingForm, setLoadingForm] = useState<boolean>(true);
  // const [obj, setObj] = useState<CourseFilterDto | undefined>(
  //   new CourseFilterDto()
  // );
  // const { registerDialog } = useMessage();
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [cookies, setCookies] = useCookies();
  // const [idFilter, setIdFilter] = useState<string>("");
  // const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  // const [rows, setRows] = useState<Array<CourseFilterDto>>(
  //   new Array<CourseFilterDto>()
  // );

  // const [total, setTotal] = useState(0);
  // const [totalPage, setTotalPage] = useState(0);

  // const [responseParams, setResponseParams] = useState<RequestListDTO>(
  //   new RequestListDTO()
  // );

  // const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  // const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
  //   new TbFilterListDTO()
  // );

  // const validateAll = () => {
  //   if (!obj) return;
  //   setLoadingForm(true);

  //   // obj.value = obj.value !== undefined ? obj.value : "";
  //   obj.idfilter = obj.idfilter !== undefined ? obj.idfilter : "";
  //   obj.idfilterdictionary =
  //     obj.idfilterdictionary !== undefined ? obj.idfilterdictionary : "";

  //   setObj(obj);
  //   setLoadingForm(false);
  // };

  // const deleteObj = (obj: any) => {
  //   setDeleteOneObject(obj);
  // };

  // const columns = [];
  // columns.push(ComponentCommonTools.columns_GetCheck());
  // columns.push(
  //   ComponentCommonTools.columns_StandardObjectColumn(LL, "filter", "name")
  // );
  // columns.push(
  //   ComponentCommonTools.columns_StandardObjectColumn(
  //     LL,
  //     "filterdictionary",
  //     "name"
  //   )
  // );
  // //   columns.push(ComponentCommonTools.columns_StandardColumn(LL, "value"));
  // columns.push(
  //   ComponentCommonTools.columns_ActionsColumn(LL, "course", deleteObj, [
  //     "delete",
  //   ])
  // );

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (!obj) return;

  //   if (id !== undefined) {
  //     registerDialog(
  //       LL("Confirm_edit_course"),
  //       LL(
  //         "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
  //       ),
  //       { cb: handleConfirmEdit },
  //       null
  //     );
  //   }
  // };

  // const handleConfirmEdit = () => {
  //   if (obj !== undefined && id !== undefined) {
  //     obj.idcourse = id;
  //     service.add(handleCallbackConfirmEdit, {}, obj);
  //     setIdFilter("");
  //   }
  // };

  // const handleCallbackConfirmEdit = (result: any) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     setLoading(true);
  //     setLoadingForm(true);
  //     setObj(new CourseFilterDto());
  //     getList();
  //   }
  // };

  // useEffect(() => {
  //   validateAll();
  // }, [obj]);

  // const loadObjects = (data: ResultListDTO) => {
  //   CommonTools.processListLoadObjects(
  //     data,
  //     setData,
  //     setRows,
  //     setTotal,
  //     setTotalPage,
  //     setResponseParams
  //   );
  // };

  // const getList = () => {
  //   if (id !== undefined) service.getList(loadObjects, {}, reqList);
  // };

  // const getReqList = () => {
  //   let reqListLocal = RouteTools.prepareListRequest(currentRoute, cookies);
  //   reqListLocal = service.getDefaultRequestList(reqListLocal);
  //   const filters = new Array<RequestFilterDTO>();
  //   const filter = new RequestFilterDTO();
  //   filter.field = "idcourse";
  //   if (id !== undefined) filter.values = [id];
  //   else filter.values = [];
  //   filters.push(filter);
  //   reqListLocal.filters = filters;
  //   reqListLocal.criteria = undefined;
  //   reqListLocal.sortcriteria = undefined;
  //   reqListLocal.range = undefined;
  //   reqListLocal.onpage = parseInt(
  //     CC("DEFAULT_ONPAGE_Edit_Coursefilter", "10")
  //   );

  //   setReqList(reqListLocal);
  // };

  // const checkIsLoading = () => {
  //   const load: boolean = CommonTools.checkIsLoading(
  //     reqList,
  //     responseParams,
  //     data,
  //     total,
  //     totalPage,
  //     rows
  //   );
  //   validateAll();
  //   setLoading(load);
  // };

  // const processTbFilterList = () => {
  //   const _tobj = new TbFilterListDTO();

  //   var t: TbFilterDTO;

  //   t = new TbFilterDTO("search", FilterTools.fiterTypeText);
  //   _tobj.addFilter(t);

  //   setTbFilterList(_tobj);
  // };

  // useEffect(() => {
  //   processTbFilterList();
  //   validateAll();
  // }, []);

  // useEffect(() => {
  //   getReqList();
  // }, [currentRoute]);

  // useEffect(() => {
  //   if (CommonTools.atLeastOneFieldDefined(reqList)) {
  //     setLoading(true);
  //     getList();
  //   }
  // }, [reqList]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [data, rows, reqList, total, totalPage]);

  // const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);

  // const handleDelete = (item: any) => {
  //   service.delete(item.id, getList, undefined);
  // };

  // const processForm = () => {
  //   if (!obj) return <></>;
  //   if (loadingForm) return <Skeleton variant="rectangular" height={200} />;
  //   return (
  //     <form onSubmit={handleSubmit}>
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("Select filter")}
  //         </Typography>
  //         <CourseFilterBlockAutocomplete
  //           setObj={setObj}
  //           obj={obj}
  //           setIdFilter={setIdFilter}
  //         />
  //       </Box>
  //       {idFilter ? (
  //         <Box sx={{ mt: 3 }}>
  //           <Typography variant="h6" mb={1}>
  //             {LL("Select dictionary")}
  //           </Typography>
  //           <CourseFilterDictionaryBlockAutocomplete
  //             setObj={setObj}
  //             obj={obj}
  //             idFilter={idFilter}
  //           />
  //         </Box>
  //       ) : null}

  //       {/* <Box sx={{ mt: 3 }}>
  //           <Typography variant="h6" mb={1}>{LL('Value filter')}</Typography>
  //               <MyTextField
  //                   tabIndex={2}
  //                   fullWidth
  //                   id="value"
  //                   name="value"
  //                   variant="standard"
  //                   className="form-input"
  //                   color="primary"
  //                   _label={LL("value")}
  //                   size="small"
  //                   value={obj.value}
  //                   setObj={setObjField}
  //                   _vresults={false}
  //               />
  //           </Box> */}
  //       <Box textAlign="center" mt={4}>
  //         <MyButton
  //           tabIndex={1}
  //           disabled={isFormDisabled}
  //           color="primary"
  //           variant="contained"
  //           size="large"
  //           className="btn"
  //           type="submit"
  //           children={LL("Bt_Submit")}
  //         />
  //       </Box>
  //     </form>
  //   );
  // };
  // return obj ? (
  //   <>
  //     <Box>{processForm()}</Box>
  //     <Box mt={4}>
  //       <MyTable
  //         _columns={columns}
  //         _data={rows}
  //         _total={total}
  //         _totalPage={totalPage}
  //         _reqList={reqList}
  //         _tbFilterList={tbFilterList}
  //         setReqList={setReqList}
  //         loading={loading}
  //         currentRoute={currentRoute}
  //         onDelete={handleDelete}
  //         mainObject={"course"}
  //         fieldToShowOnDelete="id"
  //         deleteOneObject={deleteOneObject}
  //         setDeleteOneObject={setDeleteOneObject}
  //         onRowClickCb={() => {}}
  //       />
  //     </Box>
  //   </>
  // ) : (
  //   <CircularLoading />
  // );
};

export default EditCourseFilter;
