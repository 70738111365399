import {
  ChatMessageDto,
  ChatMessageGroupDto,
} from "dto/course/chatmessage.dto";
import React from "react";
import { ChatMessageItem } from "./ChatMessageItem";
import { useAuth } from "hooks/useAuth";
import { CommonTools } from "tools/utils/commontools";
import useObject from "hooks/useObject";
import { UserSettingsService } from "services/user/usersettings.service";
import { CallbackType } from "interfaces/commontypes.interface";
import UserSettingsDto from "dto/user/usersettings.dto";
import { Box, Skeleton } from "@mui/material";
import { ChatMessageItemAvatarContent } from "./ChatMessageItemAvatar";

type Props = {
  objects: ChatMessageGroupDto[];
};

const ChatMessageGroup: React.FC<Props> = ({ objects }) => {
  const processItem = (object: ChatMessageGroupDto, index: number) => {
    if (!object) return null;
    if (object.isGroup) {
      return (
        <React.Fragment key={index}>
          <ChatMessageGroupItem object={object} />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={index}>
        {object.messages.map((item, index) => (
          <ChatMessageItem key={index} object={item} displayAvatar />
        ))}
      </React.Fragment>
    );
  };
  if (!objects) return null;
  return (
    <React.Fragment>
      {objects.map((object, index) => processItem(object, index))}
    </React.Fragment>
  );
};

type ItemProps = {
  object: ChatMessageGroupDto;
};

const ChatMessageGroupItem: React.FC<ItemProps> = ({ object }) => {
  const { user } = useAuth();

  const processItemOwn = (item: ChatMessageDto, index: number) => {
    if (!item) return null;
    const isLast = index === object.messages.length - 1;
    return <ChatMessageItem key={index} object={item} displayAvatar={isLast} />;
  };
  const idUser = CommonTools.processObjectField(user, ["id"]);
  if (!user) return null;
  if (!object) return null;
  if (idUser === CommonTools.processObjectField(object, ["iduser"])) {
    return (
      <React.Fragment>
        {object.messages.map((item, index) => processItemOwn(item, index))}
      </React.Fragment>
    );
  }
  return <ChatMessageGroupItemStranger object={object} />;
};
const service = new UserSettingsService();
const ChatMessageGroupItemStranger: React.FC<ItemProps> = ({ object }) => {
  const idUser = CommonTools.processObjectField(object, ["iduser"]);

  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getByUserId(id, cb, cbParams);
  };

  const [loading, userSettings] = useObject<UserSettingsDto>(
    get,
    idUser,
    [idUser],
    true,
    `avatar_chat_user_${idUser}`
  );

  const processItem = (item: ChatMessageDto, index: number) => {
    if (!item) return null;

    const isLast = index === object.messages.length - 1;
    return (
      <ChatMessageItem
        key={index}
        object={item}
        displayAvatar={isLast}
        isLast={isLast}
        customComponentAvatar={
          userSettings ? (
            <ChatMessageItemAvatarContent userSettings={userSettings} />
          ) : null
        }
      />
    );
  };

  if (!object) return null;
  if (!idUser) return null;
  return (
    <Box>
      <Box sx={{ marginLeft: 8 }}>
        {userSettings ? UserSettingsDto.prepareFullName(userSettings) : null}
        {loading ? <Skeleton /> : null}
      </Box>
      <Box>
        {object.messages.map((item, index) => processItem(item, index))}
      </Box>
    </Box>
  );
};

export { ChatMessageGroup };
