import { useContext } from "react";

import { UserContext } from "providers/UserProvider";
import { Status } from "tools/types/status";
import { useMessage } from "./useMessage";
import { CommonTools } from "tools/utils/commontools";

import { MessageTypes } from "tools/types/messagetypes";

export function useAuth() {
  const { RegisterMessage } = useMessage();

  const state = useContext(UserContext);
  const { user } = state;
  const needConfirmEmail =
    user && user.statusemail === Status.INACTIVE ? true : false;

  const registerMessageNeedAuth = (LL: (identifier: string) => string) => {
    if (user) return;
    RegisterMessage(
      CommonTools.createMessage(
        LL("to_perform_this_action_you_need_to_be_logged_in"),
        MessageTypes.MESSAGE_ERROR,
        "401"
      )
    );
  };
  return { ...state, needConfirmEmail, registerMessageNeedAuth };
}
