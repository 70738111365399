import { RegisterDto } from "dto/auth/registeruser.dto";
import ResultSignInDTO from "dto/auth/resultsignin.dto";
import SignInDto from "dto/auth/signin.dto";
import SocialSignInDto from "dto/auth/socialsignin.dto";
import { JWTDto, TokenDto } from "dto/system/jwt.dto";
import { StudentDto } from "dto/user/student.dto";
import { TeacherDto } from "dto/user/teacher.dto";
import UserDto from "dto/user/user.dto";
import UserSettingsDto from "dto/user/usersettings.dto";
import IProvider from "interfaces/provider.interface";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserService } from "services/user/user.service";
import { UserSettingsService } from "services/user/usersettings.service";
import { Types } from "tools/types/types";
import { JWTContext } from "./JWTProvider";
import { CommonTools, isTokenExpired } from "tools/utils/commontools";
import { LocalStorageApi } from "api/localstorage.api";
import { RouteTools } from "tools/utils/routetools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Loading } from "components/elements/loading/Loading";
import { Status } from "tools/types/status";
import { useCookies } from "react-cookie";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";


type Props = {
  user: UserDto | null;
  roles: string[];
  usersettings: UserSettingsDto | null;
  signIn: (signInDto: SignInDto, r?: boolean) => void;
  signInSocial: (socialSignInDto: SocialSignInDto) => void;
  updateUserSettings: (field: string, value: any) => void;
  signUp: (signUpDto: RegisterDto) => void;
  registerTeacher: (obj: any) => void;
  setUserInterface: (interfaceType: number) => void;
  userInterface: number;
  teacher: TeacherDto | null;
  student: StudentDto | null;
  setNextLocation: (location: any) => void;
  setStudent: (student: any) => void;
  logout: () => void;
  handleResultAuth: (result: ResultSignInDTO, cbParams: any) => void;
  setLoadingAction : (loading: boolean) => void;
};

export const UserContext = createContext<Props>({
  user: null,
  roles: [],
  usersettings: null,
  signIn: () => {},
  signInSocial: () => {},
  updateUserSettings: () => {},
  signUp: () => {},
  registerTeacher: () => {},
  setUserInterface: () => {},
  userInterface: Types.STUDENT_INTERFACE,
  teacher: null,
  student: null,
  setNextLocation: () => {},
  setStudent: () => {},
  logout: () => {},
  handleResultAuth: () => {},
  setLoadingAction: () => {},
});

const service = new UserService();
const userSettingsService = new UserSettingsService();

export const UserProvider: React.FC<IProvider> = ({ children }) => {
  const { token, processToken } = useContext(JWTContext);
  const [cookies, setCookie] = useCookies();
  const [user, setUser] = useState<UserDto | null>(processUser(token));
  const [roles, setRoles] = useState<string[]>(processRoles(token));
  const [usersettings, setUserSettings] = useState<UserSettingsDto | null>(
    processUserSettings(token)
  );
  const [teacher, setTeacher] = useState<TeacherDto | null>(
    processTeacher(token)
  );
  const [student, setStudent] = useState<StudentDto | null>(
    processStudent(token)
  );
  const [rememberToken, setRememberToken] = useState<string>(
    getLocalRememberToken()
  );
  const [loadingAction, setLoadingAction] = useState(false);

  const [userInterface, setUserInterface] = useState(Types.STUDENT_INTERFACE);

  const [nextLocation, setNextLocation] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [mustChangePassword, setMustChangePassword] = useState(
    processMustChangePassword(token)
  );
  const [params, setParams] = useState<any>({});
  const updateUserSettings = (field: string, value: any) => {
    if (!usersettings) return;
    const t = usersettings;
    (t as any)[field] = value;
    if (!t.id) return;
    userSettingsService.update(t.id, undefined, {}, t);
    setUserSettings(t);
  };

  const processRememberToken = (token: string) => {
    if (!token) return;
    LocalStorageApi.saveValue("remember_token", token);
    setRememberToken(token);
  };

  const signInRememberToken = useCallback(() => {
    if (!rememberToken) return;
    if (user) return;
    if (isTokenExpired(rememberToken)) {
      clearState();
      return;
    }
    setLoading(true);
    service.signInHash(
      {
        hash: rememberToken,
      },
      handleResultAuth,
      { remember: true, signInHash: true }
    );
  }, [rememberToken, user]);

  useEffect(() => {
    signInRememberToken();
  }, [signInRememberToken]);

  const handleMustChangePassword = useCallback(() => {
    const init =
      cookies &&
      cookies.password_was_checked != undefined &&
      cookies.password_was_checked != "undefined" &&
      cookies.password_was_checked != "null"
        ? cookies.password_was_checked
        : false;

    if (init) return;
    if (mustChangePassword) {
      if (!user) return;
      if (user.statuspassword === Status.TEMPORARY) {
        RouteTools.setHistory("/changetemporarypassword", {});
      }
    }
    setCookie("password_was_checked", true, { path: "/" });
  }, [user, mustChangePassword]);

  useEffect(() => {
    handleMustChangePassword();
  }, [handleMustChangePassword]);

  const getUserInterface = () => {
    return userInterface;
  };

  useEffect(() => {
    RouteTools.setUserInterface(getUserInterface);
    RouteTools.setUserInterfaceFunction(setUserInterface);
  }, [userInterface]);

  const handleResultAuth = (result: ResultSignInDTO, cbParams: any) => {
    
    if (!result) {
      setLoadingAction(false);
      return;
    }
    if (result.err) {
      setLoadingAction(false);
      return;
    }
    let token = "";
    if (CommonTools.processObjectField(result, ["obj", "jwttoken", "token"]))
      token = CommonTools.processObjectField(result, [
        "obj",
        "jwttoken",
        "token",
      ]);
    else
      token = CommonTools.processObjectField(result, [
        "jwttoken",
        "token",
      ]);

    
    if (!token) return;

    const userInterface = getUserInterface();
    if (userInterface === Types.TEACHER_INTERFACE && teacher) {
      setUserInterface(Types.TEACHER_INTERFACE);
    } else {
      setUserInterface(Types.STUDENT_INTERFACE);
    }
    // setUser(processUser(token));
    // setRoles(processRoles(token));
    // setUserSettings(processUserSettings(token));
    // setTeacher(processTeacher(token));
    // setStudent(processStudent(token));
    parseStateToken(token);

    setMustChangePassword(processMustChangePassword(token));
    processToken(token);
    if (cbParams && cbParams.remember) {
      const rememberToken = CommonTools.processObjectField(result, [
        "remembertoken",
      ]);
      processRememberToken(rememberToken);
    }
    if (cbParams && cbParams.goToTeacher) {
      setParams(cbParams);
    }
    if (cbParams && cbParams.defaultRoute) {
      if (nextLocation) {
        const url = nextLocation;
        setNextLocation(false);
        RouteTools.setHistory(url, {});
      } else {
        RouteTools.setHistory("/", {});
        setParams({ redirectToSavedInterface: true });
      }
    }

    if (cbParams && cbParams.settingsRoute) {
      RouteTools.setHistory("/", {});
      setParams(cbParams);
    }

    if (cbParams && cbParams.signInHash) {
      setLoading(false);
    }

    if (cbParams && cbParams.cbFunction) {
      cbParams.cbFunction();
    }
    setLoadingAction(false);
  };

  const parseStateToken = (token: string) => {
    setUser(processUser(token));
    setRoles(processRoles(token));
    setUserSettings(processUserSettings(token));
    setTeacher(processTeacher(token));
    setStudent(processStudent(token));
    setMustChangePassword(processMustChangePassword(token));
  }
  const processStateToken = useCallback(() => {
    if(!token) return;
    parseStateToken(token);
  }, [token]);

  useEffect(() => {
    processStateToken();
  }, [processStateToken]);
  
  const handleNavigateToProfileOnRegister = useCallback(() => {
    if (!user) return;
    if (params && params.settingsRoute) {
      if (params.teacherInterface) {
        RouteTools.setHistory("/teacherinterface/editprofile", {});
      } else {
        RouteTools.setHistory("/profile/edit", {});
      }
      setParams({});
    }
  }, [params, user]);

  const handleNavigateToTeacherInterface = useCallback(() => {
    if (params && params.goToTeacher) {
      if (teacher) {
        RouteTools.setHistory("/teacherinterface", {});
        setParams({});
      }
    }
  }, [teacher, params]);

  const handelNavigateToSavedInterface = useCallback(() => {
    if (params && params.redirectToSavedInterface && user) {
      const interfaceType = getUserInterfaceLocal();
      if (interfaceType === Types.TEACHER_INTERFACE && teacher) {
        setUserInterface(Types.TEACHER_INTERFACE);
        RouteTools.setHistory("/teacherinterface", {});
      } else {
        RouteTools.setHistory("/", {});
      }
    }
  }, [params, user, teacher]);

  useEffect(() => {
    handelNavigateToSavedInterface();
  }, [handelNavigateToSavedInterface]);

  useEffect(() => {
    handleNavigateToProfileOnRegister();
  }, [handleNavigateToProfileOnRegister]);

  useEffect(() => {
    handleNavigateToTeacherInterface();
  }, [handleNavigateToTeacherInterface]);

  const signInSocial = (socialSignInDto: SocialSignInDto) => {
    setUserInterface(Types.STUDENT_INTERFACE);
    setLoadingAction(true);
    service.signInSocial(socialSignInDto, handleResultAuth, {
      defaultRoute: true,
      remember: true,
    });
  };
  const signIn = (signInDto: SignInDto, r?: boolean) => {
    r = r !== undefined ? r : false;
    const cbp: any = {};
    cbp.remember = r;
    cbp.defaultRoute = true;
    setUserInterface(Types.STUDENT_INTERFACE);
    setLoadingAction(true);
    service.signIn(signInDto, handleResultAuth, cbp);
  };

  const signUp = (signUpDto: RegisterDto) => {
    if (userInterface !== Types.TEACHER_INTERFACE)
      setUserInterface(Types.STUDENT_INTERFACE);
    setLoadingAction(true);

    service.registerNewUser(signUpDto, handleResultAuth, {
      settingsRoute: true,
      teacherInterface: signUpDto.typeaccount === Types.TYPE_ACCOUNT_TEACHER,
    });
  };

  const registerTeacher = (obj: any) => {
    setLoadingAction(true);
    service.registerTeacher(obj, handleResultAuth, { goToTeacher: true });
  };

  const logout = () => {
    setLoadingAction(true);
    service.logout(handleLogout, {});
  };
  const handleLogout = (result: ResultObjectDTO<JWTDto>) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    LocalStorageApi.saveValue("user_interface", userInterface.toString());
    const token = CommonTools.processObjectField(result, [
      "obj",
      "jwttoken",
      "token",
    ]);

    processToken(token);
    RouteTools.setHistory("/", {});
    clearState();
    setLoadingAction(false);
  };

  const clearState = () => {
    setUser(null);
    setRoles([]);
    setUserSettings(null);
    setTeacher(null);
    setStudent(null);
    setRememberToken("");
    LocalStorageApi.saveValue("remember_token", "");
  };

  const value = {
    user,
    roles,
    usersettings,
    teacher,
    student,
    setUserInterface,
    userInterface,
    setNextLocation,
    setStudent,
    updateUserSettings,
    signIn,
    signInSocial,
    signUp,
    registerTeacher,
    logout,
    handleResultAuth,
    setLoadingAction
  };

  

  if (loading) return <Loading />;
  return (
    <UserContext.Provider value={value}>
      {children}
      <MyBackdrop open={loadingAction} />
    </UserContext.Provider>
  );
};

const processTokenData = (token: string | null): ResultSignInDTO | null => {
  if (!token) return null;
  return TokenDto.getResultSignInDTO(token);
};

const processUser = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return null;
  if (!data.obj) return null;
  return data.obj;
};

const processRoles = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return [];
  return data.roles ?? [];
};

const processUserSettings = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return null;
  return data.usersettings ?? null;
};

const processTeacher = (token: string | null) => {
  
  const data = processTokenData(token);
  
  if (!data) return null;
  return data.teacher ?? null;
};

const processStudent = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return null;
  return data.student ?? null;
};

const getLocalRememberToken = () => {
  const token = LocalStorageApi.getValue("remember_token");
  if (!token) return "";
  return token;
};

const processMustChangePassword = (token: string | null) => {
  const data = processTokenData(token);
  if (!data) return false;
  return data.mustchangepassword ?? false;
};

const getUserInterfaceLocal = (): number | null => {
  const userInterface = LocalStorageApi.getValue("user_interface");
  if (!userInterface) return null;
  return parseInt(userInterface);
};
