import React, { useContext, useState } from "react";

import { useResource } from "hooks/useResource";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import MyTextField from "components/form/mytextfield";
import { Box, IconButton, InputAdornment } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { CourseSearchContext } from "context/coursesearch.context";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import SearchIcon from "@mui/icons-material/Search";

type SearchDialogProps = {
  open: boolean;
  setOpen: any;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SearchDialog: React.FC<SearchDialogProps> = ({
  open,
  setOpen,
  ...props
}) => {
  const { LL } = useResource();
  const {
    actions: { navToSearch },
  } = useContext(CourseSearchContext);
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!search) return;
    handleClose();
    navToSearch(search);
    setSearch("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    // <Dialog
    //   open={open}
    //   onClose={handleClose}
    //   TransitionComponent={Transition}
    //   fullWidth
    //   sx={{
    //     "& .MuiDialog-paper": {
    //       position: "absolute",
    //       top: "3%",
    //     },
    //   }}>
    //   <DialogTitle>{LL("search in site")}</DialogTitle>
    //   <IconButton
    //     sx={{
    //       position: "absolute",
    //       right: 8,
    //       top: 8,
    //     }}
    //     onClick={handleClose}>
    //     <CloseIcon />
    //   </IconButton>
    //   <DialogContent dividers>
    //     <form onSubmit={handleSubmit}>
    //       <MyTextField
    //         fullWidth
    //         id="search"
    //         name="search"
    //         size="small"
    //         variant="outlined"
    //         _label={LL("Type something for search")}
    //         value={search}
    //         autoFocus
    //         setObj={(field: any, value: any) => {
    //           setSearch(value);
    //         }}
    //         _vresults={undefined}
    //       />
    //     </form>
    //   </DialogContent>
    // </Dialog>

    <Dialog
      className="searchDialog"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="search-dialog-title"
      aria-describedby="search-dialog-description">
      <Box className="searchDialogBox">
        <Box className="searchDialogBoxInside">
          <form onSubmit={handleSubmit}>
            <MyTextField
              className="searchField"
              fullWidth
              id="search"
              name="search"
              size="small"
              variant="outlined"
              _label={search ? "" : LL("Type something for search")}
              value={search}
              autoFocus
              setObj={(field: any, value: any) => {
                setSearch(value);
              }}
              _vresults={undefined}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      aria-label="search"
                      onClick={handleSubmit}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: false,
                disableAnimation: false,
                focused: false,
              }}
            />
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};

export { SearchDialog };
