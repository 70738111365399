import { Skeleton } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { GeneralRatingDto, LikeDislike } from "dto/system/generalrating.dto";
import useObject from "hooks/useObject";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useState } from "react";
import { logger } from "tools/utils/logger";
import { LikeDislikeStaticForm } from "./LikeDislikeStaticForm";

type Props = {
  like: (id: string, idUser: string, cb: any) => void;
  dislike: (id: string, idUser: string, cb: any) => void;
  getRating: (
    id: string,
    idUser: string,
    cb?: CallbackType,
    cbparameters?: any
  ) => void;

  idUser: string;
  id: string;
  statistic: LikeDislike;
};

const LikeDislikeForm: React.FC<Props> = ({
  like,
  dislike,
  getRating,
  id,
  idUser,
  statistic,
}) => {
  const get = (id: string, cb?: CallbackType, cbparameters?: any) => {
    if (!id) return;
    if (!idUser) return;
    getRating(id, idUser, cb, cbparameters);
  };

  const [loading, object, setObject, setLoading] = useObject<GeneralRatingDto>(
    get,
    id,
    [id, idUser]
  );
  const [likeValue, setLike] = useState(0);
  const [dislikeValue, setDislike] = useState(0);

  const processButtonDisabledLike = () => {
    if (!object) return true;
    if (object.rating === 1) return true;
    return false;
  };

  const processButtonDisabledDislike = () => {
    if (!object) return true;
    if (object.rating === -1) return true;
    return false;
  };

  const handleAction = (result: ResultObjectDTO) => {
    setLoading(false);
    if (!result) return;
    if (result.err) return;
    const ratingObj: GeneralRatingDto = result.obj ?? {};
    const rating = ratingObj.rating ?? 0;
    const obj = new GeneralRatingDto();
    obj.rating = rating;
    logger("Render rating", object);
    if (rating === 1) {
      if (object?.rating === 0) {
        setLike((prev) => prev + 1);
        setDislike(0);
      }
      if (object?.rating === -1) {
        setLike((prev) => prev + 1);
        setDislike((prev) => prev - 1);
      }
    } else if (rating === -1) {
      if (object?.rating === 0) {
        setLike(0);
        setDislike((prev) => prev + 1);
      }
      if (object?.rating === 1) {
        setLike((prev) => prev - 1);
        setDislike((prev) => prev + 1);
      }
    }
    setObject(obj);
  };

  const pressLike = () => {
    if (!idUser || !id) return;
    setLoading(true);
    like(id, idUser, handleAction);
  };

  const pressDislike = () => {
    if (!idUser || !id) return;
    setLoading(true);
    dislike(id, idUser, handleAction);
  };
  if (loading) return <Skeleton />;
  if (!object) return null;
  return (
    <LikeDislikeStaticForm
      likes={statistic.likes + likeValue}
      dislikes={statistic.dislikes + dislikeValue}
      pressLike={pressLike}
      pressDislike={pressDislike}
      disabledLike={loading || processButtonDisabledLike()}
      disabledDislike={loading || processButtonDisabledDislike()}
    />
  );
};

export { LikeDislikeForm };
