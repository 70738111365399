import { Box, Typography } from "@mui/material";

import { useAuth } from "hooks/useAuth";
import { useResource } from "hooks/useResource";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { FormTeacherAttachment } from "./FormTeacherAttachment";
import { TeacherMediaFilesDto } from "dto/user/teacher.dto";
import { FileDto } from "dto/system/file.dto";
import FilePresentIcon from "@mui/icons-material/FilePresent";

type Props = {
  attachment: FileDto | null;
  label: string;
  onSubmit: (obj: any) => void;
  onDelete: () => void;
  loading: boolean;
  messageTitle: string;
  messageContent: string;
};

const TeacherAttachment: React.FC<Props> = ({
  attachment,
  label,
  onSubmit,
  onDelete,
  loading,
  messageTitle,
  messageContent,
}) => {
  const { teacher } = useAuth();
  const { LL } = useResource();
  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);
  const urlFile = CommonTools.processFileUrl(attachment);

  if (!idTeacher) return null;
  return (
    <Box className="videoTeacher">
      <Box sx={{ flexGrow: 1, minWidth: "218px" }}>
        <Box sx={{ mb: 1, display: { xs: "none", sm: "block" } }}>
          <Typography variant="h6">{label}</Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormTeacherAttachment
            onDelete={onDelete}
            onSubmit={onSubmit}
            loading={loading}
            defaultObject={new TeacherMediaFilesDto(idTeacher)}
            canDelete={attachment !== null}
            messageTitle={messageTitle}
            messageContent={messageContent}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 1, pl: { sm: "14px" } }}>
        {urlFile ? (
          <a
            href={urlFile}
            target="_blank"
            rel="noreferrer"
            className="noUnderline">
            <Box className="fileLinkBox">
              <Box className="fileLinkBoxEmpty">
                <FilePresentIcon />
                {/* {CommonTools.processObjectField(attachment, ["name"])
                  ? CommonTools.processObjectField(attachment, ["name"])
                  : LL("file")} */}
                {LL("previewUploadedFile")}
              </Box>
            </Box>
          </a>
        ) : (
          <Box className="fileLinkBox">
            <Box className="fileLinkBoxEmpty">
              <FilePresentIcon />
              {LL("no_file")}
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, mb: 1 }}>
        <Typography variant="h6">{label}</Typography>
      </Box>
    </Box>
  );
};

export { TeacherAttachment };
