import { Box, List, Skeleton } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";
import React from "react";
import { ChatParticipantService } from "services/course/chatparticipant.service";
import { ChatParticipantItem } from "./ChatParticipantItem";
import { ChatParticipantDto } from "dto/course/chatparticipant.dto";
import { useResource } from "hooks/useResource";

type Props = {
  idChat: string;
};
const service = new ChatParticipantService();
const ChatParticipantList: React.FC<Props> = ({ idChat }) => {
  const { LL } = useResource();
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idChat) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<ChatParticipantDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idchat", [idChat])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("sortfield", true)]
    ),
    [idChat]
  );

  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Box sx={{ mt: 2 }}>
      <Box>
        <Box>{LL("Participants")}</Box>
      </Box>
      <List>
        {objects.map((object, index) => (
          <ChatParticipantItem key={index} object={object} />
        ))}
      </List>
    </Box>
  );
};

export { ChatParticipantList };
