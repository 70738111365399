import React from "react";
import { Box, Typography } from "@mui/material";
import { CourseCategoryMultiAutocomplete } from "components/elements/Autocomplete/CourseCategoryMultiAutocomplete";
import { MyButton } from "components/elements/button/MyButton";
import { CourseTypeSelect } from "components/elements/Select/CourseTypeSelect";
import MyTextField from "components/form/mytextfield";
import { CreateCourseFirstStepDto } from "dto/course/course.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import RequiredValidator from "validators/required.validator";
import { AutocompleteCourseCategory } from "../coursecategory/AutocompleteCourseCategory";

type Props = {
  defaultObject: CreateCourseFirstStepDto;
  onSubmit: (obj: CreateCourseFirstStepDto) => void;
  loading: boolean;
};

const FormCreateCourseFirstStep: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vResult] =
    useForm<CreateCourseFirstStepDto>(
      defaultObject,
      RequiredValidator.getValidators([
        "name",
        "idtypecourse",
        "idteacher",
        "idcoursecategory",
      ])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Numele cursului")}
          </Typography>
          <Box className="box-form-input-icon-end">
            <MyTextField
              tabIndex={0}
              fullWidth
              id="name"
              name="name"
              variant="standard"
              className="form-input"
              color="primary"
              _label={LL("Name")}
              size="small"
              value={obj.name}
              setObj={setObjField}
              _vresults={vResult}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Select course category")}
          </Typography>
          <AutocompleteCourseCategory
            setObjField={setObjField}
            value={obj.idcoursecategory}
          />
        </Box>
        <Box sx={{ mt: 3, ".MuiFormLabel-root": { display: "none" } }}>
          <Typography variant="h6" mb={1}>
            {LL("Tip curs")}
          </Typography>
          <CourseTypeSelect
            value={obj.idtypecourse}
            setObjectField={setObjField}
            label=""
          />
        </Box>
        <Box mt={4} textAlign="center">
          <MyButton
            disabled={disabled || loading}
            color="primary"
            className="btn"
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCreateCourseFirstStep };
