import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { useAuth } from "hooks/useAuth";
import { Divider } from "@mui/material";
import EditUserSettingsInfo from "components/user/user/user/EditUserSettingsInfo";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "components/elements/Tabs/TabPanel";

import { RouteTools } from "tools/utils/routetools";

import { CommonTools } from "tools/utils/commontools";

import { ChangePasswordForm } from "components/auth/ChangePasswordForm";

import AddUserPhone from "components/user/user/phone/AddUserPhone";
import AddUserAddress from "components/user/user/address/AddUserAddress";

import { UserEditTeacher } from "components/user/teacher/UserEditTeacher";

import { EditTeacherPrivacy } from "components/user/teacher/EditTeacherPrivacy";
import { EditTeacherNotification } from "components/user/teacher/EditTeacherNotification";
import { EditTeacherMedia } from "components/user/teacher/EditTeacherMedia";
import { EditSocialUser } from "components/static/social/EditSocialUser";
import { EditTeacherContract } from "../teacher/EditTeacherContract";

const EditTeacherProfile: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const { user } = useAuth();
  // const obj: UserDto | undefined = user ? user : undefined;
  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<UserDto | undefined>(user);
  // const _id = user !== undefined ? user.id : "";
  // const [id, setId] = useState<string>(_id);

  // useEffect(() => {
  //   // logger("user", currentRoute)
  //   if (user !== undefined) {
  //     setId(user.id);
  //     setLoading(false);
  //   }
  // }, [user]);

  const [value, setValue] = React.useState(
    CommonTools.getAnchor(currentRoute, "generalInfo")
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    RouteTools.setAnchor(newValue);
  };
  if (!user) return null;
  return (
    <>
      <Box className="container">
        <Typography variant="h3" py={3} textAlign="center">
          {LL("Edit_personal_cabinet")}
        </Typography>
      </Box>
      <Divider></Divider>
      <Box sx={{ flexGrow: 1 }} className="container" mt={2}>
        <Grid container spacing={2}>
          <Grid item display="flex" justifyContent="center">
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              orientation="horizontal"
              scrollButtons
              sx={{ maxWidth: "calc(100vw - 32px)", paddingLeft: 0 }}
              allowScrollButtonsMobile
              className="tabVertical profileTeacher"
              // centered
            >
              <Tab
                className="tabsCourse"
                label={LL("General_Info")}
                value={"generalInfo"}
              />
              <Tab
                className="tabsCourse"
                label={LL("Change_Password")}
                value={"changePassword"}
              />
              <Tab
                className="tabsCourse"
                label={LL("User_Social")}
                value={"socialLink"}
              />
              <Tab
                className="tabsCourse"
                label={LL("User_Phone")}
                value={"phone"}
              />
              <Tab
                className="tabsCourse"
                label={LL("User_Address")}
                value={"address"}
              />
              <Tab
                className="tabsCourse"
                label={LL("Teacher_Info")}
                value={"teacherInfo"}
              />
              <Tab
                className="tabsCourse"
                label={LL("Teacher_Privacy")}
                value={"teacherPrivacy"}
              />
              <Tab
                label={LL("Teacher_Notification")}
                value={"teacherNotification"}
              />
              <Tab
                className="tabsCourse"
                label={LL("Teacher_Media")}
                value={"teacherMedia"}
              />
              <Tab
                className="tabsCourse"
                label={LL("Teacher_Contract")}
                value={"teacherContract"}
              />
            </Tabs>
          </Grid>
          <Grid item>
            <Box className="">
              <TabPanel value={value} index={"generalInfo"}>
                <EditUserSettingsInfo currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"changePassword"}>
                <ChangePasswordForm currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"socialLink"}>
                <EditSocialUser currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"phone"}>
                <AddUserPhone currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"address"}>
                <AddUserAddress currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"teacherInfo"}>
                <UserEditTeacher currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"teacherPrivacy"}>
                <EditTeacherPrivacy currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"teacherNotification"}>
                <EditTeacherNotification currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"teacherMedia"}>
                <EditTeacherMedia currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"teacherContract"}>
                <EditTeacherContract currentRoute={currentRoute} />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
  // : (
  //   <CircularLoading />
  // );
};

export { EditTeacherProfile };
