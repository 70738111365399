import { Box, Typography } from "@mui/material";
import { LinkCourseCategoryDto, LinkCourseCategoryFormDto } from "dto/course/linkcoursecategory.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";
import { AutocompleteCourseCategory } from "../coursecategory/AutocompleteCourseCategory";
import { MyButton } from "components/elements/button/MyButton";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";

type Props = {
  defaultObj: LinkCourseCategoryDto;
  onSubmit: (obj: LinkCourseCategoryFormDto) => void;
  loading: boolean;
  canEdit: boolean;
};

const FormOneCourseCategory: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  canEdit,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject,,,,objectWasChanged] =
    useForm<LinkCourseCategoryFormDto>(
      LinkCourseCategoryFormDto.parseFromLinkCourseCategoryForm(defaultObj),
      RequiredValidator.getValidators(["idcoursecategory"])
    );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(LinkCourseCategoryFormDto.parseFromLinkCourseCategoryForm(defaultObj));
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };
  return (
    <Box>
      <InformationObjectWasChanged objectWasChanged={objectWasChanged} />
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Select course category")}
          </Typography>
          <AutocompleteCourseCategory
            setObjField={setObjField}
            value={obj.idcoursecategory}
            defaultObject={obj.coursecategory}
          />
        </Box>
        <Box mt={4} textAlign="center">
          <MyButton
            tabIndex={1}
            disabled={disabled || loading || !canEdit}
            color="primary"
            variant="contained"
            className="btn"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormOneCourseCategory };
