// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import {CourseDto} from "dto/course/course.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {CourseRepository} from "repositories/course/course.repository";
// import GeneralService from "services/general.service";

// export default class CourseService extends GeneralService implements IService {
//   courseRepository: CourseRepository = new CourseRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.courseRepository.update(id, this.handleUpdate, data, cbparameters);
//   }

//   async publishByTeacher(
//     id: string,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.courseRepository.publishByTeacher(id, this.handleUpdate, cbparameters);
//   }

//   async updateGeneralInfo(
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.courseRepository.updateGeneralInfo(
//       this.handleUpdate,
//       cbparameters,
//       data
//     );
//   }

//   async deletedByTeacher(
//     cb?: any,
//     cbparameters?: any,
//     id?: string
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.courseRepository.deleteByTeacher(this.handleUpdate, cbparameters, id);
//   }

//   // updateGeneralInfo

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.courseRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.get(id, this.handleGet, cbparameters);
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [{ key: "status", type: "status" }]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListSearch(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListPopular(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListPopular(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListByTeacher(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListByTeacher(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListSuggestion(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListSuggestion(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListByStudent(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListByStudent(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListInTendency(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListInTendency(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListForTeacher(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListForTeacher(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListForStudent(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListForStudent(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListRecommendedHome(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListRecommendedHome(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   async getListRecommendedStudent(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.courseRepository.getListRecommendedStudent(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.courseRepository.add(this.handleGetList, cbparameters, data);
//   }

//   async addFirstStep(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.courseRepository.addFirstStep(this.handleGetList, cbparameters, data);
//   }

//   getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = "name";
//     defaultRequestList.sortcriteria = [];
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { CourseRepository } from "repositories/course/course.repository";

import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class CourseService
  extends GeneralService<CourseRepository>
  implements IService
{
  constructor() {
    super(
      new CourseRepository(),
      [
        { key: "status", type: "status" },
        { key: "createddate", type: "timestamp" },
        { key: "updateddate", type: "timestamp" },
      ],
      "name"
    );
  }
  getDefaultRequestListSelect = (onPage?: number): RequestListDTO => {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "name";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  };

  addFirstStep = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addFirstStep(
      this.handleGet,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  duplicate = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.duplicate(
      this.handleGet,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  reschedule = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.reschedule(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };


  getForChat = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getForChat(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  start = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.start(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getListSearch = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getList(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListPopular = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListPopular(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListByTeacher = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListByTeacher(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListSuggestion = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListSuggestion(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListByStudent = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListByStudent(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListInTendency = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListInTendency(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListForTeacher = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListForTeacher(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListForStudent = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListForStudent(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListRecommendedHome = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListRecommendedHome(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListRecommendedStudent = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListRecommendedStudent(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  publish = async (id: string, cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.publish(id, this.handleGet, cbparameters);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  updateGeneralInfo = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.updateGeneralInfo(
      this.handleGet,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  deletedByTeacher = async (
    cb?: any,
    cbparameters?: any,
    id?: string
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.deleteByTeacher(
      this.handleGet,
      cbparameters,
      id
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
