import { Send } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { EmojiPicker } from "components/form/EmojiPicker";
import MyTextField from "components/form/mytextfield";
import { ChatDto } from "dto/course/chat.dto";
import { ChatMessageDto } from "dto/course/chatmessage.dto";
import { useAuth } from "hooks/useAuth";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useRef, useState } from "react";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { logger } from "tools/utils/logger";
import RequiredValidator from "validators/required.validator";
import { ChatMessageVoiceRecorder } from "./ChatMessageVoiceRecorder";

type Props = {
  object: ChatDto;
  onSubmit: (obj: ChatMessageDto) => void;
};

const FormChat: React.FC<Props> = ({ object, onSubmit }) => {
  const { LL } = useResource();
  const { userInterface, teacher, user, student } = useAuth();
  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);
  const idUser = CommonTools.processObjectField(user, ["id"]);
  const idStudent = CommonTools.processObjectField(student, ["id"]);
  const defaultObject =
    userInterface === Types.TEACHER_INTERFACE
      ? ChatMessageDto.prepareObjectTeacher(
          idTeacher,
          CommonTools.processObjectField(object, ["id"]),
          idUser
        )
      : ChatMessageDto.prepareObjectStudent(
          idStudent,
          CommonTools.processObjectField(object, ["id"]),
          idUser
        );
  const [obj, disable, setObjField, setObject] = useForm(
    defaultObject,
    RequiredValidator.getValidators(["message"])
  );

  const [isMessageAudio, setIsMessageAudio] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disable) return;
    logger("FormChat", obj);
    onSubmit(obj);
    setTimeout(() => {
      setObject(defaultObject);
    }, 100);
  };

  const textFieldRef = useRef(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey && !e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      if (!disable) handleSubmit(e as any);
    } else if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      const textField = textFieldRef.current;
      if (textField) {
        const value = obj.message || "";
        const newValue = value + "\n";
        setObjField("message", newValue);
      }
    }
  };
  const handleSubmitVoice = (file: Blob) => {
    const newObj = { ...obj };
    newObj.type = Types.TYPE_CHAT_MESSAGE_AUDIO;
    newObj.files = file;
    setObject(newObj);
    setIsMessageAudio(true);
  };

  const handleDelete = () => {
    const newObj = { ...obj };
    newObj.type = Types.TYPE_CHAT_MESSAGE_TEXT;
    newObj.files = null;
    setObject(newObj);
    setIsMessageAudio(false);
  };
  const [url, setUrl] = useState<string>("");
  const submitAudio = () => {
    if (!isMessageAudio) return;
    obj.message = "";
    setUrl("");
    onSubmit(obj);
  };
  const [isRecording, setIsRecording] = useState(false);
  if (!object) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
            alignContent: "center",
          }}>
          {!isRecording && obj.type !== Types.TYPE_CHAT_MESSAGE_AUDIO && (
            <React.Fragment>
              <Box
                className="chatBoxMesssageType"
                sx={{
                  position: "relative",
                  width: "100%",
                  pl: 4,
                }}>
                <MyTextField
                  minRows={1}
                  multiline
                  tabIndex={0}
                  sx={{}}
                  fullWidth
                  id="message"
                  name="message"
                  variant="standard"
                  color="primary"
                  className="textboxChat form-input "
                  _label={LL("message")}
                  size="small"
                  value={obj.message}
                  setObj={setObjField}
                  _vresults={undefined}
                  onKeyDown={handleKeyDown}
                  inputRef={textFieldRef}
                />

                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translate(0%, -50%)",
                  }}>
                  <EmojiPicker
                    setObjectField={setObjField}
                    value={obj.message}
                    field="message"
                  />
                </Box>
              </Box>

              {/* <Box>
                <EmojiPicker
                  setObjectField={setObjField}
                  value={obj.message}
                  field="message"
                />
              </Box> */}
            </React.Fragment>
          )}
          <Box>
            {isMessageAudio ? (
              <IconButton color="primary" onClick={submitAudio}>
                <Send />
              </IconButton>
            ) : (
              <IconButton type="submit" disabled={disable} color="primary">
                <Send />
              </IconButton>
            )}
          </Box>
        </Box>
      </form>
      <Box>
        <ChatMessageVoiceRecorder
          onSubmit={handleSubmitVoice}
          setIsRecording={setIsRecording}
          onDelete={handleDelete}
          url={url}
          setUrl={setUrl}
        />
      </Box>
    </Box>
  );
};

export { FormChat };
