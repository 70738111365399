import { StudentDto } from "dto/user/student.dto";
import { TeacherDto } from "dto/user/teacher.dto";
import Idto from "interfaces/idto.interface";

import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { FileDto } from "dto/system/file.dto";
import UserDto from "dto/user/user.dto";
import { ChatDto } from "./chat.dto";
import DateTools from "tools/utils/date.tools";

export class ChatMessageDto implements Idto {
  id?: string;
  status?: number;
  type?: number;
  idstudent?: string;
  idteacher?: string;
  iduser?: string;
  date?: number;
  idchat?: string;
  message?: string;
  idfile?: string;
  files?: Blob | null

  student?: StudentDto;
  teacher?: TeacherDto;
  user?: UserDto;
  chat?: ChatDto;
  file?: FileDto;

  constructor(
    id?: string,
    status?: number,
    type?: number,
    idstudent?: string,
    idteacher?: string,
    iduser?: string,
    date?: number,
    idchat?: string,
    message?: string,
    idfile?: string
  ) {
    this.id = id || "";
    this.status = status || Status.UNREAD;
    this.type = type || Types.TYPE_CHAT_MESSAGE_TEXT;
    this.idstudent = idstudent || "";
    this.idteacher = idteacher || "";
    this.iduser = iduser || "";
    this.date = date || DateTools.getTimeStampServer();
    this.idchat = idchat || "";
    this.message = message || "";
    this.idfile = idfile || "";
    this.files = null;
  }

  static prepareObjectTeacher(
    idTeacher: string,
    idChat: string,
    idUser: string
  ) {
    return new ChatMessageDto(
      "",
      Status.UNREAD,
      Types.TYPE_CHAT_MESSAGE_TEXT,
      "",
      idTeacher,
      idUser,
      undefined,
      idChat,
      "",
      ""
    );
  }

  static prepareObjectStudent(
    idStudent: string,
    idChat: string,
    idUser: string
  ) {
    return new ChatMessageDto(
      "",
      Status.UNREAD,
      Types.TYPE_CHAT_MESSAGE_TEXT,
      idStudent,
      "",
      idUser,
      undefined,
      idChat,
      "",
      ""
    );
  }

  
}

// export class ChatMessageGroupDto implements Idto {
//   iduser: string;
//   messages: ChatMessageDto[];
//   isGroup: boolean;

//   constructor(iduser?: string, messages?: ChatMessageDto[]) {
//     this.iduser = iduser || "";
//     this.messages = messages || [];
//     this.isGroup = false;
//   }

//   static parseList(list: ChatMessageDto[]): ChatMessageGroupDto[] {
//     const result: ChatMessageGroupDto[] = [];

//     for (const item of list) {
//       const idUser = item.iduser;

//       const index = list.indexOf(item);

//       if (index === 0) {
//         const group = new ChatMessageGroupDto(idUser, [item]);
//         result.push(group);
//       } else {
//         const lastGroup = result[result.length - 1];
//         if (lastGroup.iduser === idUser) {
//           lastGroup.messages.push(item);
//           lastGroup.isGroup = true;
//         } else {
//           const group = new ChatMessageGroupDto(idUser, [item]);
//           result.push(group);
//         }
//       }
//     }

//     return result;
//   }
// }

// export class ChatMessageGroupByDateDto implements Idto {
//   date: number;
//   objects: ChatMessageGroupDto[];

//   constructor(date?: number, objects?: ChatMessageGroupDto[]) {
//     this.date = date || 0;
//     this.objects = objects || [];
//   }

//   static parseList(list: ChatMessageDto[]): ChatMessageGroupByDateDto[] {
//     const result: ChatMessageGroupByDateDto[] = [];

//     for (const item of list) {
//       const date = item.date;
//       const index = list.indexOf(item);

//       if (index === 0) {
//         const group = new ChatMessageGroupByDateDto(date, [
//           new ChatMessageGroupDto(item.iduser, [item]),
//         ]);
//         result.push(group);
//       } else {
//         const lastGroup = result[result.length - 1];
//         if (lastGroup.date === date) {
//           const lastGroupObject =
//             lastGroup.objects[lastGroup.objects.length - 1];
//           if (lastGroupObject.iduser === item.iduser) {
//             lastGroupObject.messages.push(item);
//             lastGroupObject.isGroup = true;
//           } else {
//             const group = new ChatMessageGroupDto(item.iduser, [item]);
//             lastGroup.objects.push(group);
//           }
//         } else {
//           const group = new ChatMessageGroupByDateDto(date, [
//             new ChatMessageGroupDto(item.iduser, [item]),
//           ]);
//           result.push(group);
//         }
//       }
//     }

//     return result;
//   }
// }
// // for (const item of list) {
// //   const idUser = item.iduser;
// //   const index = list.indexOf(item);

// //   if (index === 0) {
// //     const group = new ChatMessageGroupDto(idUser, [item]);
// //     result.push(group);
// //   } else {
// //     const lastGroup = result[result.length - 1];
// //     if (lastGroup.iduser === idUser) {
// //       lastGroup.messages.push(item);
// //       lastGroup.isGroup = true;
// //     } else {
// //       const group = new ChatMessageGroupDto(idUser, [item]);
// //       result.push(group);
// //     }
// //   }
// // }

export class ChatMessageGroupDto implements Idto {
  iduser: string;
  messages: ChatMessageDto[];
  isGroup: boolean;

  constructor(iduser?: string, messages?: ChatMessageDto[]) {
    this.iduser = iduser || "";
    this.messages = messages || [];
    this.isGroup = false;
  }

  static parseList(list: ChatMessageDto[]): ChatMessageGroupDto[] {
    const result: ChatMessageGroupDto[] = [];

    for (const item of list) {
      const idUser = item.iduser;

      if (result.length === 0 || result[result.length - 1].iduser !== idUser) {
        const group = new ChatMessageGroupDto(idUser, [item]);
        result.push(group);
      } else {
        const lastGroup = result[result.length - 1];
        lastGroup.messages.push(item);
        lastGroup.isGroup = true;
      }
    }

    return result;
  }
}

export class ChatMessageGroupByDateDto implements Idto {
  date: number; // Date stored as a timestamp (e.g., UNIX time)
  objects: ChatMessageGroupDto[];

  constructor(date?: number, objects?: ChatMessageGroupDto[]) {
    this.date = date || 0;
    this.objects = objects || [];
  }

  static parseList(list: ChatMessageDto[]): ChatMessageGroupByDateDto[] {
    const result: ChatMessageGroupByDateDto[] = [];

    // Group messages by date (only day precision)
    const groupedByDate: { [key: string]: ChatMessageDto[] } = {};
    for (const item of list) {
      const dateKey = DateTools.convertTimestampToDate(item.date)
        .toISOString()
        .split("T")[0]; // Extract YYYY-MM-DD
      if (!groupedByDate[dateKey]) {
        groupedByDate[dateKey] = [];
      }
      groupedByDate[dateKey].push(item);
    }

    // Process each date group
    for (const [dateKey, messages] of Object.entries(groupedByDate)) {
      const date = Math.round(new Date(dateKey).getTime() / 1000); // Convert dateKey back to timestamp

      // Group messages by user within the same date
      const chatGroups = ChatMessageGroupDto.parseList(messages);
      result.push(new ChatMessageGroupByDateDto(date, chatGroups));
    }

    return result;
  }
}
