import DateTools from "tools/utils/date.tools";
import { Status } from "tools/types/status";
import { LabelTypes } from "tools/types/labeltypes";
import { Types } from "tools/types/types";
import IGeneralRepository from "interfaces/newirepository.interface";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Idto from "interfaces/idto.interface";


interface PopulateObject {
  key: string;
  type: string;
}

export default abstract class GlobalGeneralService<
  T extends IGeneralRepository
> {
  protected repository: T;
  protected sortField: string;
  public populateKeys: PopulateObject[];
  protected asc: boolean

  constructor(repository: T, keys?: PopulateObject[], sortField?: string,
    asc?: boolean
  ) {
    this.repository = repository;
    this.populateKeys = keys ?? [];
    this.sortField = sortField ?? "id";
    this.asc = asc ?? true;
  }

  static _LL: any = false;

  static setLL(_l: any): any {
    this._LL = _l;
  }
  public static LL = (key: any) => {
    return this._LL(key);
  };

  populateObject = (obj: any, data: any, keys: PopulateObject[]) => {
    const typeConversionMapping = {
      timestamp: (value: number) =>
        DateTools.convertTimestampToDate(value).toLocaleDateString("en-GB"),
      date: (value: any) =>
        DateTools.convertToDate(value).toLocaleDateString("en-GB"),
      status: (value: number) => Status.LL(value),
      teacherStatus: (value: number) => Status.LL(value),
      typeLabel: (value: number) => LabelTypes.LL(value),
      types: (value: number) => Types.LL(value),
      section: (value: number) => Types.LL(value),
      faqs: (value: number) => Types.LL(value),
      blogs: (value: number) => Types.LL(value),
      questions: (value: number) => Types.LL(value),
      typeowner: (value: number) => Types.LL(value),
      transactiontype: (value: number) => Types.LL(value),
      answers: (value: number) => Types.LL(value),
      advertisementsection: (value: number) => Types.LL(value),
      advertisementtype: (value: number) => Types.LL(value),
      filtertype: (value: number) => Types.LL(value),
      booleans: (value: boolean) =>
        value
          ? GlobalGeneralService.LL("True")
          : GlobalGeneralService.LL("False"),
    };

    const keysMap = new Map(keys.map((k) => [k.key, k]));

    for (var key in data) {
      let foundKeyObj = keysMap.get(key);

      if (foundKeyObj && foundKeyObj.type in typeConversionMapping) {
        const tmpKey = key + "_name";
        obj[tmpKey] = typeConversionMapping[
          foundKeyObj.type as keyof typeof typeConversionMapping
        ](data[key]);
      }
    }
  };

  parseResultPopulate = (
    result: any,
    rez: any,
    keysAndTypes: PopulateObject[]
  ) => {
    if (result.objects && rez.objects) {
      for (let index = 0; index < result.objects.length; index++) {
        const obj = result.objects[index];
        if (rez.objects[index]) {
          this.populateObject(rez.objects[index], obj, keysAndTypes);
        }
      }
    }
  };

  handleGet = (result?: any, cbparameters?: any, data?: any): any => {
    if(!result) return;
    let rez = new ResultObjectDTO();

    rez = result;
    if (result.err) {
      this.callbackError(cbparameters);
      return;
    }

    if (this.populateKeys.length > 0)
      this.populateObject(rez.obj, result.obj, this.populateKeys);

    this.callbackSuccess(rez, cbparameters, data);
    // logger("result", rez);
    return rez;
  };

  callbackError = (cbparameters?: any): any => {
    if (cbparameters && cbparameters._cb) {
      if (cbparameters.hasOwnProperty("dispatch") && cbparameters.dispatch) {
        cbparameters._cb(cbparameters.dispatch)(undefined, cbparameters);
      } else {
        cbparameters._cb(undefined, cbparameters);
      }
    }
  };

  callbackSuccess = (rez: any, cbparameters?: any, data?: any): any => {
    if (cbparameters && cbparameters._cb) {
      if (cbparameters.hasOwnProperty("dispatch") && cbparameters.dispatch) {
        cbparameters._cb(cbparameters.dispatch)(rez, cbparameters, data);
      } else {
        cbparameters._cb(rez, cbparameters, data);
      }
    }
  };

  handleGetList = (result?: any, cbparameters?: any, data?: any): any => {
    if(!result) return;
    let rez = new ResultListDTO();
    rez = result;
    if (result.err) {
      this.callbackError(cbparameters);
      return;
    }
    if (this.populateKeys.length > 0)
      this.parseResultPopulate(result, rez, this.populateKeys);

    this.callbackSuccess(rez, cbparameters, data);
    return rez;
  };

  get = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    idLanguage?: string
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    if (!idLanguage) idLanguage = "";
    const t = await this.repository.get(
      id,
      this.handleGet,
      cbparameters,
      idLanguage
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  add = async (cb?: any, cbparameters?: any, data?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    
    const t = await this.repository.add(this.handleGet, cbparameters, data);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  update = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.update(
      id,
      this.handleGet,
      data,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  delete = async (id: string, cb?: any, cbparameters?: any): Promise<any> => {
    
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.delete(id, this.handleGet, cbparameters);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getList = async (
    cb?: any,
    cbparameters?: any,
    reqList?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getList(
      this.handleGetList,
      cbparameters,
      reqList
    );

    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  addMultipart = async (
    cb?: any,
    cbparameters?: any,
    data?: Idto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.addMultipart(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  updateMultipart = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: Idto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.updateMultipart(
      id,
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      let sobj = new RequestSortCriteriaDTO();
      sobj.asc = this.asc;
      sobj.field = this.sortField;

      obj.sortcriteria = [sobj];
    }

    return obj;
  }

  populateDto = (obj: any) => {
    const newObj = { ...obj };
    this.populateObject(newObj, obj, this.populateKeys);
    return newObj;
  };

  populateDtoList = (obj: any[]):any[] => {
    const newObj = [...obj];
    for (let index = 0; index < obj.length; index++) {
      newObj[index] = this.populateDto(obj[index]);
    }
    return newObj;
  };
}
