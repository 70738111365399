import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { InformationObjectWasChanged } from "components/elements/generalcomponents/InformationObjectWasChanged";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import { StudentNotificationDto } from "dto/user/student.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: StudentNotificationDto;
  onSubmit: (obj: StudentNotificationDto) => void;
  loading: boolean;
};

const FormStudentNotification: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField,,,,,objectWasChanged] = useForm<StudentNotificationDto>(
    defaultObject,
    RequiredValidator.getValidators(["iduser"])
  );
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <InformationObjectWasChanged objectWasChanged={objectWasChanged} />
      <form onSubmit={handleSubmit}>
        <Box className="generalInfoBox">
          <Box className="generalInfoGrid">
            <Box className="likeCard" mt={3}>
              <MyCheckboxField
                label={LL("notificationtype1")}
                checked={obj.notificationtype1}
                setObjectField={setObjField}
                field="notificationtype1"
                color="primary"
                size="small"
              />
            </Box>
            <Box className="likeCard" mt={3}>
              <MyCheckboxField
                label={LL("notificationtype2")}
                checked={obj.notificationtype2}
                setObjectField={setObjField}
                field="notificationtype2"
                color="primary"
                size="small"
              />
            </Box>
            <Box className="likeCard" mt={3}>
              <MyCheckboxField
                label={LL("notificationtype3")}
                checked={obj.notificationtype3}
                setObjectField={setObjField}
                field="notificationtype3"
                color="primary"
                size="small"
              />
            </Box>
            <Box textAlign="center" mt={4}>
              <MyButton
                disabled={disabled || loading}
                color="primary"
                className="btn"
                variant="contained"
                size="large"
                type="submit">
                {LL("Bt_Submit")}
              </MyButton>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { FormStudentNotification };
