import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";
import IRepository from "interfaces/irepository.interface";
import Idto from "interfaces/idto.interface";

export class TeacherFAQResponseRatingRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("teacherfaqresponserating");
  }

  getRating = async (
    id: string,
    idUser: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    const url = `${this.mainUrl}/rating/${id}?iduser=${idUser}`;
    return await this._get(url, cb, cbparameters);
  };

  like = async (cb?: any, cbparameters?: any, data?: Idto): Promise<any> => {
    const url = `${this.mainUrl}/like`;
    return await this.postAxios(url, cb, data, cbparameters);
  };

  dislike = async (cb?: any, cbparameters?: any, data?: Idto): Promise<any> => {
    const url = `${this.mainUrl}/dislike`;
    return await this.postAxios(url, cb, data, cbparameters);
  };
}
