import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import ResultListDTO from "dto/app/resultlist.dto";

import { CourseDto } from "dto/course/course.dto";

import { useResource } from "hooks/useResource";

import React, { useReducer, useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { CourseService } from "services/course/course.service";
import { Status } from "tools/types/status";
import { RouteTools } from "tools/utils/routetools";
interface CourseSearchState {
  objects: Array<CourseDto>;
  searchValue: string;
  total: number;
  totalPage: number;
  page: number;
  loading: boolean;
}

interface CourseSearchActions {
  setSearchValue: (searchValue: string) => void;
  setPage: (page: number) => void;
  getList: (mainUrl: string) => void;
  navToSearch: (searchValue: string) => void;
  processDefaultDataReload: (currentRoute: any) => void;
  searchFunction: (searchValue: string) => void;
}

export const CourseSearchContext = React.createContext({
  state: {} as CourseSearchState & { reload: boolean },
  actions: {} as CourseSearchActions,
});

interface ProviderProps {
  children: React.ReactNode;
}

interface Action {
  type: string;
  payload?: any;
}

const reducer = (state: CourseSearchState, action: Action) => {
  switch (action.type) {
    case "set_page": {
      return {
        ...state,
        page: action.payload,
      };
    }
    case "set_loading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "set_data_server": {
      return {
        ...state,
        objects: action.payload.data,
        total: action.payload.total,
        totalPage: action.payload.totalPage,
        loading: false,
      };
    }
    case "set_search_value": {
      return {
        ...state,
        searchValue: action.payload,
      };
    }
    case "search": {
      return {
        ...state,
        searchValue: action.payload,
        page: 1,
      };
    }
    case "reset_state": {
      return {
        ...state,
        objects: [],
        searchValue: "",
        total: 0,
        totalPage: 0,
        page: 1,
        loading: false,
      };
    }

    default:
      return state;
  }
};

const courseService = new CourseService();

export const CourseSearchProvider: React.FC<ProviderProps> = ({ children }) => {
  const { CC } = useResource();
  const [cookies] = useCookies();
  const [state, dispatch] = useReducer(reducer, {
    objects: [],
    searchValue: "",
    total: 0,
    totalPage: 0,
    page: 1,
    loading: false,
  });
  const [reload, setReload] = useState(true);

  const setSearchValue = (searchValue: string) => {
    dispatch({ type: "set_search_value", payload: searchValue });
  };

  const setPage = (page: number) => {
    dispatch({ type: "set_page", payload: page });
  };

  const getList = useCallback(
    (mainUrl: string) => {
      if (!mainUrl) return;
      if (reload) return;

      const req = new RequestListDTO();
      req.page = state.page;
      req.onpage = parseInt(CC("on_page_search_global_course", "10"));
      req.filters = [];
      let filter = new RequestFilterDTO();
      filter.field = "globalsearch";
      filter.values = [state.searchValue];
      req.filters.push(filter);
      filter = new RequestFilterDTO();
      filter.field = "status";
      filter.values = Status.DISPLAY_ON_SITE_COURSES;
      req.filters.push(filter);
      dispatch({ type: "set_loading" });

      const urlPart = RouteTools.prepareListLocation(req);
      RouteTools.setHistory(mainUrl + urlPart, {});
      // logger("getListSearchCourese", req);
      courseService.getListSearch(handleSetServerData, {}, req);
    },
    [state.page, state.searchValue, reload]
  );

  const handleSetServerData = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects: Array<CourseDto> = result.objects ?? [];
    const total: number = result.total ?? 0;
    const totalPage: number = result.totalpages ?? 0;

    dispatch({
      type: "set_data_server",
      payload: { data: objects, total, totalPage },
    });
  };

  const navToSearch = (searchValue: string) => {
    dispatch({ type: "search", payload: searchValue });
    RouteTools.setHistory("/coursesearch", {});
  };

  const search = (searchValue: string) => {
    dispatch({ type: "search", payload: searchValue });
  };

  const processDefaultDataReload = (currentRoute: any) => {
    if (!currentRoute) return;
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);

    if (reqListRoute.filters && reqListRoute.filters.length > 0) {
      const existingFilter = reqListRoute.filters.find(
        (filter) => filter.field === "globalsearch"
      );
      if (
        existingFilter &&
        existingFilter.values &&
        existingFilter.values.length > 0
      ) {
        setSearchValue(existingFilter.values[0]);
      }
    }
    setReload(false);
  };

  return (
    <CourseSearchContext.Provider
      value={{
        state: { ...state, reload },
        actions: {
          setSearchValue,
          setPage,
          getList,
          navToSearch,
          processDefaultDataReload,
          searchFunction: search,
        },
      }}
    >
      {children}
    </CourseSearchContext.Provider>
  );
};
